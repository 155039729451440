
<template>
    <div class="container">
      <UserNavbar/>
      <div class="row">
        <div class="layout-main">
          <div class="grid">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="border-container">
                  <h2>{{ $t('profile.Our Favourite Therapists') }}</h2>
                 </div>
                  <!-- rows is number of doctor will displayed in one page -->
                  <DataView :value="doctors" :layout="layout" :rows="6" >
                    <template #grid="doctor">
                      <div class=" grid grid-nogutter ">
                      <div class="card m-3 " v-for ="(item, index) in doctor.items" :key="index">
                        <div class="heart-icon" @click="addToFavourite(item.id)">
                          <i :class="{
                            'pi pi-fw pi-heart text-xl font-bold': !isFavorite[item.id],
                            'pi pi-fw pi-heart text-xl font-bold text-red': isFavorite[item.id]
                          }"></i>
                        </div>
                        <div class="card-body">
                          <div class="profile-info">
                            <div class="profile-image">
                              <img class="shadow-2" :src="'https://backend.ihayanow.com/' + item.profile.photo"
                                alt="Profile Image" width="100" height="100">
                            </div>
                            <div class="profile-details">
                              <h5 class="mb-1" v-if="this.$i18n.locale === 'ar'"><i
                                  class="pi pi-fw pi-user text-xl font-bold"></i>
                                {{ $t('Dr.') }} {{ item.profile.arabic_name }}</h5>
                              <h5 class="mb-1" v-else><i class="pi pi-fw pi-user text-xl font-bold"></i>
                                {{ $t('Dr.') }} {{ item.name }}</h5>
  
                              <!-- {{ item }} -->
                              <p class="mb-0 font-weight-bold">
                                {{ item.profile.professional == "Psychologist" ?
                                  $t("profile.Psychologist") : $t("profile.Psychastric") }}
                              </p>
                              <p class="mb-0 font-weight-bold">
                                {{ $t("profile.Specialist in treating") }} :
                                {{ this.$i18n.locale === 'ar' ? item.speciality[0] : item.speciality[1] }}
                              </p>
                              <div class="px-5 mt-2">
                                <PrimeRating class="text-center" :modelValue="item.profile.rating" :readonly="true"
                                  :cancel="false">
                                </PrimeRating>
                              </div>
                            </div>
  
  
  
  
  
                          </div>
  
                        </div>
                        <div class="card-body">
                          <div class="flex align-items-center justify-content-between pt-3"
                            v-if="displayOnlinePrice(item.profile)">
                            <span class="text-xl px-2 font-semibold" style="color: #6366F1;">
  
                              <i class="pi pi-fw pi-money-bill px-1 text-xl"></i>
                              {{ $t("Online Session Price") }} :
                              {{ item.profile.online_price }} {{ $t('Payments.EGP') }} / {{
                                item.profile.session_duration }}
                              {{ $t('profile.minute') }}</span>
  
  
                          </div>
                          <div class="flex align-items-center justify-content-between pt-3"
                            v-if="displayOnsitePrice(item.profile)">
                            <span class="text-xl px-2 font-semibold" style="color: #6366F1;">
                              <i class="pi pi-money-bill px-1 text-xl"></i>
                              {{ $t("Onsite Session Price") }} :
                              {{ item.profile.onsite_price }} {{ $t('Payments.EGP') }} / {{
                                item.profile.session_duration }}
                              {{ $t('profile.minute') }}</span>
  
  
                          </div>
                          <div class="flex align-items-center justify-content-between pt-3 mx-2">
  
                            <span class="text-xl font-semibold" style="color: #6366F1;" v-if="this.$i18n.locale === 'ar'">
                              <i class="p-menuitem-icon pi pi-fw pi-cog"></i>
                              {{ (item.profile.session_type == "كليهما" ||
                                item.profile.session_type == "أونلاين و أوفلاين" || item.profile.session_type ==
                                "Both")
                                ? $t('profile.Online & Onsite Sessions') :
                                $t('profile.Sessions') + ' ' + $t('profile.' + item.profile.session_type) }}
  
                            </span>
  
                            <span class="text-xl font-semibold" style="color: #6366F1;"
                              v-else-if="this.$i18n.locale === 'en'">
                              <i class="p-menuitem-icon pi pi-fw pi-cog"></i>
                              {{ (item.profile.session_type == "Both" || item.profile.session_type == "كليهما"
                                || item.profile.session_type == "أونلاين و أوفلاين")
                                ? $t('profile.Online & Onsite Sessions') :
                                $t('profile.' + item.profile.session_type) + ' ' + $t('profile.Sessions') }}
  
                            </span>
  
                          </div>
                          <div class="flex align-items-center justify-content-between pt-3 mx-2">
  
  
                            <p for="example-text-input" class="text-xl font-semibold" style="color: #6366F1;"
                              v-if="this.$i18n.locale === 'ar'">
                              <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>
                              {{
                                item.profile.address }} - {{ city_name.arabic_name }} - {{ state_name.arabic_name }} -
                              {{
                                country_name.arabic_name }}
                            </p>
                            <p for="example-text-input" class="text-xl font-semibold" style="color: #6366F1;" v-else>{{
                              item.profile.address }} -
                              {{ city_name.english_name }} - {{ state_name.english_name }} - {{ country_name.english_name }}
                            </p>
                          </div>
  
                          <div class="flex align-items-center justify-content-between pt-3 mx-2"
                            v-if="getDay(item.calendars) != '' || getTime(item.calendars) != ''">
                            <p for="example-text-input" class="font-semibold" style="color: green;">
                              {{ $t("The nearest appointmrnt on : ") }} {{ getDay(item.calendars) }} {{ $t("at") }}
                              {{
                                getTime(item.calendars) }}
                            </p>
                          </div>
                          <div class="flex align-items-center justify-content-between pt-3 mx-2">
                          <p for="example-text-input" class="font-bold" style="color: green;">
                            {{ $t("Number of reservations : ") }} {{ item.profile.session_counter !== null ?
                              item.profile.session_counter : 0 }} {{ $t("sessions") }}
                          </p>
                        </div>
                          <div class="flex align-items-center justify-content-between pt-3 mx-3 my-3">
                            <PrimeButton @click="goToProfile(item.profile.id)">{{ $t('profile.View Profile') }}
                            </PrimeButton>
                            <PrimeButton @click="goToBook(item.profile.id)"
                              :disabled="item.profile.status === 0" v-if="this.Book == 'User'">
                              {{ $t('profile.Book Now') }}
                            </PrimeButton>
                          </div>
  
                        </div>
  
                      </div>
  </div>
                    </template>
                  </DataView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import UserNavbar from './UserNavbar.vue';
  import Footer from "../Footer.vue";
  import moment from 'moment';
  
  export default {
    name: "therapists-page",
    data() {
      return {
        isFavorite: {},
        name: "",
        doctors: [],
        profile_id: "",
        rating: 0,
        layout: 'grid',
        keyword: '',
        session: '',
        navbarComponent: null,
        Book: null,
        showDoctors: true, // Flag to control visibility of doctors list
        searchResults: [], // New data property to hold search results
        filterResults: [],
        session_type: '',
        session_duration: '',
  
        maxPriceOnline: '',
        minPriceOnline: '',
        maxPriceOnsite: '',
        minPriceOnsite: '',
        address: '',
        specialties: '',
        language: '',
        country: {
          id: null,
          name: "",
          translationKey: "",
        },
        state: {
          id: null,
          name: "",
          translationKey: "",
        },
        city: {
          id: null,
          name: "",
          translationKey: "",
        },
        countries: [],
        cities: [],
        states: [],
  
        country_name: '',
        state_name: '',
        city_name: '',
        calendars: [],
        date: '',
        from: '',
        speciality_arabic_name: '',
        speciality_english_name: '',
        specialities: [],
        apiKey : process.env.VUE_APP_API_KEY,
       apiSecret : process.env.VUE_APP_API_SECRET,
      };
    },
  
    async mounted() {
      const userType = localStorage.getItem("type");
    this.Book = userType;
         await this.fetchFavoriteDoctors();
  
    },
    methods: {

    
    getTime(time) {
        if (time.length > 0) {
          const formattedTime = moment(time[0].from, 'HH:mm:ss').format('h:mm A');
          const withoutAmPmIndicator = moment(time[0].from, 'HH:mm:ss').format('h:mm');
  
          // const filterTime = formattedTime.format('h:mm A');
  
          if (formattedTime.includes('AM')) {
            return withoutAmPmIndicator + ' ' + this.$t("am");
          } else if (formattedTime.includes('PM')) {
            return withoutAmPmIndicator + ' ' + this.$t("pm");
          }
          else {
            return formattedTime;
          }
        } else {
          return '';
        }
        // const formattedTimes = time.map(t => moment(t.from, 'HH:mm:ss').format('h:mm A'));
        // return formattedTimes.join(', '); // Join formatted times with a separator
      },
      getDay(date) {
        if (date.length > 0) {
          const firstCalendar = date[0];
          const day = firstCalendar.date; // Assuming the date property holds the date string
  
          const dayName = moment(day).format('dddd');
          const today = moment().format('dddd');
          const tomorrow = moment().add(1, 'days').format('dddd');
          if (dayName === today) {
            return this.$t("Today");
          } else if (dayName === tomorrow) {
            return this.$t("Tomorrow");
          } else {
            return this.$t(dayName);
  
          }
        } else {
          return '';
        }
  
      },
  
      async addToFavourite(id) {
        const isCurrentlyFavorite = this.isFavorite[id];
        // console.log(action);
        const action = isCurrentlyFavorite ? 'remove' : 'add';
        console.log(action);
  
  
        if (action === 'add') {
          axios.post('https://backend.ihayanow.com/api/user/favorites', {
            doctor_id: id // Pass the id directly
          }, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
              'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret
            },
          })
            .then(response => {
              this.success = true;
              this.message = response.data.message;
  
              console.log(response);
              window.location.reload();
  
            })
            .catch(error => {
              console.log(error);
              this.errors = error.response.data.message;
            });
        } else if (action === 'remove') {
          // console.log("hi");
          try {
            const response = await axios.post('https://backend.ihayanow.com/api/user/delete-favorites',
              {
                doctor_id: id
              },
              {
              headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret
            }
            });
          console.log(response.status);
          if (response.status == 200) {
            this.errorMessage = 'Doctor deleted successfully from favourite list.';
            window.location.reload();
  
          } else {
            console.log(response)
            this.errorMessage = 'Failed to delete doctor from favourite list.';
          }
        } catch (error) {
          console.error('Error deleting doctor from favourite list:', error);
          this.errorMessage = 'Failed to delete doctor from favourite list.';
        }
      }
  
  
  
      // You can add additional logic here to handle adding/removing from favorites list.
    },
  
    async fetchFavoriteDoctors() {
     
      try {
        const response = await axios.get('https://backend.ihayanow.com/api/user/show-favourite', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret
          },
        });
  
     
        this.doctors = response.data;
        this.calendars = this.doctors.calendars;
        this.specialities = this.doctors;

        console.log(this.doctors);
        this.doctors.forEach(doctor => {
          this.country_name = doctor.country_name;
          this.state_name = doctor.state_name;
          this.city_name = doctor.city_name;
        });
        this.specialities.forEach(doctor => {
          this.speciality_arabic_name = doctor.speciality[0];
          this.speciality_english_name = doctor.speciality[1];
        });

        const favoriteDoctorIds = response.data.map(doctor => doctor.profile.user_id);
      console.log(favoriteDoctorIds);
      this.doctors.forEach(doctor => {
        this.isFavorite[doctor.id] = favoriteDoctorIds.includes(doctor.id);
      });
      } catch (error) {
        console.log('Error fetching Favourite Doctors:', error);
      }
    },
  
    goToProfile(profileId) {
      this.$router.push({ name: 'TherapistProfile', params: { id: profileId } })
    },
    goToBook(profileId) {
      this.$router.push({ name: 'Calendar', params: { id: profileId } })
    },
    displayOnlinePrice(doctor) {
      if (this.$i18n.locale === 'ar') {
  
        return doctor.session_type == 'Online' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اونلاين' || doctor.session_type == 'أونلاين و أوفلاين';
  
      }
      else {
        return doctor.session_type == 'Online' || doctor.session_type === 'Online & Onsite' || doctor.session_type == 'أونلاين و أوفلاين';
  
      }
    },
    displayOnsitePrice(doctor) {
      if (this.$i18n.locale === 'ar') {
        return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';
  
      }
      else {
        return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';
  
      }
    },
  
  },
//  computed:{
//   pageinator() {
//       return this.$i18n.locale === 'ar' ? 'ltr' : 'auto';
//     },
//  },
  components: {
    UserNavbar,
      Footer,
    }
  
  
  };
  </script>
  
  <style scoped>
  .border-container {
    width: 40%;
    margin: 0 auto;
    text-align: center; /* If you want the text centered within the container */
    padding-bottom: 20px;

  }

.border-container h2 {
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 20px;
}
  .row{
    margin-top: 1rem;
  }
  body {
    background: #F4F7FD;
    margin-top: 20px;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 5px;
  }
  
  .profile-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .p-dropdown {
    width: 20rem;
  }
  
  .card {
    position: relative;
  }
  
  .heart-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 5px;
    z-index: 1;
  }
  
  .text-red {
    color: red;
  }
  
  .card-margin {
    margin-bottom: 1.875rem;
  }
  </style>
  