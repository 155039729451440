<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
    <!-- <AdminNavbar /> -->
    <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <h2>Create Promocode</h2>
              <!-- <InlineMessage class="w-full mt-3" v-show="errors">
                           {{ error }}

                        </InlineMessage> -->
                        <PrimeMessage severity="success" v-if="this.message">{{ this.message }}
                    </PrimeMessage>
                    <PrimeMessage severity="error" v-if="this.numberError">{{ this.numberError }}
                    </PrimeMessage>
              <form @submit.prevent="createPromocode">

                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">From</label>
                    <InputText type="datetime-local" id="from" v-model="from"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.from"
                           v-for="(error, index) in errors.from" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="birth_day" style="font-weight: bold;">To</label>
                    <InputText type="datetime-local" id="to" v-model="to"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.to"
                           v-for="(error, index) in errors.to" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                </div>
                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">Promo Code</label>
                    <InputText type="text" id="code" v-model="code"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.code"
                           v-for="(error, index) in errors.code" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <!-- <div class="field col-12 md:col-6">
                    <label for="birth_day" style="font-weight: bold;">Type</label>
                    <InputText type="text" id="type" v-model="type"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                           v-for="(error, index) in errors.type" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div> -->

                  <div class="field col-12 md:col-6">
                      <label for="type" style="font-weight: bold;">For Which Type</label>
                      <PrimeDropdown id="type" class="w-full mb-3" v-model="type" :options="types"
                            optionLabel="name" placeholder="Select One"
                            style="  border: 2px; border-radius: 4px;">
                          </PrimeDropdown>                      
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                             v-for="(error, index) in errors.type" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                </div>
                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">Multible</label>
                    <InputText type="number" id="multiple" v-model="multiple"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.multiple"
                           v-for="(error, index) in errors.multiple" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">Maximum Number of Uses </label>
                    <InputText type="number" id="max_number_of_uses" v-model="max_number_of_uses"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.max_number_of_uses"
                           v-for="(error, index) in errors.max_number_of_uses" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <!-- <div class="field col-12 md:col-6">
                    <label for="birth_day" style="font-weight: bold;">Number of Uses</label>
                    <InputText type="number" id="number_of_uses" v-model="number_of_uses"  />
                  </div> -->
                </div>
                <div class="grid p-fluid mt-3">
                 
                  <div class="field col-12 md:col-6">
                    <label for="birth_day" style="font-weight: bold;">Allowed Number of Uses For One User</label>
                    <InputText type="number" id="allowed_number_of_uses" v-model="allowed_number_of_uses"
                       />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.allowed_number_of_uses"
                           v-for="(error, index) in errors.allowed_number_of_uses" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">Amount</label>
                    <InputText type="number" id="amount" v-model="amount"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.amount"
                           v-for="(error, index) in errors.amount" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                </div>
                <div class="grid p-fluid mt-3">
                  
                  <div class="field col-12 md:col-6" v-show="shouldShowDiv">
                    <label for="birth_day" style="font-weight: bold;">Users Above Sessions?</label>
                    <InputText type="number" id="above" v-model="above"  />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.above"
                           v-for="(error, index) in errors.above" :key="index">
                           {{ error }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="age" style="font-weight: bold;">Percentage</label>
                    <InputText type="number" id="percentage" v-model="percentage"  step="0.01" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.percentage"
                           v-for="(error, index) in errors.percentage" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                </div>
                <div class="row edit-button text-center">
                <PrimeButton type="submit">Create Promocode</PrimeButton>
</div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// import AdminNavbar from './AdminNavbar.vue';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      doctors: [],
      pendings: [],
      types:[
        { name: 'Doctors' },
        { name: 'Users' },
        {name:'New Users'}
      ],
      numberError:'',
      message:'',
      errors:{},
      from: '',
      to: '',
      code: '',
      type: '',
      multiple: '',
      // number_of_uses: '',
      max_number_of_uses: '',
      allowed_number_of_uses: '',
      amount: '',
      above: '',
      percentage: '',
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },

        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
    };
  },

  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    async createPromocode() {

      try {
       await axios.post('https://backend.ihayanow.com/api/admin/promocodes', {
            from: this.from,
            to: this.to,
            code: this.code,
            type: this.type.name,
            multiple: this.multiple,
            max_number_of_uses: this.max_number_of_uses,
            allowed_number_of_uses: this.allowed_number_of_uses,
            amount: this.amount,
            above: this.above,
            percentage: this.percentage,
          },
     
       {
          headers: {
            'Content-Type': 'application/json',
            Authorization:`Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,

          },
         
        }).then(response =>{
           if(response.data.message == "Allowed number of uses must be less than max number of uses"){
                     this.numberError= response.data.message ;
           }else{
            this.message=response.data.message;      

           }        
        // console.log(this.error)

console.log(this.message,this.numberError)
        }).catch(error =>{
          this.errors=error.response.data.errors;

          console.log(this.errors);
        })
     
        // this.$router.push('/promocode-page')
        
      
      } catch (error) {
        this.errors = error.response.data.errors;

        console.error(this.errors)
      }
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },

  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },


  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    
    shouldShowDiv() {
      // console.log(this.type);
        return this.type.name == 'Users' || this.type.name == 'New Users';        
        },
  }

}

</script>


<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }
</style>