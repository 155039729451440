
<template>
  <div class="container">
    <UserNavbar />


        <div class="card shadow-lg mt-n6 mt-5">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto my-auto">
                <div class="profile-info">

                  <div class="profile-image" v-if="profile.photo !== null">

                    <img :src="'https://backend.ihayanow.com/' + profile.photo" alt="Profile Image">
                  </div>
                  <div class="profile-details px-4">
                    <h5 class="mb-1">{{ $t('profile.name') }} : {{ displayName }}</h5>

                    <p class="mb-0 font-weight-bold">
                      {{ $t("profile.language") }}: {{ $t(`${profile.language}`) }}
                    </p>
                  </div>


                </div>

                <div class="row edit-button text-left">
                  <PrimeButton @click="$router.push({ name: 'edit-user-profile', params: { profile } })">{{
                    $t("profile.edit_profile") }}</PrimeButton>
                </div>
              </div>

            </div>
          </div>
        </div>


      <div class="card py-4">
        <h5>{{ $t("profile.yourinfo") }}</h5>
        <div class="grid p-fluid mt-3">

          <div class="field col-12 md:col-6" v-if="displayName == this.name">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("profile.Arabic Name") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.arabic_name }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6" v-else-if="displayName == this.profile.arabic_name">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("profile.name") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ name }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon  pi pi-fw pi-user"></i> {{ $t("profile.email_address") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ email }}</p>
            </span>
          </div>

          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-clock"></i>{{ $t("profile.age") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.age }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-calendar"></i>{{ $t("profile.birthdate") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.birth_date }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>{{ $t("profile.address") }}</span>
              <p for="example-text-input" class="form-control-label" v-if="this.$i18n.locale === 'ar'">{{ profile.address
              }} - {{ city_name.arabic_name }} - {{ state_name.arabic_name }} - {{ country_name.arabic_name }}</p>
              <p for="example-text-input" class="form-control-label" v-else>{{ profile.address }} - {{
                city_name.english_name }} - {{ state_name.english_name }} - {{ country_name.english_name }}</p>

            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon  pi pi-fw pi-user"></i>
                {{ $t("profile.gender") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.gender == 'Male' ? $t('profile.male')
                : $t('profile.female') }}</p>
            </span>
          </div>


        </div>
        <hr class="horizontal dark" />
        <h5>{{ $t("profile.Additional Information") }}</h5>
        <div class="grid p-fluid mt-3">

          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-arrows-v"></i>{{ $t("profile.Weight") }}</span>
              <p for="example-text-input" class="form-control-label"> {{ profile.weight }} {{ $t('Kg') }}</p>

            </span>
          </div>
          <div class="field col-12 md:col-6">

            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-arrows-v"></i> {{ $t("profile.Height") }}</span>
              <p for="example-text-input" class="form-control-label"> {{ profile.height }} {{ $t('cm') }}</p>
            </span>

          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;"><i
                  class="p-menuitem-icon pi pi-fw pi-check-circle"></i>{{ $t('profile.Smoker') }}</span>
              <p for="example-text-input" class="form-control-label"> {{ profile.smoker == "1" ? $t("Yes") : $t("No") }}
              </p>

            </span>
          </div>
        </div>



      </div>
    <Footer />
  </div>
</template>


<script>
import router from '@/router';
import UserNavbar from './UserNavbar.vue';
import Footer from '../Footer.vue';
import axios from "axios";
export default {

  data() {
    return {

      name: '',
      email: '',
      profile: [],
      photo: '',
      imageUrl: '',
      country_name: '',
      state_name: '',
      city_name: '',
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    }
  },


  created() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
     
      await axios.get('https://backend.ihayanow.com/api/user/my-profile', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      }).then(response => {
        const data = response.data;
        this.name = data[0].name;
        this.email = data[0].email;
        this.profile = data[0].profile;
        this.country_name = data[0].country_name,
          this.state_name = data[0].state_name,
          this.city_name = data[0].city_name

console.log(data);
      })
        .catch(error => {
          this.errors = error.response.data.error;
          console.log(this.errors)
        });



    },

    goToEditProfile() {
      router.push('/edit-user-profile')
    },


  },

  computed: {
    displayName() {
      if (this.$i18n.locale === 'ar') {
        return this.profile.arabic_name;
      } else {
        return this.name;
      }
    },
  },
  components: {
    UserNavbar,
    Footer

  }
}

</script>
<style>
.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details h5 {
  margin-bottom: 5px;
}
</style>

