<template>
	<div class="layout-topbar">
		
		<router-link to="/" class="layout-topbar-logo">
			<!-- <img alt="Logo" :src="topbarImage()" /> -->
			<img :src="'mental_logo.png'" alt="IHAYANOW logo" style="width:70px; height:70px;">

			<span>IHAYANOW</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>

			
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>


		<!-- <ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button @click="goToProfile()" class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul> -->
	</div>
</template>

<script>
export default {


	

    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		// topbarImage() {
		// 	return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		// }

		// goToProfile(profileId) {
		// 	if(localStorage.getItem('type') == "Doctor"){
		// 		this.$router.push({ name: 'DoctorProfile', params: { id: profileId } })
		// 	}else{
		// 		this.$router.push({ name: 'UserProfile', params: { id: profileId } })

		// 	}
    // },

    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>