<template>
  <!-- <div class="container">
        <AdminNavbar /> -->
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <!-- <AdminNavbar /> -->
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="field col-12">
            <div class="card py-4">
              <h5>Add Category</h5>
              <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
              </PrimeMessage>
              <form @submit.prevent="submitForm">

                <div class="grid p-fluid mt-3">
                  <div class="field col-12 ">
                    <label for="author" style="font-weight: bold;">Name</label>
                    <InputText  placeholder="Category Name"  id="name" type="text" v-model="name" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.name"
                      v-for="(error, index) in errors.name" :key="index">
                      {{ error }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 ">
                    <label for="author" style="font-weight: bold;">English Name</label>
                    <InputText placeholder="Category English Name" id="english_name" type="text" v-model="english_name" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.english_name"
                      v-for="(error, index) in errors.english_name" :key="index">
                      {{ error }}
                    </InlineMessage>

                  </div>

                  <div class="field col-12">
                    <label for="description" style="font-weight: bold;">Description</label>
                    <PrimeTextarea placeholder="Category Description" :autoResize="true" rows="9" cols="70"
                      v-model="description" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                      v-for="(error, index) in errors.description" :key="index">
                      {{ error }}
                    </InlineMessage>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <div class="row edit-button text-center">
                  <PrimeButton type="submit">Create</PrimeButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
// import AdminNavbar from './AdminNavbar.vue';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
      name: '',
      english_name:'',
      success:false,
      description: "",
      errors: {},
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // <!-- { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' }, -->

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
    };
  },
  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    submitForm() {
      axios.post('https://backend.ihayanow.com/api/admin/categories', {
        name: this.name,
        english_name:this.english_name,
        description: this.description,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        },
      })
        .then(response => {
          const data = response.data;
          console.log(response);
          console.log(data.message);
          this.success = true;

          this.message = data.message;


          // this.$router.push('/knowledgebase-category-page');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
          console.log(error);
          console.log(error.response.data.errors);
            console.log(this.errors.description);
            console.log(this.errors['description']);

        });
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },

  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },


  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }
};
</script>
<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }
</style>