<template>
  <!-- <div class="container">
      <AdminNavbar /> -->
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <!-- <AdminNavbar /> -->
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="field col-12">
            <div class="card py-4">
              <h5> Create Knowledgebase</h5>
              <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
              </PrimeMessage>
              <form @submit.prevent="submitForm">
                <div class="field col-12">
                  <div class="avatar" :style="{ 'background-image': 'url(' + previewphoto + ')' }">
                    <InputText type="file" accept="image/*" hidden @change="uploadProfilePicture" id="avatar" />
                    <label for="avatar" class="avatar-label">Choose image</label>
                  
                  </div>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.photo"
                           v-for="(error, index) in errors.photo" :key="index">
                           {{ error }}
                        </InlineMessage>
                </div>
                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6">
                    <label for="category" style="font-weight: bold;">Category</label>
                    <PrimeDropdown id="state" v-model="category" :options="categories_ids" optionLabel="name" placeholder="Select One">
                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.category_id"
                           v-for="(error, index) in errors.category_id" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="author" style="font-weight: bold;">Author</label>
                    <InputText id="author" type="text" v-model="author" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.author"
                           v-for="(error, index) in errors.author" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="type" style="font-weight: bold;">Type</label>
                    <PrimeDropdown id="state" v-model="type" :options="types" optionLabel="name" placeholder="Select One">
                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                           v-for="(error, index) in errors.type" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="title" style="font-weight: bold;">Title</label>
                    <InputText id="title" type="text" v-model="title" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.title"
                           v-for="(error, index) in errors.title" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6" v-if="type && (type.name === 'Link' || type.name === 'Movie' || type.name === 'Youtube')">
                    <label for="link" style="font-weight: bold;">Link</label>
                    <InputText id="link" type="text" v-model="link" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.link"
                           v-for="(error, index) in errors.link" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6" v-if="type && (type.name === 'Link')">
                    <label for="reference" style="font-weight: bold;">Reference</label>
                    <InputText id="reference" type="text" v-model="reference" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.reference"
                           v-for="(error, index) in errors.reference" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                  <!-- <div class="field col-12 md:col-6" v-if="type && (type.name === 'Youtube' )">
                    <label for="knowfile" style="font-weight: bold;">Know File</label>
                    <InputText type="file" @change="handleKnowFileUpload" v-if="showKnowFileInput" />
                    <InlineMessage v-if="showInlineMessage && this.knowfile == '' && showKnowFileInput"
                      class="w-full mt-3">
                      Knowledge File is required
                    </InlineMessage>
                  </div> -->
                  <div class="field col-12" v-if="type && (type.name === 'Article' || type.name === 'Tip')">
                    <label for="body" style="font-weight: bold;">Body</label>
                    <PrimeTextarea placeholder="Your Message" :autoResize="true" rows="9" cols="70" v-model="body" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.body"
                           v-for="(error, index) in errors.body" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <div class="row edit-button text-center">
                  <PrimeButton type="submit">Create</PrimeButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
// import AdminNavbar from './AdminNavbar.vue';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
      success:false,
      author: '',
      title: '',
      type: "",
      link: "",
      previewphoto: "",
      reference: "",
      photo: "",
      knowfile: "",
      body: "",
      types: [
        { name: 'Article' },
        { name: 'Link' },
        { name: 'Tip' },
        { name: 'Movie' },
        { name: 'Youtube' },
      ],
      errors:{},
      categories_ids:[],
      showKnowFileInput: true,
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },

            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
    };
  },

  mounted(){
    axios.get('https://backend.ihayanow.com/api/admin/categories', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        },
      })
        .then(response => {
          console.log(response.data);
          this.categories_ids = response.data.map((category) => ({
          name: category.name,
           // Map the category_name to objects with 'name' property
           id:category.id
        }));
        this.message=response.data.message;

        console.log(this.categories_ids)
        })
        .catch(error => {
          console.log(error);
          console.log(error.response.data.message);
          this.errors = error.response.data.message;

        });
  },
  methods: {
    // handleKnowFileUpload(event) {
    //   const file = event.target.files[0];
    //   this.knowfile = file;
    // },
    uploadProfilePicture(event) {
      this.photo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.photo);
      reader.onload = () => {
        this.previewphoto = reader.result;
      }
    },
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    submitForm() {
      axios.post('https://backend.ihayanow.com/api/knowledgebases', {
        author: this.author,
        type: this.type.name,
        title: this.title,
        link: this.link,
        reference: this.reference,
        photo: this.photo,
        body: this.body,
        category_id: this.category == undefined ? '' : this.category.id
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        },
      })
        .then(response => {
          console.log(response);

          this.success=true;
          this.message=response.data.message;
        
          // console.log(this.message);
          // this.$router.push('/admin-knowledgebase-page');
        })
        .catch(error => {
          console.log(error);

          // .response.data.message
          this.errors=error.response.data.errors;
        });
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },

  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },


  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }
};
</script>
<style scoped>
.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
}

.avatar-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.avatar-upload {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.layout-topbar {   
   background-color: var(--surface-card);
}



</style>