<template>
    <div class="container">
        <DoctorNavbar />
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card py-4">
                            <h5>{{ $t('Appointments.Create Appointment') }}</h5>
                            <transition-group name="p-message" tag="div">
                                <PrimeMessage class="w-50" v-for="msg of message" :severity="msg.severity"
                                    :key="msg.content">
                                    {{ translateErrorMessage(msg.content) }}</PrimeMessage>
                            </transition-group>


                            <form @submit.prevent="createAppointment">

                                <div class="grid p-fluid mt-3">
                                    <div class="field col-12 md:col-6">
                                        <label for="date" style="font-weight: bold;">{{ $t('Appointments.Date') }}</label>
                                        <InputText type="date" id="date" v-model="form.date" />
                                        <div v-if="errors && errors.date">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.date"
                                                v-for="(error, index) in errors.date" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="from" style="font-weight: bold;">{{ $t('Sessions.From') }}</label>
                                        <InputText type="time" id="from" v-model="form.from" />
                                        <div v-if="errors && errors.from">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.from"
                                                v-for="(error, index) in errors.from" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="to" style="font-weight: bold;">{{ $t('Sessions.To') }}</label>
                                        <InputText type="time" id="to" v-model="form.to" />
                                        <div v-if="errors && errors.to">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.to"
                                                v-for="(error, index) in errors.to" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>

                                    <div class="field col-12 md:col-6">
                                        <label for="onsite" style="font-weight: bold;">{{ $t('Appointments.Onsite Session')}}</label>

                                        <PrimeDropdown id="state" class="w-full mb-3" v-model="form.onsite"
                                            :options="onsites" optionLabel="translationKey"
                                            :placeholder="$t('profile.Select_One')"
                                            style="  border: 2px; border-radius: 4px;">
                                        </PrimeDropdown>
                                        <div v-if="errors && errors.onsite">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.onsite"
                                                v-for="(error, index) in errors.onsite" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="type" style="font-weight: bold;">{{ $t('Appointments.Appointment Type')}}</label>
                                        <PrimeDropdown id="state" class="w-full mb-3" v-model="form.type" :options="types"
                                            optionLabel="translationKey" :placeholder="$t('profile.Select_One')"
                                            style="  border: 2px; border-radius: 4px;">
                                        </PrimeDropdown>
                                        <div v-if="errors && errors.type">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                                                v-for="(error, index) in errors.type" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <label for="number_of_attendees" style="font-weight: bold;">{{
                                            $t('Appointments.Number Of Attendance') }}</label>
                                        <InputText type="number" id="number_of_attendees"
                                            v-model="form.number_of_attendees" />
                                        <div v-if="errors && errors.number_of_attendees">
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.number_of_attendees"
                                                v-for="(error, index) in errors.number_of_attendees" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>

                                </div>
                                <div class="row edit-button text-center">
                                    <PrimeButton type="submit">{{ $t('Create') }}</PrimeButton>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />

    </div>
</template>
<script>
import axios from "axios";
import DoctorNavbar from './DoctorNavbar.vue';
import Footer from '../../components/Footer.vue'
export default {
    data() {
        return {

            msg: false,
            message: [],
            form: {
                date: '',
                from: '',
                to: '',
                onsite: '',
                type: '',
            },
            errors: {},
            new_type: '',
            new_onsite: '',
            successData: {},
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,


        };
    },

    methods: {
        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },
        async createAppointment() {
            try {

                if (this.form.onsite.translationKey == 'نعم' || this.form.onsite.translationKey == 'لا') {

                    this.new_onsite = this.form.onsite.name;

                } else {
                    this.new_onsite = this.form.onsite.name;
                }


                if(this.new_onsite == 'لا' || this.new_onsite == 'Online'){
  this.new_onsite =0;
}
else if(this.new_onsite == 'نعم' || this.new_onsite == 'Onsite'){
  this.new_onsite =1;
}
                if (this.form.type.translationKey == 'فردي' || this.form.type.translationKey == 'جماعة') {
                    this.new_type = this.form.type.name
                } else {
                    this.new_type = this.form.type.name;
                }
                console.log(this.form.from,this.form.to,this.new_onsite)
                const response = await axios('https://backend.ihayanow.com/api/doctor/calendars', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    },
                    data: {
                        date: this.form.date,
                        from: this.form.from,
                        to: this.form.to,
                        onsite: this.new_onsite,
                        type: this.new_type,
                        number_of_attendees: this.form.number_of_attendees,
                    },
                });
                console.log(response.data);
                this.form.date = '';
                this.form.from = '';
                this.form.to = '';
                this.form.onsite = true;
                // this.form.type = 'individual';
                // this.form.number_of_attendees = 1;
                this.msg = true;
                this.message = [{ severity: 'success', detail: 'Success Message', content: 'Session Created Successfully' }]
                this.successData = response.data.message;
                console.log(this.errors)

            } catch (error) {
                console.log(error);
                this.errors = error.response.data.errors;
                if (error) {
                    this.showInlineMessage = true;
                }
            }
        },



    },

    computed: {
        onsites() {
            return [
                {
                    name: 'Onsite', translationKey: this.$t('Yes')
                },
                { name: 'Online', translationKey: this.$t('No') }
            ]
        },

        types() {
            return [
                { name: 'Individual', translationKey: this.$t('Appointments.Individual') },
                { name: 'Group', translationKey: this.$t('Appointments.Group') }

            ]
        },




    },
    components: {
        DoctorNavbar,
        Footer

    }
}

</script>