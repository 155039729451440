
<template>
  <div class="container">
    <DoctorNavbar />

    <div class="layout-main-container">
      <div class="layout-main">
        <transition-group name="p-message" tag="div">
                <PrimeMessage class="w-50"  v-if="errors"  severity="error" :key="index">
                  {{ translateErrorMessage(errors) }}</PrimeMessage>
              </transition-group>
        <div class="card shadow-lg mt-n6">

          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto my-auto">
    
                <div class="profile-info">
                  <div class="profile-image" v-if="profile.photo !== null">
                    <img :src="'https://backend.ihayanow.com/' + profile.photo" alt="Profile Image">
                  </div>
                  <div class="profile-details px-4">
                 
                    <h5 class="mb-1">{{ $t('profile.name') }} : {{ displayName }}</h5>
                    <p class="mb-0 font-weight-bold">
                      {{ $t("profile.professional") }}: {{ profile.professional == "Psychologist" ?
                        $t("profile.Psychologist") : $t("profile.Psychastric") }} , 
                        {{ $t("profile.Specialist in treating") }}  {{ displaySpeciality }}
                    </p>
                    <p class="mb-0 font-weight-bold">
                      {{ $t("profile.language") }}: {{ $t(`${profile.language}`) }}
                    </p>
                    <PrimeRating v-model="profile.rating" class="pt-2" :cancel="false"></PrimeRating>

                  </div>
                </div>
                <div class="row edit-button text-left">
          <PrimeButton @click="$router.push({ name: 'edit-doctor-profile', params: { profile } })">
            {{ $t("profile.edit_profile") }}
          </PrimeButton>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card py-4">
        <h5>{{ $t("profile.yourinfo") }}</h5>
        <div class="grid p-fluid mt-3">

          <div class="field col-12 md:col-6" v-if="displayName == this.name">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("profile.Arabic Name") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.arabic_name }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6" v-else-if="displayName == this.profile.arabic_name">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("profile.name") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ name }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-user"></i> {{ $t("profile.email_address") }}
              </span>
              <p for="example-text-input " class="form-control-label pt-2">{{ email }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-clock"></i>{{ $t("profile.age") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.age }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-calendar"></i>{{ $t("profile.birthdate") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.birth_date }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-user" ></i>
                {{ $t("profile.gender") }}</span>
              <p for="example-text-input" class="form-control-label">{{ profile.gender == 'Male' ? $t('profile.male')
                : $t('profile.female') }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>{{ $t("profile.address") }}</span>
              <p for="example-text-input" class="form-control-label" v-if="this.$i18n.locale === 'ar'">{{ profile.address  }} - {{ city_name.arabic_name }} - {{ state_name.arabic_name }} - {{ country_name.arabic_name }}</p>
              <p for="example-text-input" class="form-control-label" v-else>{{ profile.address  }} - {{ city_name.english_name }} - {{ state_name.english_name }} - {{ country_name.english_name }}</p>
          
            </span>
          </div>
         
        </div>
        <hr class="horizontal dark" />
        <h5>{{ $t("profile.session_info") }}</h5>
        <div class="grid p-fluid mt-3">
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                <i class="p-menuitem-icon pi pi-fw pi-cog"></i> {{ $t("profile.session_type") }}</span>
              <p for="example-text-input" class="form-control-label pt-2">
                {{ profile.session_type == "Online & Onsite" ? $t("profile.Online & Onsite") : $t(`${profile.session_type}`) }}
              </p>
            </span>

          </div>

          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-clock"></i>{{ $t("profile.session_duration") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.session_duration }}</p>
            </span>
          </div>
        
          <div class="field col-12 md:col-6"  v-if="displayOnsitePrice">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-money-bill"></i>{{ $t("profile.onsite_session_price") + ' / ' + profile.session_duration  + ' ' + $t('profile.minute') }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.onsite_price }}</p>
            </span>
          </div>
      
          <div class="field col-12 md:col-6" v-if="displayOnlinePrice">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-credit-card"></i>{{ $t("profile.online_session_price") + ' / ' + profile.session_duration  + ' ' + $t('profile.minute') }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.online_price }}</p>
            </span>
          </div>
    



        </div>



        <hr class="horizontal dark" />
        <h5>{{ $t("profile.account") }}</h5>
        <div class="grid p-fluid mt-3">


          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-home"></i>{{ $t("profile.bankName") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.bankName }}</p>
            </span>
          </div>

          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-mobile"></i>{{ $t("profile.vodafoneCashNumber") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.vodafoneCashNumber }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-wallet"></i>{{ $t("profile.mobileWalletNumber") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.mobileWalletNumber }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-user"></i>{{ $t("profile.bankAccountName") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.bankAccountName }}</p>
            </span>
          </div>

          <div class="field col-12 md:col-6">

            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-credit-card"></i>{{ $t("profile.wallet") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.wallet }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon  pi pi-fw pi-id-card"></i>{{ $t("profile.accountNumber") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.accountNumber }}</p>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-home"></i>{{ $t("profile.branch") }}</span>
              <p for="example-text-input " class="form-control-label pt-2">{{ profile.branch }}</p>
            </span>
          </div>
        </div>


        <hr class="horizontal dark" />
        <h5>{{ $t("profile.files") }}</h5>
        <div class="grid p-fluid mt-3">
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-file"></i>{{ $t("profile.cv") }}</span>
              <p class="pt-3"> <a :href="'https://backend.ihayanow.com' + profile.cv" target="_blank">Preview file</a>
              </p>

            </span>
          </div>

          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-book"></i>{{ $t("profile.liscence") }}</span>
              <p class="pt-3"> <a :href="'https://backend.ihayanow.com' + profile.liscence" target="_blank">Preview file</a>
              </p>
            </span>

          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
              <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                  class="p-menuitem-icon pi pi-fw pi-id-card"></i>{{ $t("profile.ID Copy") }}</span>
              <p class="pt-3"> <a :href="'https://backend.ihayanow.com' + profile.IDCopy" target="_blank">Preview file</a>
              </p>
            </span>

          </div>

        </div>
      
      </div>



    </div>

  </div>
</template>


  
<script>
import router from '@/router';
import DoctorNavbar from './DoctorNavbar.vue';
import axios from "axios";



export default {


  data() {
    return {
      name: '',
      email: '',
      profile: [],
      country_name:'',
      state_name:'',
      city_name:'',
      speciality_arabic_name:[],
      speciality_english_name:[],
      speciality_arabic:[],
      speciality_english:[],
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    }
  },

  created() {
    this.fetchProfile();
  },

  methods: {
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    async fetchProfile() {
     
       
       await axios.get('https://backend.ihayanow.com/api/doctor/my-profile'
        , {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret
          }
        })  .then(response => {
          const data = response.data;     
          console.log(data);
          console.log(response.data); 
      this.name = data[0].name;
      this.email = data[0].email;
      this.profile = data[0].profile;
      data.forEach(d => {
      this.speciality_arabic_name.push(d.category.name);
      this.speciality_english_name.push(d.category.english_name);
    });

     

     console.log(this.speciality_arabic_name, this.speciality_english_name)

      this.country_name=data[0].country_name,
      this.state_name=data[0].state_name,
      this.city_name=data[0].city_name


        })
        .catch(error => {
          this.errors = error;
          console.log(this.errors)
        });


     
    },
    goToEditProfile() {
      router.push('/edit-doctor-profile')
    },


  },
  computed: {
    displayOnlinePrice() {
      return this.profile.session_type === "Online" || this.profile.session_type === "Online & Onsite";
    },

    displayOnsitePrice() {
      return this.profile.session_type === "Onsite" || this.profile.session_type === "Online & Onsite";
    },
    displayName() {
      if (this.$i18n.locale === 'ar') {
        return this.profile.arabic_name;
      } else {
        return this.name;
      }
    },
    displaySpeciality() {
      let specialty = '';

  if (this.$i18n.locale === 'ar') {
    // Handle Arabic specialties
    console.log(typeof this.speciality_arabic_name,Array.isArray(this.speciality_arabic_name))
    specialty = Array.isArray(this.speciality_arabic_name)
      ? this.speciality_arabic_name.join(', ')
      : this.speciality_arabic_name;
  } else {
    // Handle English specialties
    specialty = Array.isArray(this.speciality_english_name)
      ? this.speciality_english_name.join(', ')
      : this.speciality_english_name;
  }

  return specialty || ''; 
     
    },
  },
  components: {
    DoctorNavbar, 
  },

}

</script>

<style>
.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details h5 {
  margin-bottom: 5px;
}

/* .profile-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
} */
</style>