<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <!-- <AdminNavbar /> -->
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <h5>Sessions</h5>
                            <TabView>
                                <TabPanel header="Created Sessions">
                                    <DataTable :value="createdSessions" :paginator="true" class="p-datatable-gridlines"
                                        :rows="10" dataKey="id" :rowHover="true" :loading="loading1"
                                        responsiveLayout="scroll">
                                        <!-- {{ createdSessions[0].created_at }} -->
                                        <template #empty>
                                            <div v-if="createdSessions.length == 0">No Created Sessions</div>
                                        </template>
                                        <template #loading>
                                            Loading Created Sessions data. Please wait.
                                        </template>
                                        <PrimeColumn field="id" header="Zoom Session Id" style="min-width: 12rem">
                                            {{ rowData.id }}
                                        </PrimeColumn>
                                        <PrimeColumn field="doctor_name" header="Doctor Name" style="min-width: 12rem">
                                            {{ rowData.doctor_name }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Patient Name" field="user_name" style="min-width: 14rem">

                                            {{ rowData.user_name }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Date" field="created_at" style="min-width: 14rem">
                                            {{ rowData.created_at }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time From" field="session_from"
                                            style="min-width: 14rem">

                                            {{ rowData.session_from }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time To" field="session_to" style="min-width: 14rem">
                                            {{ rowData.session_to }}
                                        </PrimeColumn>


                                    </DataTable>

                                </TabPanel>
                                <TabPanel header="Live Sessions">
                                    <DataTable :value="liveSessions" :paginator="true" class="p-datatable-gridlines"
                                        :rows="10" dataKey="id" :rowHover="true" :loading="loading1"
                                        responsiveLayout="scroll">
                                        <template #empty>
                                            <div v-if="liveSessions.length == 0">No Live Sessions</div>
                                        </template>
                                        <template #loading>
                                            Loading Live Sessions data. Please wait.
                                        </template>
                                        <PrimeColumn field="id" header="Zoom Session Id" style="min-width: 12rem">
                                            {{ rowData.id }}
                                        </PrimeColumn>
                                        <PrimeColumn field="doctor name" header="Doctor Name" style="min-width: 12rem">
                                            {{ rowData.doctor_name }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Patient Name" field="user_name" style="min-width: 14rem">

                                            {{ rowData.user_name }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time From" field="session_from"
                                            style="min-width: 14rem">

                                            {{ rowData.session_from }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time To" field="session_to" style="min-width: 14rem">
                                            {{ rowData.session_to }}
                                        </PrimeColumn>


                                    </DataTable>

                                </TabPanel>
                                <TabPanel header="Past Sessions">
                                    <DataTable :value="pastSessions" :paginator="true" class="p-datatable-gridlines"
                                        :rows="10" dataKey="id" :rowHover="true" :loading="loading1"
                                        responsiveLayout="scroll">
                                        <template #empty>
                                            <div v-if="pastSessions.length == 0">No Past Sessions</div>
                                        </template>
                                        <template #loading>
                                            Loading Past Sessions data. Please wait.
                                        </template>
                                        <PrimeColumn field="id" header="Zoom Session Id" style="min-width: 12rem">
                                            {{ rowData.id }}
                                        </PrimeColumn>
                                        <PrimeColumn field="doctor name" header="Doctor Name" style="min-width: 12rem">
                                            {{ rowData.doctor_name }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Patient Name" field="user_name" style="min-width: 14rem">

                                            {{ rowData.user_name }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Date" field="created_at" style="min-width: 14rem">

                                            {{ formatDate(rowData.created_at) }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time From" field="session_from"
                                            style="min-width: 14rem">

                                            {{ rowData.session_from }}

                                        </PrimeColumn>
                                        <PrimeColumn header="Session Time To" field="session_to" style="min-width: 14rem">
                                            {{ rowData.session_to }}
                                        </PrimeColumn>


                                    </DataTable>

                                </TabPanel>
                            </TabView>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
// import AdminNavbar from './AdminNavbar.vue';
import moment from 'moment';
import axios from 'axios';

import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
    name:'AppSessions',
    data() {
        return {
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
            pastSessions: [],
            liveSessions: [],

            createdSessions: [],
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {

          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },

            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
        };
    },

    methods: {
        async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
        formatDate(dateTimeString) {
            console.log(dateTimeString)
            return moment(dateTimeString).format('YYYY-MM-DD');
        },


        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },



    async created() {
        const response = await
            fetch('https://backend.ihayanow.com/api/admin/zoom_sessions', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                }
            });
        let result = await response.json();

        console.log(result);
        this.liveSessions = result.liveSessions;
        this.pastSessions = result.pastSessions;
        this.createdSessions = result.createdSessions;

        this.createdSessions.forEach(createdSession => {
            createdSession.created_at = moment(createdSession.created_at).format('YYYY-MM-DD');
            console.log(createdSession.created_at)
        });
        this.pastSessions.forEach(pastSession => {
            pastSession.created_at = moment(pastSession.created_at).format('YYYY-MM-DD');
            console.log(pastSession.created_at)
        });
    },


    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        // 'Footer': Footer,
    },


    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
    }
}


</script>
  
<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }
</style>