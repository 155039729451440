<!-- <template>
    <Navbar />

    <div class="therapist-profile">
      <h1>{{ therapist.name }}</h1>
      <div class="therapist-info">
        <p>Language: {{ therapist.language }}</p>
        <p>Country: {{ therapist.country }}</p>
        <p>Sessions: {{ therapist.sessions.join(', ') }}</p>
      </div>
      <div class="session-info">
        <p>Session duration: <strong>{{ therapist.sessionDuration }}</strong></p>
        <p>Session price: <strong>{{ therapist.sessionPrice }}$</strong></p>
      </div>
      <div class="certificates">
        <h3>Certificates</h3>
        <div v-for="(certificate, index) in therapist.certificates" :key="index">
          <p><strong>{{ certificate.title }}</strong></p>
          <p>{{ formatDate(certificate.startDate) }} - {{ formatDate(certificate.endDate) }}</p>
          <p>{{ certificate.description }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from "./Navbar.vue";

  export default {
    data() {
      return {
        therapist: {
          "name": "Dr. Jane Smith",
          "language": "English",
          "country": "USA",
          "sessions": ["onsite", "online"],
          "sessionDuration": "60 minutes",
          "sessionPrice": 100,
          "certificates": [
            {
              "title": "Certificate in CBT",
              "startDate": "2022-01-01",
              "endDate": "2022-06-01",
              "description": "This certificate program covered the principles and techniques of cognitive-behavioral therapy."
            },
            {
              "title": "Certificate in Mindfulness-Based Stress Reduction",
              "startDate": "2021-06-01",
              "endDate": "2021-12-01",
              "description": "This certificate program covered the principles and techniques of mindfulness-based stress reduction."
            }
          ]
        }
      }
    },
    components: {
    Navbar,
  },
    methods: {
      formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      }
    }
  }
  </script>
  
  <style>
  .therapist-profile {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .therapist-info {
    margin-bottom: 2rem;
  }
  
  .session-info {
    display: flex;
    margin-bottom: 2rem;
  }
  
  .session-info .session-duration {
    margin-right: 4rem;
  }
  
  .certificates {
    margin-top: 2rem;
  }
  
  .certificates h3 {
    margin-bottom: 0.5rem;
  }
  
  .certificates p {
    margin-bottom: 1rem;
  }
  </style>
   -->
   <template>
    <Navbar/>
    <div class="therapist-profile">
      <div class="therapist-header">
        <img :src="therapist.profilePhoto" alt="Profile photo of {{ therapist.name }}">
        <h1>{{ therapist.name }}</h1>
      </div>
      <div class="therapist-info">
        <p>Language: {{ therapist.language }}</p>
        <p>Country: {{ therapist.country }}</p>
        <p>Sessions: {{ therapist.sessions.join(', ') }}</p>
      </div>
      <div class="session-info">
        <p>Session duration: <strong>{{ therapist.sessionDuration }}</strong></p>
        <p>Session price: <strong>{{ therapist.sessionPrice }}$</strong></p>
      </div>
      <div class="certificates">
        <h3>Certificates</h3>
        <div v-for="(certificate, index) in therapist.certificates" :key="index">
          <p><strong>{{ certificate.title }}</strong></p>
          <p>{{ formatDate(certificate.startDate) }} - {{ formatDate(certificate.endDate) }}</p>
          <p>{{ certificate.description }}</p>
        </div>
      </div>
    </div>
  </template>
<script>
  import Navbar from "./Navbar.vue";

export default {
  data() {
    return {
      therapist: {
        "name": "Dr. Jane Smith",
        "language": "English",
        "country": "USA",
        "sessions": ["onsite", "online"],
        "sessionDuration": "60 minutes",
        "sessionPrice": 100,
        "certificates": [
          {
            "title": "Certificate in CBT",
            "startDate": "2022-01-01",
            "endDate": "2022-06-01",
            "description": "This certificate program covered the principles and techniques of cognitive-behavioral therapy."
          },
          {
            "title": "Certificate in Mindfulness-Based Stress Reduction",
            "startDate": "2021-06-01",
            "endDate": "2021-12-01",
            "description": "This certificate program covered the principles and techniques of mindfulness-based stress reduction."
          }
        ]
      }
    }
  },
  components: {
  Navbar,
},
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    }
  }
}

</script>  
<!-- <style>
.therapist-profile {
  max-width: 800px;
  margin: 0 auto;
}

.therapist-info {
  margin-bottom: 2rem;
}

.session-info {
  display: flex;
  margin-bottom: 2rem;
}

.session-info .session-duration {
  margin-right: 4rem;
}

.certificates {
  margin-top: 2rem;
}

.certificates h3 {
  margin-bottom: 0.5rem;
}

.certificates p {
  margin-bottom: 1rem;
}
</style> -->