import TherapistsList from './components/TherapistsList.vue';
import TherapistProfile from './components/TherapistProfile.vue';
import Calendar from './components/User/Calendar.vue';
import Knowledgebase from './components/Knowledgebase.vue';
import KnowledgebaseContent from './components/KnowledgebaseContent.vue';
import EditUserProfile from './components/User/EditUserProfile.vue';
import Complete from './components/User/Complete.vue';
import UserProfile from './components/User/UserProfile.vue';
import Requests from './components/User/Requests.vue';
import SessionsRequests from './components/Doctor/SessionsRequests.vue';
import Rate from './components/Rate.vue';
import CreateSessions from './components/Doctor/CreateSessions.vue';
import DoctorCalendars from './components/Doctor/Calendars.vue';
import Admin from './components/Admin/Admin.vue';
import PromocodePage from './components/Admin/PromocodePage.vue';
import AcceptedDoctors from './components/Admin/AcceptedDoctors.vue';
import { createRouter, createWebHistory } from 'vue-router';
import FavouriteDoctors from './components/User/FavouriteDoctors.vue';
import CreatePatientFile from './components/Doctor/CreatePatientFile.vue';

import PatientFile from './components/Doctor/PatientFile.vue';


import AllUsers from './components/Admin/AllUsers.vue';

import AdminknowledgeBase from './components/Admin/AdminknowledgeBase.vue';
import CreateKnowledgebase from './components/Admin/CreateKnowledgebase.vue';
import KnowledgebaseCategory from './components/Admin/KnowledgebaseCategory.vue';
import CreateKnowledgebaseCategory from './components/Admin/CreateKnowledgebaseCategory.vue';
import CreateMedications from './components/Admin/CreateMedications.vue';
import MedicationsPage from './components/Admin/MedicationsPage.vue';
import ViewMedications from './components/ViewMedications.vue';
import Reports from './components/Admin/Reports.vue';

import Sessions from './components/Admin/Sessions.vue';
import CreatePromocode from './components/Admin/CreatePromocode.vue';
import MostReservedDoctors from './components/Admin/MostReservedDoctors.vue';
import PatientLabel from './components/Admin/PatientLabel.vue';
import CreatePatientLabel from './components/Admin/CreatePatientLabel.vue';
import AddLevels from './components/Admin/AddLevels.vue';
import Levels from './components/Admin/Levels.vue';
import AddSubscriptions from './components/Admin/AddSubscriptions.vue';
import Subscriptions from './components/Admin/Subscriptions.vue';
import Packages from './components/Packages.vue';

import UserSessions from './components/User/UserSessions.vue';
import SignUp from './components/Authentication/SignUp.vue';
import DoctorSignUp from './components/Authentication/DoctorSignUp.vue';


import CheckEmail from './components/Authentication/CheckEmail.vue';
import VerifyPassword from './components/Authentication/VerifyPassword.vue';

import ForgetPassword from './components/Authentication/ForgetPassword.vue';
import Login from './components/Authentication/Login.vue';
import DoctorProfile from './components/Doctor/DoctorProfile.vue';
import EditDoctorProfile from './components/Doctor/EditDoctorProfile.vue';
import CompleteRegisteration from './components/Doctor/CompleteRegisteration.vue';
import DoctorSessions from './components/Doctor/DoctorSessions.vue';
import Zoom from './components/Zoom.vue';
import Payment from './components/User/Payment.vue';
import App from './App.vue';
import Home from './components/Home.vue';
import GuestTherapistList from './components/GuestTherapistList.vue';
import APIGenerator from './components/Admin/APIGenerator.vue';
import PayPackages from './components/PayPackages.vue';
import Knowledge from './components/Knowledge.vue';
import DoctorHome from './components/Doctor/DoctorHome.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App
    },
    {
        path: '/doctor-home',
        name: 'doctor-home',
        component: DoctorHome,
        meta: { requiresAuth: true } 
        // Add meta field to indicate authentication requirement

    },
    {
        name: 'AppHome',
        component: Home,
        path: '/home'
    },
    {
        name: 'guest-therapist-page',
        component: GuestTherapistList,
        path: '/guest-therapist-page'
    },
    {
        name: 'SignUp',
        component: SignUp,
        path: '/sign-up'
    },
    {
        name: 'DoctorSignUp',
        component: DoctorSignUp,
        path: '/doctor-sign-up'
    },
    {
        name: 'complete',
        component: Complete,
        path: '/Complete',
        meta: { requiresAuth: true } 

    },
    {
        name: 'CheckEmail',
        component: CheckEmail,
        path: '/check-email/:email',
        meta: { requiresAuth: true } 

    },
    {
        name: 'VerifyPassword',
        component: VerifyPassword,
        path: '/verify-password/:email',

    },
    {
        name: 'login-page',
        component: Login,
        path: '/login-page'
    },
    {
        name: 'forget-password',
        component: ForgetPassword,
        path: '/forget-password'
    },
    {
        name: 'therapists-page',
        component: TherapistsList,
        path: '/therapists-page',
        meta: { requiresAuth: true } 

    },
    {
        path: '/therapists/:id',
        name: 'TherapistProfile',
        component: TherapistProfile,
        props: true,
        meta: { requiresAuth: true } 

    },
    {
        name: 'user-sessions',
        component: UserSessions,
        path: '/user-sessions',
        meta: { requiresAuth: true } 

    },
    {
        name: 'sessions-requests',
        component: SessionsRequests,
        path: '/sessions-requests',
        meta: { requiresAuth: true } 

    },
    // {
    //     name: 'edit-user-profile-for-doctor',
    //     component: EditUserProfile,
    //     path: '/edit-user-profile/:id',
    //     props: true,
    //     meta: { requiresAuth: true } 

    // },
    {
        name: 'user-profile',
        component: UserProfile,
        path: '/user-profile',
        meta: { requiresAuth: true } 

    },
    // {
    //     name: 'user-profile-for-doctor',
    //     component: UserProfile,
    //     path: '/user-profile/:id',
    //     meta: { requiresAuth: true } 

    // },
    {
        name: 'edit-doctor-profile',
        component: EditDoctorProfile,
        path: '/edit-doctor-profile',
        props: true,
        meta: { requiresAuth: true } 

    },
    {
        name: 'accepted-doctors',
        component: AcceptedDoctors,
        path: '/accepted-doctors',
        meta: { requiresAuth: true }
    },
    {
        name: 'create-knowledgebase-page',
        component: CreateKnowledgebase,
        path: '/create-knowledgebase-page',
        meta: { requiresAuth: true }
    },
    {
        name: 'create-promocode',
        component: CreatePromocode,
        path: '/create-promocode',
        meta: { requiresAuth: true }
    },
    {
        name: 'promocode-page',
        component: PromocodePage,
        path: '/promocode-page',
        meta: { requiresAuth: true }
    },
    {
        path: '/show-knowledgebase-category',
        name: 'show-knowledgebase-category',
        component: KnowledgebaseCategory,
        meta: { requiresAuth: true }
    },
    {
        path: '/complete-registeration',
        name: 'complete-registeration',
        component: CompleteRegisteration,
        meta: { requiresAuth: true }
    },
    {
        path: '/Calendar/:id',
        name: 'Calendar',
        component: Calendar,
        meta: { requiresAuth: true }
    },
    {
        name: 'knowledgebase-page',
        component: Knowledgebase,
        path: '/knowledgebase-page',
        meta: { requiresAuth: false }
    },
    {
        name: 'knowledgebase-content-page',
        component: KnowledgebaseContent,
        path: '/knowledgebase-page/:id',
        meta: { requiresAuth: true }
    },

    {
        name: 'create-sessions',
        component: CreateSessions,
        path: '/create-sessions',
        meta: { requiresAuth: true }
    },
    {
        name: 'doctor-calendars',
        component: DoctorCalendars,
        path: '/doctor-calendars',
        meta: { requiresAuth: true }
    },
    {
        name: 'user_session_requests',
        component: Requests,
        path: '/user_session_requests',
        meta: { requiresAuth: true }
    },
    {
        name: 'edit-user-profile',
        component: EditUserProfile,
        path: '/edit-user-profile',
        props: true,
        meta: { requiresAuth: true }
    },
    {
        name: 'doctor-profile',
        component: DoctorProfile,
        path: '/doctor-profile',
        meta: { requiresAuth: true }
    },
    {
        name: 'doctor-sessions',
        component: DoctorSessions,
        path: '/doctor-sessions',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin-knowledgebase-page',
        component: AdminknowledgeBase,
        path: '/admin-knowledgebase-page',
        meta: { requiresAuth: true }
    },
    {
        name: 'create-promocode',
        component: CreatePromocode,
        path: '/create-promocode',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin',
        component: Admin,
        path: '/admin',
        meta: { requiresAuth: true }
    },
   
    {
        path: '/session_meeting/:sessionId/:joined_user_name',
        component: Zoom,
        name: 'session_meeting',
        meta: { requiresAuth: true }
    },
    {
        path: '/session_payment/:id',
        component: Payment,
        name: 'session_payment',
        meta: { requiresAuth: true }
    },
    {
        path: '/Knowledge/:id',
        component: Knowledge,
        name: 'Knowledge',
        meta: { requiresAuth: true }
    },
    

    
    {
        path: '/sessions',
        component: Sessions,
        name: 'sessions',
        meta: { requiresAuth: true }
    },
    {
        path: '/rate/:sessionId',
        component: Rate,
        name: 'rate',
        meta: { requiresAuth: true }
    },
    {
        path: '/add-knowledgebase-category',
        component: CreateKnowledgebaseCategory,
        name: 'add-knowledgebase-category',
        meta: { requiresAuth: true }
    },
    {
        path: '/knowledgebase-category-page',
        component: KnowledgebaseCategory,
        name: 'knowledgebase-category-page',
        meta: { requiresAuth: true }
    }
    ,
    {
        path: '/add-medications',
        component: CreateMedications,
        name: 'add-medications',
        meta: { requiresAuth: true }
    }
    ,
    {
        path: '/medications-page',
        component: MedicationsPage,
        name: 'medications-page',
        meta: { requiresAuth: true }
    }
    ,
    {
        path: '/view-medications',
        component: ViewMedications,
        name: 'view-medications',
        meta: { requiresAuth: false }
    },
    {
        path: '/reports',
        component: Reports,
        name: 'reports',
        meta: { requiresAuth: true }
    },
    {
        path: '/favourite-doctors',
        component: FavouriteDoctors,
        name: 'favourite-doctors',
        meta: { requiresAuth: true }
    },
    {
        path: '/create-patient-file/:id',
        component: CreatePatientFile,
        name: 'create-patient-file',
        meta: { requiresAuth: true }
    }
    ,
    {
        path: '/patient-file/:id',
        component: PatientFile,
        name: 'patient-file',
        meta: { requiresAuth: true }
    }, {
        path: '/most-reserved-doctors',
        component: MostReservedDoctors,
        name: 'most-reserved-doctors',
        meta: { requiresAuth: true }
    }, {
        name: 'all-users',
        component: AllUsers,
        path: '/all-users',
        meta: { requiresAuth: true }
    },
    {
        name: 'add-label-patient',
        component: CreatePatientLabel,
        path: '/add-label-patient',
        meta: { requiresAuth: true }
    },
    {
        name: 'label-patient',
        component: PatientLabel,
        path: '/label-patient',
        meta: { requiresAuth: true }
    },
    {
        name: 'add-levels',
        component: AddLevels,
        path: '/add-levels',
        meta: { requiresAuth: true }
    },
    {
        name: 'show-levels',
        component: Levels,
        path: '/show-levels',
        meta: { requiresAuth: true }
    },
    {
        name: 'add-subscriptions',
        component: AddSubscriptions,
        path: '/add-subscriptions',
        meta: { requiresAuth: true }
    },
    {
        name: 'show-subscriptions',
        component: Subscriptions,
        path: '/show-subscriptions',
        meta: { requiresAuth: true }
    },
    {
        name: 'show-packages',
        component: Packages,
        path: '/show-packages',
        meta: { requiresAuth: true }
    },
    {
        name: 'generate-credentials',
        component: APIGenerator,
        path: '/generate-credentials',
        meta: { requiresAuth: true }
    },
    {
        path: '/package_payment/:id',
        component: PayPackages,
        name: 'package_payment',
        meta: { requiresAuth: true }
    },
    
    
]

const router = createRouter({
    mode: 'history', // This enables history mode
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, _, next) => {
    const isAuthenticated = localStorage.getItem('token');
    // const isSignUpAuthenticated = localStorage.getItem('btoken');
    // || !isSignUpAuthenticated
    console.log(isAuthenticated);
    const role = localStorage.getItem('type');
    if (to.meta.requiresAuth && !isAuthenticated ) {
        next({ name: 'login-page' }); 
        // Redirect to login page if trying to access a route that requires authentication and not authenticated
    } else if (to.name === 'login-page' && isAuthenticated && role == "Doctor") {
        next({ name: 'doctor-home' }); 
        // Redirect to doctor-home if trying to access login page when already authenticated
    }  else if (to.name === 'login-page' && isAuthenticated && role == "User") {
        next({ name: 'therapists-page' }); 
        // Redirect to doctor-home if trying to access login page when already authenticated
    }else {
        next(); // Continue navigation
    }
});
  

export default router;
