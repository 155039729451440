

<template>
  <div class="container">
    <DoctorNavbar />
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card py-4">
              <h5>{{ $t('Appointments.All Appointments') }}</h5>
              <hr>


              <transition-group name="p-message" tag="div">
                <PrimeMessage class="w-50" v-for="msg of message" :severity="msg.severity" :key="msg.content">
                  {{ translateErrorMessage(msg.content) }}</PrimeMessage>
              </transition-group>

              <DataTable :value="appointments" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                <template #empty>
                  <div v-if="appointments.length == 0">{{ $t('Appointments.No Doctor Appointments') }}</div>
                </template>
                <template #loading>
                  {{ $t('Appointments.Loading Appointments') }}
                </template>
                <PrimeColumn :dir="selectedLanguage == 'ar' ? 'rtl' : 'ltr'" field="date" :header="$t('Appointments.Appointment Date')" style="min-width: 12rem">
                  {{ rowData.appointments.date }}
                </PrimeColumn>
                <PrimeColumn field="from" :header="$t('Appointments.Appointment From')" style="min-width: 12rem">
                  {{ rowData.appointments.from }}
                </PrimeColumn>

                <PrimeColumn :header="$t('Appointments.Appointment To')" field="to" style="min-width: 14rem">

                  {{ rowData.appointments.to }}

                </PrimeColumn>
                <PrimeColumn :header="$t('Appointments.Appointment Location')" field="onsite" style="min-width: 14rem">
                  {{ rowData.appointments.onsite }}
                </PrimeColumn>
                <PrimeColumn :header="$t('Appointments.Appointment Type')" field="type" style="min-width: 14rem">
                  {{ rowData.appointments.type }}
                </PrimeColumn>

                <PrimeColumn :header="$t('Appointments.Number Of Attendance')" field="number_of_attendees"
                  style="min-width: 14rem">
                  {{ rowData.appointments.number_of_attendees }}
                </PrimeColumn>
                <PrimeColumn header="Actions">
                  <template #body="rowData">
                    <PrimeButton class="mr-2 mb-2" icon="pi pi-pencil"
                     @click="showUpdateForm(rowData.data.id)">
                    </PrimeButton>

                    <PrimeButton class="mr-2 mb-2 p-button-danger" icon="pi pi-trash"
                      @click="deleteAppointment(rowData.data.id)"></PrimeButton>
                  </template>
                </PrimeColumn>
              </DataTable>


              <div v-if="showUpdate">
                <h3>{{ $t('Appointments.Update Appointment') }}</h3>
                <hr>

                <form @submit.prevent="updateAppointment">

                  <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6">
                      <label for="date" style="font-weight: bold;">{{ $t('Appointments.Date') }}</label>
                      <InputText id="date" type="date" v-model="selectedAppointment.date" />
                      <div v-if="errors && errors.date">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.date"
                          v-for="(error, index) in errors.date" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="from" style="font-weight: bold;">{{ $t('Sessions.From') }}</label>
                      <InputText id="from" type="time" v-model="selectedAppointment.from" />
                      <div v-if="errors && errors.from">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.from"
                          v-for="(error, index) in errors.from" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>

                    <div class="field col-12 md:col-6">
                      <label for="to" style="font-weight: bold;">{{ $t('Sessions.To') }}</label>
                      <InputText id="to" type="time" v-model="selectedAppointment.to" />
                      <div v-if="errors && errors.to">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.to"
                          v-for="(error, index) in errors.to" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>


                    <div class="field col-12 md:col-6">
                      <label for="onsite" style="font-weight: bold;">{{ $t('Appointments.Appointment Location') }}</label>

                      <PrimeDropdown id="state" v-model="selectedAppointment.onsite" :options="onsites"
                        optionLabel="translationKey" optionKey="name" :placeholder="$t('profile.Select_One')">
                      </PrimeDropdown>
                      <div v-if="errors && errors.onsite">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.onsite"
                          v-for="(error, index) in errors.onsite" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="type" style="font-weight: bold;">{{ $t('Appointments.Appointment Type') }}</label>
                      <PrimeDropdown id="state" v-model="selectedAppointment.type" :options="types"
                        optionLabel="translationKey" optionKey="name" :placeholder="$t('profile.Select_One')">
                      </PrimeDropdown>
                      <div v-if="errors && errors.type">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                          v-for="(error, index) in errors.type" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="height" style="font-weight: bold;">{{ $t('Appointments.Number Of Attendance') }}</label>
                      <InputText id="height" type="number" v-model="selectedAppointment.number_of_attendees" />
                      <div v-if="errors && errors.number_of_attendees">
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.number_of_attendees"
                          v-for="(error, index) in errors.number_of_attendees" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <div class="row edit-button text-center">
                    <PrimeButton type="submit">{{ $t('Update') }}</PrimeButton>
                    <PrimeButton @click="hideUpdateForm" class="mx-3">{{ $t('Cancel') }}</PrimeButton>
                  </div>
                </form>


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import axios from "axios";
import DoctorNavbar from './DoctorNavbar.vue';

export default {
  name:'DoctorCalendars',
  data() {
    return {
      appointments: [],
      showUpdate: false,
      from: '',
      to: '',
      date: '',
      onsite: true,
      type: '',
      number_of_attendees: 1,
      new_onsite: '',
      selectedAppointment: null,
      new_type: '',
      updateId: null,
      message: [],
      errors: {},
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,

    };
  },

  methods: {
    
    getAppointments() {

      axios.get('https://backend.ihayanow.com/api/doctor/get-doctor-calendars', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
        }
      })
        .then(response => {
          this.appointments = response.data
          console.log(this.appointments);
          this.appointments.forEach(appointment => {

            if (appointment.onsite == 0) {

              appointment.onsite = this.$t('Online');
            } else {
              appointment.onsite = this.$t('Onsite');

            }
          });
          this.appointments.forEach(appointment => {
            if (appointment.type == "Group") {
              appointment.type = this.$t('Group');
            } else {
              appointment.type = this.$t('Individual');

            }
          });

          this.appointments.sort((a, b) => new Date(b.date) - new Date(a.date));

        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteAppointment(id) {
      
      // window.location.reload();
      axios.delete(`https://backend.ihayanow.com/api/doctor/calendars/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
        }
      })
        .then(response => {
          this.getAppointments();
          console.log(response)
          this.message = [{ severity: 'success', detail: 'Success Message', content: 'Session Deleted Successfully', }]
          this.errors = response.data.message;


        })
        .catch(error => {
          this.errors = error.response.data.message;

          console.log(error)
        })
    },

    showUpdateForm(id) {
      this.showUpdate = true
      this.updateId = id
      this.selectedAppointment = this.appointments.find(appointment => appointment.id === id);
      console.log(this.selectedAppointment);
    },
    hideUpdateForm() {
      this.showUpdate = false;
      this.updateId = null;
      console.log(this.updateAppointment);

    },

    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    updateAppointment() {

      if (this.selectedAppointment.onsite.translationKey == 'أونلاين' || this.selectedAppointment.onsite.translationKey == 'أوفلاين') {
        this.new_onsite = this.selectedAppointment.onsite.name;

      } else {
        this.new_onsite = this.onsite;
      }


      if (this.selectedAppointment.type.translationKey == 'فردي' || this.selectedAppointment.type.translationKey == 'جماعة') {
        this.new_type = this.selectedAppointment.type.name
      } else {
        this.new_type = this.type;
      }
      const toTime = this.selectedAppointment.to + ':00';

      const fromTime = this.selectedAppointment.from + ':00';
      
      axios.put(`https://backend.ihayanow.com/api/doctor/calendars/${this.updateId}`, {
        date: this.selectedAppointment.date,
        from: fromTime,
        to: toTime,
        onsite: this.new_onsite === "Onsite" ? 1 : 0,
        type: this.new_type,
        number_of_attendees: this.selectedAppointment.number_of_attendees
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          this.getAppointments();
          this.hideUpdateForm();
          console.log(response);
          this.message = [{ severity: 'success', detail: 'Success Message', content: 'Session Updated Successfully' }]
          this.errors = response.data.message;

        })
        .catch(error => {
          this.errors = error.response.data.errors;
          console.log(this.errrors);

          console.log(error);
        });
    },





  },
  computed: {
    onsites() {
      return [
        {
          name: 'Online', translationKey: this.$t('profile.Online')
        },
        { name: 'Onsite', translationKey: this.$t('profile.Onsite') }
      ]
    },

    types() {
      return [
        { name: 'Individual', translationKey: this.$t('Appointments.Individual') },
        { name: 'Group', translationKey: this.$t('Appointments.Group') }

      ]
    },




  },
  mounted() {
    this.getAppointments()
  },
  components: {
    DoctorNavbar
  }
}

</script>
