<template>
    <div class="container">
        <component :is="navbarComponent" />
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="card shadow-lg mt-n6">
                    <div class="card-body p-3">
                        <PrimeMessage class="w-full mt-3" severity="success" v-if="this.message">
                                    {{ translateErrorMessage(this.message) }}
                                </PrimeMessage>

                                <PrimeMessage class="w-full mt-3" severity="success"
                                    v-if="this.messages && this.message1 == ''">
                                    {{ translateErrorMessage(this.messages) }}
                                </PrimeMessage>
                                <PrimeMessage class="w-full mt-3" severity="error" v-if="this.message1">
                                    {{ translateErrorMessage(this.message1) }}
                                </PrimeMessage>
                        <div class="col-12">
                            <div class="grid">
                                <div class="col-5 flex align-items-center justify-content-center">
                                    
                                   
                                    <div class="p-fluid col-10">

                                        <h5 style="text-align: center;" v-if="this.first_subscription == null">
                                            {{ $t('Packages.This is your first subscription on this package So you will get') }}
                                            ( {{ this.packages.test_discount }} % ) {{ $t('Packages.discount') }}

                                        </h5>

                                        <div
                                            style="background-color:black; color:white; width:100%;  border-radius:5px; padding:10px; margin-bottom:10px; text-align: center;">

                                            <h5>{{ $t("Packages.Package Details") }} : {{ this.packages.name }}</h5>
                                        </div>

                                        <div class="session-price">
                                            <h5>{{ $t('Packages.description') }} </h5>

                                        </div>
                                        <p class="h5"> {{ this.packages.description }}</p>

                                        <div class="session-price">

                                            <h5>{{ $t('Packages.price') }}</h5>
                                            <h5 class="h5"> {{ this.packages.price }} {{ $t('Payments.EGP') }} </h5>
                                        </div>

                                        <div class="session-price" v-if="this.first_subscription == null">

                                            <h5>{{ $t('Packages.discount_price') }}</h5>
                                            <h5 class="h5"> {{ this.final_package_price }} {{ $t('Payments.EGP') }} </h5>
                                        </div>

                                        <div class="session-price">
                                            <h5>{{ $t('Packages.online_discount') }} </h5>
                                            <h5 class="h5"> {{ this.packages.online_discount }} %
                                            </h5>
                                        </div>
                                        <div class="session-price">
                                            <h5>{{ $t('Packages.onsite_discount') }} </h5>
                                            <h5 class="h5"> {{ this.packages.onsite_discount }} %
                                            </h5>
                                        </div>


                                        <div class="session-price">
                                            <h5>{{ $t('Packages.duration') }} </h5>
                                            <h5 class="h5"> {{ this.packages.duration }}
                                            </h5>
                                        </div>
                                        <div class="session-price">
                                            <h5>{{ $t('Packages.expired_at') }} </h5>
                                            <h5 class="h5"> {{ this.expired_at }}
                                            </h5>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-2">
                                    <PrimeDivider layout="vertical">
                                    </PrimeDivider>
                                </div>
                                <div class="col-5 align-items-center justify-content-center">
                                    <p class="line-height-3 m-0"></p>
                                    <div class="col-10">
                                        <div class="grid p-fluid mt-3">
                                            <!-- v-if="this.toggle !== true" -->
                                            <div class="field col-12 md:col-12">
                                                <label for="card_number" style="font-weight: bold;">
                                                    {{ $t('Payments.Card Number') }}</label>
                                                <InputText id="card_number" type="text" v-model="card_number" />
                                            </div>
                                            <PrimeButton type="button" @click.prevent="pay_package(this.packages.id)"
                                                :label="toggle ? $t('Payments.Confirm') : `${$t('Payments.Pay')} ${this.final_package_price} ${$t('Payments.EGP')}`" />
                                        </div>
                                    </div>
                                </div>

                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>

import axios from 'axios';
import moment from 'moment';
import UserNavbar from './User/UserNavbar.vue';
import DoctorNavbar from './Doctor/DoctorNavbar.vue';

import Footer from './Footer.vue';


export default {

    data() {
        return {
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            navbarComponent: null,
            selectedLanguage: '',

            packages: [],
            level_name: '',
            first_subscription: '',
            amount: '',
            new_session_price: '',
            final_session_price: '',

            percentage: '',
            message: '',
            messages: '',
            message1: '',
            toggle: false,
            errors: '',
            expired_at: '',
            subscription_id: '',
            final_package_price: '',

        };
    },

    mounted() {
        this.init();
    },


    methods: {
        subscribe(id) {

            // payment api 
            console.log(id);
            axios.post('https://backend.ihayanow.com/api/subscribe_in_package', {
                subscription_id: id,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                },
            })
                .then(response => {
                    this.success = true;
                    this.message = response.data.message;
                    console.log(response);
                  this.$router.push({ name: 'show-packages' })

                })
                .catch(error => {
                    this.errors = error.response.data.message;
                    console.log(error);
                });
            console.log(`Subscribed to ${id}`);
        },


        async init() {
            this.selectedLanguage = this.$i18n.locale;
            console.log(this.selectedLanguage)

            axios.get('https://backend.ihayanow.com/api/get_package_for_user', {
                params: {
                    package_id: this.$route.params.id
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            }).then(response => {

                this.packages = response.data.package;
                this.first_subscription = response.data.check_first_subscription;
                this.level_name = response.data.level_name;

                if (this.packages.test_discount == 100) {
                    this.final_package_price = 0;
                    this.toggle = true;

                } else {

                    this.final_package_price = this.packages.price - ((this.packages.price) * ((this.packages.test_discount) / 100));
                    if (this.final_package_price == 0) {
                        this.toggle = true;
                    }

                }
                const months = [
                    "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                if (this.packages.duration === "Monthly") {
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth();
                    currentDate.setMonth(currentMonth + 1); // Adding one month

                    const formattedDate = currentDate.getDate().toString().padStart(2, '0') + ' ' +
                        this.$t(months[currentDate.getMonth()]) + ' ' +
                        currentDate.getFullYear();

                    this.expired_at = formattedDate;
                } else if (this.packages.duration === "Yearly") {
                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    currentDate.setFullYear(currentYear + 1); // Adding one year

                    const formattedDate = currentDate.getDate().toString().padStart(2, '0') + ' ' +
                        this.$t(months[currentDate.getMonth()]) + ' ' +
                        currentDate.getFullYear();

                    this.expired_at = formattedDate;

                }else if (this.packages.duration === "Weekly") {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7); // Adding 7 days for a week

    const formattedDate =
        currentDate.getDate().toString().padStart(2, '0') + ' ' +
        this.$t(months[currentDate.getMonth()]) + ' ' +
        currentDate.getFullYear();

    this.expired_at = formattedDate;
} 



                console.log(this.first_subscription, this.packages, this.level_name);

                this.userType = localStorage.getItem("type");
                if (this.userType === "User") {
                    this.navbarComponent = UserNavbar;
                } else if (this.userType === "Doctor") {
                    this.navbarComponent = DoctorNavbar;
                }
            }).catch(error => {
                console.error(error);
            });
        },


        async apply_promo() {

            await axios.get('https://backend.ihayanow.com/api/get_promocodes', {
                params: {
                    code: this.promocode
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            }).then(response => {
                this.amount = response.data.amount;
                this.percentage = response.data.percentage;

                if (this.percentage == 100) {
                    this.new_session_price = 0;
                    if (this.new_session_price < (this.session_price - this.amount)) {
                        this.final_session_price = this.session_price - this.amount;
                    } else {
                        this.final_session_price = this.new_session_price;
                        if (this.final_session_price == 0) {
                            this.toggle = true;
                        }

                    }
                } else {
                    this.new_session_price = this.session_price * (this.percentage / 100);
                    if (this.new_session_price < (this.session_price - this.amount)) {
                        this.final_session_price = this.session_price - this.amount;
                    } else {
                        this.final_session_price = this.new_session_price;
                        if (this.final_session_price == 0) {
                            this.toggle = true;
                        }

                    }
                }
                this.message = response.data.message;
            })
                .catch(error => {
                    this.errors = error;
                    console.error(error);
                });
        },

        pay_package(id) {

            this.subscribe(id);
            if (this.message1 !== '') {
                console.log("hi");
            } else {
                // this.$router.push({ name: 'user-sessions' })

            }

        },



        back() {
            this.$router.push({ name: 'packages' })
        },
        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },
        async updatePromocodes() {

            axios.post('https://backend.ihayanow.com/api/update_promocodes', {
                code: this.promocode,
                saved_amount: this.final_session_price == 0 ? this.session_price : this.final_session_price - this.session_price,
                number_of_uses: 1
            },
                {
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                }).then(response => {
                    if (response.data.message == "you can't use this promocode again cause you exceed allowed number of using this promocode") {
                        this.message1 = response.data.message;
                    } else {
                        this.message = response.data.message;
                    }


                }).catch(error => {
                    console.error(error);
                });
        }
    },
    computed: {

        inputDirection() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        highlightedDates() {
            console.log(this.calendars.map(calendar => moment(calendar.date).toDate()));
            return this.calendars.map(calendar => moment(calendar.date).toDate());
        },

    },

    components: {
        UserNavbar,
        Footer,
        DoctorNavbar
    }





};
</script>
  

<style scoped>
.session-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .session-price h5 {
  margin-right: 10px;
} */
.h5 {
    padding-bottom: 20px;
}

.payment-methods {
    display: flex;
    justify-content: space-between;
    /* margin-top: 10px; */
}

.payment-methods div {
    cursor: pointer;
}

.payment-methods img {
    width: 100%;
    padding: 10px;
    /* height: auto; */
}

.mirror-button {
    direction: rtl;
}
</style>

