

<template>



<div class="container" >
    <UserNavbar/>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="grid">
                <div class="col-12">
                    <div class="card">
                        <h5>{{$t("menu.Sessions")}}</h5>
                        <TabView>
                            <TabPanel :header="$t('Sessions.Pending_Requests')">
                                <DataTable :value="pendingSessions" class="p-datatable-gridlines custom-paginator"
                                    :rows="10" dataKey="id" :rowHover="true" :loading="loading1" responsiveLayout="scroll" >
                                  <!-- {{ pendingSessions }} -->
                                    <template #empty>
                                        <div v-if="pendingSessions.length == 0">{{$t('Sessions.No_Sessions')}}</div>
                                    </template>
                                    <template #loading>
                                        {{$t('Sessions.Loading_Sessions')}}
                                    </template>
                                    <PrimeColumn field="name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                        {{ pendingSessions.name }}
                                    </PrimeColumn>
                                    <PrimeColumn field="session.date" :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                        {{ pendingSessions.session.date }}
                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="session.from" style="min-width: 14rem">

                                        {{ $t('Sessions.From') }}  : {{ rowData.session.from }}

                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="session.to" style="min-width: 14rem">

                                        {{ $t('Sessions.To') }} : {{ rowData.session.to }}

                                    </PrimeColumn>

                                    <PrimeColumn :header="$t('Sessions.Session_Location')" field="session.onsite" style="min-width: 10rem">
                                        {{ rowData.session.onsite }}
                                    </PrimeColumn>

                                    <!-- <PrimeColumn header="Actions">

                                        <template #body="rowData">
                                            <PrimeButton label="Accept" @click="accept(rowData.data.session.id,
                                                rowData.data.session.date,
                                                rowData.data.session.from, rowData.data.session.to,
                                                rowData.data.session.doctor_id,
                                                rowData.data.session.user_id)">
                                            </PrimeButton>
                                        </template>
                                    </PrimeColumn> -->

                                </DataTable>

                            </TabPanel>
                            <TabPanel :header="$t('Sessions.Accepted_Requests')">
                                <DataTable :value="acceptedSessions"  class="p-datatable-gridlines custom-paginator"
                                    :rows="10" dataKey="id" :rowHover="true" :loading="loading2" responsiveLayout="scroll" >
                                    <template #empty>
                                        <div v-if="acceptedSessions.length == 0">{{ $t('Sessions.No_Sessions') }}</div>
                                    </template>
                                    <template #loading>
                                        {{ $t('Sessions.Loading_Sessions') }} 
                                    </template>
                                    <PrimeColumn field="name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                        {{ acceptedSessions.name }}
                                    </PrimeColumn>
                                    <PrimeColumn field="session.date" :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                        {{ pendingSessions.session.date }}
                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="session.from" style="min-width: 14rem">

                                        {{ $t('Sessions.From') }}  : {{ rowData.session.from }}

                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_Time_To')"  field="session.to" style="min-width: 14rem">

                                        {{ $t('Sessions.To') }} : {{ rowData.session.to }}

                                    </PrimeColumn>

                                    <PrimeColumn :header="$t('Sessions.Session_Location')"  field="session.onsite" style="min-width: 10rem">
                                        {{ rowData.session.onsite }}
                                    </PrimeColumn>
                                </DataTable>



                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</div>
</template>
  
<script>
import axios from 'axios';
import UserNavbar from './UserNavbar.vue';
import Footer from '../Footer.vue';
export default {
    data() {
        return {
            pendingSessions: [],
            acceptedSessions: [],
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
        };
    },
  
    name: 'sessions-requests-page',
    methods: {
        async withdrawSession(sessionId) {
            try {
           
                await axios.delete(`https://backend.ihayanow.com/api/user/request-session/${sessionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                // remove session from pending sessions list
                this.pendingSessions = this.pendingSessions.filter((session) => session.session.id !== sessionId);
            } catch (error) {
                console.error(error);
            }
        },
        formatTime(time) {
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours);
            const amPm = hour >= 12 ? 'pm' : 'am';
            hour = hour % 12 || 12;
            return `${hour}:${minutes} ${amPm}`;
        },
        async fetchSessions() {
            try {
                
                const response = await axios.get('https://backend.ihayanow.com/api/user/pending-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                         'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                this.pendingSessions = response.data;
                this.pendingSessions.forEach(session => {
                    if (session.session.onsite == 0) {
                        session.session.onsite = this.$t('Online')
                    } else {
                        session.session.onsite =this.$t('Onsite');

                    }
                });
                const response2 = await axios.get('https://backend.ihayanow.com/api/user/accepted-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                         'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                this.acceptedSessions = response2.data;
                this.acceptedSessions.forEach(session => {
                    if (session.session.onsite == 0) {
                        session.session.onsite = this.$t('Online')
                    } else {
                        session.session.onsite = this.$t('Onsite');

                    }
                });
            } catch (error) {
                console.error(error);
            }
        },

       
    },
    mounted() {
        this.fetchSessions();
    },
   computed:{
    pageinator() {
        return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
   },
      components: {
        UserNavbar,
        Footer
        
      }
};
</script>
  


<style scoped>
.custom-paginator .p-paginator-first,
.custom-paginator .p-paginator-prev,
.custom-paginator .p-paginator-next,
.custom-paginator .p-paginator-last {
  direction: ltr !important; 
}

</style>