<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
          <div class="layout-main">
            <div class="grid">
              <div class="col-12">
                <div class="card">
                  <h5>Reports</h5>
                  <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
                  </PrimeMessage>
                  <TabView>
                    <TabPanel header="All Reports">
                      <DataTable :value="reports" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                        :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                        <template #empty>
                          <div v-if="reports.length == 0">No Reports</div>
                        </template>
                        <template #loading>
                          Loading Reports data. Please wait.
                        </template>
                        <!-- <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                          <template #body="rowData">
                            <div class="img-container">
                              <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.profile.photo" alt="Doctor Photo" />
    
                            </div>
                          </template>
                        </PrimeColumn> -->
                        <PrimeColumn field="from" header="Report From" style="min-width: 12rem">
                          {{ rowData.from }}
                        </PrimeColumn>
                        <PrimeColumn field="to" header="Report To" style="min-width: 12rem">
                          {{ rowData.to }}
                        </PrimeColumn>
                        <PrimeColumn field="session_id" header="Session ID" style="min-width: 12rem">
                          {{ rowData.session_id }}
                        </PrimeColumn>
                        <PrimeColumn field="session_date" header="Session Date" style="min-width: 12rem">
                          {{ rowData.session_date }}
                        </PrimeColumn>
                        <PrimeColumn field="session_from" header="Session Time From" style="min-width: 12rem">
                        {{ rowData.session_from }}
                        </PrimeColumn>
                        <PrimeColumn field="session_to" header="Session Time To" style="min-width: 12rem">
                       {{ rowData.session_to }}
                        </PrimeColumn>
                        <PrimeColumn field="reason" header="Report Reason" style="min-width: 12rem">
                       {{ rowData.reason }}
                        </PrimeColumn>
                        <PrimeColumn header="Actions" style="min-width: 10rem">
                          <template #body="rowData">
                            <PrimeButton class="mr-2 mb-2" icon="pi pi-check"
                             @click="accept(rowData.data.id)">
                          </PrimeButton>
                          <PrimeButton class="mr-2 mb-2 p-button-danger" icon="pi pi-times"
                            @click="reject(rowData.data.id)"></PrimeButton>
                          </template>
                        </PrimeColumn>
                      </DataTable>
    
                    </TabPanel>  
                    <TabPanel header="Accepted Reports">
                      <DataTable :value="acceptedReports" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                        :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                        <template #empty>
                          <div v-if="acceptedReports.length == 0">No Accepted Reports</div>
                        </template>
                        <template #loading>
                          Loading Accepted Reports data. Please wait.
                        </template>
                     
                        <PrimeColumn field="from" header="Report From" style="min-width: 12rem">
                          {{ rowData.from }}
                        </PrimeColumn>
                        <PrimeColumn field="to" header="Report To" style="min-width: 12rem">
                          {{ rowData.to }}
                        </PrimeColumn>
                        <PrimeColumn field="session_id" header="Session ID" style="min-width: 12rem">
                          {{ rowData.session_id }}
                        </PrimeColumn>
                        <PrimeColumn field="session_date" header="Session Date" style="min-width: 12rem">
                          {{ rowData.session_date }}
                        </PrimeColumn>
                        <PrimeColumn field="session_from" header="Session Time From" style="min-width: 12rem">
                        {{ rowData.session_from }}
                        </PrimeColumn>
                        <PrimeColumn field="session_to" header="Session Time To" style="min-width: 12rem">
                       {{ rowData.session_to }}
                        </PrimeColumn>
                        <PrimeColumn field="reason" header="Report Reason" style="min-width: 12rem">
                       {{ rowData.reason }}
                        </PrimeColumn>
                     
                      </DataTable>
    
                    </TabPanel>  
                    <TabPanel header="Rejected Reports">
                      <DataTable :value="rejectedReports" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                        :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                        <template #empty>
                          <div v-if="rejectedReports.length == 0">No Rejected Reports</div>
                        </template>
                        <template #loading>
                          Loading Rejected Reports data. Please wait.
                        </template>
                       
                        <PrimeColumn field="from" header="Report From" style="min-width: 12rem">
                          {{ rowData.from }}
                        </PrimeColumn>
                        <PrimeColumn field="to" header="Report To" style="min-width: 12rem">
                          {{ rowData.to }}
                        </PrimeColumn>
                        <PrimeColumn field="session_id" header="Session ID" style="min-width: 12rem">
                          {{ rowData.session_id }}
                        </PrimeColumn>
                        <PrimeColumn field="session_date" header="Session Date" style="min-width: 12rem">
                          {{ rowData.session_date }}
                        </PrimeColumn>
                        <PrimeColumn field="session_from" header="Session Time From" style="min-width: 12rem">
                        {{ rowData.session_from }}
                        </PrimeColumn>
                        <PrimeColumn field="session_to" header="Session Time To" style="min-width: 12rem">
                       {{ rowData.session_to }}
                        </PrimeColumn>
                        <PrimeColumn field="reason" header="Report Reason" style="min-width: 12rem">
                       {{ rowData.reason }}
                        </PrimeColumn>
                       
                      </DataTable>
    
                    </TabPanel> 
                  </TabView>
    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <script>
    import axios from 'axios';
    import AppTopBar from '@/AppTopbar.vue';
    import AppMenu from '@/AppMenu.vue';
    // import moment from 'moment';

    export default {
      name:'AppReports',
      data() {
        return {
          apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
          success:false,
          reports: [],
          rejectedReports:[],
          acceptedReports:[],
          message:'',
          errors:{},
          layoutMode: 'static',
          staticMenuInactive: false,
          overlayMenuActive: false,
          mobileMenuActive: false,
          menu: [
            {
              label: 'Home', icon: 'pi pi-fw pi-home',
              items: [
              { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
                // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },
    
              ]
    
            },
            {
              label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
              items: [
                { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
                { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },
    
              ]
            },
            {
              label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
              items: [
                
              { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
              { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },
    
                { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
              ]
            },
            {
              label: 'Medication', icon: 'pi pi-fw pi-globe',
              items: [
                 { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
                { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
              ]
            },
            {
              label: 'Doctors',icon: 'pi pi-fw pi-users',
              items: [
                { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
                // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

              ]
            },
            {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
            {
              label: 'Sessions', icon: 'pi pi-fw pi-clock',
              items: [
                { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
             
    
              ]
            },
            {
              label: 'Reports', icon: 'pi pi-fw pi-file',
              items: [
                { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
             
    
              ]
            },
            {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
          ]
        };
      },
    
      methods: {
        async logout() {
          await axios.post('https://backend.ihayanow.com/api/logout', null, {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            }
          })
            .then(response => {
              console.log(response);
              localStorage.clear(); // Clear all items in local storage
    
              this.$router.push('/login-page');
    
            })
            .catch(error => {
              console.log(error);
            });
        },

        async accept(id){
   console.log(id);
   await axios.put(`https://backend.ihayanow.com/api/admin/reports/${id}`,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          this.message=response.data.message;
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.errors = error.response.data.message;

        });
        },
        async reject(id){
       console.log(id);
   await axios.delete(`https://backend.ihayanow.com/api/admin/reports/${id}`,{
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          this.message=response.data.message;
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.errors = error.response.data.message;

        });
        },
         
//     getTime(time) {
//         console.log(time);
//         if (time) {
//     const formattedTime = moment(time, 'HH:mm:ss').format('h:mm A');
//     return formattedTime;
//   } else {
//     return '';
//   }
     
//       },
      
    
        onWrapperClick() {
          if (!this.menuClick) {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
          }
    
          this.menuClick = false;
        },
        onMenuToggle() {
          this.menuClick = true;
    
          if (this.isDesktop()) {
            if (this.layoutMode === 'overlay') {
              if (this.mobileMenuActive === true) {
                this.overlayMenuActive = true;
              }
    
              this.overlayMenuActive = !this.overlayMenuActive;
              this.mobileMenuActive = false;
            }
            else if (this.layoutMode === 'static') {
              this.staticMenuInactive = !this.staticMenuInactive;
            }
          }
          else {
            this.mobileMenuActive = !this.mobileMenuActive;
          }
    
          event.preventDefault();
        },
        onSidebarClick() {
          this.menuClick = true;
        },
        onMenuItemClick(event) {
          if (event.item && !event.item.items) {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
          }
        },
        onLayoutChange(layoutMode) {
          this.layoutMode = layoutMode;
        },
        addClass(element, className) {
          if (element.classList)
            element.classList.add(className);
          else
            element.className += ' ' + className;
        },
        removeClass(element, className) {
          if (element.classList)
            element.classList.remove(className);
          else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
          return window.innerWidth >= 992;
        },
        isSidebarVisible() {
          if (this.isDesktop()) {
            if (this.layoutMode === 'static')
              return !this.staticMenuInactive;
            else if (this.layoutMode === 'overlay')
              return this.overlayMenuActive;
          }
    
          return true;
        }
    
      },
    
      beforeUpdate() {
        if (this.mobileMenuActive)
          this.addClass(document.body, 'body-overflow-hidden');
        else
          this.removeClass(document.body, 'body-overflow-hidden');
      },
      components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
      },
    
      async created() {
       await axios.get('https://backend.ihayanow.com/api/admin/reports', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            }
          }).then(response => {
            this.reports = response.data;
            console.log(this.reports)

        })
        .catch(error => {
          console.log(error)
        });
   
       
        await axios.get('https://backend.ihayanow.com/api/admin/accepted-reports', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            }
          }).then(response => {
            this.acceptedReports = response.data;
            console.log(this.reports)

        })
        .catch(error => {
          console.log(error)
        });

        await axios.get('https://backend.ihayanow.com/api/admin/rejected-reports', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            }
          }).then(response => {
            this.rejectedReports = response.data;
            console.log(this.reports)

        })
        .catch(error => {
          console.log(error)
        });
      },
    
      computed:{
        containerClass() {
          return ['layout-wrapper', {
            'layout-overlay': this.layoutMode === 'overlay',
            'layout-static': this.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.mobileMenuActive,
            'p-input-filled': this.$primevue.config.inputStyle === 'filled',
            'p-ripple-disabled': this.$primevue.config.ripple === false
          }];
        },
      }
    }
    
    
    </script>
    
    <style scoped>
    .doctor-photo {
      width: 150px; /* Adjust the width as per your requirements */
      height: 150px; /* Adjust the height as per your requirements */
      object-fit: cover; /* Ensure the image covers the entire space */
    }



.layout-topbar {
   
   background-color: var(--surface-card);
   }
    </style>