import { createI18n } from 'vue-i18n'
import en from './locales/english.json'
import ar from './locales/arabic.json'

function loadLocaleMessages() {
  const locales = [{ en: en }, { ar: ar }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}
const isAdminLoggedIn = localStorage.getItem('type'); // Change this according to your logic
// window.location.reload();
// isAdminLoggedIn == "Admin" ? 'en' : 
console.log(isAdminLoggedIn)
export default createI18n({
  locale: 'ar',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})



