<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <!-- <div class="layout-main"> -->
      <div class="grid">
        <div class="col-12">
          <div class="card ">
            <h2>Insights Of Users</h2>

            <div class="chart-row">
              <div class="chart-container ">
                <h5 class="align-self-start">Total Users, Doctors, and Admins</h5>
                <PrimeChart type="pie" :data="pieData" :options="pieOptions" style="width: 50%" />
              </div>
              <div class="chart-container">
                <h5 class="align-self-start">Approved and Pending Doctors</h5>
                <PrimeChart type="pie" :data="pieDataa" :options="pieOptions" style="width: 50%" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card ">

            <h2>Insights Of Sessions</h2>
            <div class="chart-row">
              <div class="chart-container ">
                <h5>Website Sessions </h5>
                <PrimeChart type="line" :data="lineData" :options="lineOptions" />
                <PrimeChart type="line" :data="lineData2" :options="lineOptions" />

              </div>
              <div class="chart-container ">
                <h5>Zoom Sessions</h5>
                <PrimeChart type="bar" :data="barData" :options="barOptions" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card ">
            <h2>Most Reserved Doctors And Psychologists</h2>
            <div class="flex md:justify-content-center">
              <ul class="m-0 p-0 list-none  p-3 
        flex flex-column gap-2 w-full md:w-30rem">
                <li v-for="user in filteredUsers" :key="user.id"
                  :class="['p-2 hover:surface-hover border-round border-1 border-transparent transition-all transition-duration-200 flex align-items-center justify-content-between', { 'border-primary': selectedUser?.id === user.id }]"
                  @click="goToProfile(user.mostReservedDoctors.id)">
                  

 
                  <div class="flex align-items-center gap-2">
                    <img :alt="user.name" :src="'https://backend.ihayanow.com/' + user.mostReservedDoctors.photo"
                      style="width: 32px" />
                    <span class="font-bold">{{ user.mostReservedDoctors.professional == "Psychologist" ? "Psy." : "Dr." }}
                      {{
                        user.doctor_name }}</span>
                  </div>
                  <span class="font-bold text-900 ml-5">{{ user.session_counter }} Sessions</span>
                
                </li>
              </ul>
            </div>
          </div>

        </div>


      </div>
      <!-- </div> -->
    </div>

  </div>
  <!-- <Footer /> -->
</template>

<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
import { ref } from 'vue';

// import Footer from '../Footer.vue';
export default {
  name:'AdminDashboard',
  
  data() {
    return {
      users: ref(),
      selectedUser: null,
      doctors: [],
      pendings: [],
      lineOptions: null,
      pieOptions: null,
      barOptions: null,
      totalUsers: 0,
      totalDoctors: 0,
      totalAdmins: 0,
      approvedDoctors: 0,
      pendingDoctors: 0,
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      hasFetched: false,
      lineData2: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
          },
        
        ]
      },
      lineData: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
          },
          
        ]
      },
      onlineSessions: [],
      onsiteSessions: [],
      zoomSessions: [],

      barData: {
        labels: [],
        datasets: [
          {
            label: 'Zoom Sessions',
            backgroundColor: '#2f4860',
            data: []
          },

        ]
      },
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
            { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
          items: [

            { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
            { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
          label: ' Patient Label', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to: "/add-label-patient" },
            { label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to: "label-patient" }
          ]
        },
        {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


          ]
        },

        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


          ]
        },
        {
          label: '', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
          ]

        },
        {
          label: '',
          items: [
            { label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
          ]
        },
        {
          label: '',
          items: [
            { label: 'Logout', icon: 'pi pi-power-off', command: this.logout }
          ]

        },

      ],
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
    };
  },
  async mounted() {

    if (localStorage.getItem('type') == "Admin") {
      console.log("hi");
      document.documentElement.dir = 'ltr';

    }

    this.fetchInsights();
    await axios.get('https://backend.ihayanow.com/api/admin/reserved-doctors', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Api-Key': this.apiKey,
        'Api-Secret': this.apiSecret,
      }
    }).then(response => {
      this.users = ref(response.data);

      console.log(this.users);
    }).catch(error => {
      console.log(error);
    })

    await axios.get('https://backend.ihayanow.com/api/admin/sessions-per-month', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Api-Key': this.apiKey,
        'Api-Secret': this.apiSecret,
      }
    }).then(response => {
      console.log(response);
      this.onlineSessions = response.data.OnlineSessions;
      this.onsiteSessions = response.data.OnsiteSessions;
      this.zoomSessions = response.data.ZoomSessions;

      // Extracting keys (month names) and values (session counts) from the object
      const labels = Object.keys(this.zoomSessions);
      const data = Object.values(this.zoomSessions);

      const labels2 = Object.keys(this.onlineSessions);
      const data2 = Object.values(this.onlineSessions);
      const labels3 = Object.keys(this.onsiteSessions);
      const data3 = Object.values(this.onsiteSessions);
      this.lineData = {
        labels: labels2,

        datasets: [
          {
            label: 'Online Sessions',
            backgroundColor: '#ec4899',
            data: data2,
          },

        ],
      }
      this.lineData2 = {
        labels: labels3,

        datasets: [
        

          {
            label: 'Onsite Sessions',
            backgroundColor: '#3b82f6',
            data: data3,
          },

        ],
      }
      this.barData = {
        labels: labels,

        datasets: [
          {
            label: 'Zoom Sessions',
            backgroundColor: '#ec4899',
            data: data,
          },


        ],
      };
      console.log(this.barData);
    }).catch(error => {
      console.log(error);
    })




  },


  methods: {
    goToProfile(profileId) {
      this.$router.push({ name: 'TherapistProfile', params: { id: profileId } })

    },
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage
          this.$i18n.locale = 'ar';
          // window.location.reload(); // Reload the page to apply the updated direction

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    applyLightTheme() {

      this.pieOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        }
      };

      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
      this.barOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
    },
    fetchInsights() {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`, 'Api-Key': this.apiKey,
        'Api-Secret': this.apiSecret,
      };

      // Fetch total user, doctor, and admin counts
      axios.get('https://backend.ihayanow.com/api/admin/get-user-counts', { headers })
        .then(response => {
          this.totalUsers = response.data.totalUsers;
          this.totalDoctors = response.data.totalDoctors;
          this.totalAdmins = response.data.totalAdmins;


        })
        .catch(error => {
          console.error(error);
        });

      // Fetch approved and pending doctor counts
      axios.get('https://backend.ihayanow.com/api/admin/get-doctor-counts', { headers })
        .then(response => {
          this.approvedDoctors = response.data.approvedDoctors;
          this.pendingDoctors = response.data.pendingDoctors;


          this.showDoctorInsights = true;
        })
        .catch(error => {
          console.error(error);
        });


    },
    calculateDuration(from, to) {
      const fromParts = from.split(':');
      const toParts = to.split(':');


      const fromSeconds = (+fromParts[0]) * 60 * 60 + (+fromParts[1]) * 60;
      const toSeconds = (+toParts[0]) * 60 * 60 + (+toParts[1]) * 60;
      let diff = toSeconds - fromSeconds;
      if (diff < 0) {
        diff += 24 * 60 * 60; // add 1 day if end time is earlier than start time
      }
      const hours = Math.floor(diff / (60 * 60));
      const minutes = Math.floor((diff / 60) % 60);
      const duration = `${hours} hours ${minutes} minutes`;

      return duration;
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }

  },
  computed: {
    filteredUsers() {
      const filtered = [];
      console.log(this.users);
      if (Array.isArray(this.users)) {
        this.users.forEach(user => {
          if (user.session_counter !== null) {
            filtered.push(user);
          }
        });
      }

      return filtered;
    },
    pieData() {
      return {
        labels: ['totalUsers', 'totalDoctors', 'totalAdmins'],
        datasets: [
          {
            data: [this.totalUsers, this.totalDoctors, this.totalAdmins],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
          }
        ]
      };
    },
    pieDataa() {
      return {
        labels: ['Pending Doctors', 'Accepted Doctors'],
        datasets: [
          {
            data: [this.pendingDoctors, this.approvedDoctors],
            backgroundColor: ["#FF6384", "#36A2EB"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB"]
          }
        ]
      };
    },
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },

  },


  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },

}

</script>


<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }

.chart-row {
  display: flex;
  flex-direction: row;
}
.layout-topbar {
   
   background-color: var(--surface-card);}
.chart-container {
  flex: 1;
  margin-right: 10px;
}
</style>




