<template>
  <div id='sessionContainer'></div>
</template>


<script>
import axios from 'axios';
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
export default {
  name: 'SessionZoom',
  data() {
    return {
      config: {},
      SDK_KEY: null,
      SDK_SECRET: null,
      session_name: "",
      // session_password: "",
      userName: "",
      sessionId: null,
      client: null,
      canvas: null,
      stream: null,
      isVideoOn: false,
      isAudioOn: false,
      isShare: false,
      participants: [],
      currentUser: null,

      VIDEO_QUALITY_180P: 1,
      VIDEO_CANVAS_DIMS: {
        Width: 1280,
        Height: 640,
      },
      VIDEO_CANVAS: null,
      renderedList: [],

      signature: '',
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      sessionContainer: '',
      joined_user_name: '',
      joined_session_id: '',

      sessionClosed:'',
    }
  },



  methods: {

    async loadPage() {
      await this.join_meeting(false);
    },
    async join_meeting(fromButtonClick) {
      if (fromButtonClick) {
        // Called from the join button click
        this.sessionId = this.$route.params.sessionId;
        // Access the sessionId parameter in your component logic
        console.log('Session ID:', this.sessionId);
      }


      const result = await axios.get("https://backend.ihayanow.com/api/joinSession", {
        params: { session_id: this.$route.params.sessionId }, headers: {
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      });

      console.log(result);
      if (this.$route.params.joined_user_name == result.data.doctor_name) {
        this.signature = result.data.signature;
      } else if (this.$route.params.joined_user_name == result.data.user_name) {
        this.signature = result.data.user_signature;
      }

      this.session_name = result.data.topic;
      this.currentUser = result.data;
      this.joined_user_name = this.$route.params.joined_user_name;
      this.joined_session_id = this.$route.params.sessionId;
      console.log(this.joined_user_name);
      this.config = {
        videoSDKJWT: this.signature,
        sessionName: this.session_name,
        userName: this.joined_user_name,
        sessionPasscode: '',
        features: ['video', 'audio', 'share', 'chat', 'users', 'settings']
      }
      this.sessionContainer = document.getElementById('sessionContainer')
      uitoolkit.joinSession(this.sessionContainer, this.config)
      

       this.sessionClosed = (() => {
         const res=axios.get("https://backend.ihayanow.com/api/doctor/updateSession", {
        params: { session_id: this.$route.params.sessionId }, headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      });
      console.log(res);
        this.$router.push({ name: 'rate', params: { sessionId: this.joined_session_id } });
      })
      uitoolkit.onSessionClosed( this.sessionClosed)
    },





  },

  created() {
    this.SDK_KEY = process.env.VUE_APP_SDK_KEY;
    this.SDK_SECRET = process.env.VUE_APP_SDK_SECRET;
    console.log("key");
    console.log(this.SDK_KEY);
    console.log(this.SDK_SECRET);
    console.log(process.env);
    this.loadPage();


  },






}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#my-screen-share-content-video,
#my-screen-share-content-canvas {
  width: 100%;
  height: 100%;
}

#participants-screen-share-content-canvas {
  width: 100%;
  height: 100%;
}

#my-self-view-video,
#my-self-view-canvas {
  width: 50%;
  height: 60%;
  position: absolute;
  top: 0%;
  left: 0%;

}

#participant-videos-canvas {
  width: 50%;
  height: 60%;
  position: absolute;
  top: 0%;
  right: 0%;

}

@import './../../public/zoom.css';
</style>