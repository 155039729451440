<template>
    <component :is="navbarComponent" />

    <div class="containerr">

        <!-- <div class="layout-main-container"> -->
        <div class="layout-main">
            <div class="grid justify-content-center">
                <div class="">
                    <div class="card">

                        <h1 class="text-center">{{ $t('rate.Rating') }}</h1>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.rate"
                            v-for="(error, index) in errors.rate" :key="index">
                            {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <h5 class="text-center">{{ $t('rate.Session Rate') }}</h5>
                        <PrimeRating class="text-center" v-model="session_rating" :cancel="false"></PrimeRating>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.session_rating"
                            v-for="(error, index) in errors.session_rating" :key="index">
                            {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <h5 class="text-center" v-if="userType === 'User'">{{ $t('rate.Doctor Rate') }} </h5>
                        <PrimeRating class="text-center" v-if="userType === 'User'" v-model="doctor_rating" :cancel="false">
                        </PrimeRating>
                        <h5 class="text-center" v-if="userType === 'Doctor'">{{ $t('rate.Patient Rate') }} </h5>
                        <PrimeRating class="text-center" v-if="userType === 'Doctor'" v-model="user_rating" :cancel="false">
                        </PrimeRating>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.doctor_rating"
                            v-for="(error, index) in errors.doctor_rating" :key="index">
                            {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.user_rating"
                            v-for="(error, index) in errors.user_rating" :key="index">
                            {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <h5 class="text-center">{{ $t('rate.Any Other Opinion') }}</h5>
                        <PrimeTextarea :placeholder="$t('rate.Your Opinion')" :autoResize="true" rows="9" cols="70"
                            v-model="opinion" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.review"
                            v-for="(error, index) in errors.review" :key="index">
                            {{ translateErrorMessage(error) }}
                        </InlineMessage>

                        <div class="button-container">
                            <PrimeButton @click="submit_rate()" type="submit" class=" p-3 text-xl">{{ $t('save') }}
                            </PrimeButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->


    </div>
    <Footer />
</template>
  
<script>
import UserNavbar from './User/UserNavbar.vue';
import DoctorNavbar from './Doctor/DoctorNavbar.vue';
import axios from 'axios';
import Footer from './Footer.vue';

export default {
    name: 'AppRate',
    data() {
        return {
            rating: 0,
            errors: {},
            session_rating: 0,
            doctor_rating: 0,
            user_rating: 0,
            userType: '',
            opinion: '',
            navbarComponent: null,
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,

        };
    },
    mounted() {
        this.userType = localStorage.getItem("type");
        console.log(this.userType)




        // Check the type from localStorage and set the navbar component accordingly
        const type = localStorage.getItem("type");
        this.navbarComponent = type === "User" ? UserNavbar : DoctorNavbar;



    },
    methods: {


        async submit_rate() {

            if (this.userType == "User") {
                console.log(this.session_rating, this.opinion, this.user_rating);
            await axios.post('https://backend.ihayanow.com/api/user/rate-doctor', {
                rate: this.doctor_rating ,
                session_rate: this.session_rating,
                review: this.opinion,
                session_id:this.$route.params.sessionId

            }, {
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            })
                .then(response => {
                
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
            }else{
                console.log(this.session_rating, this.$route.params.sessionId,this.opinion, this.user_rating);
            await axios.post('https://backend.ihayanow.com/api/doctor/rate-user', {
                rate: this.user_rating ,
                session_rate: this.session_rating,
                review: this.opinion,
                session_id:this.$route.params.sessionId
            }, {
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            })
                .then(response => {
                
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
            }

        },


    },

    components: {
        UserNavbar,
        DoctorNavbar,
        Footer

    },



    async created() {




    },

}


</script>



<style>

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>