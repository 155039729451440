<template>
  <div class="container">


    <Navbar />
    <div class="layout-main ">
      <div class="grid">
        <form @submit.prevent="forgotPassword">

          <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">

            <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
              <div class="col-12 mt-5 xl:mt-0 text-center">
                <h1>{{ $t('Forget Password') }}</h1>
              </div>
          
              <div class="col-12 xl:col-6"
                style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4"
                  style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                

                  <div class="w-full md:w-10 mx-auto">
                    <label for="email1" class="block text-900 text-xl font-medium mb-2">{{ $t('email') }}</label>
                  <input id="email1" v-model="email" type="text" class="w-full mb-3" :placeholder="$t('email')"
                    style="padding:1rem;  border: 2px; border-radius: 4px;" />
                  <div v-if="errors">
                    <InlineMessage class="w-full mt-3 mb-3" v-show="errors && errors.email"
                      v-for="(error, index) in errors.email" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>

                  <label for="password1" class="block text-900 font-medium text-xl mb-2">{{ $t('profile.New Password')
                  }}</label>
                  <input type="password" id="password1" v-model="password" :placeholder="$t('profile.New Password')"
                    :toggleMask="true" style="padding:1rem; border: 2px; border-radius: 4px;" class="w-full mb-3"
                    inputClass="w-full" inputStyle="padding:1rem" />
                  <div v-if="errors">
                    <InlineMessage class="w-full mt-3 mb-3" v-show="errors && errors.password"
                      v-for="(error, index) in errors.password" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                 
                    <div class="row edit-button text-center pt-3">
                      <PrimeButton value="Change" type="submit" class="text-xl">{{ $t('menu.change') }}</PrimeButton>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>

      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../Navbar.vue';
import Footer from '../Footer.vue';
import axios from 'axios';


export default {

  data() {
    return {
      email: '',
      password: '',
      success: false,
      showInlineMessage: false,
      showNoEmailMessage: false,
      showNoPasswordMessage: false,
      errors: {},
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,

    }
  },
  name: 'forget-password',
  methods: {
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    async forgotPassword() {


      try {
        await axios.post('https://backend.ihayanow.com/api/forgot-password',
          {
            email: this.email,
            password: this.password
          },
          {

            headers: {
              'Content-Type': 'application/json',
              'Api-Key': this.apiKey,
              'Api-Secret': this.apiSecret,
            },

          }).then(response => {

            this.errors = response.data.message;
            console.log(this.errors);
            if (response.data.success == true) {
              localStorage.setItem('password', this.password);
              this.$router.push({ name: 'VerifyPassword', params: { email: this.email } });

            } else {
              if (response.data.message === "Invalid Credentials") {
                this.showNoEmailMessage = true; // Show the error message
              }
              if (response.data.message === "Invalid Credentials") {
                this.showNoPasswordMessage = true; // Show the error message
              }
              if (response.data.message.email[0] === "The email must be a valid email address.") {
                this.showInlineMessage = true; // Show the error message
              }
            }
          }).catch(error => {
            console.error(error)
            this.errors = error.response.data.errors;

          });

      } catch (error) {
        console.error(error)
      }
    },

  },
  computed: {
    isEmailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    }
  },
  components: {
    Navbar,
    Footer
  }
}
</script>
<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>