<template>
    <div class="container">

        <DoctorNavbar />


        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <h5>{{ $t("menu.Sessions") }}</h5>
                            <transition-group name="p-message" tag="div">
                                <PrimeMessage class="w-50" v-for="msg of message" :severity="msg.severity"
                                    :key="msg.content">
                                    {{ msg.content }}</PrimeMessage>
                                <PrimeMessage severity="error" v-show="this.successMessages">
                                    {{ $t(`${this.successMessage}`) }}</PrimeMessage>
                            </transition-group>
                            <TabView>
                                <TabPanel :header="$t('Sessions.Upcomming_Sessions')">
                                    <DataTable :value="upcommingsessions" class="p-datatable-gridlines" :rows="10"
                                        dataKey="id" :rowHover="true" :loading="loading1" responsiveLayout="scroll"
                                        :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">

                                        <template #empty>
                                            <div v-if="upcommingsessions.length == 0"> {{ $t('Sessions.No_Sessions') }}
                                            </div>
                                        </template>
                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                        <PrimeColumn field="user_name" :header="$t('Sessions.Patient_Name')"
                                            style="min-width: 12rem">
                                            {{ this.user_name }}
                                        </PrimeColumn>
                                        <PrimeColumn field="date" :header="$t('Sessions.Session_Date')"
                                            style="min-width: 12rem">
                                            {{ rowData.date }}
                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="from"
                                            style="min-width: 14rem">

                                            <!-- {{ this.$t("Sessions.From") }} : -->
                                            {{ rowData.from }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="to"
                                            style="min-width: 14rem">

                                            <!-- {{ this.$t("Sessions.To") }} :  -->
                                            {{ rowData.to }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Sessions_Duration')" field="duration"
                                            style="min-width: 10rem">
                                            {{ this.duration }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Location')" field="onsite"
                                            style="min-width: 10rem">
                                            {{ this.onsite }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Actions">

                                            <template #body="rowData">
                                                <PrimeButton
                                                    v-if="rowData.data.onsite == 'Online' || rowData.data.onsite == 'أونلاين'"
                                                    :disabled="!isSessionInProgress(rowData.data.from, rowData.data.to)"
                                                    @click="(this.joinMeetingLabel && (this.isCreated[rowData.data.id] || !iscreate)) ? joinMeeting(rowData.data.id,rowData.data.doctor_name) : createmeeting(rowData.data.id, rowData.data.doctor_id, rowData.data.user_id)">
                                                    {{ (this.joinMeetingLabel && (this.isCreated[rowData.data.id] ||
                                                        !iscreate)) ? $t('Sessions.Join Meeting') : $t('Sessions.Create Session Meeting') }}
                                                </PrimeButton>


                                                <PrimeButton
                                                    :disabled="isSessionInProgress(rowData.data.from, rowData.data.to)"
                                                    class="mr-2  p-button-danger" icon="pi pi-trash"
                                                    @click="reject(rowData.data.id)">
                                                </PrimeButton>
                                            </template>
                                        </PrimeColumn>
                                    </DataTable>

                                </TabPanel>
                                <TabPanel :header="$t('Sessions.History_Sessions')">
                                    <DataTable :value="sessions" class="p-datatable-gridlines" :rows="10" dataKey="id"
                                        :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                                        <template #empty>
                                            <div v-if="sessions.length == 0">{{ $t('Sessions.No_Sessions') }}</div>
                                        </template>
                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                        <PrimeColumn field="user_name" :header="$t('Sessions.Patient_Name')"
                                            style="min-width: 12rem">
                                            {{ this.user_name }}
                                        </PrimeColumn>
                                        <PrimeColumn field="date" :header="$t('Sessions.Session_Date')"
                                            style="min-width: 12rem">
                                            {{ rowData.date }}
                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="from"
                                            style="min-width: 14rem">

                                            {{ rowData.from }}

                                        </PrimeColumn>

                                        <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="to"
                                            style="min-width: 14rem">

                                            {{ rowData.to }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Sessions_Duration')" field="duration"
                                            style="min-width: 10rem">
                                            {{ this.duration }}

                                        </PrimeColumn>

                                        <PrimeColumn :header="$t('Sessions.Session_Location')" field="onsite"
                                            style="min-width: 10rem">
                                            {{ this.onsite }}
                                        </PrimeColumn>

                                        <!-- <PrimeColumn v-if="show" :header="$t('LabelPatient')" :field="this.userLabel"
                                            style="min-width: 10rem">
                                            {{ this.userLabel }}
                                        </PrimeColumn>  -->
                                        <!-- <PrimeColumn> -->
                                        <!-- <template #body="rowData"> -->
                                        <!-- <PrimeDropdown v-model="selectedLabels[rowData.data.id]"  :options="label_ids" optionLabel="name" 
                                                 @change="chooseLabel(rowData.data.id, rowData.data.user_id)"></PrimeDropdown> 
                                               $router.push({ name: 'user-profile', params: { {{ rowData.data.user_id }} } })  -->
                                        <!-- {{ rowData }} -->
                                        <!-- <PrimeButton @click="goToProfile(rowData.data.user_id)">{{
                                                    $t("file.View Patient File") }}</PrimeButton> -->
                                        <!-- </template> -->
                                        <!-- </PrimeColumn> -->

                                    </DataTable>



                                </TabPanel>

                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import axios from 'axios';
import DoctorNavbar from './DoctorNavbar.vue';
import Footer from '../Footer.vue';
export default {

    data() {
        return {
            successMessages: false,
            successMessage: '',
            selectedLanguage: '',
            sessions: [],
            upcommingsessions: [],
            message: [],
            joinMeetingLabel: false,
            session_id: '',
            session_doctor_id: '',
            session_user_id: '',
            joined_session_id: '',
            label_ids: [],
            label: '',
            labelOptions: [],
            selectedLabel: '',
            choosenLabel: '',
            selectedLabels: {},
            user_name: '',

            isCreated: {},
            session_from: '',
            session_to: '',

            userLabel: '',
            onsite: '',
            duration: '',
            UserId: '',
            show: false,
            iscreate: false,
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
        };
    },
    components: {
        DoctorNavbar,
        Footer,
    },

    methods: {


        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },

        formatTime(time) {
            const [hours, minutes] = time.split(':').map(Number); // Split the time string and convert parts to numbers
            let formattedTime = '';

            // Validate the hour and minute values
            if (!isNaN(hours) && !isNaN(minutes)) {
                let hour = hours % 12 || 12; // Get the hour in 12-hour format

                // Determine if it's AM or PM based on the hour value
                const amPm = hours >= 12 ? this.$t('pm') : this.$t('am');

                // Format the hour and minute with leading zeros if necessary
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');

                formattedTime = `${formattedHour}:${formattedMinutes} ${amPm}`;
            } else {
                // Handle invalid time format or parsing errors
                formattedTime = 'Invalid Time';
            }

            return formattedTime;
        },


        isDateAfterCurrent(sessionDate) {
            const currentDate = new Date();
            const sessionDateObject = new Date(sessionDate);
            return sessionDateObject >= currentDate;
        },


        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.locale = this.selectedLanguage;
            this.updatePageDirection();
        },
        updatePageDirection() {
            if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
                document.documentElement.dir = 'rtl';
            } else {
                document.documentElement.dir = 'ltr';
            }
        },
        goToProfile(userId) {
            console.log(userId);
            this.$router.push({ name: 'patient-file', params: { id: userId } })
            // this.$router.push('/user-profile');
            // this.$route.params.id
        },
        calculateDuration(from, to) {
            const parseTime = timeString => {
                const [time, meridiem] = timeString.split(' '); // Split time and meridiem (AM/PM)
                const [hours, minutes] = time.split(':').map(Number); // Split the time string and convert parts to numbers

                let parsedHours = hours;
                if (meridiem === 'مساءا' && hours !== 12) {
                    parsedHours += 12; // Adjust hours for PM times (except for 12 PM)
                } else if (meridiem === 'صباحا' && hours === 12) {
                    parsedHours = 0; // Adjust 12 AM to 0 hours for calculation purposes
                }

                return parsedHours * 60 + minutes; // Return time in minutes
            };

            const fromMinutes = parseTime(from);
            const toMinutes = parseTime(to);

            let diff = toMinutes - fromMinutes;
            if (diff < 0) {
                diff += 24 * 60; // add 24 hours if end time is earlier than start time
            }

            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;
            const duration = `${hours} ${this.$t('hours')} ${minutes} ${this.$t('minutes')}`;

            return duration;
        },
        async createmeeting(session_id, session_doctor_id, session_user_id) {

            axios.post('https://backend.ihayanow.com/api/doctor/createmeeting', {
                doctor_id: session_doctor_id,
                user_id: session_user_id,
                session_id: session_id
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret

                },
            })
                .then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.message = [{
                            severity: 'success', detail: 'Success Message',
                            content: this.$t('Meeting Created Successfully')
                        }];
                        this.joinMeetingLabel = true;
                    }
                    console.log(this.joinMeetingLabel);
                    // if (this.message) {
                    //     window.location.reload();
                    // }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        async joinMeeting(joined_session_id,joined_user_name) {
            if (this.joinMeetingLabel) {
                this.$router.push({ name: 'session_meeting', params: { sessionId: joined_session_id, joined_user_name:joined_user_name } });
            } else {
                this.joinMeetingLabel = true; // Set the flag to true
            }
        },

        // isSessionInProgress(from, to) {

        //     const now = new Date();
        //     const startTime = new Date(now.toDateString() + ' ' + from);
        //     const endTime = new Date(now.toDateString() + ' ' + to);
        //     console.log(from,to,now,startTime,endTime);
        //     return startTime <= now && now <= endTime;
        // },
        isSessionInProgress(from, to) {

            const now = new Date();

            // Parse the time strings (from and to) into hours and minutes
            const fromParts = from.match(/(\d+):(\d+)\s+([^\s]+)/);
            const toParts = to.match(/(\d+):(\d+)\s+([^\s]+)/);

            if (!fromParts || !toParts) {
                // Invalid time format
                return false;
            }

            const fromHours = parseInt(fromParts[1]);
            const fromMinutes = parseInt(fromParts[2]);
            const fromPeriod = fromParts[3].toLowerCase();

            const toHours = parseInt(toParts[1]);
            const toMinutes = parseInt(toParts[2]);
            const toPeriod = toParts[3].toLowerCase();

            // Convert 12-hour format to 24-hour format
            const from24 = fromPeriod === 'صباحا' ? fromHours % 12 : fromHours % 12 + 12;
            const to24 = toPeriod === 'صباحا' ? toHours % 12 : toHours % 12 + 12;

            // Create Date objects with today's date and specified hours and minutes
            const startTime = new Date();
            startTime.setHours(from24, fromMinutes, 0, 0);

            const endTime = new Date();
            endTime.setHours(to24, toMinutes, 0, 0);

            return startTime <= now && now <= endTime;
        },
        async reject(sessionId) {
            console.log(sessionId);
            try {

                const response = await axios.post('https://backend.ihayanow.com/api/doctor/delete-unattend-upcomming-sessions',
                    { id: sessionId },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Api-Key': this.apiKey,
                            'Api-Secret': this.apiSecret
                        }
                    }

                );
                if (response.data.message == "done") {
                    console.log(response, response.data.message);
                    this.successMessages = true;
                    this.successMessage = 'Session canceled successfully.';
                }
            } catch (error) {
                console.error('Error deleting session request:', error);
                this.errorMessage = 'Failed to delete session request.';
            }
        },

    },
    async created() {

        this.selectedLanguage = this.$i18n.locale;
        console.log(this.selectedLanguage)
        axios.get('https://backend.ihayanow.com/api/doctor/history_sessions', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        }).then(response => {
            console.log(response);
            this.sessions = response.data.sessions;
            this.sessions.forEach(session => {
                session.from = this.formatTime(session.from);
                session.to = this.formatTime(session.to);
            });

            this.sessions = response.data.sessions.map(session => ({
                ...session,
                onsite: session.onsite === 0 ? this.$t('Online') : this.$t('Onsite'),
                duration: this.calculateDuration(session.from, session.to),
                UserId: session.user_id,
                user_name: this.$i18n.locale === 'ar' ? session.user_arabic_name : session.user_name

            }));

        }).catch(error => { console.log(error); });


        console.log(this.sessions);
        const upcommingresponse = await
            fetch('https://backend.ihayanow.com/api/doctor/upcoming-sessions', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            });
        const result = await upcommingresponse.json();
        console.log(result);
// this.joined_user_name=result.doctor_name;
// console.log(this.joined_user_name);
        this.upcommingsessions = result.sessions;
        console.log(this.upcommingsessions)

        this.upcommingsessions.forEach(upcommingsession => {
            upcommingsession.from = this.formatTime(upcommingsession.from);
            upcommingsession.to = this.formatTime(upcommingsession.to);
        });

        this.upcommingsessions = result.sessions.map(session => ({
            ...session,
            onsite: session.onsite == 0 ? this.$t('Online') : this.$t('Onsite'),
            duration: this.calculateDuration(session.from, session.to),
            UserId: session.user_id,
            user_name: this.$i18n.locale === 'ar' ? session.user_arabic_name : session.user_name

        }));
        console.log(result.zoom_session)

        this.upcommingsessions.forEach(upcommingsession => {
            axios.post('https://backend.ihayanow.com/api/is_meeting_created',
                { session_id: upcommingsession.id },
                {
                    headers: {
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                }).then(response => {

                    this.isCreated[upcommingsession.id] = response.data.length !== 0;
                    if (this.isCreated[upcommingsession.id]) {
                        this.joinMeetingLabel = true;
                        this.iscreate = true;

                    }

                }).catch(error => {

                    console.log(error);
                });
        });


    },

    mounted() {
        this.selectedLanguage = this.$i18n.locale;

    },

}


</script>

