<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <!-- <AdminNavbar /> -->
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div>
                                <h2>All Levels</h2>
                                <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
                                </PrimeMessage>
                                <PrimeMessage severity="error" v-if="this.errorMessage">{{ this.errorMessage }}
                                </PrimeMessage>
                                <TabView>
                                    <TabPanel header="Levels">

                                        <DataTable :value="levels" :paginator="true" class="p-datatable-gridlines"
                                            :rows="10" dataKey="id" :rowHover="true" :loading="loading1"
                                            responsiveLayout="scroll">
                                            <template #empty>
                                                <div v-if="levels.length == 0">No Levels</div>
                                            </template>
                                            <template #loading>
                                                Loading levels data. Please wait.
                                            </template>

                                            <PrimeColumn field="category_name" header="Level Name" style="min-width: 12rem">
                                                {{ rowData.category_name }}
                                            </PrimeColumn>
                                            <PrimeColumn field="category_description" header="Level description"
                                                style="min-width: 12rem">
                                                {{ rowData.category_description }}
                                            </PrimeColumn>
                                            <PrimeColumn header="Show level for doctor" field="show"
                                                style="min-width: 14rem">
                                                <template #body="rowData">
                                                    {{ rowData.data.show == "1" ? "Yes" : "No" }}
                                                </template>
                                            </PrimeColumn>


                                            <PrimeColumn header="Created At" field="created_at" style="min-width: 10rem">
                                                <template #body="rowData">

                                                    {{ formatDate(rowData.data.created_at) }}
                                                </template>
                                            </PrimeColumn>
                                            <PrimeColumn header="Updated At" field="updated_at" style="min-width: 10rem">
                                                <template #body="rowData">

                                                    {{ formatDate(rowData.data.updated_at) }}
                                                </template>
                                            </PrimeColumn>
                                        
                                            <PrimeColumn :exportable="false" style="min-width:8rem">
                  <template #body="rowData">
                    <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2"
                      @click="showUpdateForm(rowData.data.id)" />
                    <PrimeButton icon="pi pi-trash" outlined rounded severity="danger"
                      @click="deleteLevel(rowData.data.id)" />
                  </template>
                </PrimeColumn>

                                        </DataTable>

                                    </TabPanel>

                                </TabView>



                                <div v-if="showUpdate">
                                    <h3>Update Levels</h3>
                                    <form @submit.prevent="submitUpdateForm">

                                        <div class="grid p-fluid mt-3">
                                            <div class="field col-12 md:col-6">
                                                <label>Level Name:</label>
                                                <InputText type="text" v-model="updatedLevels.category_name" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.category_name"
                                                    v-for="(error, index) in errors.category_name" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label>Show For Doctor :</label>
                                                <PrimeDropdown id="show" class="w-full mb-3" v-model="updatedLevels.show"
                                                    :options="shows" optionLabel="name" placeholder="Select One"
                                                    style="  border: 2px; border-radius: 4px;">
                                                </PrimeDropdown>
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.show"
                                                    v-for="(error, index) in errors.show" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>

                                        </div>


                                        <div class="grid p-fluid mt-3">

                                            <div class="field col-12">
                                                <label>Level Description :</label>
                                                <InputText type="text" v-model="updatedLevels.category_description" />
                                                <InlineMessage class="w-full mt-3"
                                                    v-show="errors && errors.category_description"
                                                    v-for="(error, index) in errors.category_description" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>

                                        </div>


                                        <div class="grid p-fluid mt-3">
                                            <div class="field col-12 md:col-6">
                                                <PrimeButton type="submit">Update</PrimeButton>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
    name:'AppLevels',
    data() {
        return {
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
            errors: {},
            levels: [],
            shows: [
                { name: 'Yes' },
                { name: 'No' },

            ],
            errorMessage: '',
            successMessage: '',
            showUpdate: false,
            updateId: null,
            updatedLevels: null,
            category_name: '',
            category_description: '',
            show: '',
new_show:'',
            layoutMode: 'static',

            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
                {
                    label: 'Home', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
                        // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

                    ]

                },
                {
                    label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
                    items: [
                        { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
                        { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

                    ]
                },
                {
                    label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
                    items: [

                        { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
                        { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

                        { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
                    ]
                },
                {
                    label: 'Medication', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
                        { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
                    ]
                },
                {
                    label: 'Doctors', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
                        // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

                    ]
                },
                {
                    label: ' Patient Label', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Add Patient Label', icon: 'pi pi-fw pi-list', to: "/add-label-patient" },
                        { label: 'Show Patient Label', icon: 'pi pi-fw pi-list', to: "label-patient" }
                    ]
                },
                {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
                {
                    label: 'Sessions', icon: 'pi pi-fw pi-clock',
                    items: [
                        { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


                    ]
                },
                {
                    label: 'Reports', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


                    ]
                },
                {
                    label: '', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
                    ]

                },
                { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
            ]

        };
    },
    mounted() {
        this.fetchLevels();
    },

    methods: {

        async logout() {
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear(); // Clear all items in local storage
                    this.$router.push('/login-page');
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async fetchLevels() {
            try {
                const response = await fetch('https://backend.ihayanow.com/api/admin/doctors-levels', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                    },
                });

                const data = await response.json();
                this.levels = data.levels;

                this.levels.forEach(level => {
                    if (level.show == "1") {
                        this.show = "Yes";
                    } else {
                        this.show = "No";
                    }
                })
                this.updatedLevels = this.levels;

            } catch (error) {
                console.log('Error fetching Medications:', error);
            }
        },


        showUpdateForm(id) {
            console.log(id);
            this.showUpdate = true
            this.updateId = id
            this.updatedLevels =
                this.levels.find(level => level.id === id);

            console.log(this.updatedLevels);
        },


        hideUpdateForm() {

            this.showUpdate = false;
            this.updateId = null;

        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        },

        async submitUpdateForm() {
            if (this.updatedLevels.show.name == 'Yes' || this.updatedLevels.show.name == 'No') {
                this.new_show = this.updatedLevels.show.name;
        }
        else {
            this.new_show = this.show;
        }

            if(this.new_show == "Yes"){
                    this.new_show=1;
                }else{
                    this.new_show=0;
                }

                console.log(this.updatedLevels.id,this.updatedLevels.category_name,
              this.updatedLevels.category_description, this.new_show)
            await axios.post('https://backend.ihayanow.com/api/admin/levels_update', {
                id: this.updatedLevels.id,
                category_name: this.updatedLevels.category_name,
                category_description: this.updatedLevels.category_description,
                show: this.new_show
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                }
            })
                .then(response => {
                    this.successMessage = 'Level updated successfully.';
                    this.fetchLevels();
                    this.hideUpdateForm();
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                    this.errors = error.response.data.errors;

                });
        },


          async deleteLevel(levelId) {
            try {
              const response = await fetch(`https://backend.ihayanow.com/api/admin/doctors-levels/${levelId}`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                },
              });
              if (response.ok) {
                this.errorMessage = 'Level deleted successfully.';
                this.showUpdateForm = false;
                this.fetchLevels();
              } else {
                console.log(response)
                this.errorMessage = 'Failed to delete level.';
              }
            } catch (error) {
              console.error('Error deleting level:', error);
              this.errorMessage = 'Failed to delete level.';
            }
          },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
    },

    computed: {

        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
    }

}

</script>
  
<style scoped>
.avatar {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #eee;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-position: center;
}

.avatar-label {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    color: #555;
    cursor: pointer;
}

.avatar-upload {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.doctor-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.body-preview {
    max-height: 80px;
    /* Adjust the height as per your requirements */
    overflow: hidden;
}

.body-preview p {
    margin-bottom: 5px;
}

.body-preview button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
}


.layout-topbar {
   
   background-color: var(--surface-card);
   }

</style>