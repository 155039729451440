
<template>
  <div class="container">
    <UserNavbar />
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card py-4">

              <h5>{{ $t('menu.Edit_Profile') }}</h5>
              <form @submit.prevent="register">
                <div class="field col-12">
                  <div class="avatar" :style="{ 'background-image': 'url(' + previewphoto + ')' }">
                    <InputText type="file" hidden @change="uploadProfilePicture" id="avatar" />
                    <label for="avatar" class="avatar-label">{{ $t('profile.choose_image') }}</label>

                  </div>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.photo"
                    v-for="(error, index) in errors.photo" :key="index">
                    {{ translateErrorMessage(error) }}
                  </InlineMessage>
                </div>
                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="arabic_name" style="font-weight: bold;">{{ $t("profile.Arabic Name") }}</label>
                    <InputText id="arabic_name" type="text" v-model="fetchedData.arabic_name" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.arabic_name"
                      v-for="(error, index) in errors.arabic_name" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="age" style="font-weight: bold;">{{ $t("profile.age") }}</label>
                    <InputText id="age" type="text" v-model="fetchedData.age" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.age" v-for="(error, index) in errors.age"
                      :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="birth_day" style="font-weight: bold;">{{ $t("profile.birthdate") }}</label>
                    <InputText id="birth_day" type="date" v-model="fetchedData.birth_date" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.birth_date"
                      v-for="(error, index) in errors.birth_date" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>

                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="language" style="font-weight: bold;">{{ $t('profile.language') }}</label>
                    <PrimeDropdown id="state" v-model="fetchedData.language" :options="languages" optionKey="name"
                      optionLabel="translationKey" :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.language"
                      v-for="(error, index) in errors.language" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="country" style="font-weight: bold;">{{ $t('profile.country') }}</label>
                    <PrimeDropdown id="state" v-model="country" :options="countries" optionKey="name" optionLabel="name"
                      :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.country_id"
                      v-for="(error, index) in errors.country_id" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="state" style="font-weight: bold;">{{ $t('profile.state') }}</label>
                    <PrimeDropdown id="state" v-model="state" :options="states" optionKey="name" optionLabel="name"
                      :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.state_id"
                      v-for="(error, index) in errors.state_id" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="city" style="font-weight: bold;">{{ $t('profile.city') }}</label>
                    <PrimeDropdown id="city" v-model="city" :options="cities" optionKey="name" optionLabel="name"
                      :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.city_id"
                      v-for="(error, index) in errors.city_id" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="address" style="font-weight: bold;">{{ $t("profile.address") }}</label>
                    <InputText id="address" type="text" v-model="fetchedData.address" />

                    <InlineMessage class="w-full mt-3" v-show="errors && errors.address"
                      v-for="(error, index) in errors.address" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="weight" style="font-weight: bold;">{{ $t("profile.Weight") }}</label>
                    <InputText id="weight" type="text" v-model="fetchedData.weight" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.weight"
                      v-for="(error, index) in errors.weight" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="height" style="font-weight: bold;">{{ $t("profile.Height") }}</label>
                    <InputText id="height" type="text" v-model="fetchedData.height" />
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.height"
                      v-for="(error, index) in errors.height" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                  <div class="field col-12 md:col-6" v-if="fetchedData">
                    <label for="smoking" style="font-weight: bold;">{{ $t('profile.Smoker') }}</label>
                    <PrimeDropdown id="state" v-model="fetchedData.smoker" :options="smoking" optionLabel="name"
                      :placeholder="$t('profile.Select_One')">
                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.smoker"
                      v-for="(error, index) in errors.smoker" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>

                  </div>
                </div>
                <hr class="horizontal dark" />
                <div class="row edit-button text-center">
                  <PrimeButton type="submit">{{ $t('save') }}</PrimeButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import axios from 'axios';
import UserNavbar from './UserNavbar.vue';
import Footer from '../Footer.vue';
export default {

  data() {
    return {
      birth_date: "",
      age: "",
      smoker: [],
      weight: "",
      height: "",
      photo: "",
      event: "",
      previewphoto: "",
      language: "",
      fetchedData: {}, // Object to store the fetched data
      new_smoker: '',
      new_language: '',
      name: '',
      email: '',
      profile: '',
      errors: {},
      country: {
        id: null,
        name: "",
        translationKey: "",
      },
      state: {
        id: null,
        name: "",
        translationKey: "",
      },
      city: {
        id: null,
        name: "",
        translationKey: "",
      },
      countries: [],
      cities: [],
      states: [],
      new_country: '',
      new_state: '',
      new_city: '',
      city_id: '',
      country_id: '',
      state_id: '',
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,

    }
  },


  methods: {
    uploadProfilePicture(event) {
      this.photo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.photo);

      reader.onload = () => {
        this.previewphoto = reader.result;
      }
    },
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    async register() {
      try {
        if (this.fetchedData.smoker.name == 'نعم' || this.fetchedData.smoker.name == 'لا') {
          this.new_smoker = this.fetchedData.smoker.name;
        }
        else if (this.fetchedData.smoker.name == 'Yes' || this.fetchedData.smoker.name == 'No') {
          this.new_smoker = this.fetchedData.smoker.name;
        }
        else {
          this.new_smoker = this.smoker;
        }
        if (this.fetchedData.language.translationKey == 'عربي' || this.fetchedData.language.translationKey == 'إنجليزي' || this.fetchedData.language.translationKey == 'عربي و إنجليزي') {
          this.new_language = this.fetchedData.language.name;
        }
        else if (this.fetchedData.language.translationKey == 'Arabic' || this.fetchedData.language.translationKey == 'English' || this.fetchedData.language.translationKey == 'Arabic & English') {
          this.new_language = this.fetchedData.language.name;

        }
        else {
          this.new_language = this.language;
        }

        if (this.country.id == null) {
          this.new_country = this.country_id
        }
        else if (this.country_id !== this.country.id || this.country_id == this.country.id) {
          this.new_country = this.country.id
        }

        if (this.state.id == null) {
          this.new_state = this.state_id
        }
        else if (this.state_id !== this.state.id || this.state_id == this.state.id) {
          this.new_state = this.state.id
        }

        if (this.city.id == null) {
          this.new_city = this.city_id
        }
        else if (this.city_id !== this.city.id || this.city_id == this.city.id) {
          this.new_city = this.city.id
        }

        axios.post('https://backend.ihayanow.com/api/user/update', {
          birth_date: this.fetchedData.birth_date,
          age: this.fetchedData.age,
          language: this.new_language,
          smoker: this.new_smoker == "Yes" || this.new_smoker == "نعم" ? '1' : '0',
          weight: this.fetchedData.weight,
          height: this.fetchedData.height,
          photo: this.photo,
          arabic_name: this.fetchedData.arabic_name,
          country_id: this.new_country,
          state_id: this.new_state,
          city_id: this.new_city,
          address: this.fetchedData.address,
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          },
        })
          .then(response => {

            if (response.success) {
              this.success = true
              this.message = response.message
              this.token = response.token
            }
            this.$router.push('/user-profile')
          })
          .catch(error => {
            this.errors = error.response.data.errors;
            console.log(error);
          });



      } catch (error) {
        console.log(error);
        this.errors = error.response.data.errors;
      }
    },
    async fetchProfile() {
      
      await axios.get('https://backend.ihayanow.com/api/user/my-profile', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      }).then(response => {
        const data = response.data;
        this.name = data[0].name;
        this.email = data[0].email;
        this.profile = data[0].profile;
        this.country_name = data[0].country_name,
          this.state_name = data[0].state_name,
          this.city_name = data[0].city_name,
          this.country_id = data[0].profile.country_id,
          this.city_id = data[0].profile.city_id,
          this.state_id = data[0].profile.state_id,


          this.fetchedData = this.profile; // Assign the fetched data to the property
        this.language = this.fetchedData.language;
        this.smoker = this.fetchedData.smoker == '1' ? 'Yes' || 'نعم' : 'No' || 'لا';



      })
        .catch(error => {
          this.errors = error.response.data.error;
          console.log(this.errors)
        });

    },
    async fetchCountries() {

      try {
       
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`, 'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        };
        const response = await axios.get("https://backend.ihayanow.com/api/get-countries", { headers });
        this.countries = response.data.map(country => ({
          id: country.id,
          name: this.$i18n.locale === 'ar' ? country.arabic_name : country.english_name,
          translationKey: country.english_name, // Assuming english_name is unique identifier
        }));
      } catch (error) {
        console.error("Error fetching countries:", error);
      }

    },
    async fetchStates(countryId) {
      try {
     
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`, 'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        };
        const response = await axios.get(`https://backend.ihayanow.com/api/get-states/${countryId}`, { headers });

        this.states = response.data.map(state => ({
          id: state.id,
          name: this.$i18n.locale === 'ar' ? state.arabic_name : state.english_name,
          translationKey: state.english_name, // Assuming english_name is unique identifier

        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },
    async fetchCities(cityId) {
      try {
      
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`, 'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        };
        const response = await axios.get(`https://backend.ihayanow.com/api/get-cities/${cityId}`, { headers });

        this.cities = response.data.map(city => ({
          id: city.id,
          name: this.$i18n.locale === 'ar' ? city.arabic_name : city.english_name,
          translationKey: city.english_name, // Assuming english_name is unique identifier

        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },

  },
  created() {
    this.fetchProfile();
    this.fetchCountries();

  },
  components: {
    UserNavbar,
    Footer
  },

  watch: {
    // Watch for changes in the selected country
    country: {
      handler(newCountry) {
        if (newCountry.id) {
          this.fetchStates(newCountry.id); // Call API to fetch states
        }
      },
      deep: true, // Watch for changes in nested properties of country
    },
    state: {
      handler(newState) {
        if (newState.id) {
          this.fetchCities(newState.id); // Call API to fetch states
        }
      },
      deep: true, // Watch for changes in nested properties of country
    },
  },
  computed: {
    languages() {
      return [
        { translationKey: this.$t('profile.arabic'), name: "Arabic" },
        { translationKey: this.$t('profile.english'), name: "English" },
        { translationKey: this.$t('profile.both'), name: "Arabic & English" },
      ]
    },
    smoking() {
      return [
        { name: this.$t('Yes'), translationKey: 'Yes' },
        { name: this.$t('No'), translationKey: 'No' },

      ]

    },


  },
}

</script>
<style scoped>
.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
}

.avatar-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.avatar-upload {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
</style>