<template  >
    <!--  :class="{'layout-sidebar':this.selecetedLanguage =='en' , 'layout-sidebar-ar':this.selectedLanguage =='ar' }" -->
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" />
        </div>
        <div class="layout-main-container">
            <div class="grid">
                <div class="col-12">
                    <div class="card ">
                        <h3 v-bind:dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">{{ $t('My Upcomming Sessions') }}</h3>
                        <DataTable :paginator="false" :value="upcommingsessions.slice(0, 2)" class="p-datatable-gridlines"
                            :rows="10" dataKey="id" :loading="loading1" :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
                            <template #empty>
                                <div v-if="upcommingsessions.length == 0"> {{ $t('Sessions.No_Sessions') }}
                                </div>
                            </template>
                            <template #loading>
                                {{ $t('Sessions.Loading_Sessions') }}
                            </template>
                            <PrimeColumn field="user_name" :header="$t('Sessions.Patient_Name')" style="min-width: 8rem">
                                {{ this.user_name }}
                            </PrimeColumn>
                            <PrimeColumn field="date" :header="$t('Sessions.Session_Date')" style="min-width: 8rem">
                                {{ rowData.date }}
                            </PrimeColumn>
                            <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="from" style="min-width: 8rem">

                                <!-- {{ this.$t("Sessions.From") }} : -->
                                {{ rowData.from }}

                            </PrimeColumn>
                            <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="to" style="min-width: 8rem">

                                <!-- {{ this.$t("Sessions.To") }} :  -->
                                {{ rowData.to }}

                            </PrimeColumn>
                            <PrimeColumn :header="$t('Sessions.Sessions_Duration')" field="duration"
                                style="min-width: 8rem">
                                {{ this.duration }}

                            </PrimeColumn>
                            <PrimeColumn :header="$t('Sessions.Session_Location')" field="onsite" style="min-width: 8rem">
                                {{ this.onsite }}
                            </PrimeColumn>

                        </DataTable>

                    </div>
                </div>

                <div class="col-6">
                    <div class="card " v-bind:dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">

                        <h3 v-bind:dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">{{ $t('Insights Of Sessions') }}</h3>
                        <div class="chart-row">
                            <div class="chart-container col-12 ">
                                <PrimeChart type="bar" :data="barData" :options="barOptions" />
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-6 d-flex justify-content-center">
                    <div class="card" v-bind:dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                        <h3>{{ $t('Insights Of Users Sessions') }}</h3>
                        <div class="text-center">

                            <PrimeChart type="pie" :data="pieData" :options="pieOptions" style="width: 50%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
    name: 'doctor-home',
    data() {
        return {
            AllUsersCount: '',
            UsersSessionsCount: '',
            doctors: [],
            pendings: [],
            lineOptions: null,
            pieOptions: null,
            barOptions: null,
            totalUsers: 0,
            totalDoctors: 0,
            totalAdmins: 0,
            approvedDoctors: 0,
            pendingDoctors: 0,
            selectedLanguage: '',
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            hasFetched: false,
            historySessions: [],
            upcommingSessions: [],
            upcommingsessions: [],

            profile: [],
            lineData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Online Sessions',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        backgroundColor: '#2f4860',
                        borderColor: '#2f4860',
                        tension: .4
                    },
                    {
                        label: 'Offline Sessions',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        backgroundColor: '#00bb7e',
                        borderColor: '#00bb7e',
                        tension: .4
                    }
                ]
            },
            barData: {
                labels: [],
                datasets: [
                    {
                        label: 'History Sessions',
                        backgroundColor: '#2f4860',
                        data: [],
                    },
                ],
            },
            duration: '',
            UserId: '',

            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
        };
    },
    mounted() {

        this.selectedLanguage = this.$i18n.locale;
        if (localStorage.getItem('type') == "Doctor") {
            console.log("hi");
            document.documentElement.dir = 'ltr';

        }

        this.fetchInsights();
        this.fetchProfile();

    },


    methods: {
        async fetchProfile() {
            await axios.get('https://backend.ihayanow.com/api/doctor/get-profile-status'
                , {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                }).then(response => {
                    this.profile = response.data;
                    console.log(this.profile)
                })
                .catch(error => {
                    console.log(error);
                    this.errors = error.response.data.error;
                    console.log(this.errors)
                });


        },
        async logout() {
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret,
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear(); // Clear all items in local storage
                    this.$i18n.locale = 'ar';
                    // window.location.reload(); // Reload the page to apply the updated direction

                    this.$router.push('/login-page');

                })
                .catch(error => {
                    console.log(error);
                });
        },
        applyLightTheme() {

            this.pieOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            };

            this.lineOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                }
            };
            this.barOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                }
            };
        },
        fetchInsights() {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`, 'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret,
            };

            // Fetch total user, doctor, and admin counts
            axios.get('https://backend.ihayanow.com/api/doctor/get-doctor-history-sessions', { headers })
                .then(response => {
                    axios.get('https://backend.ihayanow.com/api/doctor/get-doctor-upcomming-sessions', { headers })
                        .then(response2 => {
                            this.historySessions = response.data.History_Sessions;
                            this.upcommingSessions = response2.data.Upcomming_Sessions;

                            // Extracting keys (month names) and values (session counts) from the object
                            const labels = Object.keys(this.historySessions);
                            const data = Object.values(this.historySessions);
                            // const labels2 = Object.keys(this.upcommingSessions);
                            const data2 = Object.values(this.upcommingSessions);
                            this.barData = {
                                labels: labels,

                                datasets: [
                                    {
                                        label: this.$t('History Sessions'),
                                        backgroundColor: '#ec4899',
                                        data: data,
                                    },
                                    {
                                        label: this.$t('Upcomming Sessions'),
                                        backgroundColor: '#3b82f6',
                                        data: data2,
                                    },

                                ],
                            };
                            console.log(this.barData);

                            console.log(this.historySessions);
                        })
                        .catch(error => {
                            console.error(error);
                        });

                })
                .catch(error => {
                    console.error(error);
                });

            // Fetch approved and pending doctor counts
            axios.get('https://backend.ihayanow.com/api/admin/get-doctor-counts', { headers })
                .then(response => {
                    this.approvedDoctors = response.data.approvedDoctors;
                    this.pendingDoctors = response.data.pendingDoctors;


                    this.showDoctorInsights = true;
                })
                .catch(error => {
                    console.error(error);
                });
            axios.get('https://backend.ihayanow.com/api/doctor/upcoming-sessions', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            }).then(response => {
                this.upcommingsessions = response.data.sessions;
                //  this.upcommingsessions = this.upcommingsessions.slice(0,2);

                console.log(this.upcommingsessions)

                this.upcommingsessions.forEach(upcommingsession => {
                    upcommingsession.from = this.formatTime(upcommingsession.from);
                    upcommingsession.to = this.formatTime(upcommingsession.to);
                });
                this.upcommingsessions = response.data.sessions.map(session => ({
                    ...session,
                    onsite: session.onsite == 0 ? this.$t('Online') : this.$t('Onsite'),
                    duration: this.calculateDuration(session.from, session.to),
                    UserId: session.user_id,
                    user_name: this.$i18n.locale === 'ar' ? session.user_arabic_name : session.user_name

                }));
            })

            axios.get('https://backend.ihayanow.com/api/doctor/get-doctor-users-sessions-count', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            })
                .then(response => {
                    this.UsersSessionsCount = response.data.sessions_users_count;
                    this.AllUsersCount = response.data.all_users_count;
                    console.log(this.UsersSessionsCount);
                })
                .catch(error => {
                    console.error(error);
                });




        },
        formatTime(time) {
            const [hours, minutes] = time.split(':').map(Number); // Split the time string and convert parts to numbers
            let formattedTime = '';

            // Validate the hour and minute values
            if (!isNaN(hours) && !isNaN(minutes)) {
                let hour = hours % 12 || 12; // Get the hour in 12-hour format

                // Determine if it's AM or PM based on the hour value
                const amPm = hours >= 12 ? this.$t('pm') : this.$t('am');

                // Format the hour and minute with leading zeros if necessary
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');

                formattedTime = `${formattedHour}:${formattedMinutes} ${amPm}`;
            } else {
                // Handle invalid time format or parsing errors
                formattedTime = 'Invalid Time';
            }

            return formattedTime;
        },
        calculateDuration(from, to) {
            const parseTime = timeString => {
                const [time, meridiem] = timeString.split(' '); // Split time and meridiem (AM/PM)
                const [hours, minutes] = time.split(':').map(Number); // Split the time string and convert parts to numbers

                let parsedHours = hours;
                if (meridiem === 'مساءا' && hours !== 12) {
                    parsedHours += 12; // Adjust hours for PM times (except for 12 PM)
                } else if (meridiem === 'صباحا' && hours === 12) {
                    parsedHours = 0; // Adjust 12 AM to 0 hours for calculation purposes
                }

                return parsedHours * 60 + minutes; // Return time in minutes
            };

            const fromMinutes = parseTime(from);
            const toMinutes = parseTime(to);

            let diff = toMinutes - fromMinutes;
            if (diff < 0) {
                diff += 24 * 60; // add 24 hours if end time is earlier than start time
            }

            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;
            const duration = `${hours} ${this.$t('hours')} ${minutes} ${this.$t('minutes')}`;

            return duration;
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }

    },
    computed: {
        menu() {
            return [
                {
                    items: [
                        { label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', to: '/doctor-home' }
                    ]

                },
                {
                    label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user', to: '/doctor-profile'
                        },
                        {
                            label: this.$t('menu.Edit_Profile'), icon: 'pi pi-fw pi-user-edit', to: '/edit-doctor-profile'
                        },
                    ]
                },
                {
                    label: this.$t('menu.Sessions'), icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: this.$t('menu.Show_Requested_Sessions'), icon: 'pi pi-fw pi-id-card', to: '/sessions-requests', disabled: this.profile.status === 0 },
                        { label: this.$t('menu.Show_Your_Session'), icon: 'pi pi-fw pi-id-card', to: '/doctor-sessions', disabled: this.profile.status === 0 },
                        { label: this.$t('menu.Calendar'), icon: 'pi pi-fw pi-id-card', to: '/doctor-calendars', disabled: this.profile.status === 0 },
                        {
                            label: this.$t('menu.Create_Session'), icon: 'pi pi-fw pi-check-square', to: '/create-sessions', disabled: this.profile.status === 0
                        },
                    ]
                },
                {
                    label: '',
                    items: [
                        {
                            label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', to: "/knowledgebase-page"
                        }]
                },
                {
                    label: '',
                    items: [
                        {
                            label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', to: "/view-medications"
                        }]
                },
                {
                    label: '',
                    items: [
                        {
                            label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', to: "/therapists-page"
                        }]
                },
                {
                    label: '',
                    items: [
                        {
                            label: this.$t('menu.show_packages'), icon: 'pi pi-fw pi-users', to: "/show-packages"
                        }]
                },
                {
                    label: '',
                    items: [
                        {
                            label: this.$t('logout'), icon: 'pi pi-power-off', command: this.logout,
                        }]
                },

            ];
        },

        pieData() {
            return {
                labels: [this.$t('All Users'), this.$t('Sessions Users')],
                datasets: [
                    {
                        data: [this.AllUsersCount, this.UsersSessionsCount],
                        backgroundColor: ["#FF6384", "#36A2EB"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB"]
                    }
                ]
            };
        },
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },

    },


    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        // 'Footer': Footer,
    },

}

</script>
  
  
<style scoped>
/* .layout-sidebar-ar{
    float:right;
    position: absolute;
	width: 280px;
	height: calc(100vh - 9rem);
	z-index: 999;
	overflow-y: auto;
	user-select: none;
	top: 7rem;
	right: 1rem;
	transition: transform $transitionDuration, left $transitionDuration;
	background-color: var(--surface-overlay);
	border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08)

} */
.en-direction {
    direction: ltr
}

.ar-direction {
    direction: rtl
}

.layout-topbar {

    background-color: var(--surface-card);
}

.chart-row {
    display: flex;
    flex-direction: row;
}

.chart-container {
    flex: 1;
    margin-right: 10px;
}
</style>
  
  
  
  
  