<template>
  <!--  class="card relative z-2" class="layout-topbar" -->
  <div :key="selectedLanguage" >
    <PrimeMenubar  :model="menu" >
      <template #start>
        <div class="mx-2 layout-topbar">
          <img :src="require('/public/mental_logo.png')" alt="IHAYANOW logo" style="width:70px; height:70px;">
          <span class="text">IHAYANOW</span>
        </div>
      </template>


      <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
    </template>
           
      <template #end>
<!-- -->
        <div class="toggle-with-notification" :class="{ 'margin-left-230': this.selectedLanguage == 'en', 'margin-right-230': this.selectedLanguage == 'ar' }" >
        <li class="nav-item dropdown" style="list-style-type: none;">
        <a id="navbarDropdown" style="color:black;" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-bell" id="notify_icon"></i>
            <span id="notifiy_num" v-show="this.notifications.length > 0">{{this.notifications.length}}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" v-show="this.notifications.length > 0" @click="markAsRead">Mark all as read!</a>
            <a class="dropdown-item" v-for="(unread, index) in this.notifications" :key="index">
                <h5>{{unread.data.data}}</h5>
                <!-- <p>{{unread.data.post_title}}</p> -->
                <!-- <p>{{moment(unread.created_at).fromNow()}}</p> -->
            </a>
            <a class="dropdown-item"  v-show="this.show"  v-if="this.notifications.length == 0">   {{ $t('notification.no') }}</a>
        </div>
    </li>
          <!-- <div class="notification-icon" @click="toggleNotifications" v-if="this.notifications.length >= 0">
            <i class="fa fa-bell"></i>
            <span class="notification-count">
              {{ notifications.length }}
            </span>
          </div>
          <div class="notification-container" v-show="this.show" v-if="this.notifications.length >= 0">
            <div class="notification-dropdown" ref="notificationDropdown">
              <div class="notification-item" v-for="notification in notifications" :key="notification.id">
                {{ $t('notification.message') }} {{ getTime(notification.session_from) }}
              </div>
              <div v-if="notifications.length === 0" class="no-notifications">
                {{ $t('notification.no') }}
              </div>
            </div>
          </div> -->
          <div class="language-toggle mx-5"
            >
            <ToggleSwitchButton class="toggle" v-model="this.isActive" label="AR|EN" @click="toggleLanguage" />

          </div>


        </div>
      </template>

    </PrimeMenubar>
  </div>
</template>


<script>
import axios from 'axios'
import ToggleSwitchButton from "toggle-switch-button-vue";
import moment from 'moment';

export default {

  data() {
    return {
      notifications: [],
      isActive: 'AR',
      selectedLanguage: 'ar', // Store the selected language
      supportedLanguages: ['en', 'ar'],// Add the supported languages here
      locale: this.$i18n.locale, // Access the locale directly from $i18n
      isRtl: false, // Set initial value for page direction
         apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    };



  },

  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      this.locale = this.selectedLanguage;
      this.updatePageDirection();
    },
    toggleLanguage() {
      this.selectedLanguage = this.selectedLanguage === 'ar' ? 'en' : 'ar';
      this.changeLanguage(); // Call your existing method to update the language
    },
    updatePageDirection() {
      // Check the selected language and set the page direction accordingly
      if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
        document.documentElement.dir = 'rtl'; // Set the page direction to right-to-left for Arabic

      } else {
        document.documentElement.dir = 'ltr'; // Set the page direction to right-to-left for Arabic

      }

    },
    getTime(time) {
      if (time.length > 0) {
        const formattedTime = moment(time, 'HH:mm:ss').format('h:mm A');
        const withoutAmPmIndicator = moment(time, 'HH:mm:ss').format('h:mm');

        if (formattedTime.includes('AM')) {
          return withoutAmPmIndicator + ' ' + this.$t("am");
        } else if (formattedTime.includes('PM')) {
          return withoutAmPmIndicator + ' ' + this.$t("pm");
        }
        else {
          return formattedTime;
        }
      } else {
        return '';
      }

    },
    async fetchNotifications() {
      
      try {
        const response = await axios.get('https://backend.ihayanow.com/api/get_reminders', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret

          }
        });
        this.notifications = response.data; // Assuming the API response is an array of notifications

        console.log(this.notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    },
    async logout() {
      
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },

    toggleNotifications() {
      this.show = true;
      this.$refs.notificationDropdown.style.display =
        this.$refs.notificationDropdown.style.display == 'block' ? 'none' : 'block';
    }
  },

  created() {
    this.updatePageDirection();
    this.fetchNotifications();
  },
  computed: {
    menu() {
      return [
        {
          label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', route: '/'

        },
        {
          label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user',
          items: [
            {
              label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user', route: '/user-profile'
            },
            {
              label: this.$t('menu.Edit_Profile'), icon: 'pi pi-fw pi-user-edit', route: '/edit-user-profile'
            },
          ]
        },
        // {
        //   label: this.$t('menu.Sessions'), icon: 'pi pi-fw pi-sitemap',
        //   items: [
        //     { label: this.$t('menu.Show_Requested_Sessions'), icon: 'pi pi-fw pi-id-card', route: '/user_session_requests' },
            
        //   ]
        // },
        { label: this.$t('menu.Sessions'), icon: 'pi pi-fw pi-sitemap', route: '/user-sessions' },

        {
          label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', route: "/knowledgebase-page"

        },
        {
                    label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', route: "/view-medications"

                },

        {
          label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-user',
          items: [
            {
              label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', route: "/therapists-page"

            },
            {
              label: this.$t('menu.show_favourite_doctors'), icon: 'pi pi-fw pi-users', route: "/favourite-doctors"

            },
          ]
        },

        {
          label: this.$t('menu.show_packages'), icon: 'pi pi-fw pi-users', route: "/show-packages"

        },
        {
          label: this.$t('logout'), icon: 'pi pi-power-off', command: this.logout,

        },
      ]
    }
  },
  components: {
    ToggleSwitchButton
  }

}

</script>
<style scoped>
@import '../../../public/navbar.css';
@media (max-width: 768px) {
    .margin-left-230 {
      margin-left: 0;
    }

    .margin-right-230 {
      margin-right: 0;
    }
    }
</style>