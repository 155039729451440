

<template>
   <div class="container">
      <Navbar />
      <div class="layout-main ">
      <div class="grid">
      <form style="background-color:#d0e1fd;">

         <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">

            <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%; margin-top: 20px;">
               <div class="col-12 mt-5 xl:mt-0 text-center">
                  <h1>{{ $t("sign_up_as_doctor") }}</h1>
               </div>
               <!-- <div class="col-12 mt-5 xl:mt-0 text-center">
                      <img :src="'logo.png'" alt="Sakai logo" class="mb-5"
                         style="width:81px; height:70px;">
                   </div> -->

               <div class="col-12 xl:col-6"
                  style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                  <div class="h-full w-full m-0 py-7 px-4"
                     style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                     <div class="text-center mb-5">
                        <!-- <img src="layout/images/avatar.png" alt="Image" height="50" class="mb-3"> -->
                        <div class="text-900 text-3xl font-medium mb-3">{{ $t("welcome_user") }}</div>
                     </div>

                     <div class="w-full md:w-10 mx-auto">
                        <label for="name1" class="block text-900 text-xl font-medium mb-2">{{ $t("profile.name") }}</label>
                        <input id="name1" v-model="name" type="text" class="w-full mb-3" :placeholder="$t('profile.name')"
                           style="padding:1rem;  border: 2px; border-radius: 4px;" />

                        <InlineMessage class="w-full mb-3" v-show="errors && errors.name"
                           v-for="(error, index) in errors.name" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>

                        <label for="email1" class="block text-900 text-xl font-medium mb-2">{{ $t("email") }}</label>
                        <input id="email1" v-model="email" type="email" class="w-full mb-3" :placeholder="$t('email')"
                           style="padding:1rem;  border: 2px; border-radius: 4px;" />
                        <InlineMessage class="w-full mb-3" v-show="errors && errors.email"
                           v-for="(error, index) in errors.email" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>

                        <label for="password1" class="block text-900 font-medium text-xl mb-2">{{ $t("password") }}</label>
                        <input type="password" id="password1" v-model="password" :placeholder="$t('password')"
                           :toggleMask="true" style="padding:1rem; border: 2px; border-radius: 4px;" class="w-full mb-3"
                           inputClass="w-full" inputStyle="padding:1rem" />
                        <InlineMessage class="w-full mb-3" v-show="errors && errors.password"
                           v-for="(error, index) in errors.password" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <label for="password_confirmation1" class="block text-900 text-xl font-medium mb-2">{{
                           $t("password_confiramtion") }}</label>
                        <input id="password_confirmation1" v-model="password_confirmation" type="password"
                           class="w-full mb-3" :placeholder="$t('password_confiramtion')"
                           style="padding:1rem;  border: 2px; border-radius: 4px;" />
                        <InlineMessage class="w-full mb-3" v-show="errors && errors.password_confirmation"
                           v-for="(error, index) in errors.password_confirmation" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                        <label for="phone1" class="block text-900 text-xl font-medium mb-2">{{ $t("phone") }}</label>
                        <input id="phone1" v-model="phone" type="text" class="w-full mb-3" :placeholder="$t('phone')"
                           style="padding:1rem;  border: 2px; border-radius: 4px;" />
                        <InlineMessage class="w-full mb-3" v-show="errors && errors.phone"
                           v-for="(error, index) in errors.phone" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>

                        <div class="row edit-button text-center">
                           <PrimeButton @click="register">{{ $t("sign_up") }}</PrimeButton>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>

      </form>
</div>
</div>

   </div>
</template>
 
 
 
<script>
import axios from 'axios';

import Navbar from '../Navbar.vue';
export default {
   data() {
      return {
         apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
         name: '',
         email: '',
         password: '',
         password_confirmation: '',
         phone: '',
         type: 'Doctor',
         // success: false,
         message: '',
         token: '',
         errors:{},

      }
   },




   methods: {
      translateErrorMessage(error) {
        
         return this.$i18n.t(error);
      },
      async register() {         
             await axios.post('https://backend.ihayanow.com/api/register',
             {
                  name: this.name,
                  email: this.email,
                  password: this.password,
                  password_confirmation: this.password_confirmation,
                  phone: this.phone,
                  type: this.type,
               }, {
               headers: {
                  'Content-Type': 'application/json',
                  'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
               },
              
            }).then(response=>{
             
            if (response.data.success == true) {
               this.success = true
               this.message = response.data.message
               this.token = response.data.token
               localStorage.setItem('token', this.token);
               localStorage.setItem('email', this.email);
               localStorage.setItem('type', this.type);

               this.$router.push({ name: 'CheckEmail', params: { email: this.email } });

            }
            else {
               this.errors = response.data.message;
            }
            }).catch(error=>{
               this.errors = error.response.data.errors;
            })

        
      },
   },
 

   components: {
      Navbar
   },
}
</script>