<template>
    <div class="container">
        <div class="layout-main-container">
            <div class="grid mt-5 px-5">

                <div class="d-flex justify-content-center"
                    v-if="visible && selectedKnowledgebase && selectedKnowledgebase.length > 0">
                    <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal 
                        :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        <template #header>
                              <div class="inline-flex align-items-center justify-content-center gap-2">
                                <!-- <div class="knowledgebase-image text-center">
                      <img class="col-12 " :src="'https://backend.ihayanow.com/' + knowledgebase.data.photo"
                        alt="Knowledge Image" />
                      </div> -->
<span class="font-bold white-space-nowrap text-center">{{ $t('title') }} : {{
                                selectedKnowledgebase[0].knowledgebase.title }}</span>


                                </div>
                        </template>
                        
                        
                        <div
                            v-if="selectedKnowledgebase[0].knowledgebase.type == 'Article' || selectedKnowledgebase[0].knowledgebase.type == 'Tip'">
                            <p> <span class="font-bold">{{ $t('Description') }} </span> : {{
                                selectedKnowledgebase[0].knowledgebase.body }}</p>
                            <h6> <span class="font-bold">{{ $t('Written by') }} </span> : {{
                                selectedKnowledgebase[0].knowledgebase.author }}</h6>
                        </div>
                        <div v-if="selectedKnowledgebase[0].knowledgebase.type === 'Youtube'">

                            <div class="youtube-player">
                                <iframe width="560" height="315" :src="embedURL" frameborder="0" allowfullscreen></iframe>
                            </div>
                            <h6><span class="font-bold">{{ $t('Filmed by') }}</span> : {{
                                selectedKnowledgebase[0].knowledgebase.author }}</h6>
                        </div>

                        <div v-if="selectedKnowledgebase[0].type === 'Movie'">
                            <span class="font-bold">{{ $t('Movie Link') }}</span> :
                            <a :href="selectedKnowledgebase[0].link" target="_blank">{{ $t('IMDb Link') }}</a>
                            <h6><span class="font-bold">{{ $t('Filmed by') }}</span> : {{
                                selectedKnowledgebase[0].knowledgebase.author }}</h6>
                        </div>
                        <div
                            v-if="selectedKnowledgebase[0].knowledgebase.type == 'Link' || selectedKnowledgebase[0].knowledgebase.type == 'Reference'">
                            <a :href="selectedKnowledgebase[0].knowledgebase.type === 'Link' ? selectedKnowledgebase[0].knowledgebase.link : selectedKnowledgebase[0].knowledgebase.reference"
                                target="_blank">{{ $t('goToLink') }} {{ selectedKnowledgebase[0].type }}</a>
                            <h6><span class="font-bold">{{ $t('Added by') }}</span> : {{
                                selectedKnowledgebase[0].knowledgebase.author }}</h6>
                        </div>

                    </PrimeDialog>
                </div>
            </div>






        </div>

    </div>
</template>
  
  
  
<script>
import axios from 'axios';

import { ref } from "vue";


export default {
    name:'AppKnowledge',
    data() {
        return {
            visible: ref(true),
            searchResults: [], // New data property to hold search results
            keyword: '',
            knowledgebases: [],
            navbarComponent: null,

            selectedKnowledgebase: null,
            youtubePlayer: null, // Variable to store the YouTube player instance
            youtubePlayerReady: false, // Flag to check if the YouTube player is ready
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],


        };
    },


    methods: {

        extractVideoIdFromLink(youtubeLink) {
            // Check if the link contains a time parameter
            const timeParamIndex = youtubeLink.indexOf("&t=");
            if (timeParamIndex !== -1) {
                // Remove the time parameter from the link
                youtubeLink = youtubeLink.substring(0, timeParamIndex);
            }

            // Extract the video ID from the modified link
            const videoId = youtubeLink.split("v=")[1];
            return videoId;
        },



        formatDate(date) {
            return new Date(date).toLocaleDateString()
        },

        navigateToLink(link) {
            this.$router.push(link);
        },

    },



    mounted() {

        console.log(this.$route.params.id);
        axios.get('https://backend.ihayanow.com/api/article_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });

        axios.get('https://backend.ihayanow.com/api/link_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });

        axios.get('https://backend.ihayanow.com/api/youtube_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });

        axios.get('https://backend.ihayanow.com/api/video_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });

        axios.get('https://backend.ihayanow.com/api/tip_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });

        axios.get('https://backend.ihayanow.com/api/movie_by_category', {
            params: {
                //id of knowledgebase
                id: this.$route.params.id
            },
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            }
        })
            .then(response => {
                this.visible = true;
                this.selectedKnowledgebase = response.data;
                console.log(this.selectedKnowledgebase[0].knowledgebase);
            })
            .catch(error => {
                console.log(error);
            });
    },
    computed: {
    inputDirection() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        embedURL() {
            if (
                this.selectedKnowledgebase[0] &&
                this.selectedKnowledgebase[0].knowledgebase.type === "Youtube"
            ) {
                const youtubeLink = this.selectedKnowledgebase[0].knowledgebase.link;
                const videoId = this.extractVideoIdFromLink(youtubeLink);
                return `https://www.youtube.com/embed/${videoId}`;
            }
            return "";
        },

    },


}


</script>
<style scoped>
.knowledgebase-image {

    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.knowledgebase-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.custom-button {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.custom-button .p-button {
    width: 25% !important;
}

.youtube-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}</style>
  