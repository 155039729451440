
<template>
  <div class="container">
    <component :is="navbarComponent" />
    <div class="row ">
      <div class="layout-main">
        <div class="grid">
          <div class="md:col-12 ">
            <div class="card ">
              <div class="card-header">
                <h2 class="text-center">{{ $t("Filters") }}</h2>
                <hr class="horizontal dark" />

              </div>
              <div class="card-body">
                <div class="flex-container">
                  <div class="h5-container sm:col-12 md:col-3 px-5 ">
                    <div class="p-inputgroup " :dir="searchButtonDirection">

                      <PrimeButton type="button" @click.prevent="search()" :label="$t('Search')" />
                      <InputText v-model="keyword" :dir="inputDirection" :placeholder="$t('profile.Keyword')" />
                    </div>
                  </div>
                  <div class="h5-container sm:col-12 md:col-3 pt-2 px-5">
                    <h5>{{ $t('profile.session_type') }}</h5>

                    <PrimeCheckbox id="checkOption1" name="option" value="Online" v-model="session_type" />
                    <label for="checkOption1" class="m-2">{{ $t('profile.Online') }}</label>
                    <PrimeCheckbox id="checkOption2" name="option" value="Onsite" v-model="session_type" />
                    <label for="checkOption2" class="m-2">{{ $t('profile.Onsite') }}</label>

                  </div>

                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5">
                    <h5>{{ $t('profile.session_duration') }}</h5>


                    <PrimeCheckbox id="checkOption" name="option" value="60" v-model="session_duration" />
                    <label for="checkOption" class="m-2">60 {{ $t('profile.minute') }}</label>
                    <PrimeCheckbox id="checkOption" name="option" value="30" v-model="session_duration" />
                    <label for="checkOption" class="m-2">30 {{ $t('profile.minute') }}</label>

                  </div>


                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5">
                    <h5>{{ $t('profile.language') }}</h5>

                    <PrimeCheckbox id="checkOption1" name="option" value="Arabic" v-model="language" />
                    <label for="checkOption1" class="m-2">{{ $t('profile.Arabic') }}</label>
                    <PrimeCheckbox id="checkOption2" name="option" value="English" v-model="language" />
                    <label for="checkOption2" class="m-2">{{ $t('profile.English') }}</label>

                  </div>


                  <div :class="{ 'margin-left-230':   this.$i18n.locale == 'ar', 'margin-right-230':   this.$i18n.locale == 'en' }" v-if="expand == false">
                    <PrimeButton id="filter" type="button" @click.prevent="filter()" class="mt-4">{{ $t('Payments.Apply')
                    }}
                    </PrimeButton>
                  </div>


                  <div class="h5-container  sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('profile.professional') }}</h5>

                    <PrimeCheckbox id="checkOption1" name="option" value="Psychastric" v-model="specialties" />
                    <label for="checkOption1" class="m-2">{{ $t('profile.Psychastric') }}</label>
                    <PrimeCheckbox id="checkOption2" name="option" value="Psychologist" v-model="specialties" />
                    <label for="checkOption2" class="m-2">{{ $t('profile.Psychologist') }}</label>

                  </div>

                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('rate.Rating') }}</h5>

                    <PrimeRating v-model="rating" :cancel="false"></PrimeRating>
                  </div>

                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('profile.online_session_price') }}
                    </h5>

                    <InputText class="px-4 " id="minPriceOnline" v-model="minPriceOnline" type="text"
                      :placeholder="$t('profile.Enter Min Price')" />
                    <InputText class="px-4 mt-3" id="maxPriceOnline" v-model="maxPriceOnline" type="text"
                      :placeholder="$t('profile.Enter Max Price')" />

                  </div>
                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('profile.onsite_session_price') }}
                    </h5>
                    <InputText class="px-4 " id="minPriceOnsite" v-model="minPriceOnsite" type="text"
                      :placeholder="$t('profile.Enter Min Price')" />
                    <InputText class="px-4 mt-3" id="maxPriceOnsite" v-model="maxPriceOnsite" type="text"
                      :placeholder="$t('profile.Enter Max Price')" />

                  </div>
                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('profile.specialties') }}</h5>

                    <!-- <div class="pt-1" v-for="(spec, index) in distinctSpecialities" :key="index"> -->
                    <!-- <PrimeCheckbox id="checkOption1" name="option" :value="spec" 
                      v-model="selectedSpecialities" /> -->
                    <!-- <label for="checkOption1">{{ spec }}</label> -->
                    <MultiSelect v-model="selectedSpecialities" display="chip" :options="distinctSpecialities"
                      optionLabel="name" :placeholder="$t('profile.Select_One')" :maxSelectedLabels="10" class="w-full" />

                    <!-- <PrimeDropdown class="mb-3" id="spec" v-model="selectedSpecialities" :options="distinctSpecialities" optionKey="name"
                      optionLabel="name" :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown> -->
                    <!-- </div> -->

                  </div>
                  <div class="h5-container sm:col-12 md:col-3 pt-2  px-5" v-if="expand">
                    <h5>{{ $t('profile.address') }}</h5>

                    <PrimeDropdown class="mb-3" id="state" v-model="country" :options="countries" optionKey="name"
                      optionLabel="name" :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>

                    <PrimeDropdown class="mb-3" id="state" v-model="state" :options="states" optionKey="name"
                      optionLabel="name" :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>

                    <PrimeDropdown id="city" v-model="city" :options="cities" optionKey="name" optionLabel="name"
                      :placeholder="$t('profile.Select_One')">

                    </PrimeDropdown>

                  </div>
                  <!-- <div class="field-checkbox mb-0">
                  <PrimeCheckbox id="checkOption1" name="option" value="most-reserved-doctors"
                    v-model="mostReservedDoctors" />
                  <h5 class="mx-3">{{ $t('most reserved doctors') }}</h5>
                </div> -->
                </div>

                <div class="text-center">
                  <div class="button-group">
                    <PrimeButton :icon="expand ? 'pi pi-fw pi-minus' : 'pi pi-fw pi-plus'"
                      :label="expand ? $t('Collapse All') : $t('Expand All')" type="button" @click.prevent="expandAll()"
                      class="mt-4">
                    </PrimeButton>

                    <PrimeButton v-if="expand" id="filter" type="button" @click.prevent="filter()" class="mx-4 mt-4">
                      {{ $t('Payments.Apply') }}
                    </PrimeButton>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- <div class="col-12"> -->
          <div class="card md:col-12">
            <div class="card-body">
              <div class="border-container">
                <h2>{{ $t('profile.Our Therapists') }}</h2>


              </div>

              <!-- rows is number of doctor will displayed in one page -->
              <DataView :value="displayedDoctors" :layout="layout" :rows="6">
                <template #grid="doctor">
                  <!-- <div class="row d-flex justify-content-center"> -->
                  <div class=" grid grid-nogutter md:col-12 justify-content-center">
                    <div class="card md:col-3 m-2" v-for="(item, index) in doctor.items" :key="index">
                      <!-- {{ item.profile }} -->
                      <div class="md:col-12">
                        <div
                          :class="{ 'heart-icon-en': this.$i18n.locale == 'en', 'heart-icon-ar': this.$i18n.locale == 'ar' }"
                          @click="addToFavourite(item.id)">
                          <i :class="{
                            'pi pi-fw pi-heart text-xl font-bold': !isFavorite[item.id],
                            'pi pi-fw pi-heart text-xl font-bold text-red': isFavorite[item.id]
                          }"></i>
                        </div>
                      </div>

                      <div class="card-body md:col-12 p-3">
                        <div class="profile-info">
                          <div class="profile-image">
                            <img class="shadow-2" :src="'https://backend.ihayanow.com/' + item.profile.photo"
                              alt="Profile Image" width="100" height="100">
                          </div>
                          <div class="profile-details">

                            <h5 class="mb-1" v-if="this.$i18n.locale === 'ar'"><i
                                class="pi pi-fw pi-user text-xl font-bold"></i>
                              {{ $t('Dr.') }} {{ item.profile.arabic_name }}</h5>
                            <h5 class="mb-1" v-else><i class="pi pi-fw pi-user text-xl font-bold"></i>
                              {{ $t('Dr.') }} {{ item.name }}</h5>

                            <p class="mb-0 font-weight-bold">
                              {{ item.profile.professional == "Psychologist" ?
                                $t("profile.Psychologist") : $t("profile.Psychastric") }}
                            </p>

                            <p class="mb-0 font-weight-bold">

                              {{ $t("profile.Specialist in treating") }} :
                              <span v-for="(spec, index) in item.speciality" :key="index">
                                <!-- {{ item.speciality }} -->
                                <span v-if="spec !== null">
                                  {{ this.$i18n.locale === 'ar' ? spec.name + ' ، ' : spec.english_name + ' ، ' }}
                                </span>
                              </span>
                            </p>

                            <div class="pt-3 ">
                              <PrimeRating class="text-center" :modelValue="item.profile.rating" :readonly="true"
                                :cancel="false">
                              </PrimeRating>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card-body md:col-12 p-3">
                        <div class="flex align-items-center justify-content-between pt-3"
                          v-if="displayOnlinePrice(item.profile)" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                          <span class=" px-2 font-semibold" style="color: #6366F1;">

                            <i class="pi pi-fw pi-money-bill px-1"></i>
                            {{ $t("Online Session Price") }} :
                            {{ item.profile.online_price }} {{ $t('Payments.EGP') }} / {{
                              item.profile.session_duration }}
                            {{ $t('profile.minute') }}</span>


                        </div>

                        <div class="flex align-items-center justify-content-between pt-3"
                          v-if="displayOnsitePrice(item.profile)" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                          <span class=" px-2 font-semibold" style="color: #6366F1;">
                            <i class="pi pi-money-bill px-1 text-xl"></i>
                            {{ $t("Onsite Session Price") }} :
                            {{ item.profile.onsite_price }} {{ $t('Payments.EGP') }} / {{
                              item.profile.session_duration }}
                            {{ $t('profile.minute') }}</span>


                        </div>


                        <div class="flex align-items-center justify-content-between pt-3 mx-2">

                          <span class="font-semibold" style="color: #6366F1;" v-if="this.$i18n.locale === 'ar'">
                            <i class="p-menuitem-icon pi pi-fw pi-globe"></i>
                            {{ (item.profile.session_type == "كليهما" ||
                              item.profile.session_type == "أونلاين و أوفلاين" || item.profile.session_type ==
                              "Online & Onsite")
                              ? $t('profile.Online & Onsite Sessions') :
                              $t('profile.Sessions') + ' ' + $t('profile.' + item.profile.session_type) }}
                          </span>

                          <span class="text-xl font-semibold" style="color: #6366F1;"
                            v-else-if="this.$i18n.locale === 'en'">
                            <i class="p-menuitem-icon pi pi-fw pi-globe"></i>
                            {{ (item.profile.session_type == "Online & Onsite" || item.profile.session_type
                              == "كليهما"
                              || item.profile.session_type == "أونلاين و أوفلاين")
                              ? $t('profile.Online & Onsite Sessions') :
                              $t('profile.' + item.profile.session_type) + ' ' + $t('profile.Sessions') }}

                          </span>

                        </div>
                        <div class="flex align-items-center justify-content-between pt-3 mx-2">


                          <p for="example-text-input" class="font-semibold" style="color: #6366F1;"
                            v-if="this.$i18n.locale === 'ar'">
                            <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>
                            {{
                              item.profile.address }} - {{ item.city_name.arabic_name }} - {{ item.state_name.arabic_name
  }} -
                            {{
                              item.country_name.arabic_name }}
                          </p>
                          <p for="example-text-input" class="text-xl font-semibold" style="color: #6366F1;" v-else>{{
                            item.profile.address }} -
                            {{ item.city_name.english_name }} - {{ item.state_name.english_name }} - {{
                              item.country_name.english_name }}
                          </p>
                        </div>

                        <div class="flex align-items-center justify-content-between pt-3 mx-2"
                          v-if="getDay(item.calendars) != '' && getTime(item.calendars) != ''">


                          <p for="example-text-input" class="font-semibold" style="color: green;">
                            <i class="p-menuitem-icon pi pi-fw pi-stopwatch"></i>
                            {{ $t("The nearest appointmrnt on : ") }}
                            {{ getDay(item.calendars) }} {{ $t("at") }}
                            {{ getTime(item.calendars) }}
                          </p>
                        </div>
                        <div class="flex align-items-center justify-content-between pt-3 mx-2">
                          <p for="example-text-input" class="font-bold" style="color: green;">
                            {{ $t("Number of reservations : ") }} {{ item.session_counter !== null ?
                              item.session_counter : 0 }} {{ $t("sessions") }}
                          </p>
                        </div>
                        <div class="flex col-12 align-items-center justify-content-between pt-3 my-3">
                          <PrimeButton @click="goToProfile(item.profile.id)">{{ $t('profile.View Profile') }}
                          </PrimeButton>
                          <PrimeButton @click="goToBook(item.profile.id)" :disabled="item.profile.status === 0"
                            v-if="this.Book == 'User'">
                            {{ $t('profile.Book Now') }}
                          </PrimeButton>
                        </div>

                      </div>


                    </div>
                    <!-- </div> -->
                  </div>
                </template>
              </DataView>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import DoctorNavbar from "./Doctor/DoctorNavbar.vue";
import UserNavbar from './User/UserNavbar.vue';
import Footer from "./Footer.vue";
// import moment from 'moment';
import moment from "moment";

export default {
  name: "therapists-page",
  data() {
    return {

      expand: false,
      selectedLanguage: 'ar',
      selectedSpecialities: [],
      isFavorite: {},
      name: "",
      doctors: [],
      profile_id: "",
      rating: 0,
      layout: 'grid',
      keyword: '',
      session: '',
      navbarComponent: null,
      Book: null,
      showDoctors: true, // Flag to control visibility of doctors list
      searchResults: [], // New data property to hold search results
      filterResults: [],
      reservedDoctors: [],
      session_type: '',
      session_duration: '',

      maxPriceOnline: '',
      minPriceOnline: '',
      maxPriceOnsite: '',
      minPriceOnsite: '',
      address: '',
      specialties: '',
      mostReservedDoctors: '',
      language: '',
      country: {
        id: "",
        name: "",
        translationKey: "",
      },
      state: {
        id: "",
        name: "",
        translationKey: "",
      },
      city: {
        id: "",
        name: "",
        translationKey: "",
      },
      countries: [],
      cities: [],
      states: [],

      country_name: '',
      state_name: '',
      city_name: '',
      calendars: [],
      date: '',
      from: '',
      speciality_arabic_name: '',
      speciality_english_name: '',
      specialities: [],
      allSpecialities: [],
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
   
    };
  },

  async mounted() {

    const userType = localStorage.getItem("type");
    this.navbarComponent = userType === "User" ? UserNavbar : DoctorNavbar;
    this.Book = userType;
    // await this.changeLanguage();
    await this.fetchDoctors();
    await this.fetchCountries();

    await this.fetchFavoriteDoctors();

  },
  methods: {

    expandAll() {
      this.expand = !this.expand;
      // document.getElementById('filter');


    },
    async fetchDoctors() {

      let result = await axios.get("https://backend.ihayanow.com/api/therapist_data", {
        headers: {
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      });

      this.doctors = result.data;
      this.calendars = this.doctors.calendars;
      this.specialities = this.doctors;
      console.log(this.doctors);
      this.specialities.forEach(doctor => {
        this.speciality_arabic_name = doctor.speciality[0];
        this.speciality_english_name = doctor.speciality[1];
      });

    },
    getTime(time) {
      if (time.length > 0) {
        const theCurrentTime = moment();
        // const formattedCurrentTime = theCurrentTime.format('h:mm A');

        let nearestTime = null;
        let withoutAmPmIndicator = null;
        let minDifference = Infinity;

        for (const t of time) {
          const formattedTime = moment(`${t.date} ${t.from}`, 'YYYY-MM-DD HH:mm:ss').format('h:mm A');
          const timeDiff = moment.duration(moment(formattedTime, 'h:mm A').diff(theCurrentTime)).asMilliseconds();

          if (timeDiff >= 0 && timeDiff < minDifference) {
            minDifference = timeDiff;
            nearestTime = formattedTime;

            withoutAmPmIndicator = moment(nearestTime, 'HH:mm:ss').format('h:mm');
          }
        }
        console.log(nearestTime);
        if (nearestTime !== null) {
          if (nearestTime.includes('AM')) {
            console.log("withoutAmPmIndicator AM ", withoutAmPmIndicator)
            return withoutAmPmIndicator + ' ' + this.$t("am");
          } else if (nearestTime.includes('PM')) {
            console.log("withoutAmPmIndicator PM ", withoutAmPmIndicator)
            return withoutAmPmIndicator + ' ' + this.$t("pm");
          }
          else {
            console.log(nearestTime);
            return nearestTime;
          }
        } else {
          return '';
        }


      } else {
        return '';
      }
    },


    getDay(date) {
      console.log(date);
      if (date.length > 0) {
        const firstCalendar = date[0];
        const day = firstCalendar.date; // Assuming the date property holds the date string

        const dayName = moment(day).format('dddd');
        const today = moment().format('dddd');
        const tomorrow = moment().add(1, 'days').format('dddd');
        if (dayName === today) {
          return this.$t("Today");
        } else if (dayName === tomorrow) {
          return this.$t("Tomorrow");
        } else {
          return this.$t(dayName);
        }
      } else {
        return '';
      }

    },

    async addToFavourite(id) {
      const isCurrentlyFavorite = this.isFavorite[id];
      const action = isCurrentlyFavorite ? 'remove' : 'add';

      if (action === 'add') {
        axios.post('https://backend.ihayanow.com/api/user/favorites', {
          doctor_id: id // Pass the id directly
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          },
        })
          .then(response => {
            this.success = true;
            this.message = response.data.message;
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            this.errors = error.response.data.message;
          });
      } else if (action === 'remove') {
        try {

          const response = await axios.post('https://backend.ihayanow.com/api/user/delete-favorites',
            { doctor_id: id },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret

              }
            });
          // console.log(response.status);
          if (response.status == 200) {
            this.errorMessage = 'Doctor deleted successfully from favourite list.';
            window.location.reload();

          } else {
            // console.log(response)
            this.errorMessage = 'Failed to delete doctor from favourite list.';
          }
        } catch (error) {
          console.error('Error deleting doctor from favourite list:', error);
          this.errorMessage = 'Failed to delete doctor from favourite list.';
        }
      }



      // You can add additional logic here to handle adding/removing from favorites list.
    },

    async fetchFavoriteDoctors() {
      try {

        const response = await axios.get('https://backend.ihayanow.com/api/user/favorites', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          },
        });

        const favoriteDoctorIds = response.data.map(doctor => doctor.profile.user_id);
        // console.log(favoriteDoctorIds);
        this.doctors.forEach(doctor => {
          this.isFavorite[doctor.id] = favoriteDoctorIds.includes(doctor.id);
        });

      } catch (error) {
        console.log('Error fetching Favourite Doctors:', error);
      }
    },
    async fetchCountries() {
      try {
        const headers = {
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        };
        const response = await axios.get("https://backend.ihayanow.com/api/get-countries", { headers });
        this.countries = response.data.map(country => ({
          id: country.id,
          name: this.$i18n.locale === 'ar' ? country.arabic_name : country.english_name,
          translationKey: country.english_name, // Assuming english_name is unique identifier
        }));
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },
    async fetchStates(countryId) {
      try {
        const response = await axios.get(`https://backend.ihayanow.com/api/get-states`,
          {
            params: { id: countryId },
            headers: {
              'Content-Type': 'application/json',
              'Api-Key': this.apiKey,
              'Api-Secret': this.apiSecret
            },
          }
        );
        this.states = response.data.map(state => ({
          id: state.id,
          name: this.$i18n.locale === 'ar' ? state.arabic_name : state.english_name,
          translationKey: state.english_name, // Assuming english_name is unique identifier

        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },
    async fetchCities(cityId) {
      try {

        const response = await axios.get(`https://backend.ihayanow.com/api/get-cities`, {
          params: { id: cityId },
          headers: {
            'Content-Type': 'application/json',

            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          },
        });

        this.cities = response.data.map(city => ({
          id: city.id,
          name: this.$i18n.locale === 'ar' ? city.arabic_name : city.english_name,
          translationKey: city.english_name, // Assuming english_name is unique identifier

        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },



    goToProfile(profileId) {
      this.$router.push({ name: 'TherapistProfile', params: { id: profileId } })
    },
    goToBook(profileId) {
      this.$router.push({ name: 'Calendar', params: { id: profileId } })
    },
    displayOnlinePrice(doctor) {
      if (this.$i18n.locale === 'ar') {

        return doctor.session_type == 'Online' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اونلاين' || doctor.session_type == 'أونلاين و أوفلاين';

      }
      else {
        return doctor.session_type == 'Online' || doctor.session_type === 'Online & Onsite' || doctor.session_type == 'أونلاين و أوفلاين';

      }
    },
    displayOnsitePrice(doctor) {
      if (this.$i18n.locale === 'ar') {

        return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';

      }
      else {
        return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';

      }
    },


    async search() {

      await axios.post("https://backend.ihayanow.com/api/search",

        {
          keyword: this.keyword
        },
        {
          headers: {
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          }
        }).then(response => {
          this.searchResults = response.data;
          console.log(this.searchResults)
        })
        .catch(error => {
          console.log(error)
        });


    },

    async filter() {

      if (this.$i18n.locale === 'ar') {
        if (this.session_type.includes(this.$t('profile.Online')) && this.session_type.includes(this.$t('profile.Onsite'))) {
          this.session_type = this.$t('profile.Both');
        }

        if (this.specialties.includes(this.$t('profile.Psychastric'))) {
          this.specialties = "Psychastric";
        } else if (this.specialties.includes(this.$t('profile.Psychologist'))) {
          this.specialties = "Psychologist";
        }

        if (this.language.includes(this.$t('profile.Arabic'))) {
          this.language = "Arabic";

        } else if (this.language.includes(this.$t('profile.English'))) {
          this.language = "English";

        }
        else if (this.language == "Arabic,English") {
          this.language = "Arabic & English";
        }



      } else if (this.$i18n.locale === 'en') {
        if (this.language.includes(this.$t('profile.Arabic')) && this.language.includes(this.$t('profile.English'))) {
          this.language = "Arabic & English";
        }
      }
      // let objectToArray = Object.values(this.selectedSpecialities);
      // console.log(typeof this.selectedSpecialities,objectToArray);
      // console.log(Array.isArray(objectToArray));
      const selectedSpecialityNames = this.selectedSpecialities.map(speciality => speciality.name);
      // const selectedSpecialityNames = this.selectedSpecialities.map((speciality) => ({name: speciality.name }));

      console.log(selectedSpecialityNames);
      let params = {
        session_type: this.session_type,
        session_duration: this.session_duration,
        rating: this.rating !== 0 ? this.rating : '',
        minPriceOnline: this.minPriceOnline,
        maxPriceOnline: this.maxPriceOnline,
        minPriceOnsite: this.minPriceOnsite,
        maxPriceOnsite: this.maxPriceOnsite,
        address: this.address,
        specialties: this.specialties,
        language: this.language,
        country_id: this.country.id,
        state_id: this.state.id,
        city_id: this.city.id,
        mostReservedDoctor: this.mostReservedDoctors,
        selectedSpecialities: selectedSpecialityNames
      };

      console.log(params);

      let queryString = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
      let url = `https://backend.ihayanow.com/api/filter?${queryString}`;
      await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      }).then(response => {
        // console.log('hello',response);
        this.filterResults = response.data;
        console.log(this.filterResults, this.name, this.calendars);

        // this.filterResults.forEach(res => {
        //   this.name = res.name;
        //   this.calendars = res.calendars;
        // });

      })
        .catch(error => {
          console.log(error)
        })

    },



  },
  watch: {
    country: {
      handler(newCountry) {
        if (newCountry.id) {
          this.fetchStates(newCountry.id); // Call API to fetch states
        }
      },
      deep: true, // Watch for changes in nested properties of country
    },
    state: {
      handler(newState) {
        if (newState.id) {
          this.fetchCities(newState.id); // Call API to fetch states
        }
      },
      deep: true, // Watch for changes in nested properties of country
    },
  },
  computed: {

    inputDirection() {
      return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
    searchButtonDirection() {
      return this.$i18n.locale === 'ar' ? 'ltr' : 'auto';
    },
    displayedDoctors() {
      // console.log(this.searchResults);
      if (this.filterResults.length > 0) {
        console.log("hello", this.filterResults);
        return this.filterResults;
      }
      else if (this.searchResults.length > 0) {
        return this.searchResults;
      } else {
        console.log(this.doctors);
        return this.doctors;
      }


    },
    //     distinctSpecialities() {
    //     const uniqueSpecialities = new Set();

    //     this.doctors.forEach(doctor => {
    //         // console.log(doctor);
    //         doctor.speciality.forEach(s => {
    //             const name = this.$i18n.locale === 'ar' ? s.name : s.english_name;
    //             uniqueSpecialities.add(name);
    //             // name is assigned here but not used afterwards
    //         });
    //     });

    //     // console.log(uniqueSpecialities);
    //     // return Array.from(uniqueSpecialities);
    //     // Convert uniqueSpecialities set to an array of objects with 'name' property
    //     return Array.from(uniqueSpecialities).map(name => ({ name }));
    //     // return distinctSpecialitiesArray;
    // },
    distinctSpecialities() {
      const uniqueNames = [];
      const uniqueSpecialities = new Set();

      this.doctors.forEach(doctor => {
        doctor.speciality.forEach(s => {
          const name = this.$i18n.locale === 'ar' ? s.name : s.english_name;

          // Instead of using Array.from() and map(), directly add objects to the Set
          if (!uniqueNames.includes(name)) {
            uniqueNames.push(name); // Add name to the array
            uniqueSpecialities.add({ name }); // Add object to the Set
          }
        });
      });

      // Convert uniqueSpecialities set to an array
      return Array.from(uniqueSpecialities);
    },

  },


  components: {
    UserNavbar,
    Footer,
    DoctorNavbar,
  }


};
</script>

<style scoped>

.margin-left-230{
  position: absolute; 
  top: 90px; 
  left: -70px;
  text-align: center;
}

.margin-right-230{
  position: absolute; 
  top: 90px; 
  right: 0px;
  text-align: center;
}

/* @media (min-width: 100px) {
    .margin-left-230 {
      position: absolute; 
  top: 155px; 
  left: 300px;
  text-align: center;
    }

    .margin-right-230 {
      position: absolute; 
  top: 90px; 
  right: 170px;
  text-align: center;
    }
  } */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container label {
  margin-right: 10px;
}

.row {
  margin-top: 1rem;
}

body {
  background: #F4F7FD;
  margin-top: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.p-dropdown {
  width: 20rem;
}

.card {
  position: relative;
}

.heart-icon-ar {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px;
  z-index: 1;
}

.heart-icon-en {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
  z-index: 1;
}

.text-red {
  color: red;
}

.card-margin {
  margin-bottom: 1.875rem;
}

.border-container {
  width: 30%;
  margin: 0 auto;
  text-align: center;
  /* If you want the text centered within the container */
  padding-bottom: 20px;

}

.border-container h2 {
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: 20px;
}

/* .p-rating{
    display: block !important; 
} */
</style>
