<template>
    <div class="container">
        <UserNavbar />
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <h5>{{ $t("menu.Sessions") }}</h5>
                            <TabView>
                                <TabPanel :header="$t('Sessions.Upcomming_Sessions')">
                                    <DataTable :value="upcommingsessions"  class="p-datatable-gridlines"
                                        :rows="10" dataKey="id" :rowHover="true" :loading="loading1"
                                        responsiveLayout="scroll">
                                        
                                        <!-- {{ upcommingsessions.data  }} -->
                                        <template #empty>
                                            <div v-if="upcommingsessions.length == 0"> {{ $t('Sessions.No_Sessions') }}</div>
                                        </template>

                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                        <PrimeColumn field="doctor_name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                            {{ rowData.doctor_name }}
                                        </PrimeColumn>
                                        <PrimeColumn field="date"  :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                            {{ rowData.date }}
                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="from" style="min-width: 14rem">

                                            <!-- {{$t("Sessions.From")}}  :  -->
                                            {{ rowData.from }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="to" style="min-width: 14rem">

                                            <!-- {{$t("Sessions.To")}}  :  -->
                                            {{ rowData.to }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Sessions_Duration')" field="duration" style="min-width: 10rem">
                                            {{ rowData.duration }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Location')"  field="onsite" style="min-width: 10rem">
                                            {{ rowData.onsite }}
                                        </PrimeColumn>
                                        <PrimeColumn header="Actions">

                                            <template #body="rowData">

                                                <PrimeButton
                                                    :disabled="!isSessionInProgress(rowData.data.from, rowData.data.to, isCreated[rowData.data.id]) "
                                                    v-if="rowData.data.onsite == 'Online' || rowData.data.onsite == 'أونلاين' || rowData.data.pending_status == 0" type="button"
                                                    @click.prevent="joinMeeting(rowData.data.id, rowData.data.doctor_id, rowData.data.user_id,rowData.data.user_name)"
                                                    :label="$t('Sessions.Join Meeting')" />

                                                    
                                                    <PrimeButton  :disabled="rowData.data.pending_status == 0" class="mt-4" @click="generateInvoice(rowData.data)" :label="$t('Download Invoice')" /> 
                                           
                                           
                              <div v-if="rowData.data.pending_status==0">
                            
                                <span style="color:red;" >{{ $t('this session is cancelled cuz the doctor is deleted from website for some reasons') }}</span>

                            </div>                   
   
                                                </template>
                                        </PrimeColumn>
                                    </DataTable>

                                </TabPanel>
                                <TabPanel :header="$t('Sessions.History_Sessions')">
                                    <DataTable :value="sessions"  class="p-datatable-gridlines" :rows="10"
                                        dataKey="id" :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                                    
                                        <template #empty>
                                            <div v-if="sessions.length == 0">{{ $t('Sessions.No_Sessions') }}</div>
                                        </template>
                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                       
                                        <PrimeColumn field="doctor_name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                            <!-- {{ this.$i18n.locale === 'ar' ? arabic_names : rowData.doctor_name}} -->

                                            {{ displayName(rowData.doctor_name) }}
                                        </PrimeColumn>
                                        <PrimeColumn field="date" :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                            {{ rowData.date }}
                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="from" style="min-width: 14rem">

                                            {{ $t("Sessions.From") }} : {{ rowData.from }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="to" style="min-width: 14rem">

                                            {{ $t("Sessions.To") }}: {{ rowData.to }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Sessions_Duration')" field="duration" style="min-width: 10rem">
                                            {{ rowData.duration }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Location')" field="onsite" style="min-width: 10rem">
                                            {{ rowData.onsite }}
                                        </PrimeColumn>

                                        <PrimeColumn header="Actions">

<template #body="rowData">

        <PrimeButton class="mt-4" @click="generateInvoice(rowData.data)" :label="$t('Download Invoice')" /> 
</template>
</PrimeColumn>
                                    </DataTable>



                                </TabPanel>


                                <TabPanel :header="$t('Sessions.Pending_Requests')">
                                <DataTable :value="pendingSessions" class="p-datatable-gridlines custom-paginator"
                                    :rows="10" dataKey="id" :rowHover="true" :loading="loading1" responsiveLayout="scroll" >
                                  <!-- {{ pendingSessions }} -->
                                    <template #empty>
                                        <div v-if="pendingSessions.length == 0">{{$t('Sessions.No_Sessions')}}</div>
                                    </template>
                                    <template #loading>
                                        {{$t('Sessions.Loading_Sessions')}}
                                    </template>
                                    <PrimeColumn field="name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                        {{ pendingSessions.name }}
                                    </PrimeColumn>
                                    <PrimeColumn field="session.date" :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                        {{ pendingSessions.session.date }}
                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="session.from" style="min-width: 14rem">

                                        {{ $t('Sessions.From') }}  : {{ rowData.session.from }}

                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="session.to" style="min-width: 14rem">

                                        {{ $t('Sessions.To') }} : {{ rowData.session.to }}

                                    </PrimeColumn>

                                    <PrimeColumn :header="$t('Sessions.Session_Location')" field="session.onsite" style="min-width: 10rem">
                                        {{ rowData.session.onsite }}
                                    </PrimeColumn>

                                    <PrimeColumn header="Actions">

                                        <template #body="rowData">
                                            <span style="color:red;" v-if="rowData.data.session.pending_status==0">{{ $t('this session is cancelled cuz the doctor is deleted from website for some reasons') }}</span>
                                            <!-- <PrimeButton label="Accept" @click="accept(rowData.data.session.id,
                                                rowData.data.session.date,
                                                rowData.data.session.from, rowData.data.session.to,
                                                rowData.data.session.doctor_id,
                                                rowData.data.session.user_id)">
                                            </PrimeButton> -->
                                        </template>
                                    </PrimeColumn>

                                </DataTable>

                            </TabPanel>
                            <TabPanel :header="$t('Sessions.Accepted_Requests')">
                                <DataTable :value="acceptedSessions"  class="p-datatable-gridlines custom-paginator"
                                    :rows="10" dataKey="id" :rowHover="true" :loading="loading2" responsiveLayout="scroll" >
                                    <template #empty>
                                        <div v-if="acceptedSessions.length == 0">{{ $t('Sessions.No_Sessions') }}</div>
                                    </template>
                                    <template #loading>
                                        {{ $t('Sessions.Loading_Sessions') }} 
                                    </template>
                                    <PrimeColumn field="name" :header="$t('Sessions.Doctor_Name')" style="min-width: 12rem">
                                        {{ acceptedSessions.name }}
                                    </PrimeColumn>
                                    <PrimeColumn field="session.date" :header="$t('Sessions.Session_Date')" style="min-width: 12rem">
                                        {{ pendingSessions.session.date }}
                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="session.from" style="min-width: 14rem">

                                        {{ $t('Sessions.From') }}  : {{ rowData.session.from }}

                                    </PrimeColumn>
                                    <PrimeColumn :header="$t('Sessions.Session_Time_To')"  field="session.to" style="min-width: 14rem">

                                        {{ $t('Sessions.To') }} : {{ rowData.session.to }}

                                    </PrimeColumn>

                                    <PrimeColumn :header="$t('Sessions.Session_Location')"  field="session.onsite" style="min-width: 10rem">
                                        {{ rowData.session.onsite }}
                                    </PrimeColumn>
                                    <PrimeColumn header="Actions">

<template #body="rowData">
    <span style="color:red;" v-if="rowData.data.session.pending_status==0">{{ $t('this session is cancelled cuz the doctor is deleted from website for some reasons') }}</span>
   
</template>
</PrimeColumn>
                                </DataTable>



                            </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>
  
<script>
import UserNavbar from './UserNavbar.vue';
import axios from 'axios';
import moment from 'moment';

import Footer from '../Footer.vue';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            discount:'',
            isCreated: {},
            sessions: [],
            pendingSessions: [],
            acceptedSessions: [],
            upcommingsessions: [],
            DataForPDF:'',
            joined_session_id: '',
            session_doctor_id: '',
            session_user_id: '',
            sessionData : '',
            calendarData:'',
            city_name:'',
            state_name:'',
            price:'',
        promocode:'',
            arabic_names:'',
            english_name:'',
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
        };
    },

    methods: {
        async generateInvoice(data) {
        
         const response= await axios.get('https://backend.ihayanow.com/api/user/get_data_for_pdf', {
                params:{
                    session_id:data.id
                },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            },
        });
console.log(response);

                this.sessionData = response.data[0];
                this.calendarData=response.data[1];
               
                if(this.calendarData.onsite == 0){
                    console.log("hello");
                    this.calendarData.onsite="Online";
                    this.price=response.data[2].online_price ;
                    this.discount=this.price - this.sessionData.price_after_discount;
                    this.promocode=response.data[3];
                }else if(this.calendarData.onsite == 1){
                    this.calendarData.onsite="Onsite";
                    this.price=response.data[2].onsite_price;
                    this.discount=this.price - this.sessionData.price_after_discount;
                    this.city_name=response.data[4];
                    this.state_name=response.data[3];
                      this.promocode=response.data[5];
                }

                
                console.log( this.sessionData,this.calendarData.onsite,
                this.calendarData,
            this.price,this.promocode);


            console.log(data);
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            const logoUrl = await this.getImageDataUrl('/mental_logo.png');

           
            const docDefinition = {
                content: [
                    {
                        columns: [
                            {
                                image: logoUrl,
                                width: 100
                            },
                          
                        ]
                    },
                    {
                        text: 'Invoice',
                        style: 'header'
                    },
                    {
                        text: `Details: Session Between Doctor : ${data.doctor_name}  ,  and Mr/s: ${data.user_name} `,
                        style: 'subheader'
                    },
                    {
                        text: `Session Place : the Session Will be ${this.calendarData.onsite}`,
                        style: 'subheader'
                    },
                   
                    {
                        text: `Date: ${this.formatDate(data.date)}`,
                        style: 'subheader'
                    },
                    {
                        text: `Session Time From: ${this.formatTime(data.from)}   , To: ${this.formatTime(data.to)}`,
                        style: 'subheader'
                    },
                    // {
                    //     text: `To: ${this.formatTime(data.to)}`,
                    //     style: 'subheader'
                    // },
                

                    {
                       
                        table: {
                            headerRows: 1,
                            widths: ['*', 'auto'],
                            body: [
                                ['Description', 'Amount'],
                            
                                ['Session Price', `${this.price}  EGP`],

                                ['Promo Code Applied', `${this.promocode.code}`],
                                ['Discounted', `${this.discount} EGP`],
                                ['Final Amount Paid', `${this.sessionData.price_after_discount}  EGP`]
                                // Add more rows for additional details if needed
                            ]
                        },
                        layout: {
                            fillColor: function (rowIndex) {
                                return (rowIndex % 2 === 0) ? '#f2f2f2' : null; // Alternate row background color
                            }
                        },
                        style: 'table'
                    },

                ],
                styles: {
                    header: {
                        fontSize: 28,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 0, 0, 10]
                    },
                    description: {
                        fontSize: 12,
                        alignment: 'center',
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 14,
                        margin: [0, 0, 0, 5]
                    },
                    table: {
                        margin: [0, 20, 0, 0],
                        padding: [5, 10, 10, 5]
                    }
                }


            };

            pdfMake.createPdf(docDefinition).download('invoice.pdf');
           


         
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },

        getImageDataUrl(imagePath) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    resolve(canvas.toDataURL('image/png'));
                };
                img.onerror = function (error) {
                    reject(error);
                };
                img.src = imagePath;
            });
        },
        async withdrawSession(sessionId) {
            try {
           
                await axios.delete(`https://backend.ihayanow.com/api/user/request-session/${sessionId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                // remove session from pending sessions list
                this.pendingSessions = this.pendingSessions.filter((session) => session.session.id !== sessionId);
            } catch (error) {
                console.error(error);
            }
        },
        formatTime(time) {
            // console.log(time);
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours);
            const amPm = hour >= 12 ? 'pm' : 'am';
            hour = hour % 12 || 12;
            return `${hour}:${minutes} ${amPm}`;
        },
        async fetchSessions() {
            try {
                
                const response = await axios.get('https://backend.ihayanow.com/api/user/pending-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                         'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                this.pendingSessions = response.data;
                console.log(this.pendingSessions);
                this.pendingSessions.forEach(session => {
                    if (session.session.onsite == 0) {
                        session.session.onsite = this.$t('Online')
                    } else {
                        session.session.onsite =this.$t('Onsite');

                    }
                });
                this.pendingSessions.sort((a, b) => new Date(b.session.date) - new Date(a.session.date));

                const response2 = await axios.get('https://backend.ihayanow.com/api/user/accepted-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                         'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
                this.acceptedSessions = response2.data;
                this.acceptedSessions.forEach(session => {
                    if (session.session.onsite == 0) {
                        session.session.onsite = this.$t('Online')
                    } else {
                        session.session.onsite = this.$t('Onsite');

                    }
                });
                this.acceptedSessions.sort((a, b) => new Date(b.session.date) - new Date(a.session.date));

            } catch (error) {
                console.error(error);
            }
        },
        calculateDuration(from, to) {
            const fromParts = from.split(':');
            const toParts = to.split(':');


            const fromSeconds = (+fromParts[0]) * 60 * 60 + (+fromParts[1]) * 60;
            const toSeconds = (+toParts[0]) * 60 * 60 + (+toParts[1]) * 60;
            let diff = toSeconds - fromSeconds;
            if (diff < 0) {
                diff += 24 * 60 * 60; // add 1 day if end time is earlier than start time
            }
            const hours = Math.floor(diff / (60 * 60));
            const minutes = Math.floor((diff / 60) % 60);
            const duration = `${hours} ${this.$t('hours')} ${minutes} ${this.$t('minutes')}`;

            return duration;
        },

        async joinMeeting(joined_session_id, session_doctor_id, session_user_id,joined_user_name) {
          
            console.log(joined_session_id, session_doctor_id,
                session_user_id);
            axios.post('https://backend.ihayanow.com/api/doctor/createmeeting', {
                user_id: session_user_id,
                session_id: joined_session_id
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                },
            })
                .then(response => {
                    console.log(response);
                    // if (response.status == 201) {
                    // this.message = [{
                    //     severity: 'success', detail: 'Success Message',
                    //     content: 'Meeting Created Successfully'
                    // }]
                 
                        console.log(joined_session_id);
                        console.log(joined_user_name);
                        this.$router.push({ name: 'session_meeting', params: { sessionId: joined_session_id,joined_user_name:joined_user_name } });
                  

                })
                .catch(error => {
                    console.log(error);
                });



        },
        isSessionInProgress(from, to, isMeetingCreated) {
            const now = new Date();
            const startTime = new Date(now.toDateString() + ' ' + from);
            const endTime = new Date(now.toDateString() + ' ' + to);

            console.log(startTime <= now && now <= endTime)
            return startTime <= now && now <= endTime && isMeetingCreated;
        },
        displayName(english_name) {
      if (this.$i18n.locale === 'ar') {
        const arabicName = this.arabic_names.find(nameObj => nameObj === english_name);
        console.log(arabicName);
        return arabicName ? arabicName.arabic_name : '';
        // console.log(this.arabic_names);
        // this.arabic_names.foreach(arabic_name=>{
        //     return arabic_name[english_name]
        // })
      } else {
        return  "hello";
      }
    },



    },
    mounted() {
        this.fetchSessions();
    },
   computed:{
    pageinator() {
        return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
   },
    components: {
        UserNavbar,
        Footer

    },
    async created() {
        
        const response = await
            fetch('https://backend.ihayanow.com/api/user/sessions-history', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                }
            });
        const data = await response.json();   
        this.sessions = data.sessions; 
        this.arabic_names=data.arabic_names;
        console.log(this.sessions);
    //    this.arabic_names.forEach(arabic_name=>{
    //     if(this.$i18n.locale === 'ar'){
    //         this.arabic_name = arabic_name;
    //     }else{
    //         this.arabic_name = arabic_name;
    //     }
         
    //    });

    
        this.sessions.forEach(session => {
            session.duration = this.calculateDuration(session.from, session.to);
        });
        this.sessions.forEach(session => {
            if (session.onsite == 0) {
                session.onsite = this.$t('Online')
            } else {
                session.onsite = this.$t('Onsite');

            }
        });
        this.sessions.sort((a, b) => new Date(b.date) - new Date(a.date));

        const upcommingresponse = await
            fetch('https://backend.ihayanow.com/api/user/upcoming-sessions', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                }
            });
        this.upcommingsessions = await upcommingresponse.json();
        console.log(this.upcommingsessions);
        // this.joined_user_name=this.upcommingsessions.user_name;
        this.upcommingsessions.forEach(upcommingsession => {
            upcommingsession.duration = this.calculateDuration(upcommingsession.from, upcommingsession.to);
        });
        this.upcommingsessions.forEach(upcommingsession => {
            if (upcommingsession.onsite == 0) {
                upcommingsession.onsite = this.$t('Online')
            } else {
                upcommingsession.onsite = this.$t('Onsite');

            }
        });
        // this.upcommingsessions.sort((a, b) => new Date(b.sessions.date) - new Date(a.sessions.date));

        this.upcommingsessions.forEach(upcommingsession => {
            axios.post('https://backend.ihayanow.com/api/is_meeting_created',
            {session_id: upcommingsession.id}, 
            {
            headers: {
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                }
            }).then(response => {
                this.isCreated[upcommingsession.id] = response.data.length !== 0;
            }).catch(error => {
                console.log(error);
            });
        });
    },




}


</script>

<style scoped>
.custom-paginator .p-paginator-first,
.custom-paginator .p-paginator-prev,
.custom-paginator .p-paginator-next,
.custom-paginator .p-paginator-last {
  direction: ltr !important; 
}

</style>



