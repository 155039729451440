<template>
    <div :key="selectedLanguage" class="relative z-2">
        <PrimeMenubar :model="menu" @item-click="handleMenuItemClick">
            <template #start >
                <div class="mx-4 layout-topbar">
                    <img :src="require('/public/mental_logo.png')" alt="Sakai logo" style="width:70px; height:70px;">
                    <span class="text">IHAYANOW</span>
                </div>
            </template>
    <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
    </template>
           
          
            <template #end>
                <div class="toggle-with-notification">

                    <div class="language-toggle" :class="{ 'margin-left-230': this.selectedLanguage == 'en', 'margin-right-230': this.selectedLanguage == 'ar' }">
                        <ToggleSwitchButton class="toggle" v-model="this.isActive" label="AR|EN" @click="toggleLanguage" />

                    </div>
                </div>

            </template>
        </PrimeMenubar>
    </div>
</template>



<script>
import axios from 'axios';
import ToggleSwitchButton from "toggle-switch-button-vue";

export default {
    name:'AppNavbar',
    data() {
        return {
            isActive: 'AR',
            selectedLanguage: 'ar', // Store the selected language
            supportedLanguages: ['en', 'ar'],// Add the supported languages here
            locale: this.$i18n.locale, // Access the locale directly from $i18n
            isRtl: false, // Set initial value for page direction
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
        };

    },

    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.locale = this.selectedLanguage;
            this.updatePageDirection();
        },
        toggleLanguage() {
            this.selectedLanguage = this.selectedLanguage === 'ar' ? 'en' : 'ar';
            this.changeLanguage();
        },
        updatePageDirection() {
            if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
                document.documentElement.dir = 'rtl';
            } else {
                document.documentElement.dir = 'ltr';
            }
        },
        handleMenuItemClick(event) {
            if (event.item.id === 'logout') {
                this.logout();
            }
        },
        async logout() {
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear();
                    this.$router.push('/login-page');
                    window.location.reload();

                })
                .catch(error => {
                    console.log(error);
                });
        },
 async login(){
      this.$router.push({ name: 'login-page'})

 },

    },
    created() {
        this.updatePageDirection(); // Call the method on component creation to initialize the page direction
    },
    computed: {

        menu() {
            const hasToken = localStorage.getItem('token');
            console.log(hasToken);
            const menuItems = [
                {
                    label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', route: '/'

                },

                {
                    label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', route: "/knowledgebase-page"

                },
                {
                    label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', route: "/view-medications"

                },
                {
                    label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', route: "/guest-therapist-page"

                },
               
               {
                label: this.$t('menu.sign_up'), icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: this.$t('menu.sign_up_as_user'), icon: 'pi pi-fw pi-user-plus', route: "/sign-up"
                        },
                        {
                            label: this.$t('menu.sign_up_as_doctor'), icon: 'pi pi-fw pi-user-plus', route: "/doctor-sign-up"
                        },
                    ]
               },
               
                // {
                //     label: this.$t('menu.sign_in'), icon: 'pi pi-fw pi-sign-in', route: "/login-page"

                // },

            ];
            // If there is a token in localStorage, add the "Logout" item
            if (hasToken !== null) {
                menuItems.push({
                    label: this.$t('logout'),
                    icon: 'pi pi-power-off',
                    command: this.logout,
                });
            }
            if (hasToken == null) {
                menuItems.push({
                    label: this.$t('menu.sign_in'),
                    icon: 'pi pi-fw pi-sign-in', 
                    command: this.login,
                });
            }

            return menuItems;
        }

    },
    components: {
        ToggleSwitchButton
    }

}


</script>


<style scoped>

.text{
  font-size:1.80rem;
}

.toggle-switch-button {
    border: none !important;
}

.toggle-switch-button .switch-button-knob {
    border: solid 1px white !important;

}

.toggle-with-notification {
    display: flex;
    align-items: center;
}
/* .language-toggle{
   margin-right: 440px;
} */

.margin-left-230 {
    margin-left: 290px;
}

.margin-right-230 {
    margin-right: 440px;
}


.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.layout-topbar {
    flex-grow: 1;
}

.language-dropdown {
    margin-right: 1rem;
}

/* Add CSS rules for RTL direction */

.logout-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    font-weight: bold;
}

.logout-button i {
    margin-right: 0.5rem;
}

.logout-button:hover {
    color: #ff0000;
}

@media (max-width: 768px) {
    .margin-left-230 {
      margin-left: 0;
    }

    .margin-right-230 {
      margin-right: 0;
    }
  }
</style>