

<template>
    <div class="container">
        <DoctorNavbar />
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <h5>{{ $t("menu.Sessions") }}</h5>
                            <PrimeMessage severity="success" v-show="this.successs">
                                {{ $t(`${this.messages}`) }}</PrimeMessage>
                            <PrimeMessage severity="error" v-show="this.successMessages">
                                {{ $t(`${this.successMessage}`) }}</PrimeMessage>


                            <TabView>
                                <TabPanel :header="$t('Sessions.Pending_Requests')">

                                    <DataTable :value="pendingSessions" class="p-datatable-gridlines" :rows="10"
                                        dataKey="id" :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                                        <!-- <template #body="rowData">
                                        <PrimeMessage v-if="isDateAfterCurrent(rowData.data.sessions.date)" severity="error"  >
                                {{ $t('expired messages') }}</PrimeMessage>
                                </template> -->
                                        <template #empty>
                                            <div v-if="pendingSessions.length == 0">{{ $t('Sessions.No_Sessions') }}</div>
                                        </template>
                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                        <PrimeColumn field="user_name" :header="$t('Sessions.Patient_Name')"
                                            style="min-width: 12rem">
                                        
                                          <span> {{ this.user_name }} </span>   
                                      
                                        </PrimeColumn>
                                        <PrimeColumn  field="sessions.date" :header="$t('Sessions.Session_Date')"
                                            style="min-width: 12rem">
                                            <template #body="rowData">
                                               
                                                <span class="text_color"  v-if="rowData.data.no_overlap">
                                                {{ rowData.data.sessions.date }}
                                            </span>
                                             
                                            <span class="text_color" v-if="rowData.data.overlap" style="color:red;">
                                               {{ rowData.data.sessions.date }}
                                           </span>
                                            </template>
                                          
                                        </PrimeColumn>
                                      
                                        <PrimeColumn  :header="$t('Sessions.Session_From_Time')" field="sessions.from"
                                            style="min-width: 14rem">

                                            <template #body="rowData">
                                               <!--  {{ $t('Sessions.From') }} : -->
                                               <span class="text_color"  v-if="rowData.data.no_overlap">
                                                {{ rowData.data.sessions.from }}
                                           </span>
                                            <!--   {{ $t('Sessions.From') }} : -->
                                           <span class="text_color" v-if="rowData.data.overlap" style="color:red;">
                                           {{ rowData.data.sessions.from }}
                                          </span>
                                           </template>
                                            

                                        </PrimeColumn>
                                       
                                        <PrimeColumn  :header="$t('Sessions.Session_Time_To')" field="sessions.to"
                                            style="min-width: 14rem">
                                            <template #body="rowData">
                                               <!-- {{ $t('Sessions.To') }}: -->
                                               <span class="text_color"  v-if="rowData.data.no_overlap">
                                                 {{ rowData.data.sessions.to }}
                                           </span>
                                            <!--    {{ $t('Sessions.To') }}: -->
                                           <span class="text_color" v-if="rowData.data.overlap" style="color:red;">
                                          {{ rowData.data.sessions.to }}
                                          </span>
                                           </template>
                                           

                                        </PrimeColumn>
                                       

                                        <PrimeColumn  :header="$t('Sessions.Session_Location')" field="sessions.onsite"
                                            style="min-width: 10rem">
                                            
                                            <template #body="rowData">
                                               
                                               <span class="text_color"  v-if="rowData.data.no_overlap">
                                                {{ rowData.data.sessions.onsite }}
                                           </span>
                                            
                                           <span class="text_color" v-if="rowData.data.overlap" style="color:red;">
                                            {{ rowData.data.sessions.onsite }}
                                          </span>
                                           </template>
                                        </PrimeColumn>
                                       
                                        <PrimeColumn :header="$t('Actions')">

                                            <template #body="rowData">
                                                <PrimeButton
                                                    v-if="isDateAfterCurrent(rowData.data.sessions.date, rowData.data.sessions.from) && rowData.data.no_overlap"
                                                    class="mr-2 mb-2 " icon="pi pi-check" @click="accept(rowData.data.sessions.id,
                                                        rowData.data.sessions.from, rowData.data.sessions.to,
                                                        rowData.data.sessions.doctor_id,
                                                        rowData.data.sessions.user_id)">
                                                </PrimeButton>
                                                <PrimeButton class="mr-2 mb-2  p-button-danger" icon="pi pi-trash"
                                                    @click="reject(rowData.data.sessions.id)">
                                                </PrimeButton>
                                                <div  v-if="rowData.data.overlap">
                                                    <span  style="color:red;">{{ $t(rowData.data.overlap) }}</span>

                                                </div>
                                            </template>
                                        </PrimeColumn>

                                    </DataTable>

                                </TabPanel>
                                <TabPanel :header="$t('Sessions.Accepted_Requests')">
                                    <DataTable :value="acceptedSessions" class="p-datatable-gridlines" :rows="10"
                                        dataKey="id" :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                                        <template #empty>
                                            <div v-if="acceptedSessions.length == 0"> {{ $t('Sessions.No_Sessions') }}</div>
                                        </template>
                                        <template #loading>
                                            {{ $t('Sessions.Loading_Sessions') }}
                                        </template>
                                        <PrimeColumn field="user_name" :header="$t('Sessions.Patient_Name')"
                                            style="min-width: 12rem">
                                            {{ this.user_name }}
                                        </PrimeColumn>
                                        <PrimeColumn field="sessions.date" :header="$t('Sessions.Session_Date')"
                                            style="min-width: 12rem">
                                            {{ acceptedSessions.sessions.date }}
                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_From_Time')" field="sessions.from"
                                            style="min-width: 14rem">

                                            {{ $t('Sessions.From') }} : {{ rowData.sessions.from }}

                                        </PrimeColumn>
                                        <PrimeColumn :header="$t('Sessions.Session_Time_To')" field="sessions.to"
                                            style="min-width: 14rem">

                                            {{ $t('Sessions.To') }} : {{ rowData.sessions.to }}

                                        </PrimeColumn>

                                        <PrimeColumn :header="$t('Sessions.Session_Location')" field="sessions.onsite"
                                            style="min-width: 10rem">
                                            {{ rowData.sessions.onsite }}
                                        </PrimeColumn>
                                    </DataTable>



                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import axios from 'axios';
import DoctorNavbar from './DoctorNavbar.vue';
import Footer from '../Footer.vue';
import moment from 'moment';

export default {
    data() {
        return {
            user_name: '',
            pendingSessions: [],
            acceptedSessions: [],
            session_id: '',
            message: [],
            session_from: '',
            session_to: '',
            session_doctor_id: '',
            session_user_id: '',
            errors: {},
            successs: false,
            messages: {},
            successMessages: false,
            selectedLanguage: '',
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
        };
    },
    name: 'requests-page',
    components: {
        DoctorNavbar,
        Footer
    },


    methods: {

        async accept(session_id, session_from, session_to, session_doctor_id, session_user_id) {
            try {
                const fromMeridiem = session_from.includes('مساءا') ? 'مساءا' : 'صباحا';
        const toMeridiem = session_to.includes('مساءا') ? 'مساءا' : 'صباحا';

        let from = moment(session_from, 'hh:mm A').format('HH:mm:ss');
        let to = moment(session_to, 'hh:mm A').format('HH:mm:ss');

        if (fromMeridiem === 'مساءا') {
            if (moment(from, 'HH:mm:ss').format('HH') !== '12') {
                from = moment(from, 'HH:mm:ss').add(12, 'hours').format('HH:mm:ss');
            }
        } else if (moment(from, 'HH:mm:ss').format('HH') === '12') {
            from = moment(from, 'HH:mm:ss').subtract(12, 'hours').format('HH:mm:ss');
        }

        if (toMeridiem === 'مساءا') {
            if (moment(to, 'HH:mm:ss').format('HH') !== '12') {
                to = moment(to, 'HH:mm:ss').add(12, 'hours').format('HH:mm:ss');
            }
            if (moment(to, 'HH:mm:ss').isBefore(moment(from, 'HH:mm:ss'))) {
                // Increment 'to' by a day as it's in the evening of the next day
                to = moment(to, 'HH:mm:ss').add(1, 'day').format('HH:mm:ss');
            }
        } else if (moment(to, 'HH:mm:ss').format('HH') === '12') {
            to = moment(to, 'HH:mm:ss').subtract(12, 'hours').format('HH:mm:ss');
        }

                console.log("after conditions",from, to);

                await axios.post('https://backend.ihayanow.com/api/doctor/accept-request-session', {
                    user_id: session_user_id,
                    doctor_id: session_doctor_id,
                    from: from,
                    to: to,
                    id: session_id
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret

                    },
                })
                    .then(response => {
                        // const data = response;
                        console.log(response);

                        if (response.status == 200) {
                        this.successs = true;
                        this.messages = response.data.message;
                        console.log(this.messages);
                        this.message = [{ severity: 'success', detail: 'Success Message', content: 'Session Accepted Successfully' }]
                        this.fetchAcceptedSessions(); // Fetch the updated accepted sessions
                        this.fetchSessions();
                        axios.get('https://backend.ihayanow.com/api/get_reminders', {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Api-Key': this.apiKey,
                                'Api-Secret': this.apiSecret
                            }
                        })
                            .then(response => {
                                console.log(response.data);

                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }}
                    ).catch(error => {
                        console.log(error);

                    });
            } catch (error) {
                console.error(error);
            }
        },
        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },
        async fetchAcceptedSessions() {
            try {

                const response = await axios.get('https://backend.ihayanow.com/api/doctor/accepted-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    },
                });
                this.acceptedSessions = response.data;
                console.log(this.acceptedSessions);
                this.message = [{ severity: 'success', detail: 'Success Message', content: 'Session Accepted Successfully' }]

            } catch (error) {
                console.error(error);
            }
        },
        formatTime(time) {
            const [hours, minutes] = time.split(':').map(Number); // Split the time string and convert parts to numbers
            let formattedTime = '';

            // Validate the hour and minute values
            if (!isNaN(hours) && !isNaN(minutes)) {
                let hour = hours % 12 || 12; // Get the hour in 12-hour format

                // Determine if it's AM or PM based on the hour value
                const amPm = hours >= 12 ? this.$t('pm') : this.$t('am');

                // Format the hour and minute with leading zeros if necessary
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');

                formattedTime = `${formattedHour}:${formattedMinutes} ${amPm}`;
            } else {
                // Handle invalid time format or parsing errors
                formattedTime = 'Invalid Time';
            }

            return formattedTime;
        },

        async fetchSessions() {

            try {

                const response = await axios.get('https://backend.ihayanow.com/api/doctor/pending-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    },
                });
                console.log(response);
                this.pendingSessions = response.data;

                response.data.forEach(session => {
                    
                    if (session.sessions.onsite == 0) {
                        session.sessions.onsite = this.$t('Online')
                    } else {
                        session.sessions.onsite = this.$t('Onsite');

                    }
                    session.sessions.from = this.formatTime(session.sessions.from);
                    session.sessions.to = this.formatTime(session.sessions.to);

                    console.log(session.sessions.from, session.sessions.to)

                });

                this.pendingSessions = response.data.map(session => ({
                    ...session,
                    user_name: this.$i18n.locale === 'ar' ? session.arabic_name : session.name

                }));

                this.pendingSessions.sort((a, b) => new Date(b.sessions.date) - new Date(a.sessions.date));

                console.log(this.user_name, this.pendingSessions)


                const response2 = await axios.get('https://backend.ihayanow.com/api/doctor/accepted-requests', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    },
                });
                this.acceptedSessions = response2.data;
                this.acceptedSessions.forEach(session => {

                    if (session.sessions.onsite == 0) {
                        session.sessions.onsite = this.$t('Online')
                    } else {
                        session.sessions.onsite = this.$t('Onsite');

                    }
                    session.sessions.from = this.formatTime(session.sessions.from);
                    session.sessions.to = this.formatTime(session.sessions.to);
                    console.log(session.sessions.from, session.sessions.to)


                });


                this.acceptedSessions = response2.data.map(session => ({
                    ...session,
                    user_name: this.$i18n.locale === 'ar' ? session.arabic_name : session.name

                }));
                this.acceptedSessions.sort((a, b) => new Date(b.sessions.date) - new Date(a.sessions.date));

            } catch (error) {
                console.error(error);
            }
        },
        // isDateAfterCurrent(sessionDate,sessionTime) {

        //     const fromMeridiem = sessionTime.includes('مساءا') ? 'مساءا' : 'صباحا';

        //     let from = moment(sessionTime, 'hh:mm A').format('HH:mm:ss');

        //     if (fromMeridiem === 'مساءا') {
        //         const fromHour = parseInt(from.split(':')[0]);
        //         from = `${fromHour + 12}:${from.split(':')[1]}:00`;
        //     }

        //     const currentDate = new Date();
        //     const sessionDateObject = new Date(sessionDate);
        //     console.log(sessionDate,sessionTime,currentDate,sessionDateObject);
        //     return sessionDateObject >= currentDate;
        // },


        isDateAfterCurrent(sessionDate, sessionTime) {
            const fromMeridiem = sessionTime.includes('مساءا') ? 'مساءا' : 'صباحا';
            const sessionTimeString = sessionTime.replace('صباحا', 'AM').replace('مساءا', 'PM');

            let sessionDateTime = moment(`${sessionDate} ${sessionTimeString}`, 'YYYY-MM-DD hh:mm A');

            if (fromMeridiem === 'مساءا') {
                sessionDateTime.add(12, 'hours');
            }

            const currentDate = moment();

            // console.log(currentDate, sessionDateTime, fromMeridiem, sessionTimeString, sessionDate, sessionTime)

            return sessionDateTime.isSameOrAfter(currentDate);
        },

        async reject(sessionId) {
            try {

                const response = await axios.post('https://backend.ihayanow.com/api/doctor/delete-request-sessions',
                    { id: sessionId },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Api-Key': this.apiKey,
                            'Api-Secret': this.apiSecret
                        }
                    }

                );
                // if (response.ok) {
                console.log(response);
                this.successMessages = true;
                this.successMessage = 'Session Request deleted successfully.';
                this.fetchAcceptedSessions();
                // } else {
                // this.errorMessage = 'Failed to delete session request.';
                // }
            } catch (error) {
                console.error('Error deleting session request:', error);
                this.errorMessage = 'Failed to delete session request.';
            }
        },
    },
    mounted() {
        this.selectedLanguage = this.$i18n.locale;
        console.log(this.selectedLanguage)
        this.fetchSessions();
        // this.fetchAcceptedSessions();

    },
};
</script>
  


<style scoped>
/* .p-message-close ,.p-link{
      !important;
} */
</style>
