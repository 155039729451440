<template>
    <div class="container">
        <component :is="navbarComponent" />
        <div class="layout-main">
            <div class="grid mt-5 px-5">
                <div class="md:col-12">
                    <div class="search-container">
                        <div class="p-inputgroup pb-5" :dir="searchButtonDirection">
                            <PrimeButton type="button" @click.prevent="search()" :label="$t('Search')" />
                            <InputText style="border-radius:5px; " :dir="inputDirection" v-model="keyword"
                                :placeholder="$t('Search')" />
                        </div>
                    </div>
                </div>
                <div class="card md:col-12 py-4" v-if="searchResults.length === 0">
                    <div class="md:col-2">
                        <h2 class="pb-4" style="border-bottom: 1px solid var(--primary-color);">
                            {{ $t('All Medications') }}</h2>
                    </div>
                    <div class="pricing-tiers-container" style="display: flex; flex-wrap: wrap;">

                        <div class="md:col-4 pricing-tier " v-for="(medication, meIndex) in medications" :key="meIndex">
                                <div class="img-container">
                                    <img :src="'https://backend.ihayanow.com/' + medication.photo" alt="Medication Image"
                                        class="md:col-12">
                                </div>

                                <h5 class="card-title">{{ $t("Medications.scientific_name") }} : {{
                                    medication.scientific_name }}
                                </h5>
                                <h5 class="card-title">{{ $t("Medications.commercial_name") }} : {{
                                    medication.commercial_name }}
                                </h5>
                                <h5 class="card-title">{{ $t("Medications.active_ingredient") }} : {{
                                    medication.active_ingredient
                                }}</h5>
                                <small class="text-body-secondary">
                                    <PrimeButton type="button" @click.prevent="KnowMore(medication)"
                                        class="p-button p-button-rounded">{{ $t('Know More') }}
                                    </PrimeButton>
                                </small>



        <div class="card flex justify-content-center" v-if="visible">
             
             <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal header="Header"
               :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
               <template #header>
                <div class="img-container">
                                        <img :src="'https://backend.ihayanow.com/' + selectedMedication.photo"
                                            alt="Medication Image" class="col-12"
                                            style=" display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                    </div>
               </template>

              
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t("Medications.scientific_name") }} : {{
                                            selectedMedication.scientific_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.commercial_name") }} : {{
                                            selectedMedication.commercial_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.active_ingredient") }} : {{
                                            selectedMedication.active_ingredient
                                        }}</h5>
                                        <p class="card-text">{{ $t("Medications.description") }} : {{
                                            selectedMedication.description
                                        }}
                                        </p>
                                    </div>

             </PrimeDialog>
           </div>
      

                            <!-- <div class="pt-5"
                                    v-if="selectedMedication !== null && selectedMedication.id == medication.id">

                                    <div class="img-container">
                                        <img :src="'https://backend.ihayanow.com/' + selectedMedication.photo"
                                            alt="Medication Image" class="col-12"
                                            style=" display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t("Medications.scientific_name") }} : {{
                                            selectedMedication.scientific_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.commercial_name") }} : {{
                                            selectedMedication.commercial_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.active_ingredient") }} : {{
                                            selectedMedication.active_ingredient
                                        }}</h5>
                                        <p class="card-text">{{ $t("Medications.description") }} : {{
                                            selectedMedication.description
                                        }}
                                        </p>
                                    </div>


                                </div> -->
                        </div>
                    </div>
                </div>

                <div class="card md:col-12 py-4" v-if="searchResults.length > 0">
                    <div class="md:col-2">
                        <h2 class="pb-4" style="border-bottom: 1px solid var(--primary-color);">
                            {{ $t('All Medications') }}</h2>
                    </div>
                    <div class="pricing-tiers-container" style="display: flex; flex-wrap: wrap;">

                        <div class="md:col-4" v-for="(medication, meIndex) in searchResults" :key="meIndex">
                            <div class="pricing-tier">
                                <div class="img-container">
                                    <img :src="'https://backend.ihayanow.com/' + medication.photo" alt="Medication Image"
                                        class="md:col-12">
                                </div>

                                <h5 class="card-title">{{ $t("Medications.scientific_name") }} : {{
                                    medication.scientific_name }}
                                </h5>
                                <h5 class="card-title">{{ $t("Medications.commercial_name") }} : {{
                                    medication.commercial_name }}
                                </h5>
                                <h5 class="card-title">{{ $t("Medications.active_ingredient") }} : {{
                                    medication.active_ingredient
                                }}</h5>
                                <!-- </div> -->
                                <!-- <div class="card-footer"> -->
                                <small class="text-body-secondary">
                                    <PrimeButton type="button" @click.prevent="KnowMore(medication)"
                                        class="p-button p-button-rounded">{{ $t('Know More') }}
                                    </PrimeButton>
                                </small>
                            </div>

                            <div class="pt-5"
                                    v-if="selectedMedication !== null && selectedMedication.id == medication.id">

                                    <div class="img-container">
                                        <img :src="'https://backend.ihayanow.com/' + selectedMedication.photo"
                                            alt="Medication Image" class="col-12"
                                            style=" display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t("Medications.scientific_name") }} : {{
                                            selectedMedication.scientific_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.commercial_name") }} : {{
                                            selectedMedication.commercial_name }}
                                        </h5>
                                        <h5 class="card-title">{{ $t("Medications.active_ingredient") }} : {{
                                            selectedMedication.active_ingredient
                                        }}</h5>
                                        <p class="card-text">{{ $t("Medications.description") }} : {{
                                            selectedMedication.description
                                        }}
                                        </p>
                                    </div>


                                </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <Footer />
    </div>
</template>
  
  
  
  
<script>
import axios from 'axios';
import UserNavbar from './User/UserNavbar.vue';
import DoctorNavbar from './Doctor/DoctorNavbar.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';
import { ref } from "vue";


export default {
    data() {
        return {
            visible: ref(false),
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            searchResults: [], // New data property to hold search results
            keyword: '',
            medications: [],
            navbarComponent: null,
            selectedSearchedMedication: null,
            selectedMedication: null,
            selectedCategory: [],


        };
    },

    methods: {

        async search() {

            let result = await axios.post("https://backend.ihayanow.com/api/medication-search",
                { keyword: this.keyword },
                {
                    headers: {
                        'Content-Type': 'application/json',

                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
            this.searchResults = result.data;
            console.log(this.searchResults);
        },

        getMedications() {

            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret

            };

            axios.get('https://backend.ihayanow.com/api/medicine', { headers })
                .then(response => {
                    this.medications = response.data.Medications;

                    console.log(this.medications);

                })
                .catch(error => {
                    console.log(error)
                })
        },
        KnowMore(medication) {
            this.visible = true;
            this.selectedMedication = medication;
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString()
        },


    },
    components: {
        UserNavbar,
        DoctorNavbar,
        Footer,
        Navbar



    },

    mounted() {

        this.getMedications();
        const userType = localStorage.getItem("type");
        if (userType === "User") {
            this.navbarComponent = UserNavbar;
        } else if (userType === "Doctor") {
            this.navbarComponent = DoctorNavbar;
        } else {

            this.navbarComponent = Navbar;
        }

    },
    computed: {
        searchButtonDirection() {
            return this.$i18n.locale === 'ar' ? 'ltr' : 'auto';
        },
        inputDirection() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },

        menu() {
            return [
                {
                    label: 'Home',
                    items: [
                        {
                            label: 'Profile', icon: 'pi pi-fw pi-home', to: '/doctor-profile'
                        },
                        {
                            label: 'Edit Profile', icon: 'pi pi-fw pi-home', to: '/edit-doctor-profile'
                        },
                    ]
                },
                {
                    label: 'Sessions', icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: 'Show Requested Sessions', icon: 'pi pi-fw pi-id-card', to: '/sessions-requests' },
                        { label: 'Show Your Session', icon: 'pi pi-fw pi-id-card', to: '/doctor-sessions' },
                        { label: 'Calendar', icon: 'pi pi-fw pi-id-card', to: '/doctor-calendars' },
                    ]
                },
                {
                    label: 'Medications', icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: 'Show Medications', icon: 'pi pi-fw pi-id-card', to: '/view-medications' },

                    ]
                },
                {
                    label: 'Show Doctors',
                    items: [
                        { label: 'Show Doctors', icon: 'pi pi-fw pi-globe', to: "/doctor-therapists-page" },
                    ]
                },
            ];
        }
    },


}


</script>
<style scoped>
.pricing-tier {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.knowledgebases-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
}

.knowledgebase-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center vertically */
    align-items: center;
    /* Center horizontally */
    padding-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.knowledgebase-card:hover {
    transform: translateY(-3px);
}

.img-container {
    width: 100%;
    height: 200px; /* Set your desired fixed height for the container */
    overflow: hidden;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 100%; /* Make sure the image doesn't exceed the container height */
}
.doctor-photo {
    width: 100%;
    height: 100%;
    /* Set the desired height for your images here */
    object-fit: cover;
    max-width: 100%; /* Add this line to make sure the image doesn't exceed the container */

}

.chart-row {
    display: flex;
    flex-wrap: wrap;

}



.custom-button {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.custom-button .p-button {
    width: 25% !important;
}
</style>
  