<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <!-- <AdminNavbar /> -->
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <div>
                <h2>Promocodes</h2>
                <PrimeMessage severity="success" v-if="this.message">{{ this.message }}
                </PrimeMessage>
                <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
                </PrimeMessage>
                <PrimeMessage severity="error" v-if="this.errorMessage">{{ this.errorMessage }}
                </PrimeMessage>
              
                    <DataTable :value="promocodes" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                      :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                      <template #empty>
                        <div v-if="promocodes.length == 0">No Promocodes</div>
                      </template>
                      <template #loading>
                        Loading Promocodes data. Please wait.
                      </template>
                      <PrimeColumn field="from" header="From" style="min-width: 12rem">
                        {{ rowData.from }}
                      </PrimeColumn>
                      <PrimeColumn field="to" header="To" style="min-width: 12rem">
                        {{ rowData.to }}
                      </PrimeColumn>
                      <PrimeColumn header="Code" field="code" style="min-width: 14rem">

                        {{ rowData.code }}

                      </PrimeColumn>
                      <PrimeColumn header="Type" field="type" style="min-width: 14rem">

                        {{ rowData.type }}

                      </PrimeColumn>
                      <PrimeColumn header="Multiple" field="multiple" style="min-width: 10rem">
                        {{ rowData.multiple }}
                      </PrimeColumn>
                      <!-- <PrimeColumn header="Number of Uses" field="number_of_uses" style="min-width: 10rem">
                        {{ rowData.number_of_uses }}
                      </PrimeColumn> -->
                      <PrimeColumn header="Maximum Number of Uses" field="max_number_of_uses" style="min-width: 10rem">
                        {{ rowData.max_number_of_uses }}
                      </PrimeColumn>
                      <PrimeColumn header="Allowed Number of Uses" field="allowed_number_of_uses"
                        style="min-width: 10rem">
                        {{ rowData.allowed_number_of_uses }}
                      </PrimeColumn>
                      <PrimeColumn header="Amount" field="amount" style="min-width: 10rem">
                        {{ rowData.amount }}
                      </PrimeColumn>
                      <PrimeColumn header="Above" field="above" style="min-width: 10rem">
                        {{ rowData.above }}
                      </PrimeColumn>
                      <PrimeColumn header="Percentage" field="percentage" style="min-width: 10rem">
                        {{ rowData.percentage }}
                      </PrimeColumn>

                      <PrimeColumn header="Created At" field="created_at" style="min-width: 10rem">
                        <template #body="rowData">

                          {{ formatDate(rowData.data.created_at) }}
                        </template>
                      </PrimeColumn>
                      <PrimeColumn header="Updated At" field="updated_at" style="min-width: 10rem">
                        <template #body="rowData">

                          {{ formatDate(rowData.data.updated_at) }}
                        </template>
                      </PrimeColumn>

                      <PrimeColumn :exportable="false" style="min-width:8rem">
                        <template #body="rowData">
                          <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2"
                            @click="showUpdateForm(rowData.data.id)" />
                          <PrimeButton icon="pi pi-trash" outlined rounded severity="danger"
                            @click="deletePromocode(rowData.data.id)" />
                        </template>
                      </PrimeColumn>

                    </DataTable>


                <!-- <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                <p v-if="successMessage" class="success-message">{{ successMessage }}</p> -->


                <div v-if="showUpdate">

                  <h3>Update Promocode</h3>

                  <form @submit.prevent="submitUpdateForm">
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <label>From:</label>
                        <InputText type="datetime-local" v-model="updatedPromocode.from" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.from"
                          v-for="(error, index) in errors.from" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label>To:</label>
                        <InputText type="datetime-local" v-model="updatedPromocode.to" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.to"
                          v-for="(error, index) in errors.to" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <label>Code:</label>
                        <InputText type="text" v-model="updatedPromocode.code" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.code"
                          v-for="(error, index) in errors.code" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">

                        <label>Type:</label>
                        <InputText type="text" v-model="updatedPromocode.type" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                          v-for="(error, index) in errors.type" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <label>Multiple:</label>
                        <InputText type="number" v-model="updatedPromocode.multiple" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.multiple"
                          v-for="(error, index) in errors.multiple" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <!-- <div class="field col-12 md:col-6">

                        <label>Number of Uses:</label>
                        <InputText type="number" v-model="updatedPromocode.number_of_uses" required />
                      </div> -->
                      <div class="field col-12 md:col-6">
                        <label>Max Number of Uses:</label>
                        <InputText type="number" v-model="updatedPromocode.max_number_of_uses" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.max_number_of_uses"
                          v-for="(error, index) in errors.max_number_of_uses" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="grid p-fluid mt-3">

                      <div class="field col-12 md:col-6">

                        <label>Allowed Number of Uses:</label>
                        <InputText type="number" v-model="updatedPromocode.allowed_number_of_uses" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.allowed_number_of_uses"
                          v-for="(error, index) in errors.allowed_number_of_uses" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label>Amount:</label>
                        <InputText type="number" v-model="updatedPromocode.amount" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.amount"
                          v-for="(error, index) in errors.amount" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="grid p-fluid mt-3">

                      <div class="field col-12 md:col-6">
                        <label>Above:</label>
                        <InputText type="number" v-model="updatedPromocode.above" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.above"
                          v-for="(error, index) in errors.above" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label>Percentage:</label>
                        <InputText type="number" step="0.01" v-model="updatedPromocode.percentage" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.percentage"
                          v-for="(error, index) in errors.percentage" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>

                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <PrimeButton type="submit">Update Promocode</PrimeButton>
                      </div>
                      <div class="field col-12 md:col-6">

                        <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// import AdminNavbar from './AdminNavbar.vue';

import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      doctors: [],
      pendings: [],
      promocodes: [],
      errorMessage: '',
      successMessage: '',


      showUpdate: false,
      updateId: null,
      updatedPromocode: null,
      // updatedPromocode: {
      id: null,
      from: null,
      to: null,
      code: '',
      type: '',
      multiple: 0,
      number_of_uses: 0,
      max_number_of_uses: 0,
      allowed_number_of_uses: 0,
      amount: 0,
      above: 0,
      percentage: 0.0,
      // },
      errors: {},
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
            { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
          items: [

            { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
            { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
          label: ' Patient Label', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to: "/add-label-patient" },
            { label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to: "label-patient" }
          ]
        },
        {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


          ]
        },
        {
          label: '', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
          ]

        },
        {
          label: '',
          items: [
            { label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
          ]
        },
        {
          label: '',
          items: [
            { label: 'Logout', icon: 'pi pi-power-off', command: this.logout }
          ]

        },
      ]
    };
  },
  mounted() {
    this.fetchPromocodes();
  },
  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    async fetchPromocodes() {
      try {
        const response = await fetch('https://backend.ihayanow.com/api/admin/promocodes', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        });

        const data = await response.json();
        this.promocodes = data;
        this.updatedPromocode = this.promocodes
        console.log(this.updatedPromocode)

      } catch (error) {
        console.error('Error fetching promocodes:', error);
        this.errorMessage = 'Failed to fetch promocodes.';
      }
    },


    showUpdateForm(id) {
      this.showUpdate = true
      this.updateId = id
      this.updatedPromocode = this.promocodes.find(promocode => promocode.id === id);
      console.log(this.updatedPromocode);
    },
    hideUpdateForm() {

      this.showUpdate = false;
      this.updateId = null;

    },

    async submitUpdateForm() {
      const { id, from, to, code, type, multiple, max_number_of_uses, allowed_number_of_uses
        , amount, above, percentage } = this.updatedPromocode;

      await axios.put(`https://backend.ihayanow.com/api/admin/promocodes/${this.updatedPromocode.id}`, {
        id, from, to, code, type, multiple, max_number_of_uses, allowed_number_of_uses
        , amount, above, percentage
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          this.message = response.data.message;

          this.fetchPromocodes();
          this.hideUpdateForm();
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.errors = error.response.data.message;

        });
    },


    async deletePromocode(promocodeId) {
      try {
        const response = await fetch(`https://backend.ihayanow.com/api/admin/promocodes/${promocodeId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        });

        if (response.ok) {
          // this.messages=response.data;
          this.successMessage = 'Promocode deleted successfully.';
          this.showUpdateForm = false;
          this.fetchPromocodes();
        } else {
          console.log(response)
          this.errorMessage = 'Failed to delete promocode.';
        }
      } catch (error) {
        console.error('Error deleting promocode:', error);
        this.errorMessage = 'Failed to delete promocode.';
        // this.errors = error.response.data.message;

      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    calculateDuration(from, to) {
      const fromParts = from.split(':');
      const toParts = to.split(':');


      const fromSeconds = (+fromParts[0]) * 60 * 60 + (+fromParts[1]) * 60;
      const toSeconds = (+toParts[0]) * 60 * 60 + (+toParts[1]) * 60;
      let diff = toSeconds - fromSeconds;
      if (diff < 0) {
        diff += 24 * 60 * 60; // add 1 day if end time is earlier than start time
      }
      const hours = Math.floor(diff / (60 * 60));
      const minutes = Math.floor((diff / 60) % 60);
      const duration = `${hours} hours ${minutes} minutes`;

      return duration;
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },

  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },


  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }

}

</script>

<style scoped>
.layout-topbar {

  background-color: var(--surface-card);
}
</style>

