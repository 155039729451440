
<template>
  <div class="container">


    <Navbar />
    <div class="layout-main ">
      <div class="grid">
        <form @submit.prevent="login">

          <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">

            <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
              <div class="col-12 mt-5 xl:mt-0 text-center">
                <h1>{{ $t('menu.sign_in') }}</h1>
              </div>
              <!-- <div class="col-12 mt-5 xl:mt-0 text-center">
            <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="Sakai logo" class="mb-5"
              style="width:81px; height:60px;">
          </div> -->

              <div class="col-12 xl:col-6"
                style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4"
                  style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                  <div class="text-center mb-5">
                    <!-- <img src="layout/images/avatar.png" alt="Image" height="50" class="mb-3"> -->
                    <div class="text-900 text-3xl font-medium mb-3">{{ $t('Login.Welcome Back') }}</div>
                    <span class="text-600 font-medium">{{ $t('Login.Sign in to continue') }}</span>
                  </div>

                  <div class="w-full md:w-10 mx-auto">
                    <label for="email1" class="block text-900 text-xl font-medium mb-2">{{ $t('email') }}</label>
                    <input id="email1" v-model="email" type="text" class="w-full mb-3" :placeholder="$t('email')"
                      style="padding:1rem;  border: 2px; border-radius: 4px;" />

                    <div v-if="errors">
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.email"
                        v-for="(error, index) in errors.email" :key="index">
                        {{ translateErrorMessage(error) }}
                      </InlineMessage>
                    </div>

                    <label for="password1" class="block text-900 font-medium text-xl mb-2">{{ $t('password') }}</label>
                    <input type="password" id="password1" v-model="password" :placeholder="$t('password')"
                      :toggleMask="true" style="padding:1rem; border: 2px; border-radius: 4px;" class="w-full mb-3"
                      inputClass="w-full" inputStyle="padding:1rem" />
                    <div v-if="errors">
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.password"
                        v-for="(error, index) in errors.password" :key="index">
                        {{ translateErrorMessage(error) }}
                      </InlineMessage>
                    </div>
                    <div class="flex align-items-center justify-content-between mb-5">
                      <div class="flex align-items-center">
                        <input type="checkbox" id="rememberme1" v-model="checked" :binary="true" class="mr-2" />
                        <label for="rememberme1">{{ $t('Login.Remember me') }}</label>

                      </div>
                      <a href="./forget-password" class="font-medium no-underline ml-2 text-right cursor-pointer"
                        style="color: var(--primary-color)">{{ $t('Login.Forgot password') }}</a>
                    </div>

                    <PrimeMessage severity="error" v-show="this.success">{{ this.message2 }}
                    </PrimeMessage>
                    <div class="row edit-button text-center">
                      <PrimeButton type="submit">
                        {{ $t('menu.sign_in') }}</PrimeButton>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>

      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../Navbar.vue';
import Footer from '../Footer.vue';
import axios from 'axios';

export default {

  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      email: '',
      password: '',
      // platform:'',
      success: false,
      showInlineMessage: false,
      showNoEmailMessage: false,
      showNoPasswordMessage: false,
      message: [],
      errors: {},

    }
  },
  name: 'login-page',
  methods: {
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    async login() {
      try {
        // this.platform = navigator.platform; // Get the user agent string of the browser
        console.log(this.email, this.password);
        await axios.post('https://backend.ihayanow.com/api/login',
          {
            email: this.email,
            password: this.password,
            // user_platform:this.platform
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Api-Key': this.apiKey,
              'Api-Secret': this.apiSecret
            },

          }).then(response => {
            // const data = response;
            console.log(response.data.success);
            console.log(response.data.message);
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('type', response.data.type)
            // if (response.data.success == true) {
            //   this.success = true;
            //   // this.errors = response.data.message;
            //   // this.message = [{ severity: 'error', detail: 'Error Message', content: this.$t('Invalid Credentials.') }];
            //   // this.message2=this.message[0].content;
            // } else {
            if (response.data.type === "Doctor" && response.data.success == true) {

              this.$router.push('/doctor-home')
              // this.$router.push('/doctor-profile')

            }
            else if (response.data.type === "Admin" && response.data.success == true) {
              this.$i18n.locale = 'en';
              // window.location.reload(); // Reload the page to apply the updated direction

              console.log(this.$i18n.locale)
              this.$router.push('/admin')

            }
            else if (response.data.type === "User" && response.data.success == true) {
              this.$router.push('/therapists-page')

            } else if (response.data.success == false && response.data.message == "Invalid Credentials.") {
              this.success = true;
              this.message2 = this.$t('Invalid Credentials.');
            } else if (response.data.type === "Doctor" && response.data.success == false) {
              this.$router.push('/complete-registeration')
            }
            else if (response.data.type === "User" && response.data.success == false) {
              this.$router.push('/Complete')
            }
            // }
          }).catch(error => {
            console.error(error)
            this.errors = error.response.data.errors;
          });


      } catch (error) {
        console.error(error)
        this.errors = error.response.data.errors;
      }
    },

    async forgotPassword() {
      try {
        const response = await fetch('https://backend.ihayanow.com/api/forgot-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
          }),
        })
        const data = await response.json()
        console.log(data)
        if (data.success) {
          this.$router.push('/check-email')

        } else {
          console.error(data.message)
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    isEmailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },

  },

  components: {
    Navbar, Footer
  },
}
</script>
<style scoped>
.layout-main {
  margin-top: 1rem !important;
}


.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>