

<template>
    <div class="container">
        <DoctorNavbar />
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card py-4">
                            <h5>{{ $t('file.All Patient Files') }}</h5>
                            <hr>


                            <transition-group v-if="this.errors" name="p-message" tag="div">
                                <PrimeMessage class="w-50" v-for="msg of message" :severity="msg.severity"
                                    :key="msg.content">
                                    {{ translateErrorMessage(msg.content) }}</PrimeMessage>
                            </transition-group>
                            <h5>{{ $t("file.Personal identification information") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.material_status") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ material_status }}
                                        </p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.birth_place") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ file[0].birth_place
                                        }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_children") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_children }}</p>
                                    </span>
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about the primary complaint") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.current_problem") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">
                                            {{ file[0].current_problem }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.date_of_current_problem") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].date_of_current_problem }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.symptoms") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ file[0].symptoms }}
                                        </p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about the family background") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.general_income_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].general_income_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.father_education_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].father_education_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.his_occupation")
                                            }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].his_occupation }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.his_age") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ file[0].his_age }}
                                        </p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.mother_education_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].mother_education_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.her_occupation")
                                            }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].her_occupation }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.her_age") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ file[0].her_age }}
                                        </p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_siblings") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_siblings }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_birth_order_among_siblings") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].patient_birth_order_among_siblings }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_father") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_father }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_mother") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_mother }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_grandFather") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_grandFather }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_grandMother") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_grandMother }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_aunt") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_aunt }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_uncle") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_uncle }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_uncle_in_law") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_uncle_in_law }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.patient_relationship_with_aunt_in_law") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            patient_relationship_with_aunt_in_law }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.divorce_cases_in_family") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            divorce_cases_in_family }}</p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about the medical history") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.chronic_illnesses_in_family") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].chronic_illnesses_in_family }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.genetic_diseases_in_family") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].genetic_diseases_in_family }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.mental_illnesses_in_family") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].mental_illnesses_in_family }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.psychological_disorders_in_family") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].psychological_disorders_in_family }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.acute_injuries")
                                            }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].acute_injuries }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.addiction_cases") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].addiction_cases }}</p>
                                    </span>
                                </div>


                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about personality and social compatibility") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.personality_traits") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].personality_traits }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_another_sex") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_another_sex }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_neighbors") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_neighbors }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_friends") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_friends }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_relatives") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_relatives }}</p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about educational history") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.current_educational_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].current_educational_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_elementary_years") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_elementary_years }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.academic_elementary_performance") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].academic_elementary_performance }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_middle_years") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_middle_years }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.academic_middle_performance") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].academic_middle_performance }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_high_years") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_high_years }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_high_years") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_high_years }}</p>
                                    </span>
                                </div>

                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.academic_high_performance") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].academic_high_performance }}</p>
                                    </span>
                                </div>

                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.number_of_college_years") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].number_of_college_years }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.academic_college_performance") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].academic_college_performance }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.subjects_with_high_grades") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].subjects_with_high_grades }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.subjects_with_average_grades") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].subjects_with_average_grades }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.subjects_with_low_grades") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].subjects_with_low_grades }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationships_with_teachers_and_school_administration") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationships_with_teachers_and_school_administration }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationships_with_schoolmates") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationships_with_schoolmates }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.identity_interests_and_hobbies") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].identity_interests_and_hobbies }}</p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Information about professional experiences") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="file.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.current_occupation") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].current_occupation }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.professional_performance_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            professional_performance_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.professional_competence_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            professional_competence_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.income_level") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ income_level }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.previous_jobs") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].previous_jobs }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.transitioning_reason_to_the_current_profession") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].transitioning_reason_to_the_current_profession }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.working_hours_per_day") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].working_hours_per_day }}</p>
                                    </span>
                                </div>

                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.working_hours_per_week") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].working_hours_per_week }}</p>
                                    </span>
                                </div>

                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.work_health_insurance") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            work_health_insurance }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.work_social_security") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ work_social_security
                                        }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.retirement_age")
                                            }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].retirement_age }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.expected_workplace_hazards") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].expected_workplace_hazards }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.expected_compensation_from_work") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            file[0].expected_compensation_from_work }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_colleagues") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_colleagues }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_supervisors") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_supervisors }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_subordinates") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_subordinates }}</p>
                                    </span>
                                </div>


                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.relationship_with_who_report") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            relationship_with_who_report }}</p>
                                    </span>
                                </div>


                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.aspirations") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{ file[0].aspirations
                                        }}</p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Assessment information") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="staticFile.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.psychological_tests_conducted") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            staticFile[0].psychological_tests_conducted }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{ $t("file.tests_results") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            staticFile[0].tests_results }}</p>
                                    </span>
                                </div>


                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("file.Overall assessment of the case") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="staticFile.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.case_assumptions") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            staticFile[0].case_assumptions }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.development_necessary_recommendations") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            staticFile[0].development_necessary_recommendations }}</p>
                                    </span>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("file.referral_agency") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            staticFile[0].referral_agency }}</p>
                                    </span>
                                </div>
                            </div>

                            <hr class="horizontal dark" />
                            <h5>{{ $t("Patient Label") }}</h5>
                            <div class="grid p-fluid mt-3" v-if="staticFile.length > 0">
                                <div class="field col-12 md:col-6">
                                    <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                                        <span for="example-text-input" class="form-control-label mr-2 "
                                            style="font-weight: bold;">
                                            <i class="p-menuitem-icon pi pi-fw pi-check"></i> {{
                                                $t("Patient Label") }}
                                        </span>
                                        <p for="example-text-input " class="form-control-label pt-2">{{
                                            this.patientLabel }}</p>
                                    </span>
                                </div>
                            
                            </div>


                            <div class="row edit-button text-center">
                                <PrimeButton class="mr-2 mb-2" :label="$t('Edit')" @click="showUpdateForm(file[0].user_id)">
                                </PrimeButton>



                            </div>


                            <div v-if="showUpdate">
                                <h3>{{ $t('file.Update Patient File') }}</h3>


                                <form @submit.prevent="updatePatientFile">
                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about the primary complaint") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="current_problem" style="font-weight: bold;">{{
                                                $t("file.current_problem") }}</label>
                                            <InputText id="current_problem" type="string"
                                                v-model="selectedFile.current_problem" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.current_problem"
                                                v-for="(error, index) in errors.current_problem" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="date_of_current_problem" style="font-weight: bold;">{{
                                                $t("file.date_of_current_problem") }}</label>
                                            <InputText id="date_of_current_problem" type="date"
                                                v-model="selectedFile.date_of_current_problem" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.date_of_current_problem"
                                                v-for="(error, index) in errors.date_of_current_problem" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="symptoms" style="font-weight: bold;">{{ $t('file.symptoms')
                                            }}</label>
                                            <PrimeTextarea :placeholder="$t('file.symptoms')" :autoResize="true" rows="9"
                                                cols="70" v-model="selectedFile.symptoms" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.symptoms"
                                                v-for="(error, index) in errors.symptoms" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                    </div>

                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about the family background") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="general_income_level" style="font-weight: bold;">{{
                                                $t("file.general_income_level") }}</label>
                                            <InputText id="general_income_level" type="text"
                                                v-model="selectedFile.general_income_level" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.general_income_level"
                                                v-for="(error, index) in errors.general_income_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="father_education_level" style="font-weight: bold;">{{
                                                $t("file.father_education_level") }}</label>
                                            <InputText id="father_education_level" type="text"
                                                v-model="selectedFile.father_education_level" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.father_education_level"
                                                v-for="(error, index) in errors.father_education_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="his_occupation" style="font-weight: bold;">{{
                                                $t('file.his_occupation')
                                            }}</label>
                                            <InputText id="his_occupation" type="text"
                                                v-model="selectedFile.his_occupation" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.his_occupation"
                                                v-for="(error, index) in errors.his_occupation" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="his_age" style="font-weight: bold;">
                                                {{ $t("file.his_age") }}</label>
                                            <InputText id="his_age" type="text" v-model="selectedFile.his_age" />

                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.his_age"
                                                v-for="(error, index) in errors.his_age" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="mother_education_level" style="font-weight: bold;">{{
                                                $t("file.mother_education_level") }}</label>
                                            <InputText id="mother_education_level" type="text"
                                                v-model="selectedFile.mother_education_level" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.mother_education_level"
                                                v-for="(error, index) in errors.mother_education_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="her_occupation" style="font-weight: bold;">{{
                                                $t('file.her_occupation')
                                            }}</label>
                                            <InputText id="her_occupation" type="text"
                                                v-model="selectedFile.her_occupation" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.her_occupation"
                                                v-for="(error, index) in errors.her_occupation" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="her_age" style="font-weight: bold;">{{
                                                $t("file.her_age") }}</label>
                                            <InputText id="her_age" type="text" v-model="selectedFile.her_age" />

                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.her_age"
                                                v-for="(error, index) in errors.her_age" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="number_of_siblings" style="font-weight: bold;">{{
                                                $t("file.number_of_siblings") }}</label>
                                            <InputText id="number_of_siblings" type="number"
                                                v-model="selectedFile.number_of_siblings" />

                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.number_of_siblings"
                                                v-for="(error, index) in errors.number_of_siblings" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_birth_order_among_siblings" style="font-weight: bold;">{{
                                                $t("file.patient_birth_order_among_siblings") }}</label>
                                            <InputText id="father_educatiopatient_birth_order_among_siblingsn_level"
                                                type="text" v-model="selectedFile.patient_birth_order_among_siblings" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_birth_order_among_siblings"
                                                v-for="(error, index) in errors.patient_birth_order_among_siblings"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_father" style="font-weight: bold;">{{
                                                $t('file.patient_relationship_with_father') }}</label>

                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_father" :options="relations"
                                                optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_father"
                                                v-for="(error, index) in errors.patient_relationship_with_father"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_mother" style="font-weight: bold;">{{
                                                $t("file.patient_relationship_with_mother") }}</label>
                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_mother" :options="relations"
                                                optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_mother"
                                                v-for="(error, index) in errors.patient_relationship_with_mother"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_grandFather" style="font-weight: bold;">{{
                                                $t("file.patient_relationship_with_grandFather") }}</label>
                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_grandFather"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_grandFather"
                                                v-for="(error, index) in errors.patient_relationship_with_grandFather"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_grandMother" style="font-weight: bold;">{{
                                                $t('file.patient_relationship_with_grandMother') }}</label>
                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_grandMother"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_grandMother"
                                                v-for="(error, index) in errors.patient_relationship_with_grandMother"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_aunt" style="font-weight: bold;">{{
                                                $t("file.patient_relationship_with_aunt") }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.patient_relationship_with_aunt"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_aunt"
                                                v-for="(error, index) in errors.patient_relationship_with_aunt"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_uncle" style="font-weight: bold;">{{
                                                $t("file.patient_relationship_with_uncle") }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.patient_relationship_with_uncle"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_uncle"
                                                v-for="(error, index) in errors.patient_relationship_with_uncle"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_uncle_in_law"
                                                style="font-weight: bold;">{{
                                                    $t('file.patient_relationship_with_uncle_in_law') }}</label>
                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_uncle_in_law"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_uncle_in_law"
                                                v-for="(error, index) in errors.patient_relationship_with_uncle_in_law"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="patient_relationship_with_aunt_in_law" style="font-weight: bold;">{{
                                                $t("file.patient_relationship_with_aunt_in_law") }}</label>

                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.patient_relationship_with_aunt_in_law"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.patient_relationship_with_aunt_in_law"
                                                v-for="(error, index) in errors.patient_relationship_with_aunt_in_law"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="divorce_cases_in_family" style="font-weight: bold;">{{
                                                $t("file.divorce_cases_in_family") }}</label>
                                            <InputText id="divorce_cases_in_family" type="number"
                                                v-model="selectedFile.divorce_cases_in_family" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.divorce_cases_in_family"
                                                v-for="(error, index) in errors.divorce_cases_in_family" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>


                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about the medical history") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="chronic_illnesses_in_family" style="font-weight: bold;">
                                                {{ $t('file.chronic_illnesses_in_family') }}</label>
                                            <PrimeTextarea :placeholder="$t('file.chronic_illnesses_in_family')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.chronic_illnesses_in_family" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.chronic_illnesses_in_family"
                                                v-for="(error, index) in errors.chronic_illnesses_in_family" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="genetic_diseases_in_family" style="font-weight: bold;">{{
                                                $t("file.genetic_diseases_in_family") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.genetic_diseases_in_family')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.genetic_diseases_in_family" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.genetic_diseases_in_family"
                                                v-for="(error, index) in errors.genetic_diseases_in_family" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="mental_illnesses_in_family" style="font-weight: bold;">{{
                                                $t('file.mental_illnesses_in_family') }}</label>
                                            <PrimeTextarea :placeholder="$t('file.mental_illnesses_in_family')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.mental_illnesses_in_family" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.mental_illnesses_in_family"
                                                v-for="(error, index) in errors.mental_illnesses_in_family" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="psychological_disorders_in_family" style="font-weight: bold;">{{
                                                $t("file.psychological_disorders_in_family") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.psychological_disorders_in_family')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.psychological_disorders_in_family" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.psychological_disorders_in_family"
                                                v-for="(error, index) in errors.psychological_disorders_in_family"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <label for="acute_injuries" style="font-weight: bold;">{{
                                                $t('file.acute_injuries')
                                            }}</label>
                                            <PrimeTextarea :placeholder="$t('file.acute_injuries')" :autoResize="true"
                                                rows="9" cols="70" v-model="selectedFile.acute_injuries" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.acute_injuries"
                                                v-for="(error, index) in errors.acute_injuries" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="addiction_cases" style="font-weight: bold;">{{
                                                $t("file.addiction_cases") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.addiction_cases')" :autoResize="true"
                                                rows="9" cols="70" v-model="selectedFile.addiction_cases" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.addiction_cases"
                                                v-for="(error, index) in errors.addiction_cases" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>


                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about personality and social compatibility") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="personality_traits" style="font-weight: bold;">{{
                                                $t("file.personality_traits") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.personality_traits')" :autoResize="true"
                                                rows="9" cols="70" v-model="selectedFile.personality_traits" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.general_income_level"
                                                v-for="(error, index) in errors.general_income_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_another_sex" style="font-weight: bold;">{{
                                                $t('file.relationship_with_another_sex') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_another_sex"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_another_sex"
                                                v-for="(error, index) in errors.relationship_with_another_sex" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_neighbors" style="font-weight: bold;">{{
                                                $t("file.relationship_with_neighbors") }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_neighbors"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_neighbors"
                                                v-for="(error, index) in errors.relationship_with_neighbors" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_friends" style="font-weight: bold;">{{
                                                $t("file.relationship_with_friends") }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_friends"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_friends"
                                                v-for="(error, index) in errors.relationship_with_friends" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_relatives" style="font-weight: bold;">{{
                                                $t('file.relationship_with_relatives') }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_relatives"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_relatives"
                                                v-for="(error, index) in errors.relationship_with_relatives" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                    </div>

                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about educational history") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="current_educational_level" style="font-weight: bold;">{{
                                                $t("file.current_educational_level") }}</label>
                                            <InputText id="current_educational_level" type="text"
                                                v-model="selectedFile.current_educational_level" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.current_educational_level"
                                                v-for="(error, index) in errors.current_educational_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="number_of_elementary_years" style="font-weight: bold;">{{
                                                $t("file.number_of_elementary_years") }}</label>
                                            <InputText id="number_of_elementary_years" type="number"
                                                v-model="selectedFile.number_of_elementary_years" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.number_of_elementary_years"
                                                v-for="(error, index) in errors.number_of_elementary_years" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="academic_elementary_performance" style="font-weight: bold;">{{
                                                $t("file.academic_elementary_performance") }}</label>
                                            <InputText id="academic_elementary_performance" type="text"
                                                v-model="selectedFile.academic_elementary_performance"
                                                :placeholder="$t('file.GoodVerygood')" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.academic_elementary_performance"
                                                v-for="(error, index) in errors.academic_elementary_performance"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="number_of_middle_years" style="font-weight: bold;">{{
                                                $t("file.number_of_middle_years") }}</label>
                                            <InputText id="number_of_middle_years" type="number"
                                                v-model="selectedFile.number_of_middle_years" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.number_of_middle_years"
                                                v-for="(error, index) in errors.number_of_middle_years" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="academic_middle_performance" style="font-weight: bold;">{{
                                                $t("file.academic_middle_performance") }}</label>
                                            <InputText id="academic_middle_performance" type="text"
                                                v-model="selectedFile.academic_middle_performance"
                                                :placeholder="$t('file.GoodVerygood')" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.academic_middle_performance"
                                                v-for="(error, index) in errors.academic_middle_performance" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="number_of_high_years" style="font-weight: bold;">{{
                                                $t("file.number_of_high_years") }}</label>
                                            <InputText id="number_of_high_years" type="number"
                                                v-model="selectedFile.number_of_high_years" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.number_of_high_years"
                                                v-for="(error, index) in errors.number_of_high_years" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="academic_high_performance" style="font-weight: bold;">{{
                                                $t("file.academic_high_performance") }}</label>
                                            <InputText id="academic_high_performance" type="text"
                                                v-model="selectedFile.academic_high_performance"
                                                :placeholder="$t('file.GoodVerygood')" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.academic_high_performance"
                                                v-for="(error, index) in errors.academic_high_performance" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="number_of_college_years" style="font-weight: bold;">{{
                                                $t("file.number_of_college_years") }}</label>
                                            <InputText id="number_of_college_years" type="number"
                                                v-model="selectedFile.number_of_college_years" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.number_of_college_years"
                                                v-for="(error, index) in errors.number_of_college_years" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="academic_college_performance" style="font-weight: bold;">{{
                                                $t("file.academic_college_performance") }}</label>
                                            <InputText id="academic_college_performance" type="text"
                                                v-model="selectedFile.academic_college_performance"
                                                :placeholder="$t('file.GoodVerygood')" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.academic_college_performance"
                                                v-for="(error, index) in errors.academic_college_performance" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="subjects_with_high_grades" style="font-weight: bold;">{{
                                                $t("file.subjects_with_high_grades") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.subjects_with_high_grades')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.subjects_with_high_grades" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.subjects_with_high_grades"
                                                v-for="(error, index) in errors.subjects_with_high_grades" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="subjects_with_average_grades" style="font-weight: bold;">{{
                                                $t("file.subjects_with_average_grades") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.subjects_with_average_grades')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.subjects_with_average_grades" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.subjects_with_average_grades"
                                                v-for="(error, index) in errors.subjects_with_average_grades" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="subjects_with_low_grades" style="font-weight: bold;">{{
                                                $t("file.subjects_with_low_grades") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.subjects_with_low_grades')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.subjects_with_low_grades" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.subjects_with_low_grades"
                                                v-for="(error, index) in errors.subjects_with_low_grades" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="relationships_with_teachers_and_school_administration"
                                                style="font-weight: bold;">{{
                                                    $t('file.relationships_with_teachers_and_school_administration') }}</label>
                                            <PrimeDropdown id="state"
                                                v-model="selectedFile.relationships_with_teachers_and_school_administration"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">
                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationships_with_teachers_and_school_administration"
                                                v-for="(error, index) in errors.relationships_with_teachers_and_school_administration"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="relationships_with_schoolmates" style="font-weight: bold;">{{
                                                $t('file.relationships_with_schoolmates') }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.relationships_with_schoolmates"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">
                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationships_with_schoolmates"
                                                v-for="(error, index) in errors.relationships_with_schoolmates"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="identity_interests_and_hobbies" style="font-weight: bold;">{{
                                                $t("file.identity_interests_and_hobbies") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.identity_interests_and_hobbies')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.identity_interests_and_hobbies" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.identity_interests_and_hobbies"
                                                v-for="(error, index) in errors.identity_interests_and_hobbies"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>

                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Information about professional experiences") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="current_occupation" style="font-weight: bold;">{{
                                                $t("file.current_occupation") }}</label>
                                            <InputText id="current_occupation" type="text"
                                                v-model="selectedFile.current_occupation" />

                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.current_occupation"
                                                v-for="(error, index) in errors.current_occupation" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="professional_performance_level" style="font-weight: bold;">{{
                                                $t("file.professional_performance_level") }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.professional_performance_level"
                                                :options="incomeLevels" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.professional_performance_level"
                                                v-for="(error, index) in errors.professional_performance_level"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="professional_competence_level" style="font-weight: bold;">{{
                                                $t("file.professional_competence_level") }}</label>
                                            <PrimeDropdown id="state" v-model="selectedFile.professional_competence_level"
                                                :options="competences" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.professional_competence_level"
                                                v-for="(error, index) in errors.professional_competence_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <label for="income_level" style="font-weight: bold;">{{ $t('file.income_level')
                                            }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.income_level"
                                                :options="incomeLevels" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.income_level"
                                                v-for="(error, index) in errors.income_level" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>


                                        <div class="field col-12 md:col-6">


                                            <label for="previous_jobs" style="font-weight: bold;">{{
                                                $t("file.previous_jobs") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.previous_jobs')" :autoResize="true"
                                                rows="9" cols="70" v-model="selectedFile.previous_jobs" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.previous_jobs"
                                                v-for="(error, index) in errors.previous_jobs" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">


                                            <label for="transitioning_reason_to_the_current_profession"
                                                style="font-weight: bold;">{{
                                                    $t("file.transitioning_reason_to_the_current_profession") }}</label>
                                            <PrimeTextarea
                                                :placeholder="$t('file.transitioning_reason_to_the_current_profession')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.transitioning_reason_to_the_current_profession" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.transitioning_reason_to_the_current_profession"
                                                v-for="(error, index) in errors.transitioning_reason_to_the_current_profession"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="working_hours_per_day" style="font-weight: bold;">{{
                                                $t("file.working_hours_per_day") }}</label>
                                            <InputText id="working_hours_per_day" type="text"
                                                v-model="selectedFile.working_hours_per_day" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.working_hours_per_day"
                                                v-for="(error, index) in errors.working_hours_per_day" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <label for="working_hours_per_week" style="font-weight: bold;">{{
                                                $t("file.working_hours_per_week") }}</label>
                                            <InputText id="working_hours_per_week" type="text"
                                                v-model="selectedFile.working_hours_per_week" />

                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.working_hours_per_week"
                                                v-for="(error, index) in errors.working_hours_per_week" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="work_health_insurance" style="font-weight: bold;">{{
                                                $t('file.work_health_insurance') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.work_health_insurance"
                                                :options="choices" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.work_health_insurance"
                                                v-for="(error, index) in errors.work_health_insurance" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="work_social_security" style="font-weight: bold;">{{
                                                $t('file.work_social_security') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.work_social_security"
                                                :options="choices" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.work_social_security"
                                                v-for="(error, index) in errors.work_social_security" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="retirement_age" style="font-weight: bold;">{{
                                                $t("file.retirement_age") }}</label>
                                            <InputText id="retirement_age" type="text"
                                                v-model="selectedFile.retirement_age" />

                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.retirement_age"
                                                v-for="(error, index) in errors.retirement_age" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <label for="expected_workplace_hazards" style="font-weight: bold;">{{
                                                $t("file.expected_workplace_hazards") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.expected_workplace_hazards')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.expected_workplace_hazards" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.expected_workplace_hazards"
                                                v-for="(error, index) in errors.expected_workplace_hazards" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">


                                            <label for="expected_compensation_from_work" style="font-weight: bold;">{{
                                                $t("file.expected_compensation_from_work") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.expected_compensation_from_work')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedFile.expected_compensation_from_work" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.expected_compensation_from_work"
                                                v-for="(error, index) in errors.expected_compensation_from_work"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>


                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_colleagues" style="font-weight: bold;">{{
                                                $t('file.relationship_with_colleagues') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_colleagues"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_colleagues"
                                                v-for="(error, index) in errors.relationship_with_colleagues" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_supervisors" style="font-weight: bold;">{{
                                                $t('file.relationship_with_supervisors') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_supervisors"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_supervisors"
                                                v-for="(error, index) in errors.relationship_with_supervisors" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_subordinates" style="font-weight: bold;">{{
                                                $t('file.relationship_with_subordinates') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_subordinates"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_subordinates"
                                                v-for="(error, index) in errors.relationship_with_subordinates"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="relationship_with_who_report" style="font-weight: bold;">{{
                                                $t('file.relationship_with_who_report') }}</label>

                                            <PrimeDropdown id="state" v-model="selectedFile.relationship_with_who_report"
                                                :options="relations" optionKey="name" optionLabel="translationKey"
                                                :placeholder="$t('profile.Select_One')">

                                            </PrimeDropdown>
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.relationship_with_who_report"
                                                v-for="(error, index) in errors.relationship_with_who_report" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="aspirations" style="font-weight: bold;">{{
                                                $t("file.aspirations") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.aspirations')" :autoResize="true" rows="9"
                                                cols="70" v-model="selectedFile.aspirations" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.aspirations"
                                                v-for="(error, index) in errors.aspirations" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                    </div>

                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Assessment information") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="psychological_tests_conducted" style="font-weight: bold;">{{
                                                $t("file.psychological_tests_conducted") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.psychological_tests_conducted')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedStaticFile.psychological_tests_conducted" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.psychological_tests_conducted"
                                                v-for="(error, index) in errors.psychological_tests_conducted" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="tests_results" style="font-weight: bold;">{{
                                                $t('file.tests_results') }}</label>
                                            <InputText id="tests_results" type="text"
                                                v-model="selectedStaticFile.tests_results" />


                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.tests_results"
                                                v-for="(error, index) in errors.tests_results" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>
                                    </div>

                                    <hr class="horizontal dark" />
                                    <h5>{{ $t("file.Overall assessment of the case") }}</h5>
                                    <div class="grid p-fluid mt-3">
                                        <div class="field col-12 md:col-6">
                                            <label for="case_assumptions" style="font-weight: bold;">{{
                                                $t("file.case_assumptions") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.case_assumptions')" :autoResize="true"
                                                rows="9" cols="70" v-model="selectedStaticFile.case_assumptions" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.case_assumptions"
                                                v-for="(error, index) in errors.case_assumptions" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <label for="development_necessary_recommendations" style="font-weight: bold;">{{
                                                $t("file.development_necessary_recommendations") }}</label>
                                            <PrimeTextarea :placeholder="$t('file.development_necessary_recommendations')"
                                                :autoResize="true" rows="9" cols="70"
                                                v-model="selectedStaticFile.development_necessary_recommendations" />
                                            <InlineMessage class="w-full mt-3"
                                                v-show="errors && errors.development_necessary_recommendations"
                                                v-for="(error, index) in errors.development_necessary_recommendations"
                                                :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <label for="referral_agency" style="font-weight: bold;">{{
                                                $t('file.referral_agency') }}</label>
                                            <InputText id="referral_agency" type="text"
                                                v-model="selectedStaticFile.referral_agency" />
                                            <InlineMessage class="w-full mt-3" v-show="errors && errors.referral_agency"
                                                v-for="(error, index) in errors.referral_agency" :key="index">
                                                {{ translateErrorMessage(error) }}
                                            </InlineMessage>

                                        </div>

                                    </div>


                                    <hr class="horizontal dark" />
                            <h5>{{ $t("LabelPatient") }}</h5>
                            <div class="grid p-fluid mt-3">
                                <div class="field col-12 md:col-6">
                                    <PrimeDropdown v-model="selectedLabels"  :options="label_ids" optionLabel="name" 
                                     @change="chooseLabel(this.userId)"></PrimeDropdown> 
                                     <p class="phrase" v-if="this.show">{{ $t('once you choose patient label it is saved automatically') }}</p>
                                </div>

                            </div>
                                    <hr class="horizontal dark" />
                                    <div class="row edit-button text-center">
                                        <PrimeButton type="submit">{{ $t('Update') }}</PrimeButton>
                                        <PrimeButton @click="hideUpdateForm" class="mx-3">{{ $t('Cancel') }}</PrimeButton>
                                    </div>
                                </form>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import axios from "axios";
import DoctorNavbar from './DoctorNavbar.vue';

export default {
    data() {
        return {
            new_patient_label:'',
            // selectedPatientLabel:'',
            show:false,
            selectedLabels: '',
            userId:'',
label_ids: [],
            patientLabel:'',
            staticFile: [],
            selectedStaticFile: null,
            selectedFile: null,
            file: [],
            showUpdate: false,

            selectedAppointment: null,
            new_type: '',
            updateId: null,
            message: [],
            errors: {},
            material_status: '',

            new_material_status: '',
            birth_place: '',
            number_of_children: '',
            current_problem: '',
            date_of_current_problem: '',
            symptoms: '',
            general_income_level: '',
            father_education_level: '',
            his_occupation: '',
            his_age: '',
            mother_education_level: '',
            her_occupation: '',
            her_age: '',
            number_of_siblings: '',
            patient_birth_order_among_siblings: '',
            patient_relationship_with_father: '',
            patient_relationship_with_mother: '',
            patient_relationship_with_grandFather: '',
            patient_relationship_with_grandMother: '',
            patient_relationship_with_aunt: '',
            patient_relationship_with_uncle: '',
            patient_relationship_with_uncle_in_law: '',
            patient_relationship_with_aunt_in_law: '',


            new_patient_birth_order_among_siblings: '',
            new_patient_relationship_with_father: '',
            new_patient_relationship_with_mother: '',
            new_patient_relationship_with_grandFather: '',
            new_patient_relationship_with_grandMother: '',
            new_patient_relationship_with_aunt: '',
            new_patient_relationship_with_uncle: '',
            new_patient_relationship_with_uncle_in_law: '',
            new_patient_relationship_with_aunt_in_law: '',

            divorce_cases_in_family: '',
            chronic_illnesses_in_family: '',
            genetic_diseases_in_family: '',
            mental_illnesses_in_family: '',
            psychological_disorders_in_family: '',
            acute_injuries: '',
            addiction_cases: '',
            personality_traits: '',
            relationship_with_another_sex: '',
            relationship_with_neighbors: '',
            relationship_with_friends: '',
            relationship_with_relatives: '',

            new_relationship_with_another_sex: '',
            new_relationship_with_neighbors: '',
            new_relationship_with_friends: '',
            new_relationship_with_relatives: '',

            current_educational_level: '',
            number_of_elementary_years: '',
            academic_elementary_performance: '',
            number_of_middle_years: '',
            academic_middle_performance: '',
            number_of_high_years: '',
            academic_high_performance: '',
            number_of_college_years: '',
            academic_college_performance: '',
            subjects_with_high_grades: '',
            subjects_with_average_grades: '',
            subjects_with_low_grades: '',
            relationships_with_teachers_and_school_administration: '',
            relationships_with_schoolmates: '',
            new_relationships_with_teachers_and_school_administration: '',
            new_relationships_with_schoolmates: '',
            identity_interests_and_hobbies: '',
            current_occupation: '',
            professional_performance_level: '',
            new_professional_performance_level: '',
            professional_competence_level: '',
            previous_jobs: '',
            transitioning_reason_to_the_current_profession: '',
            working_hours_per_day: '',
            working_hours_per_week: '',
            work_health_insurance: '',
            work_social_security: '',

            new_work_health_insurance: '',
            new_work_social_security: '',

            expected_workplace_hazards: '',
            expected_compensation_from_work: '',
            retirement_age: '',
            relationship_with_colleagues: '',
            relationship_with_supervisors: '',
            relationship_with_subordinates: '',
            relationship_with_who_report: '',
            new_relationship_with_colleagues: '',
            new_relationship_with_supervisors: '',
            new_relationship_with_subordinates: '',
            new_relationship_with_who_report: '',
            new_professional_competence_level: '',
            aspirations: '',
            income_level: '',
            new_income_level: '',
            psychological_tests_conducted: '',
            tests_results: '',
            case_assumptions: '',
            development_necessary_recommendations: '',
            referral_agency: '',
               apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,


        };
    },

    methods: {
        getPatientFile() {
            this.userId=this.$route.params.id;
            const token = localStorage.getItem('token');
            axios.get('https://backend.ihayanow.com/api/doctor/files', {
                params: {
                    user_id:this.userId
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Api-Key':this.apiKey,
                    'Api-Secret':this.apiSecret
                }
            }).then(response => {
                this.file = response.data.patientFile;
            //   this.selectedPatientLabel=response.data.Label.title;
                this.patientLabel=response.data.Label.title;
                console.log(response.data,this.patientLabel);
                this.file.forEach(f => {
                    if (f.material_status == 1) {
                        this.material_status = this.$t('file.Married');
                    } else if (f.material_status == -1) {
                        this.material_status = this.$t('file.Divorced');
                    } else if (f.material_status == 0) {
                        this.material_status = this.$t('file.Single');
                    }

                    if (f.professional_competence_level == 0) {
                        this.professional_competence_level = this.$t('file.Dissatisfied');
                    } else if (f.professional_competence_level == 1) {
                        this.professional_competence_level = this.$t('file.Satisfied');
                    }

                    if (f.patient_relationship_with_father == 1) {
                        this.patient_relationship_with_father = this.$t('file.Good');
                    } else if (f.patient_relationship_with_father == 0) {
                        this.patient_relationship_with_father = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_father = this.$t('file.Bad');
                    }

                    if (f.patient_relationship_with_mother == 1) {
                        this.patient_relationship_with_mother = this.$t('file.Good');
                    } else if (f.patient_relationship_with_mother == 0) {
                        this.patient_relationship_with_mother = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_mother = this.$t('file.Bad');
                    }
                    if (f.patient_relationship_with_grandFather == 1) {
                        this.patient_relationship_with_grandFather = this.$t('file.Good');
                    } else if (f.patient_relationship_with_grandFather == 0) {
                        this.patient_relationship_with_grandFather = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_grandFather = this.$t('file.Bad');
                    }
                    if (f.patient_relationship_with_grandMother == 1) {
                        this.patient_relationship_with_grandMother = this.$t('file.Good');
                    } else if (f.patient_relationship_with_grandMother == 0) {
                        this.patient_relationship_with_grandMother = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_grandMother = this.$t('file.Bad');
                    }

                    if (f.patient_relationship_with_aunt == 1) {
                        this.patient_relationship_with_aunt = this.$t('file.Good');
                    } else if (f.patient_relationship_with_aunt == 0) {
                        this.patient_relationship_with_aunt = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_aunt = this.$t('file.Bad');
                    }

                    if (f.patient_relationship_with_uncle == 1) {
                        this.patient_relationship_with_uncle = this.$t('file.Good');
                    } else if (f.patient_relationship_with_uncle == 0) {
                        this.patient_relationship_with_uncle = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_uncle = this.$t('file.Bad');
                    }

                    if (f.patient_relationship_with_uncle_in_law == 1) {
                        this.patient_relationship_with_uncle_in_law = this.$t('file.Good');
                    } else if (f.patient_relationship_with_uncle_in_law == 0) {
                        this.patient_relationship_with_uncle_in_law = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_uncle_in_law = this.$t('file.Bad');
                    }
                    if (f.patient_relationship_with_aunt_in_law == 1) {
                        this.patient_relationship_with_aunt_in_law = this.$t('file.Good');
                    } else if (f.patient_relationship_with_aunt_in_law == 0) {
                        this.patient_relationship_with_aunt_in_law = this.$t('file.Mediocre');
                    } else {
                        this.patient_relationship_with_aunt_in_law = this.$t('file.Bad');
                    }
                    if (f.relationship_with_another_sex == 1) {
                        this.relationship_with_another_sex = this.$t('file.Good');
                    } else if (f.relationship_with_another_sex == 0) {
                        this.relationship_with_another_sex = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_another_sex = this.$t('file.Bad');
                    }
                    if (f.relationship_with_neighbors == 1) {
                        this.relationship_with_neighbors = this.$t('file.Good');
                    } else if (f.relationship_with_neighbors == 0) {
                        this.relationship_with_neighbors = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_neighbors = this.$t('file.Bad');
                    }
                    if (f.relationship_with_friends == 1) {
                        this.relationship_with_friends = this.$t('file.Good');
                    } else if (f.relationship_with_friends == 0) {
                        this.relationship_with_friends = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_friends = this.$t('file.Bad');
                    }
                    if (f.relationship_with_relatives == 1) {
                        this.relationship_with_relatives = this.$t('file.Good');
                    } else if (f.relationship_with_relatives == 0) {
                        this.relationship_with_relatives = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_relatives = this.$t('file.Bad');
                    }
                    if (f.relationships_with_teachers_and_school_administration == 1) {
                        this.relationships_with_teachers_and_school_administration = this.$t('file.Good');
                    } else if (f.relationships_with_teachers_and_school_administration == 0) {
                        this.relationships_with_teachers_and_school_administration = this.$t('file.Mediocre');
                    } else {
                        this.relationships_with_teachers_and_school_administration = this.$t('file.Bad');
                    }
                    if (f.relationships_with_schoolmates == 1) {
                        this.relationships_with_schoolmates = this.$t('file.Good');
                    } else if (f.relationships_with_schoolmates == 0) {
                        this.relationships_with_schoolmates = this.$t('file.Mediocre');
                    } else {
                        this.relationships_with_schoolmates = this.$t('file.Bad');
                    }
                    if (f.relationship_with_colleagues == 1) {
                        this.relationship_with_colleagues = this.$t('file.Good');
                    } else if (f.relationship_with_colleagues == 0) {
                        this.relationship_with_colleagues = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_colleagues = this.$t('file.Bad');
                    }
                    if (f.relationship_with_supervisors == 1) {
                        this.relationship_with_supervisors = this.$t('file.Good');
                    } else if (f.relationship_with_supervisors == 0) {
                        this.relationship_with_supervisors = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_supervisors = this.$t('file.Bad');
                    }
                    if (f.relationship_with_subordinates == 1) {
                        this.relationship_with_subordinates = this.$t('file.Good');
                    } else if (f.relationship_with_subordinates == 0) {
                        this.relationship_with_subordinates = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_subordinates = this.$t('file.Bad');
                    }
                    if (f.relationship_with_who_report == 1) {
                        this.relationship_with_who_report = this.$t('file.Good');
                    } else if (f.relationship_with_who_report == 0) {
                        this.relationship_with_who_report = this.$t('file.Mediocre');
                    } else {
                        this.relationship_with_who_report = this.$t('file.Bad');
                    }
                    if (f.work_health_insurance == 1 || f.work_social_security == 1) {
                        this.work_health_insurance = this.$t('file.find');
                        this.work_social_security = this.$t('file.find');
                    } else if (f.work_health_insurance == -1 ||
                        this.work_social_security.name == -1) {
                        this.work_health_insurance = this.$t('file.notFind');
                        this.work_social_security = this.$t('file.notFind');
                    }


                    if (f.income_level == 1 || f.professional_performance_level == 1) {
                        this.income_level = this.$t('file.High');
                        this.professional_performance_level = this.$t('file.High');
                    }
                    else if (f.income_level == 0 || f.professional_performance_level == 0) {
                        this.income_level = this.$t('file.Mid');
                        this.professional_performance_level = this.$t('file.Mid');
                    }
                    else if (f.income_level == -1 || f.professional_performance_level == -1) {
                        this.income_level = this.$t('file.Low');
                        this.professional_performance_level = this.$t('file.Low');
                    }


                });



            }).catch(error => {
                console.log(error);
            });


            axios.get('https://backend.ihayanow.com/api/doctor/dynamic-files', {
                params: {
                    user_id: 3
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Api-Key':this.apiKey,
                    'Api-Secret':this.apiSecret
                }
            }).then(response => {
                this.staticFile = response.data.staticPatientFile;
                
                console.log(this.staticFile);
                console.log(this.staticFile[0].development_necessary_recommendations);
            }).catch(error => {
                console.log(error);
            });


        },

        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },


        showUpdateForm(id) {
            this.showUpdate = true;
            this.updateId = id;
            this.selectedFile = this.file.find(f => f.user_id === id);
            this.selectedStaticFile = this.staticFile.find(f => f.user_id === id)
            console.log(this.selectedStaticFile);
        },
        hideUpdateForm() {
            this.showUpdate = false;
            this.updateId = null;
        },
        updatePatientFile() {
            if (this.selectedFile.material_status.name == "Single") {
                this.new_material_status = 0;
            } else if (this.selectedFile.material_status.name == "Married") {
                this.new_material_status = 1;
            } else if (this.selectedFile.material_status.name == "Divorced") {
                this.new_material_status = -1;
            } else {
                this.new_material_status = this.selectedFile.material_status;
            }

            if (this.selectedFile.professional_competence_level.name == "Dissatisfied") {
                this.new_professional_competence_level = 0;
            } else if (this.selectedFile.professional_competence_level.name == "Satisfied") {
                this.new_professional_competence_level = 1;
            } else {
                this.new_professional_competence_level = this.selectedFile.professional_competence_level;
            }

            if (this.selectedFile.work_health_insurance.name == "There is" || this.selectedFile.work_social_security.name == "There is") {
                this.new_work_health_insurance = 1;
                this.new_work_social_security = 1;
            } else if (this.selectedFile.work_health_insurance.name == "There is not" ||
                this.selectedFile.work_social_security.name == "There is not") {
                this.new_work_health_insurance = -1;
                this.new_work_social_security = -1;
            } else {
                this.new_work_health_insurance = this.selectedFile.work_health_insurance;
                this.new_work_social_security = this.selectedFile.work_social_security;
            }
            if (this.selectedFile.income_level.name == "High" || this.selectedFile.professional_performance_level.name == "High") {
                this.new_income_level = 1;
                this.new_professional_performance_level = 1;
            }
            else if (this.selectedFile.income_level.name == "Mid" || this.selectedFile.professional_performance_level.name == "Mid") {
                this.new_income_level = 0;
                this.new_professional_performance_level = 0;
            }
            else if (this.selectedFile.income_level.name == "Low" || this.selectedFile.professional_performance_level.name == "Low") {
                this.new_income_level = -1;
                this.new_professional_performance_level = -1;
            } else {
                this.new_income_level = this.selectedFile.income_level;
                this.new_professional_performance_level = this.selectedFile.income_level;
            }
            if (this.selectedFile.patient_relationship_with_father.name == "Good" || this.selectedFile.patient_relationship_with_father == 1) {
                console.log("hi father good");
                this.new_patient_relationship_with_father = 1;
            } else if (this.selectedFile.patient_relationship_with_father.name == "Bad" || this.selectedFile.patient_relationship_with_father == -1) {
                console.log("hi father bad");
                this.new_patient_relationship_with_father = -1;
            } else if (this.selectedFile.patient_relationship_with_father.name == "Mediocre" || this.selectedFile.patient_relationship_with_father == 0) {
                console.log("hi father medicore");
                this.new_patient_relationship_with_father = 0;
            }
            else {
                console.log("hi father else")
                this.new_patient_relationship_with_father = this.selectedFile.patient_relationship_with_father;
            }

            if (this.selectedFile.patient_relationship_with_mother.name == "Good" || this.selectedFile.patient_relationship_with_mother == 1) {
                console.log("hi mother good");
                this.new_patient_relationship_with_mother = 1;
            } else if (this.selectedFile.patient_relationship_with_mother.name == "Bad" || this.selectedFile.patient_relationship_with_mother == -1) {
                console.log("hi mother bad");
                this.new_patient_relationship_with_mother = -1;
            } else if (this.selectedFile.patient_relationship_with_mother.name == "Mediocre" || this.selectedFile.patient_relationship_with_mother == 0) {
                console.log("hi mother medicore");
                this.new_patient_relationship_with_mother = 0;
            } else {
                console.log("hi else mother");
                this.new_patient_relationship_with_mother = this.selectedFile.patient_relationship_with_mother;
            }

            if (this.selectedFile.patient_relationship_with_grandFather.name == "Good" || this.selectedFile.patient_relationship_with_grandFather == 1) {
                console.log("hi grand father good");
                this.new_patient_relationship_with_grandFather = 1;
            } else if (this.selectedFile.patient_relationship_with_grandFather.name == "Bad" || this.selectedFile.patient_relationship_with_grandFather == -1) {
                console.log("hi grand father bad");
                this.new_patient_relationship_with_grandFather = -1;
            } else if (this.selectedFile.patient_relationship_with_grandFather.name == "Mediocre" || this.selectedFile.patient_relationship_with_grandFather == 0) {
                console.log("hi grand father medicore");
                this.new_patient_relationship_with_grandFather = 0;
            } else {
                console.log("hi grand father else");
                this.new_patient_relationship_with_grandFather = this.selectedFile.patient_relationship_with_grandFather;
            }

            if (this.selectedFile.patient_relationship_with_grandMother.name == "Good" || this.selectedFile.patient_relationship_with_grandMother == 1) {
                console.log("hi grand mother good");
                this.new_patient_relationship_with_grandMother = 1;
            } else if (this.selectedFile.patient_relationship_with_grandMother.name == "Bad" || this.selectedFile.patient_relationship_with_grandMother == -1) {
                console.log("hi grand mother bad");
                this.new_patient_relationship_with_grandMother = -1;
            } else if (this.selectedFile.patient_relationship_with_grandMother.name == "Mediocre" || this.selectedFile.patient_relationship_with_grandMother == 0) {
                console.log("hi grand mother medicore");
                this.new_patient_relationship_with_grandMother = 0;
            } else {
                console.log("hi grand mother else");
                this.new_patient_relationship_with_grandMother = this.selectedFile.patient_relationship_with_grandMother;
            }

            if (this.selectedFile.patient_relationship_with_aunt.name == "Good" || this.selectedFile.patient_relationship_with_aunt == 1) {
                console.log("hi aunt good");
                this.new_patient_relationship_with_aunt = 1;
            } else if (this.selectedFile.patient_relationship_with_aunt.name == "Bad" || this.selectedFile.patient_relationship_with_aunt == -1) {
                console.log("hi aunt bad");
                this.new_patient_relationship_with_aunt = -1;
            } else if (this.selectedFile.patient_relationship_with_aunt.name == "Mediocre" || this.selectedFile.patient_relationship_with_aunt == 0) {
                console.log("hi aunt medicore");
                this.new_patient_relationship_with_aunt = 0;
            } else {
                console.log("hi aunt else");
                this.new_patient_relationship_with_aunt = this.selectedFile.patient_relationship_with_aunt;
            }

            if (this.selectedFile.patient_relationship_with_uncle.name == "Good" || this.selectedFile.patient_relationship_with_uncle == 1) {
                console.log("hi uncle good");
                this.new_patient_relationship_with_uncle = 1;
            } else if (this.selectedFile.patient_relationship_with_uncle.name == "Bad" || this.selectedFile.patient_relationship_with_uncle == -1) {
                console.log("hi uncle bad");
                this.new_patient_relationship_with_uncle = -1;
            } else if (this.selectedFile.patient_relationship_with_uncle.name == "Mediocre" || this.selectedFile.patient_relationship_with_uncle == 0) {
                console.log("hi uncle medicore");
                this.new_patient_relationship_with_uncle = 0;
            } else {
                console.log("hi uncle else");
                this.new_patient_relationship_with_uncle = this.selectedFile.patient_relationship_with_uncle;
            }

            if (this.selectedFile.patient_relationship_with_uncle_in_law.name == "Good" || this.selectedFile.patient_relationship_with_uncle_in_law == 1) {
                console.log("hi uncle in law good");
                this.new_patient_relationship_with_uncle_in_law = 1;
            } else if (this.selectedFile.patient_relationship_with_uncle_in_law.name == "Bad" || this.selectedFile.patient_relationship_with_uncle_in_law == -1) {
                console.log("hi uncle in law bad");
                this.new_patient_relationship_with_uncle_in_law = -1;
            } else if (this.selectedFile.patient_relationship_with_uncle_in_law.name == "Mediocre" || this.selectedFile.patient_relationship_with_uncle_in_law == 0) {
                console.log("hi uncle in law medicore");
                this.new_patient_relationship_with_uncle_in_law = 0;
            } else {
                  console.log("hi uncle in law else");
                this.new_patient_relationship_with_uncle_in_law = this.selectedFile.patient_relationship_with_uncle_in_law;
            }

            if (this.selectedFile.patient_relationship_with_aunt_in_law.name == "Good" || this.selectedFile.patient_relationship_with_aunt_in_law == 1) {
                console.log("hi aunt in law good");
                this.new_patient_relationship_with_aunt_in_law = 1;
            } else if (this.selectedFile.patient_relationship_with_aunt_in_law.name == "Bad" || this.selectedFile.patient_relationship_with_aunt_in_law == -1) {
                console.log("hi aunt in law bad");
                this.new_patient_relationship_with_aunt_in_law = -1;
            } else if (this.selectedFile.patient_relationship_with_aunt_in_law.name == "Mediocre" || this.selectedFile.patient_relationship_with_aunt_in_law == 0) {
                console.log("hi aunt in law medicore");
                this.new_patient_relationship_with_aunt_in_law = 0;
            } else {
                console.log("hi aunt in law else");
                this.new_patient_relationship_with_aunt_in_law = this.selectedFile.patient_relationship_with_aunt_in_law;
            }

            if (this.selectedFile.relationship_with_another_sex.name == "Good" || this.selectedFile.relationship_with_another_sex == 1) {
                console.log("hi sex good");
                this.new_relationship_with_another_sex = 1;
            } else if (this.selectedFile.relationship_with_another_sex.name == "Bad" || this.selectedFile.relationship_with_another_sex == -1) {
                console.log("hi sex bad");
                this.new_relationship_with_another_sex = -1;
            } else if (this.selectedFile.relationship_with_another_sex.name == "Mediocre" || this.selectedFile.relationship_with_another_sex == 0) {
                console.log("hi sex medicore");
                this.new_relationship_with_another_sex = 0;
            } else {
                console.log("hi sex else");
                this.new_relationship_with_another_sex = this.selectedFile.relationship_with_another_sex;
            }

            if (this.selectedFile.relationship_with_neighbors.name == "Good" || this.selectedFile.relationship_with_neighbors == 1) {
                console.log("hi neighbors good");
                this.new_relationship_with_neighbors = 1;
            } else if (this.selectedFile.relationship_with_neighbors.name == "Bad" || this.selectedFile.relationship_with_neighbors == -1) {
                console.log("hi neighbors bad");
                this.new_relationship_with_neighbors = -1;
            } else if (this.selectedFile.relationship_with_neighbors.name == "Mediocre" || this.selectedFile.relationship_with_neighbors == 0) {
                console.log("hi neighbors medicore");
                this.new_relationship_with_neighbors = 0;
            } else {
                console.log("hi neighbors else");
                this.new_relationship_with_neighbors = this.selectedFile.relationship_with_neighbors;
            }
            if (this.selectedFile.relationship_with_friends.name == "Good" || this.selectedFile.relationship_with_friends == 1) {
                console.log("hi friends good");
                this.new_relationship_with_friends = 1;
            } else if (this.selectedFile.relationship_with_friends.name == "Bad" || this.selectedFile.relationship_with_friends == -1) {
                console.log("hi friends bad");
                this.new_relationship_with_friends = -1;
            } else if (this.selectedFile.relationship_with_friends.name == "Mediocre" || this.selectedFile.relationship_with_friends == 0) {
                console.log("hi friends medicore");
                this.new_relationship_with_friends = 0;
            } else {
                console.log("hi friends else");
                this.new_relationship_with_friends = this.selectedFile.relationship_with_friends;
            }

            if (this.selectedFile.relationship_with_relatives.name == "Good" || this.selectedFile.relationship_with_relatives == 1) {
                console.log("hi relatives good");
                this.new_relationship_with_relatives = 1;
            } else if (this.selectedFile.relationship_with_relatives.name == "Bad" || this.selectedFile.relationship_with_relatives == -1) {
                console.log("hi relatives bad");
                this.new_relationship_with_relatives = -1;
            } else if (this.selectedFile.relationship_with_relatives.name == "Mediocre" || this.selectedFile.relationship_with_relatives == 0) {
                console.log("hi relatives medicore");
                this.new_relationship_with_relatives = 0;
            } else {
                console.log("hi relatives else");
                this.new_relationship_with_relatives = this.selectedFile.relationship_with_relatives;
            }
            if (this.selectedFile.relationships_with_teachers_and_school_administration.name == "Good" || this.selectedFile.relationships_with_teachers_and_school_administration == 1) {
                this.new_relationships_with_teachers_and_school_administration = 1;
            } else if (this.selectedFile.relationships_with_teachers_and_school_administration.name == "Bad" || this.selectedFile.relationships_with_teachers_and_school_administration == -1) {
                this.new_relationships_with_teachers_and_school_administration = -1;
            } else if (this.selectedFile.relationships_with_teachers_and_school_administration.name == "Mediocre" || this.selectedFile.relationships_with_teachers_and_school_administration == 0) {
                this.new_relationships_with_teachers_and_school_administration = 0;
            } else {
                this.new_relationships_with_teachers_and_school_administration = this.selectedFile.relationships_with_teachers_and_school_administration;
            }
            if (this.selectedFile.relationships_with_schoolmates.name == "Good" || this.selectedFile.relationships_with_schoolmates == 1) {
                this.new_relationships_with_schoolmates = 1;
            } else if (this.selectedFile.relationship_with_schoolmates.name == "Bad" || this.selectedFile.relationships_with_schoolmates == -1) {
                this.new_relationships_with_schoolmates = -1;
            } else if (this.selectedFile.relationship_with_schoolmates.name == "Mediocre" || this.selectedFile.relationships_with_schoolmates == 0) {
                this.new_relationships_with_schoolmates = 0;
            } else {
                this.new_relationships_with_schoolmates = this.selectedFile.relationships_with_schoolmates;
            }
            if (this.selectedFile.relationship_with_colleagues.name == "Good" || this.selectedFile.relationship_with_colleagues == 1) {
                this.new_relationship_with_colleagues = 1;
            } else if (this.selectedFile.relationship_with_colleagues.name == "Bad" || this.selectedFile.relationship_with_colleagues == -1) {
                this.new_relationship_with_colleagues = -1;
            } else if (this.selectedFile.relationship_with_colleagues.name == "Mediocre" || this.selectedFile.relationship_with_colleagues == 0) {
                this.new_relationship_with_colleagues = 0;
            } else {
                this.new_relationship_with_colleagues = this.selectedFile.relationship_with_colleagues;
            }
            if (this.selectedFile.relationship_with_supervisors.name == "Good" || this.selectedFile.relationship_with_supervisors == 1) {
                this.new_relationship_with_supervisors = 1;
            } else if (this.selectedFile.relationship_with_supervisors.name == "Bad" || this.selectedFile.relationship_with_supervisors == -1) {
                this.new_relationship_with_supervisors = -1;
            } else if (this.selectedFile.relationship_with_supervisors.name == "Mediocre" || this.selectedFile.relationship_with_supervisors == 0) {
                this.new_relationship_with_supervisors = 0;
            } else {
                this.new_relationship_with_supervisors = this.selectedFile.relationship_with_supervisors;
            }
            if (this.selectedFile.relationship_with_subordinates.name == "Good" || this.selectedFile.relationship_with_subordinates == 1) {
                this.new_relationship_with_subordinates = 1;
            } else if (this.selectedFile.relationship_with_subordinates.name == "Bad" || this.selectedFile.relationship_with_subordinates == -1) {
                this.new_relationship_with_subordinates = -1;
            } else if (this.selectedFile.relationship_with_subordinates.name == "Mediocre" || this.selectedFile.relationship_with_subordinates == 0) {
                this.new_relationship_with_subordinates = 0;
            } else {
                this.new_relationship_with_subordinates = this.selectedFile.relationship_with_subordinates;
            }
            if (this.selectedFile.relationship_with_who_report.name == "Good" || this.selectedFile.relationship_with_who_report == 1) {
                this.new_relationship_with_who_report = 1;
            } else if (this.selectedFile.relationship_with_who_report.name == "Bad" || this.selectedFile.relationship_with_who_report == -1) {
                this.new_relationship_with_who_report = -1;
            } else if (this.selectedFile.relationship_with_who_report.name == "Mediocre" || this.selectedFile.relationship_with_who_report == 0) {
                this.new_relationship_with_who_report = 0;
            } else {
                this.new_relationship_with_who_report = this.selectedFile.relationship_with_who_report;
            }

            

            axios.put(`https://backend.ihayanow.com/api/doctor/files/${this.updateId}`, {
                user_id: this.userId,
                material_status: this.new_material_status,
                birth_place: this.selectedFile.birth_place,
                number_of_children: this.selectedFile.number_of_children,
                current_problem: this.selectedFile.current_problem,
                date_of_current_problem: this.selectedFile.date_of_current_problem,
                symptoms: this.selectedFile.symptoms,
                general_income_level: this.selectedFile.general_income_level,
                father_education_level: this.selectedFile.father_education_level,
                his_occupation: this.selectedFile.his_occupation,
                his_age: this.selectedFile.his_age,
                mother_education_level: this.selectedFile.mother_education_level,
                her_occupation: this.selectedFile.her_occupation,
                her_age: this.selectedFile.her_age,
                number_of_siblings: this.selectedFile.number_of_siblings,
                patient_birth_order_among_siblings: this.selectedFile.patient_birth_order_among_siblings,
                patient_relationship_with_father: this.new_patient_relationship_with_father,
                patient_relationship_with_mother: this.new_patient_relationship_with_mother,
                patient_relationship_with_grandFather: this.new_patient_relationship_with_grandFather,
                patient_relationship_with_grandMother: this.new_patient_relationship_with_grandMother,
                patient_relationship_with_aunt: this.new_patient_relationship_with_aunt,
                patient_relationship_with_uncle: this.new_patient_relationship_with_uncle,
                patient_relationship_with_uncle_in_law: this.new_patient_relationship_with_uncle_in_law,
                patient_relationship_with_aunt_in_law: this.new_patient_relationship_with_aunt_in_law,
                divorce_cases_in_family: this.selectedFile.divorce_cases_in_family,
                chronic_illnesses_in_family: this.selectedFile.chronic_illnesses_in_family,
                genetic_diseases_in_family: this.selectedFile.genetic_diseases_in_family,
                mental_illnesses_in_family: this.selectedFile.mental_illnesses_in_family,
                psychological_disorders_in_family: this.selectedFile.psychological_disorders_in_family,
                acute_injuries: this.selectedFile.acute_injuries,
                addiction_cases: this.selectedFile.addiction_cases,
                personality_traits: this.selectedFile.personality_traits,
                relationship_with_another_sex: this.new_relationship_with_another_sex,
                relationship_with_neighbors: this.new_relationship_with_neighbors,
                relationship_with_friends: this.new_relationship_with_friends,
                relationship_with_relatives: this.new_relationship_with_relatives,
                current_educational_level: this.selectedFile.current_educational_level,
                number_of_elementary_years: this.selectedFile.number_of_elementary_years,
                academic_elementary_performance: this.selectedFile.academic_elementary_performance,
                number_of_middle_years: this.selectedFile.number_of_middle_years,
                academic_middle_performance: this.selectedFile.academic_middle_performance,
                number_of_high_years: this.selectedFile.number_of_high_years,
                academic_high_performance: this.selectedFile.academic_high_performance,
                number_of_college_years: this.selectedFile.number_of_college_years,
                academic_college_performance: this.selectedFile.academic_college_performance,
                subjects_with_high_grades: this.selectedFile.subjects_with_high_grades,
                subjects_with_average_grades: this.selectedFile.subjects_with_average_grades,
                subjects_with_low_grades: this.selectedFile.subjects_with_low_grades,
                relationships_with_teachers_and_school_administration: this.new_relationships_with_teachers_and_school_administration,
                relationships_with_schoolmates: this.new_relationships_with_schoolmates,
                identity_interests_and_hobbies: this.selectedFile.identity_interests_and_hobbies,
                current_occupation: this.selectedFile.current_occupation,
                professional_performance_level: this.new_professional_performance_level,
                professional_competence_level: this.new_professional_competence_level,
                previous_jobs: this.selectedFile.previous_jobs,
                transitioning_reason_to_the_current_profession: this.selectedFile.transitioning_reason_to_the_current_profession,
                working_hours_per_day: this.selectedFile.working_hours_per_day,
                working_hours_per_week: this.selectedFile.working_hours_per_week,
                work_health_insurance: this.new_work_health_insurance,
                work_social_security: this.new_work_social_security,
                expected_workplace_hazards: this.selectedFile.expected_workplace_hazards,
                expected_compensation_from_work: this.selectedFile.expected_compensation_from_work,
                retirement_age: this.selectedFile.retirement_age,
                relationship_with_colleagues: this.new_relationship_with_colleagues,
                relationship_with_supervisors: this.new_relationship_with_supervisors,
                relationship_with_subordinates: this.new_relationship_with_subordinates,
                relationship_with_who_report: this.new_relationship_with_who_report,
                aspirations: this.selectedFile.aspirations,
                income_level: this.new_income_level,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
                    'Api-Secret':this.apiSecret
                }
            })
                .then(response => {
                    this.getPatientFile();
                    this.hideUpdateForm();
                    this.message = [{ severity: 'success', detail: 'Success Message', content: 'Data Updated Successfully' }]
                    this.errors = response.data.message;
                    window.location.reload();

                })
                .catch(error => {
                    this.errors = error.response.data.message;
                    console.log(this.errrors);

                });


axios.put(`https://backend.ihayanow.com/api/doctor/dynamic-files/${this.updateId}`, {
    user_id: this.userId,
    psychological_tests_conducted: this.selectedStaticFile.psychological_tests_conducted,
    tests_results: this.selectedStaticFile.tests_results,
    case_assumptions: this.selectedStaticFile.case_assumptions,
    development_necessary_recommendations: this.selectedStaticFile.development_necessary_recommendations,
    referral_agency: this.selectedStaticFile.referral_agency,
}, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret
    },
})
    .then(response => {
        this.success = true;
        this.message = response;
         window.location.reload();

    })
    .catch(error => {
        this.errors = error;
    });

        },
        chooseLabel(userId){
            if (this.selectedLabels.name ==  this.patientLabel) {
                console.log("hello");
                this.new_patient_label = this.patientLabel;
                console.log(this.new_patient_label);
            }else if (this.selectedLabels.name !==  this.patientLabel) {
              console.log("kkk")
                this.new_patient_label = this.selectedLabels.name;
                console.log(this.new_patient_label);
            }else{
                console.log("mmmmm")
                this.new_patient_label = this.patientLabel;
                console.log(this.new_patient_label);
            }
      axios.post('https://backend.ihayanow.com/api/doctor/label-user', {
                user_id: userId,
                label_name: this.new_patient_label
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                    'Api-Key':this.apiKey,
                    'Api-Secret':this.apiSecret

                },
            })
                .then(response => {
                    console.log(response);
                    this.message = [{
                        severity: 'success', detail: 'Success Message',
                        content: this.$t('Patient Labeled Successfully')
                    }]
                    if (this.message) {
                        this.show=true;  
                    }
                }).catch(error => {
                    console.log(error);
                });

        },
     

    },
    mounted() {
        this.getPatientFile();

        this.userId=this.$route.params.id;
         axios.get('https://backend.ihayanow.com/api/doctor/get-labels', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key':this.apiKey,
                'Api-Secret':this.apiSecret
            },
        })
            .then(response => {
                console.log(response.data);

                this.label_ids = response.data.Label.map((label) => ({
                    name: label.title,
                    id: label.id
                }));
                this.message = response.data.message;

                console.log(this.label_ids)
            })
            .catch(error => {
                console.log(error);
                console.log(error.response);
                this.errors = error.response;

            });
       
    },
    components: {
        DoctorNavbar
    },

    computed: {

        relations() {
            return [
                { translationKey: this.$t('file.Good'), name: "Good" },
                { translationKey: this.$t('file.Mediocre'), name: "Mediocre" },
                { translationKey: this.$t('file.Bad'), name: "Bad" },

            ]
        },

        status() {
            return [
                { translationKey: this.$t("file.Single"), name: "Single" },
                { translationKey: this.$t("file.Married"), name: "Married" },
                { translationKey: this.$t("file.Divorced"), name: "Divorced" },

            ]
        },

        incomeLevels() {
            return [
                { translationKey: this.$t("file.High"), name: "High" },
                { translationKey: this.$t("file.Mid"), name: "Mid" },
                { translationKey: this.$t("file.Low"), name: "Low" },
            ]
        },
        competences() {
            return [
                { translationKey: this.$t("file.Satisfied"), name: "Satisfied" },
                { translationKey: this.$t("file.Dissatisfied"), name: "Dissatisfied" }
            ]
        },
        choices() {
            return [
                { translationKey: this.$t("file.find"), name: 'There is' },
                { translationKey: this.$t("file.notFind"), name: 'There is not' },
            ]
        }

    },

}

</script>
<style scoped>
.phrase{
    color:red;
margin-top:15px;
}
</style>
