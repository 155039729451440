<template>
  <div class="container">
    <Navbar />
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <h2 class="text-center">{{ $t('check_email') }}</h2>

              <div class="formgroup-center  pt-4">
                <div class="formgroup-inline">
                  <div class="field px-3">
                    <label for="token" class="px-3">{{ $t('enter_token') }}</label>
                    <InputText id="token" v-model="token" type="text" :placeholder="$t('enter_token')" />
                    <div v-if="errors">

                      <InlineMessage class="mx-3 mt-3" v-show="errors && errors.token" v-for="(error, index) in errors.token"
                        :key="index">
                        {{ translateErrorMessage(error) }}
                      </InlineMessage>
                     
                    </div>
                    <InlineMessage class="mx-3 mt-3" v-show="showinvalid">
                        {{ translateErrorMessage(errorMessage) }}
                      </InlineMessage>
                       <InlineMessage class="mx-3 mt-3" v-show="success">
                        {{ translateErrorMessage(message) }}
                      </InlineMessage>
                      <InlineMessage class="mx-3 mt-3" severity="success" v-show="resendSuccess">
                        {{ translateErrorMessage(resendMessage) }}
                      </InlineMessage>
                 
                  </div>
                  <PrimeButton @click.prevent="verify()">{{ $t('submit') }}</PrimeButton>
                </div>
              </div>
              <div class="formgroup-center  pt-4">
                <div class="formgroup-inline">
                  <PrimeButton @click.prevent="resend()">{{ $t('resend_pin') }}</PrimeButton>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Navbar from '../Navbar.vue';
import Footer from '../Footer.vue';
import axios from 'axios';


export default {
  name:'CheckEmail',
  data() {
    return {
      message:'',
      token: '',
      email: localStorage.getItem('email'),
      y: localStorage.getItem('btoken'),
      errorss: [],
      show: false,
      resendSuccess:false,
      resendMessage:'',
      errors: {},
      showerrors: [],
      errorMessage: '',
      showinvalid: false,
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    }
  },

  methods: {
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    async verify() {

        await axios.post('https://backend.ihayanow.com/api/verify/pin',
        {
            email: this.email,
            token: this.token

          }, {
          
          headers: {
            'Content-Type': 'application/json',
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
          },
         
        }).then(response=>{
      
        if (response.data.success == true) {
          this.success = true;
          this.messag = response.data.message;
          this.token = response.data.token;
        } else {
          console.log("hi")
          this.success = false;
          this.showinvalid = true;
          this.errorMessage = response.data.message;
console.log(this.errorMessage);

        }
        console.log(response);

        if (this.success) {
          localStorage.setItem('token', this.token)
          if (localStorage.getItem('type') == "Doctor") {
            this.$router.push('/Complete-registeration')
          } else {
            this.$router.push('/Complete')
          }

        }
        }).catch(error=>{
          this.errors = error.response.data.message;
 
            console.log(this.errors)
          console.log(error)
         
            
        })
    
    },

    async resend() {
   

      await axios.post('https://backend.ihayanow.com/api/resend/pin', 
      {
          email : this.$route.params.email,
        }
        ,{
        
          headers: {
            'Content-Type': 'application/json',
            'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret,

          },
        }).then(response=>{
          console.log(response)
          if (response.data.success == true) {
               this.resendSuccess = true
               this.resendMessage = response.data.message
               console.log(this.resendMessage)
             

            }
            else {
               this.errors = response.data.message;
            }
            }).catch(error=>{
              console.log(error);
               this.errors = error.response.data.errors;
            })
       


    },

  },

  components: {
    Navbar,
    Footer
  }




}
</script>


<style>
.formgroup-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>