<template>
    <div :key="selectedLanguage" >
    <PrimeMenubar  :model="menu" >
      <template #start>
        <div class="mx-2 layout-topbar">
          <img :src="require('/public/mental_logo.png')" alt="IHAYANOW logo" style="width:70px; height:70px;">
          <span class="text">IHAYANOW</span>
        </div>
      </template>


      <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
    </template>
           
      <template #end>
<!-- -->
        <div class="toggle-with-notification" :class="{ 'margin-left-230': this.selectedLanguage == 'en', 'margin-right-230': this.selectedLanguage == 'ar' }" >
        <li class="nav-item dropdown" style="list-style-type: none;">
        <a id="navbarDropdown" style="color:black;" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-bell" id="notify_icon"></i>
            <span id="notifiy_num" v-show="this.notifications.length > 0">{{this.notifications.length}}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" v-show="this.notifications.length > 0" @click="markAsRead">Mark all as read!</a>
            <a class="dropdown-item" v-for="(unread, index) in this.notifications" :key="index">
                <h5>{{unread.data.data}}</h5>
                <!-- <p>{{unread.data.post_title}}</p> -->
                <p>{{moment(unread.created_at).fromNow()}}</p>
            </a>
            <a class="dropdown-item"  v-show="this.show"  v-if="this.notifications.length == 0">   {{ $t('notification.no') }}</a>
        </div>
    </li>
          <!-- <div class="notification-icon" @click="toggleNotifications" v-if="this.notifications.length >= 0">
            <i class="fa fa-bell"></i>
            <span class="notification-count">
              {{ notifications.length }}
            </span>
          </div>
          <div class="notification-container" v-show="this.show" v-if="this.notifications.length >= 0">
            <div class="notification-dropdown" ref="notificationDropdown">
              <div class="notification-item" v-for="notification in notifications" :key="notification.id">
                {{ $t('notification.message') }} {{ getTime(notification.session_from) }}
              </div>
              <div v-if="notifications.length === 0" class="no-notifications">
                {{ $t('notification.no') }}
              </div>
            </div>
          </div> -->
          <div class="language-toggle mx-5"
            >
            <ToggleSwitchButton class="toggle" v-model="this.isActive" label="AR|EN" @click="toggleLanguage" />

          </div>


        </div>
      </template>

    </PrimeMenubar>
  </div>
</template>

<script>
import axios from 'axios';
import ToggleSwitchButton from "toggle-switch-button-vue";
import moment from 'moment';


export default {

    data() {
        return {
            show: false,
            notifications: [],
            profile: [],
            notify: '',
            notificationss: [],
            // selectedLanguage: '', 
            // supportedLanguages: ['en', 'ar'],
            // locale: this.$i18n.locale, 
            // isRtl: false,
            isActive: 'AR',
            selectedLanguage: 'ar', // Store the selected language
            supportedLanguages: ['en', 'ar'],// Add the supported languages here
            locale: this.$i18n.locale, // Access the locale directly from $i18n
            isRtl: false, // Set initial value for page direction
            apiKey : process.env.VUE_APP_API_KEY,
             apiSecret : process.env.VUE_APP_API_SECRET,
        };
    },
    methods: {
        async fetchProfile() {
     
            await axios.get('https://backend.ihayanow.com/api/doctor/my-profile'
                , {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                }).then(response => {
                    const data = response.data;
                    this.name = data[0].name;
                    this.email = data[0].email;
                    this.profile = data[0].profile;


                })
                .catch(error => {
                    this.errors = error.response.data.error;
                    console.log(this.errors)
                });


        },
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.locale = this.selectedLanguage;
            this.updatePageDirection();
        },
        toggleLanguage() {
            this.selectedLanguage = this.selectedLanguage === 'ar' ? 'en' : 'ar';
            this.changeLanguage();
        },
        updatePageDirection() {
            if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
                document.documentElement.dir = 'rtl';
            } else {
                document.documentElement.dir = 'ltr';
            }
        },
        getTime(time) {
            if (time.length > 0) {
                const formattedTime = moment(time, 'HH:mm:ss').format('h:mm A');
                const withoutAmPmIndicator = moment(time, 'HH:mm:ss').format('h:mm');

                if (formattedTime.includes('AM')) {
                    return withoutAmPmIndicator + ' ' + this.$t("am");
                } else if (formattedTime.includes('PM')) {
                    return withoutAmPmIndicator + ' ' + this.$t("pm");
                }
                else {
                    return formattedTime;
                }
            } else {
                return '';
            }

        },
        async fetchNotifications() {
            try {
              
                const response = await axios.get('https://backend.ihayanow.com/api/notifications_reminder', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret

                    }
                });
                this.notifications = response.data; // Assuming the API response is an array of notifications

                console.log(this.notifications);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },

        async logout() {
          
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear();
                    this.$router.push('/login-page');

                })
                .catch(error => {
                    console.log(error);
                });
        },
        toggleNotifications() {
            this.show = true;
            this.$refs.notificationDropdown.style.display =
                this.$refs.notificationDropdown.style.display == 'block' ? 'none' : 'block';
        }
    },

    created() {
        this.fetchProfile();
        this.updatePageDirection();
        this.fetchNotifications();
    },
    computed: {
        isTransparentNavbar() {
            return this.$route.path === '/';

        },

        menu() {
            return [
                {
                    label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', route: '/doctor-home'

                },
                {
                    label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: this.$t('menu.Profile'), icon: 'pi pi-fw pi-user', route: '/doctor-profile'
                        },
                        {
                            label: this.$t('menu.Edit_Profile'), icon: 'pi pi-fw pi-user-edit', route: '/edit-doctor-profile'
                        },
                    ]
                },
                {
                    label: this.$t('menu.Sessions'), icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: this.$t('menu.Show_Requested_Sessions'), icon: 'pi pi-fw pi-id-card', route: '/sessions-requests', disabled: this.profile.status === 0 },
                        { label: this.$t('menu.Show_Your_Session'), icon: 'pi pi-fw pi-id-card', route: '/doctor-sessions', disabled: this.profile.status === 0 },
                        { label: this.$t('menu.Calendar'), icon: 'pi pi-fw pi-id-card', route: '/doctor-calendars', disabled: this.profile.status === 0 },
                        {
                            label: this.$t('menu.Create_Session'), icon: 'pi pi-fw pi-check-square', route: '/create-sessions', disabled: this.profile.status === 0
                        },
                    ]
                },
                {
                    label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', route: "/knowledgebase-page"

                },
                {
                    label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', route: "/view-medications"

                },
                {
                    label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', route: "/therapists-page"

                },
                {
                    label: this.$t('menu.show_packages'), icon: 'pi pi-fw pi-users', route: "/show-packages"

                },
                {
                    label: this.$t('logout'), icon: 'pi pi-power-off', command: this.logout,

                },

            ];
        }

    },

    components: {
        ToggleSwitchButton
    }
}

</script>

<style scoped>
.layout-topbar .layout-topbar-logo {
    padding-left: 10px !important;
}

.toggle-switch-button {
    border: none !important;
}

.toggle-switch-button .switch-button-knob {
    border: solid 1px white !important;

}

.toggle-with-notification {
    display: flex;
    align-items: center;
}

.margin-left-230 {
    margin-left: 290px;
}

.margin-right-230 {
    margin-right: 220px;
}

.notification-icon {
    margin-left: 50px;
    position: relative;
    cursor: pointer;
    /* margin-right: 20px; */
}

.notification-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #ff6f61;
    color: white;
    border-radius: 50%;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: bold;
}

.notification-dropdown {
    position: absolute;
    top: 100%;
    left: 5%;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 250px;
    max-height: 300px;
    overflow-y: auto;
    animation: slide-down 0.3s ease;
    transform-origin: top;
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    color: #333;
}

.notification-item:last-child {
    border-bottom: none;
}

.no-notifications {
    padding: 10px;
    color: #888;
    text-align: center;
}
</style>



