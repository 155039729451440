<template>
    <!-- <div class="container">
        <AdminNavbar /> -->
    <div :class="containerClass" @click="onWrapperClick">
      <AppTopBar @menu-toggle="onMenuToggle" />
      <div class="layout-sidebar" @click="onSidebarClick">
        <!-- <AdminNavbar /> -->
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
      <div class="layout-main-container">
        <div class="layout-main">
          <div class="grid">
            <div class="field col-12">
              <div class="card py-4">
                <h5>Add Packages</h5>
                <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
                </PrimeMessage>
                <form @submit.prevent="submitForm">
                  
                  <div class="grid p-fluid mt-3">
                 
                    <div class="field col-12 md:col-6">
                      <label for="name" style="font-weight: bold;">Package Name</label>
                      <InputText id="name" type="text" v-model="name" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.name"
                             v-for="(error, index) in errors.name" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                    <label for="level_name" style="font-weight: bold;">Level Name</label>
                    <PrimeDropdown id="state" v-model="level_name" :options="level_ids" optionLabel="name" placeholder="Select One">
                    </PrimeDropdown>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.level_id"
                           v-for="(error, index) in errors.level_id" :key="index">
                           {{ error }}
                        </InlineMessage>
                  </div>
                    <div class="field col-12 md:col-6">
                      <label for="user_type" style="font-weight: bold;">For Which Type</label>
                      <PrimeDropdown id="user_type" class="w-full mb-3" v-model="user_type" :options="types"
                            optionLabel="name" placeholder="Select One"
                            style="  border: 2px; border-radius: 4px;">
                          </PrimeDropdown>                      
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.user_type"
                             v-for="(error, index) in errors.user_type" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="duration" style="font-weight: bold;">Package Duration</label>
                      <PrimeDropdown id="duration" class="w-full mb-3" v-model="duration" :options="durations"
                            optionLabel="name" placeholder="Select One"
                            style="  border: 2px; border-radius: 4px;">
                          </PrimeDropdown>                       <InlineMessage class="w-full mt-3" v-show="errors && errors.name"
                             v-for="(error, index) in errors.name" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="test_discount" style="font-weight: bold;">Package Test Discount</label>
                      <InputText id="test_discount" type="text" v-model="test_discount" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.test_discount"
                             v-for="(error, index) in errors.test_discount" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="onsite_discount" style="font-weight: bold;">Package Onsite Discount</label>
                      <InputText id="onsite_discount" type="text" v-model="onsite_discount" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.onsite_discount"
                             v-for="(error, index) in errors.onsite_discount" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="online_discount" style="font-weight: bold;">Package Online Discount</label>
                      <InputText id="online_discount" type="text" v-model="online_discount" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.online_discount"
                             v-for="(error, index) in errors.online_discount" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label for="price" style="font-weight: bold;">Package Price</label>
                      <InputText id="price" type="number" v-model="price" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.price"
                             v-for="(error, index) in errors.price" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6" v-show="user_type.name === 'Doctor'">
                      <label for="all_doctors" style="font-weight: bold;">For All Doctors?</label>
                      <PrimeDropdown id="all_doctors" class="w-full mb-3" v-model="all_doctors" :options="all_doctorss"
                            optionLabel="name" placeholder="Select One"
                            style="  border: 2px; border-radius: 4px;">
                          </PrimeDropdown>                      
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.all_doctors"
                             v-for="(error, index) in errors.all_doctors" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                    <div class="field col-12 ">
                      <label for="description" style="font-weight: bold;">Package Description</label>
                      <PrimeTextarea :placeholder="$t('Your Description')" :autoResize="true" rows="9" cols="70"
                                        v-model="description" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                             v-for="(error, index) in errors.description" :key="index">
                             {{ error }}
                          </InlineMessage>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                  <div class="row edit-button text-center">
                    <PrimeButton type="submit">Add</PrimeButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import AppTopBar from '@/AppTopbar.vue';
  import AppMenu from '@/AppMenu.vue';
  export default {
    data() {
      return {
   apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
        success:false,
        level_ids:[],
      name :'',
        user_type :'',
        status:'',
        description:'',
        duration:'',
        type:'',
        test_discount:'',
        onsite_discount:'',
        online_discount:'',
        price:'',
        all_doctorss:[
        { name: 'Yes' },
        { name: 'No' },

      ],
      types:[
        { name: 'Doctor' },
        { name: 'User' },

      ],
      durations:[
      { name: 'Yearly' },
        { name: 'Monthly' },
        { name: 'Weekly' },
      ],
      all_doctors:'',
        errors:{},
        showKnowFileInput: true,
        layoutMode: 'static',
        staticMenuInactive: false,
        overlayMenuActive: false,
        mobileMenuActive: false,
       
        menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
      };
    },
    mounted(){
    axios.get('https://backend.ihayanow.com/api/admin/doctors-levels', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        },
      })
        .then(response => {
          console.log(response.data);
          this.level_ids = response.data.levels.map((level) => ({
          name: level.category_name,
           // Map the category_name to objects with 'name' property
           id:level.id
        }));
        this.message=response.data.message;

        console.log(this.level_ids)
        })
        .catch(error => {
          console.log(error);
          console.log(error.response.data.errors);
          this.errors = error.response.data.errors;

        });
  },
    methods: {
       
      async logout() {
        await axios.post('https://backend.ihayanow.com/api/logout', null, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
          }
        })
          .then(response => {
            console.log(response);
            localStorage.clear(); 
  
            this.$router.push('/login-page');
  
          })
          .catch(error => {
            console.log(error);
          });
      },
      submitForm() {
        if(this.all_doctors.name == 'Yes'){
            this.all_doctors = 1;
        }else{
            this.all_doctors = 0;
        }


        axios.post('https://backend.ihayanow.com/api/admin/subscriptions', {
        name: this.name,
        level_id: this.level_name == undefined ? '' : this.level_name.id,
        description: this.description,
        all_doctors:this.all_doctors,
        price:this.price,
        onsite_discount:this.onsite_discount,
        online_discount: this.online_discount,
        test_discount:this.test_discount,
        duration:this.duration.name,
        user_type:this.user_type.name
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
          },
        })
          .then(response => {
            this.success=true;
            this.message=response.data.message;          
            console.log(response);
          })
          .catch(error => {
            this.errors=error.response.data.errors;
            console.log(error);
          });
      },
      onWrapperClick() {
        if (!this.menuClick) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
  
        this.menuClick = false;
      },
      onMenuToggle() {
        this.menuClick = true;
  
        if (this.isDesktop()) {
          if (this.layoutMode === 'overlay') {
            if (this.mobileMenuActive === true) {
              this.overlayMenuActive = true;
            }
  
            this.overlayMenuActive = !this.overlayMenuActive;
            this.mobileMenuActive = false;
          }
          else if (this.layoutMode === 'static') {
            this.staticMenuInactive = !this.staticMenuInactive;
          }
        }
        else {
          this.mobileMenuActive = !this.mobileMenuActive;
        }
  
        event.preventDefault();
      },
      onSidebarClick() {
        this.menuClick = true;
      },
      onMenuItemClick(event) {
        if (event.item && !event.item.items) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
      },
      onLayoutChange(layoutMode) {
        this.layoutMode = layoutMode;
      },
      addClass(element, className) {
        if (element.classList)
          element.classList.add(className);
        else
          element.className += ' ' + className;
      },
      removeClass(element, className) {
        if (element.classList)
          element.classList.remove(className);
        else
          element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },
      isDesktop() {
        return window.innerWidth >= 992;
      },
      isSidebarVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === 'static')
            return !this.staticMenuInactive;
          else if (this.layoutMode === 'overlay')
            return this.overlayMenuActive;
        }
  
        return true;
      }
    },
  
    beforeUpdate() {
      if (this.mobileMenuActive)
        this.addClass(document.body, 'body-overflow-hidden');
      else
        this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
      'AppTopBar': AppTopBar,
      'AppMenu': AppMenu,
    },
  
  
    computed: {
      
      containerClass() {
        return ['layout-wrapper', {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }];
      },
    }
  };
  </script>

<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }
</style>