<template>
  <div>
    <template v-if="isGuestTherapistPage">
      <router-view></router-view>
    </template>
    
    <template v-else-if="isDoctorSignUpPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isLoginPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isDoctorHomePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isUserHomePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isDoctorProfilePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isEditDoctorProfilePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isAcceptedDoctorsPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isUserProfilePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isEditUserProfilePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isKnowledgebasePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isMedicinePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isSignUpUser">
      <router-view></router-view>
    </template>
    <template v-else-if="isSignUpDoctor">
      <router-view></router-view>
    </template>
    <template v-else-if="isCompleteRegistartionUser">
      <router-view></router-view>
    </template>
    <template v-else-if="isCompleteRegistartionDoctor">
      <router-view></router-view>
    </template>
    <template v-else-if="isForgetPassword">
      <router-view></router-view>
    </template>
    <template v-else-if="isVerifyEmail">
      <router-view></router-view>
    </template>
    <template v-else-if="isVerifyPassword">
      <router-view></router-view>
    </template>
    <template v-else-if="isTherapistProfile">
      <router-view></router-view>
    </template>
    <template v-else-if="isUserSessions">
      <router-view></router-view>
    </template>
    <template v-else-if="isUserSessionsRequests">
      <router-view></router-view>
    </template>
    <template v-else-if="isCreateKnowledgebasePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isPromocodePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isShowKnowledgebaseCategory">
      <router-view></router-view>
    </template>
    <template v-else-if="isCalendarPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isOneKnowledgebase">
      <router-view></router-view>
    </template>
    <template v-else-if="isDoctorSessionsRequests">
      <router-view></router-view>
    </template>
    <template v-else-if="isCreateSessions">
      <router-view></router-view>
    </template>
    <template v-else-if="isDoctorCalendars">
      <router-view></router-view>
    </template>
    <template v-else-if="isDoctorSessions">
      <router-view></router-view>
    </template>
    <template v-else-if="isAdminKnowledgebasePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isCreatePromocode">
      <router-view></router-view>
    </template>
    <template v-else-if="isAdminHomePage">
      <router-view></router-view>
    </template>
    <template v-else-if="isSessionsMeeting">
      <router-view></router-view>
    </template>
    <template v-else-if="isSessionPayment">
      <router-view></router-view>
    </template>
    <template v-else-if="isKnowledge">
      <router-view></router-view>
    </template>
    <template v-else-if="isSessions">
      <router-view></router-view>
    </template>
    <template v-else-if="isSessionsRate">
      <router-view></router-view>
    </template>
    <template v-else-if="isAddKnowledgebaseCategory">
      <router-view></router-view>
    </template>
    <template v-else-if="isKnowledgebaseCategoryPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isAddMedications">
      <router-view></router-view>
    </template>
    <template v-else-if="isMedicationsAdminPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isReportsPage">
      <router-view></router-view>
    </template>
    <template v-else-if="isFavouriteDoctors">
      <router-view></router-view>
    </template>
    <template v-else-if="isCreatePatientFile">
      <router-view></router-view>
    </template>
    <template v-else-if="isPatientFile">
      <router-view></router-view>
    </template>
    <template v-else-if="isMostReservedDoctors">
      <router-view></router-view>
    </template>
    <template v-else-if="isAllUsers">
      <router-view></router-view>
    </template>
    <template v-else-if="isAddLabelPatient">
      <router-view></router-view>
    </template>
    <template v-else-if="isLabelPatient">
      <router-view></router-view>
    </template>
    <template v-else-if="isAddLevels">
      <router-view></router-view>
    </template>
    <template v-else-if="isShowLevels">
      <router-view></router-view>
    </template>
    <template v-else-if="isAddSubscriptions">
      <router-view></router-view>
    </template>
    <template v-else-if="isShowSubscriptions">
      <router-view></router-view>
    </template>
    <template v-else-if="isShowPackages">
      <router-view></router-view>
    </template>
    <template v-else-if="isGenerateCredentials">
      <router-view></router-view>
    </template>
    <template v-else-if="isPackagePayment">
      <router-view></router-view>
    </template>
    <template v-else>
      <Home />
    </template>
  </div>
</template>
 
<script>
import Home from '../src/components/Home.vue'

export default {

  name: 'App',
  computed: {

    isGuestTherapistPage() {
      return this.$route.name === 'guest-therapist-page';
    },
    isDoctorSignUpPage() {
      return this.$route.name === 'DoctorSignUp';
    },
    isLoginPage() {
      return this.$route.name === 'login-page';
    },
    isDoctorHomePage() {
      return this.$route.path === '/doctor-home';
    },
    isUserHomePage() {
      return this.$route.path === '/therapists-page';
    },
    isAdminHomePage() {
      return this.$route.path === '/admin';
    },
    isDoctorProfilePage() {
      return this.$route.path === '/doctor-profile';
    },
    isEditDoctorProfilePage() {
      return this.$route.path === '/edit-doctor-profile';
    }, isAcceptedDoctorsPage() {
      return this.$route.path === '/accepted-doctors';
    },
    isUserProfilePage() {
      return this.$route.path === '/user-profile';
    },
    isEditUserProfilePage() {
      return this.$route.path === '/edit-user-profile';
    },
    isKnowledgebasePage() {
      return this.$route.path === '/knowledgebase-page';
    },
    isMedicinePage() {
      return this.$route.path === '/view-medications';
    },
    isSignUpUser() {
      return this.$route.path === '/sign-up';
    },
    isSignUpDoctor() {
      return this.$route.path === '/doctor-sign-up';
    },
    isCompleteRegistartionUser() {
      return this.$route.path === '/Complete';
    },
    isCompleteRegistartionDoctor() {
      return this.$route.path === '/Complete-registeration';
    },
    isVerifyPassword() {
      const email = this.$route.params.email;
      return this.$route.path.includes(`/verify-password/${email}`) && email !== undefined && email !== null;
    },
    isVerifyEmail() {
      const email = this.$route.params.email;
      return this.$route.path.includes(`/check-email/${email}`) && email !== undefined && email !== null;
    },
    isForgetPassword() {
      return this.$route.path === '/forget-password';
    },
    isTherapistProfile() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/therapists/${id}`) && id !== undefined && id !== null;
    },
    isUserSessions() {
      return this.$route.path === '/user-sessions';
    },

    isDoctorSessionsRequests() {
      return this.$route.path === '/sessions-requests';
    },
    isCreateKnowledgebasePage() {
      return this.$route.path === '/create-knowledgebase-page';
    },
    isPromocodePage() {
      return this.$route.path === '/promocode-page';
    },
    isShowKnowledgebaseCategory() {
      return this.$route.path === '/show-knowledgebase-category';
    },
    isCalendarPage() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/Calendar/${id}`) && id !== undefined && id !== null;
    },
    isOneKnowledgebase() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/knowledgebase-page/${id}`) && id !== undefined && id !== null;
    },
    isCreateSessions() {
      return this.$route.path === '/create-sessions';
    },
    isDoctorCalendars() {
      return this.$route.path === '/doctor-calendars';
    },
    isUserSessionsRequests() {
      return this.$route.path === '/user_session_requests';
    },
    isDoctorSessions() {
      return this.$route.path === '/doctor-sessions';
    },
    isAdminKnowledgebasePage() {
      return this.$route.path === '/admin-knowledgebase-page';
    },
    isCreatePromocode() {
      return this.$route.path === '/create-promocode';
    },
    isSessionsMeeting() {
      const sessionId = this.$route.params.sessionId;
      const joined_user_name=this.$route.params.joined_user_name;
      return this.$route.path.includes(`/session_meeting/${sessionId}/${joined_user_name}`) && sessionId !== undefined && sessionId !== null;

    },
    
    isSessionPayment() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/session_payment/${id}`) && id !== undefined && id !== null;
    },
    isKnowledge() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/Knowledge/${id}`) && id !== undefined && id !== null;
    },
    isSessions() {
      return this.$route.path === '/sessions';
    },
    isSessionsRate() {
      const sessionId = this.$route.params.sessionId;
      return this.$route.path.includes(`/rate/${sessionId}`) && sessionId !== undefined && sessionId !== null;

    },
    isAddKnowledgebaseCategory() {
      return this.$route.path === '/add-knowledgebase-category';
    },
    isKnowledgebaseCategoryPage() {
      return this.$route.path === '/knowledgebase-category-page';
    },
    isAddMedications() {
      return this.$route.path === '/add-medications';
    },
    isMedicationsAdminPage() {
      return this.$route.path === '/medications-page';
    },
    isReportsPage() {
      return this.$route.path === '/reports';
    },

    isFavouriteDoctors() {
      return this.$route.path === '/favourite-doctors';
    },

    isCreatePatientFile() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/create-patient-file/${id}`) && id !== undefined && id !== null;

    },

    isPatientFile() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/patient-file/${id}`) && id !== undefined && id !== null;

    },

    isMostReservedDoctors() {
      return this.$route.path === '/most-reserved-doctors';
    },
    isAllUsers() {
      return this.$route.path === '/all-users';
    },

    isAddLabelPatient() {
      return this.$route.path === '/add-label-patient';
    },


    isLabelPatient() {
      return this.$route.path === '/label-patient';
    },
    isAddLevels() {
      return this.$route.path === '/add-levels';
    },
    isShowLevels() {
      return this.$route.path === '/show-levels';
    },
    isAddSubscriptions() {
      return this.$route.path === '/add-subscriptions';
    },
    isShowSubscriptions() {
      return this.$route.path === '/show-subscriptions';
    },
    isShowPackages() {
      return this.$route.path === '/show-packages';
    },
    isGenerateCredentials() {
      return this.$route.path === '/generate-credentials';
    },
    isPackagePayment() {
      const id = this.$route.params.id;
      return this.$route.path.includes(`/package_payment/${id}`) && id !== undefined && id !== null;

    },


  },
  components: {
    Home
  }
}
</script>

