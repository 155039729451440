<template>
    <div class="container">
        <UserNavbar />

        <div class="layout-main-container">
            <div class="layout-main">
                <div class="card shadow-lg mt-n6">
                    <div class="card-body p-3">

                        <div class="col-12">
                            <div class="grid">
                                <PrimeMessage class="w-full mt-3" severity="error" v-if="this.promomessage">{{ translateErrorMessage(this.promomessage) }}
                    </PrimeMessage>
                    <PrimeMessage class="w-full mt-3" severity="success" v-if="this.message">
                                    {{ translateErrorMessage(this.message) }}
                                </PrimeMessage>
                              
                                <PrimeMessage class="w-full mt-3" severity="success"
                                    v-if="this.messages && this.message1 == ''">
                                    {{ translateErrorMessage(this.messages) }}
                                </PrimeMessage>
                                <PrimeMessage class="w-full mt-3" severity="error" v-if="this.message1">
                                    {{ translateErrorMessage(this.message1) }}
                                </PrimeMessage>

                                <div class="col-5 flex align-items-center justify-content-center">
                                    <div class="p-fluid col-10">
                                        <div
                                            style="background-color:black; color:white; width:100%;  border-radius:5px; padding:10px; margin-bottom:10px; text-align: center;">

                                            <h5>{{ $t("Payments.My Booking on") }} {{ formatDate(this.Date) }}</h5>
                                            <p>{{ $t('Sessions.From') }} {{ formatTime(this.from) }} {{ $t('Sessions.To') }} {{
                                                formatTime(this.to) }} </p>
                                        </div>


                                        <div class="session-price">
                                            <h5>{{ $t('Sessions.Doctor_Name') }}</h5>
                                            <h5 class="h5"> {{ $t('Dr.') }} {{ this.doctor_name }} </h5>
                                        </div>
                                        <div class="session-price" >
                                            <h5>{{ $t('profile.session_type') }}</h5>
                                            <h5 class="h5"> {{ this.onsite == 0 ? $t('profile.Online')  :  $t('profile.Onsite') }} </h5>
                                        </div>
                                        <div class="session-price" >
                                            <h5>{{ $t('Payments.Session Price') }}</h5>
                                            <h5 class="h5"> {{ this.session_price }} {{ $t('Payments.EGP') }} </h5>
                                        </div>
                                      
                                       
                                        <div class="p-inputgroup" :dir="selectedLanguage == 'ar' ? 'ltr' : 'rtl'">
                                            <InputText v-model="promocode" :placeholder="$t('Payments.Promocode')" />
                                            <PrimeButton type="button" @click.prevent="apply_promo()"
                                                :label="$t('Payments.Apply')" />

                                        </div>
                                        <div class="session-price" v-show="Done" v-if="this.promomessage !== 'You are not allowed to use this promocode because you used it before.'
                                         && this.promomessage !== 'This promocode is expired!' && this.promomessage !== 'This promocode is only for doctors' && 
                                         this.promomessage !== 'This promocode is only for users'
                                         && this.promomessage !== 'you can not use this promocode again cause you exceed allowed number of using this promocode'
                                         && this.promomessage !== 'This promocode is invalid!'">
                                            <h5>{{ $t('Payments.Discount is') }} </h5>
                                            <h5 class="h5"> {{ this.percentage }}  %
                                            </h5>
                                        </div>
                                        <div class="session-price" v-show="Done" v-if="this.promomessage !== 'You are not allowed to use this promocode because you used it before.'
                                        && this.promomessage !== 'This promocode is expired!' && this.promomessage !== 'This promocode is only for doctors' 
                                        && this.promomessage !== 'This promocode is only for users'
                                        && this.promomessage !== 'you can not use this promocode again cause you exceed allowed number of using this promocode'
                                        && this.promomessage !== 'This promocode is invalid!'">
                                            <h5>{{ $t('Payments.Session Price Discount') }} </h5>
                                            <h5 class="h5"> {{ this.final_session_price }} {{ $t('Payments.EGP') }}
                                            </h5>
                                        </div>

                                        <div class="col-12" v-show="Done" 
                                        v-if="this.promomessage !== 'You are not allowed to use this promocode because you used it before.'
                                         && this.promomessage !== 'This promocode is expired!'&& this.promomessage !== 'This promocode is only for doctors' && this.promomessage !== 'This promocode is only for users'
                                         && this.promomessage !== 'you can not use this promocode again cause you exceed allowed number of using this promocode' && this.promomessage !== 'This promocode is invalid!'">
                                    
                                    <PrimeButton @click.prevent="pay_session()" :label=" toggle ? $t('Payments.Confirm') : $t('Payments.Confirm')" />
                                    </div>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <PrimeDivider layout="vertical">
                                    </PrimeDivider>
                                </div>
                                <div class="col-5 align-items-center justify-content-center">
                                    <p class="line-height-3 m-0"></p>

                                    <div class="col-10">
                                        <div class="payment-methods">
                                            <div v-for="method in paymentMethods" :key="method.id"
                                                @click="handlePayment(method)">
                                                <img :src="getImagePath(method.image)" alt="Payment Method"
                                                    :class="[method.name]" />
                                            </div>
                                        </div>
                                        <PrimeDivider layout="horizontal" align="center">
                                        </PrimeDivider>
                                        <div class="grid p-fluid mt-3">
                                            <div class="field col-12 md:col-12">
                                                <label for="card_number" style="font-weight: bold;">
                                                    {{ $t('Payments.Card Number')}}
                                                </label>
                                                <InputText id="card_number" type="text" v-model="card_number" />

                                            </div>
                                                 <PrimeButton type="button" 
                                            :label="$t('Payments.Confirm Number')"/>

                                        </div>

                                    </div>


                                </div>
                                <PrimeButton v-if="this.onsite == 1" type="button" @click.prevent="back()"
                                    :label="$t('Skip Payment')" />
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>

import axios from 'axios';
import moment from 'moment';
import UserNavbar from './UserNavbar.vue';
import Footer from '../Footer.vue';

export default {
    name:'SessionPayment',

    data() {
        return {
            promomessage:'',
            selectedLanguage: '',
            calendars: [],
            Date: '',
            sessionId: null,
            sessionDuration: '',
            from: '',
            to: '',
            onsite: '',
            view: 'month',
            Done:false,
            id: '',
            result: '',
            selectedDay: null,
            session_price: '',
            promocode: "",
            amount: '',
            new_session_price: '',
            final_session_price: '',
            user_name: '',
            doctor_name: '',
            percentage: '',
            message: '',
            messages: '',
            message1: '',
            toggle: false,
            promo:false,
            errors: '',
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            paymentMethods: [
                {
                    id: 1,
                    name: 'Fawry',
                    image: 'fawry.png'
                },
                {
                    id: 2,
                    name: 'Visa',
                    image: 'visa.png'
                },
                {
                    id: 3,
                    name: 'PayPal',
                    image: 'paypal.png'
                },
            ]
        };
    },

    mounted() {
        this.init();
    },


    methods: {
 
        formatTime(time) {
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours);
            hour = hour % 12 || 12;
            return `${hour}:${minutes}`;
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },

        async init() {
            this.selectedLanguage = this.$i18n.locale;

            let doctorData = [];
            axios.get('https://backend.ihayanow.com/api/get_calendar_for_user', {
                params: {
                    id: this.$route.params.id
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            }).then(response => {
                this.calendars = response.data[0];
                console.log(response.data);
                doctorData = response.data[1];
                console.log(doctorData);
                this.from = this.$route.query.from;
                this.to = this.$route.query.to;
                this.Date = this.calendars.date;
                this.onsite = this.calendars.onsite;
                this.doctor_name = response.data[2];
                this.user_name = response.data[3];
                if(this.$i18n.locale === 'ar'){
                    this.doctor_name=doctorData.arabic_name
                }else{
                    this.doctor_name = response.data[2];
                }
                if (this.onsite == 0) {
                    this.session_price = doctorData.online_price;

                } else {
                    this.session_price = doctorData.onsite_price;

                }
            }).catch(error => {
                console.error(error);
            });
        },

        async apply_promo() {
            await axios.get('https://backend.ihayanow.com/api/get_promocodes', {
                params: {
                    code: this.promocode
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                }
            }).then(response => {
                console.log(response);
                this.promomessage=response.data.message;
                // this.message = response.data.message;
console.log(this.promomessage)
console.log(this.promomessage !== "you can not use this promocode again cause you exceed allowed number of using this promocode")

                this.amount = response.data.amount;
                this.percentage = response.data.percentage;

                if (this.percentage == 100) {
                    this.new_session_price = 0;
                    if (this.new_session_price < (this.session_price - this.amount)) {
                        this.final_session_price = this.session_price - this.amount;
                    } else {
                        this.final_session_price = this.new_session_price;
                        if (this.final_session_price == 0) {
                            this.toggle = true;
                        }

                    }
                    this.Done=true;
                } else {
                    this.new_session_price = this.session_price - (this.session_price * (this.percentage / 100)) ;
                    if (this.new_session_price < (this.session_price - this.amount)) {
                        this.final_session_price = this.session_price - this.amount;
                    } else {
                        this.final_session_price = this.new_session_price;
                        if (this.final_session_price == 0) {
                            this.toggle = true;
                        }

                    }
                    this.Done=true;
                }
            })
                .catch(error => {
                    this.errors = error;
                    console.error(error);
                });
        },
        getImagePath(image) {
            return process.env.BASE_URL + image;
        },

        handlePayment(method) {
            console.log('Selected payment method:', method);
        },
        pay_session() {
            this.sessionId = this.$route.params.id
            this.updatePromocodes();
            this.acceptSession();
            if (this.message1 !== '') {
                console.log("hi");
            } else {
                this.$router.push({ name: 'user-sessions' })

            }

        },


        acceptSession() {

            const date = moment(this.Date).format('YYYY-MM-DD');
            const fromMeridiem =  this.$route.query.from.includes('مساءا') ? 'مساءا' : 'صباحا';
        const toMeridiem = this.$route.query.to.includes('مساءا') ? 'مساءا' : 'صباحا';

        let from = moment(this.$route.query.from, 'hh:mm A').format('HH:mm:ss');
        let to = moment(this.$route.query.to, 'hh:mm A').format('HH:mm:ss');

        if (fromMeridiem === 'مساءا') {
            if (moment(from, 'HH:mm:ss').format('HH') !== '12') {
                from = moment(from, 'HH:mm:ss').add(12, 'hours').format('HH:mm:ss');
            }
        } else if (moment(from, 'HH:mm:ss').format('HH') === '12') {
            from = moment(from, 'HH:mm:ss').subtract(12, 'hours').format('HH:mm:ss');
        }

        if (toMeridiem === 'مساءا') {
            if (moment(to, 'HH:mm:ss').format('HH') !== '12') {
                to = moment(to, 'HH:mm:ss').add(12, 'hours').format('HH:mm:ss');
            }
            if (moment(to, 'HH:mm:ss').isBefore(moment(from, 'HH:mm:ss'))) {
                // Increment 'to' by a day as it's in the evening of the next day
                to = moment(to, 'HH:mm:ss').add(1, 'day').format('HH:mm:ss');
            }
        } else if (moment(to, 'HH:mm:ss').format('HH') === '12') {
            to = moment(to, 'HH:mm:ss').subtract(12, 'hours').format('HH:mm:ss');
        }
        
            this.id = this.calendars.user_id;
            // const onsite = this.onsite === 0 ? 0 : 1;
            console.log(this.id, from, to, date,this.$route.params.id,toMeridiem);
            const url = `https://backend.ihayanow.com/api/user/accept-session?doctor_id=
            ${this.id}&from=${from}&to=${to}&date=${date}&calendar_id=${this.$route.params.id}
            &promocode=${this.promocode}&price_after_discount=${this.final_session_price}`;
            const token = localStorage.getItem('token');

            axios.post(url, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                },
            }).then(response => {

                this.messages = response.data.message;
                console.log(this.messages);
                axios.get('https://backend.ihayanow.com/api/get_reminders', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                })
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
                .catch(error => {
                    console.error(error);
                });
        },
        back() {
            this.requestSession();
            this.$router.push({ name: 'Calendar' })
        },
        translateErrorMessage(error) {
            return this.$i18n.t(error);
        },
        async updatePromocodes() {

            axios.post('https://backend.ihayanow.com/api/update_promocodes', {
                code: this.promocode,
                saved_amount: this.final_session_price == 0 ? 
                this.session_price : this.final_session_price - this.session_price,
                number_of_uses: 1
            },
                {
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                }).then(response => {
                    console.log(response);
                    if (response.data.message == "you can't use this promocode again cause you exceed allowed number of using this promocode") {
                        this.message1 = response.data.message;
                    } else {
                        this.message = response.data.message;
                    }


                }).catch(error => {
                    console.error(error);
                });
        }
    },
    computed: {

        inputDirection() {
            return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
        },
        highlightedDates() {
            console.log(this.calendars.map(calendar => moment(calendar.date).toDate()));
            return this.calendars.map(calendar => moment(calendar.date).toDate());
        },

    },

    components: {
        UserNavbar,
        Footer
    }





};
</script>
  

<style scoped>
.session-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .session-price h5 {
  margin-right: 10px;
} */
.h5 {
    padding-bottom: 20px;
}

.payment-methods {
    display: flex;
    justify-content: space-between;
    /* margin-top: 10px; */
}

.payment-methods div {
    cursor: pointer;
}

.payment-methods img {
    width: 100%;
    padding: 10px;
    /* height: auto; */
}

.mirror-button {
    direction: rtl;
}
</style>

