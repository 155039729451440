<template>
    <section class="has-bg-img py-0 ">
        <div class="container ">
            <div class="footer ">
                <div class="footer-lists ">
                    <ul class="list ">
                        <li class="list-head ">
                            <h6 class="font-weight-bold ">{{$t('ABOUT US')}}</h6>
                        </li>
                        <li class="list-body ">
                            <a href="# " class="logo ">
                                <img :src="require('/public/mental_logo.png')" alt="IHAYANOW Logo "  style="width:70px; height:70px;">
                                <h6>IHAYANOW</h6>
                            </a>
                            <p>
{{$t('description')}} 
                            </p>
                        </li>
                    </ul>
                    <ul class="list ">
                        <li class="list-head ">
                            <h6 class="font-weight-bold ">{{$t('USEFUL LINKS')}}</h6>
                        </li>
                        <li class="list-body ">
                            <div class="row ">
                                <div class="col ">
                                    <router-link to="/about">{{$t('About')}}</router-link>
<router-link to="/knowledgebase-page">{{$t('Knowledge')}}</router-link>
                                    <!-- <a href="#portfolio ">Portfolio</a>
                                    <a href="#mission ">Mission</a> -->
                                </div>
                                <!-- <div class="col ">
                                    <a href="#vision ">Vision</a>
                                    <a href="#team ">Team</a>

                                </div> -->
                            </div>
                        </li>
                    </ul>
                    <ul class="list ">
                        <li class="list-head ">
                            <h6 class="font-weight-bold ">{{$t('CONTACT INFO')}}</h6>
                        </li>
                        <li class="list-body ">
                            <p>- {{$t('Contact us and we will get back to you within 24 hours.')}}</p>
                            <p><i class="ti-location-pin "></i>
                               - {{$t('profile.address')}} : {{$t('Almadina Almonwara Street, AlFardous,6 of October City,Giza,Egypt')}}</p>

                            <p><i class="ti-email "></i> {{$t('profile.email_address')}} : info@ihayanow.com</p>
                            <div class="social-links ">
                                <a href="https://www.facebook.com/Qader-107181435594824" class="link ">
                                    <i class="pi pi-facebook "></i></a>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>




<script>
export default{
    name:'AppFooter',
}
</script>


<style scoped>
.footer{
    color: #fff;
    background-color: rgba(33, 37, 41, 0.95) !important;
    padding: 30px 0;
}
.footer-lists {
    padding:  0 85px  0 70px;
}

.footer .footer-lists {
    display: flex;
    justify-content: space-around;
   
}

.pi {
        font-size: 24px; /* Set the desired size in pixels */
    }

    /* Target the icon with the "pi-facebook" class */
    .pi-facebook {
        font-size: 24px; /* Set the desired size in pixels */
    }

@media (max-width: 991.98px){
    .footer .list {
    width: 80%;
}
}


.footer .list {
    list-style-type: none;
    padding-left: 0;
    width: calc(100% / 3);
    padding: 0 30px;
    padding-bottom: 0 !important;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}
.list {
    flex: 0 0 30%;
}

.footer .list-head {
    border-bottom: 1px solid rgba(222, 226, 230, 0.2) !important;
    padding-bottom: 6px;
    margin-bottom: 10px;
}
/* .list-head h6 {
    font-weight: bold; */
    .font-weight-bold {
    font-weight: 700 !important;
/* } */
}

.footer .list-body {
    padding-top: 6px;
}
.footer .list-body a {
    display: block;
    text-decoration: none;
    margin-bottom: 4px;
}


.footer .logo {
    padding-bottom: 6px;
}
.footer a {
    color: #eaf0fc;
    padding-bottom: 5px;
    opacity: 0.7;
}
.footer .list-body p {
    margin-bottom: 5px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}


</style>





























