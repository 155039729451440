<template>
    <div :class="containerClass" @click="onWrapperClick">
      <AppTopBar @menu-toggle="onMenuToggle" />
      <div class="layout-sidebar" @click="onSidebarClick">
        <!-- <AdminNavbar /> -->
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
      <div class="layout-main-container">
        <div class="layout-main">
          <div class="grid">
            <div class="col-12">
              <div class="card">
                <div>
                  <h2>Medications</h2>
                  <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
                      </PrimeMessage>
                      <PrimeMessage severity="error" v-if="this.errorMessage">{{  this.errorMessage }}
                      </PrimeMessage>
               
                      <DataTable :value="medications" 
                      class="p-datatable-gridlines" dataKey="id" :rows="10" 
                      :paginator="true">
                 
                        <PrimeColumn header="Medication Image" field="photo" style="min-width: 12rem">
                        <template #body="rowData">
                          <div class="img-container">
                            <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.photo"
                              alt="Medication Image" />
                          </div>
                        </template>
                      </PrimeColumn>
                        <PrimeColumn field="scientific_name" header="Scientific Name" style="min-width: 12rem">
                          {{ rowData.scientific_name }}
                        </PrimeColumn>
                        <PrimeColumn field="commercial_name" header="Commercial Name" style="min-width: 12rem">
                          {{ rowData.commercial_name }}
                        </PrimeColumn>
                        <PrimeColumn header="Active Ingredient" field="active_ingredient" style="min-width: 14rem">
  
                          {{ rowData.active_ingredient }}
  
                        </PrimeColumn>
                        <PrimeColumn field="category" header="Category" style="min-width: 12rem">
                          {{ rowData.category }}
                        </PrimeColumn>
                        <PrimeColumn header="Description" field="description" style="min-width: 14rem">
  
                          {{ rowData.description }}
  
                        </PrimeColumn>
  
                        <PrimeColumn header="Created At" field="created_at" style="min-width: 10rem">
                          <template #body="rowData">
  
                            {{ formatDate(rowData.data.created_at) }}
                          </template>
                        </PrimeColumn>
                        <PrimeColumn header="Updated At" field="updated_at" style="min-width: 10rem">
                          <template #body="rowData">
  
                            {{ formatDate(rowData.data.updated_at) }}
                          </template>
                        </PrimeColumn>
                       
                        <PrimeColumn :exportable="false" style="min-width:8rem">
                  <template #body="rowData">
                    <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2"
                      @click="showUpdateForm(rowData.data.id)" />
                    <PrimeButton icon="pi pi-trash" outlined rounded severity="danger"
                      @click="deleteMedication(rowData.data.id)" />
                  </template>
                </PrimeColumn>
  
  
                      </DataTable>

                  <div v-if="showUpdate">
                    <h3>Update Medication</h3>
                    <form @submit.prevent="submitUpdateForm">
                        <div class="field col-12 ">
                      <div class="avatar" :style="{ 'background-image': 'url(' + previewphoto + ')' }">
                        <InputText type="file" accept="image/*" hidden @change="uploadProfilePicture" id="avatar" />
                        <label for="avatar" class="avatar-label">Choose image</label>
                      </div>
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.photo"
                           v-for="(error, index) in errors.photo" :key="index">
                           {{ error }}
                        </InlineMessage>
                    </div>
                      <div class="grid p-fluid mt-3">
                     
  
                        <div class="field col-12 md:col-6">
                          <label>Scientific Name:</label>
                          <InputText type="text" v-model="updatedMedications.scientific_name" />
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.scientific_name"
                             v-for="(error, index) in errors.scientific_name" :key="index">
                             {{ error }}
                          </InlineMessage>
                        </div>
                        <div class="field col-12 md:col-6">
                          <label>Commercial Name:</label>
                          <InputText type="text" v-model="updatedMedications.commercial_name" />
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.commercial_name"
                             v-for="(error, index) in errors.commercial_name" :key="index">
                             {{ error }}
                          </InlineMessage>
                        </div>
  
                      </div>
                      <div class="grid p-fluid mt-3">
                      
                        <div class="field col-12 md:col-6">
                            <label>Active Ingredient:</label>
                          <InputText type="text" v-model="updatedMedications.active_ingredient" />
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.active_ingredient"
                             v-for="(error, index) in errors.active_ingredient" :key="index">
                             {{ error }}
                          </InlineMessage>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label>Category :</label>
                          <InputText type="text" v-model="updatedMedications.category" />
                          <InlineMessage class="w-full mt-3" v-show="errors && errors.category"
                             v-for="(error, index) in errors.category" :key="index">
                             {{ error }}
                          </InlineMessage>
                        </div>
                      </div>
                   
                      <div class="grid p-fluid mt-3">
                      
                      <div class="field col-12 md:col-6">
                          <label>Description :</label>
                        <InputText type="text" v-model="updatedMedications.description" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                           v-for="(error, index) in errors.description" :key="index">
                           {{ error }}
                        </InlineMessage>
                      </div>
                   
                    </div>
            

                      <div class="grid p-fluid mt-3">
                        <div class="field col-12 md:col-6">
                          <PrimeButton type="submit">Update</PrimeButton>
                        </div>
                        <div class="field col-12 md:col-6">
                          <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
  
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import AppTopBar from '@/AppTopbar.vue';
  import AppMenu from '@/AppMenu.vue';
  export default {
    data() {
      return {
        previewphoto:"",
        errors:{},
        medications: [],
      
        errorMessage: '',
        successMessage: '',
        showUpdate: false,
        updateId: null,
        updatedMedications: null,
        scientific_name: '',
        commercial_name: '',
        active_ingredient: '',
        category: '',
        description: '',
        photo: '',
      updatedphoto: '',
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
        layoutMode: 'static',
  
        staticMenuInactive: false,
        overlayMenuActive: false,
        mobileMenuActive: false,
        menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ]
  
      };
    },
    mounted() {
      this.fetchMedications();
    },
  
    methods: {
        
    uploadProfilePicture(event) {
      this.photo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.photo);
      reader.onload = () => {
        this.previewphoto = reader.result;
      }
    },
      async logout() {
        await axios.post('https://backend.ihayanow.com/api/logout', null, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
          }
        })
          .then(response => {
            console.log(response);
            localStorage.clear(); // Clear all items in local storage
            this.$router.push('/login-page');
          })
          .catch(error => {
            console.log(error);
          });
      },
  
      async fetchMedications() {
        try {
       await axios.get('https://backend.ihayanow.com/api/admin/medicines', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            },
          }).then(response=>{
            console.log(response);

          this.medications = response.data.Medications;
       
          this.updatedMedications = this.medications;
          })
  
         
    
        } catch (error) {
          console.log('Error fetching Medications:', error);
        }
      },
  
  
      showUpdateForm(id) {
        console.log(id);
        this.showUpdate = true
        this.updateId = id
        this.updatedMedications =
          this.medications.find(medication => medication.id === id);
  
        console.log(this.updatedMedications);
      },
  
  
      hideUpdateForm() {
  
        this.showUpdate = false;
        this.updateId = null;
  
      },
      formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      },
  
      async submitUpdateForm() {
        await axios.post('https://backend.ihayanow.com/api/admin/medicines_update', {
          id:this.updatedMedications.id,
          scientific_name: this.updatedMedications.scientific_name,
          commercial_name: this.updatedMedications.commercial_name,
          active_ingredient: this.updatedMedications.active_ingredient,
          category: this.updatedMedications.category,
          description: this.updatedMedications.description,
          photo: this.photo,

        }, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
          }
        })
          .then(response => {
            this.successMessage = 'Medications updated successfully.';
            this.fetchMedications();
            this.hideUpdateForm();
            console.log(response);
          })
          .catch(error => {
            console.log(error);
            this.errors = error.response.data.errors;
  
          });
      },
  
  
      async deleteMedication(medicationId) {
        try {
          const response = await fetch(`https://backend.ihayanow.com/api/admin/medicines/${medicationId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
            },
          });
  
          if (response.ok) {
            this.errorMessage = 'Medication deleted successfully.';
            this.showUpdateForm = false;
            this.fetchMedications();
          } else {
            console.log(response)
            this.errorMessage = 'Failed to delete medication.';
          }
        } catch (error) {
          console.error('Error deleting medication:', error);
          this.errorMessage = 'Failed to delete medication.';
        }
      },
  
      onWrapperClick() {
        if (!this.menuClick) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
  
        this.menuClick = false;
      },
      onMenuToggle() {
        this.menuClick = true;
  
        if (this.isDesktop()) {
          if (this.layoutMode === 'overlay') {
            if (this.mobileMenuActive === true) {
              this.overlayMenuActive = true;
            }
  
            this.overlayMenuActive = !this.overlayMenuActive;
            this.mobileMenuActive = false;
          }
          else if (this.layoutMode === 'static') {
            this.staticMenuInactive = !this.staticMenuInactive;
          }
        }
        else {
          this.mobileMenuActive = !this.mobileMenuActive;
        }
  
        event.preventDefault();
      },
      onSidebarClick() {
        this.menuClick = true;
      },
      onMenuItemClick(event) {
        if (event.item && !event.item.items) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }
      },
      onLayoutChange(layoutMode) {
        this.layoutMode = layoutMode;
      },
      addClass(element, className) {
        if (element.classList)
          element.classList.add(className);
        else
          element.className += ' ' + className;
      },
      removeClass(element, className) {
        if (element.classList)
          element.classList.remove(className);
        else
          element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },
      isDesktop() {
        return window.innerWidth >= 992;
      },
      isSidebarVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === 'static')
            return !this.staticMenuInactive;
          else if (this.layoutMode === 'overlay')
            return this.overlayMenuActive;
        }
  
        return true;
      }
    },  
    beforeUpdate() {
      if (this.mobileMenuActive)
        this.addClass(document.body, 'body-overflow-hidden');
      else
        this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
      'AppTopBar': AppTopBar,
      'AppMenu': AppMenu,
    },
  
    computed: {
  
      containerClass() {
        return ['layout-wrapper', {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }];
      },
    }
  
  }
  
  </script>
  
  <style scoped>

.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
}

.avatar-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.avatar-upload {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

  .doctor-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

  .body-preview {
    max-height: 80px;
    /* Adjust the height as per your requirements */
    overflow: hidden;
  }
  
  .body-preview p {
    margin-bottom: 5px;
  }
  
  .body-preview button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
  }
  
  

.layout-topbar {
   
   background-color: var(--surface-card);
   }

  </style>