<template>
  <div class="container">
    <component :is="navbarComponent" />
    <div class="layout-main-container">
      <div class="grid mt-5 px-5">


        <div class="col-12">
          <div class="search-container">
            <div class="p-inputgroup pb-5" :dir="searchButtonDirection">
              <PrimeButton type="button" @click.prevent="search()" :label="$t('Search')" />
              <InputText style="border-radius:5px; " :dir="inputDirection" v-model="keyword"
                :placeholder="$t('profile.SearchKeyword')" />
            </div>
          </div>
        </div>

        <div class="col-12 card d-flex flex-wrap">
          <div v-if="searchResults.length === 0">
            <div v-for="(category, index) in categories" :key="index">
              <h3>{{ category.category_name.name }}</h3>
           
              <PrimeCarousel :value="category.knowledgebases" :numVisible="3" :numScroll="3"
              :responsiveOptions="carouselResponsiveOptions" 
                :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
             
                <template #item="knowledgebase">
                <div class="card col-md-4 mx-3" >
                   <div class="knowledgebase-image text-center">
                      <img class="col-12 " :src="'https://backend.ihayanow.com/' + knowledgebase.data.photo"
                        alt="Knowledge Image" />
                      
                    </div>
                      <div class="col-12 text-center">
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Title') }} : {{ knowledgebase.data.title }}</h6>
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Type') }} : {{ $t(knowledgebase.data.type) }}</h6>
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Author') }} : {{ knowledgebase.data.author }}</h6>
                        <PrimeButton type="button" @click.prevent="KnowMore(knowledgebase.data, category)"
                          class="custom-button p-button p-button-rounded">{{ $t('Know More') }}
                        </PrimeButton>


                      </div>
                 
                  </div>

             <div class="card flex justify-content-center" v-if="visible">
             
              <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal header="Header"
                :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <template #header>
                  <div class="inline-flex align-items-center justify-content-center gap-2">
                    <Avatar :image="'https://backend.ihayanow.com/' + selectedKnowledgebase.photo" shape="circle" />
                    <span class="font-bold white-space-nowrap"> {{ $t('Title') }} : {{ selectedKnowledgebase.title }}</span>
                  </div>
                </template>


                <div v-if="selectedKnowledgebase.type == 'Article' || selectedKnowledgebase.type == 'Tip'">
                  <p><span class="font-bold">{{ $t('Description') }}  </span> : {{ selectedKnowledgebase.body }}</p>
                  <h6><span class="font-bold">{{ $t('Written by') }}  </span> : {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type === 'Youtube'">

                  <div class="youtube-player">
                    <iframe width="560" height="315" :src="embedURL" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <h6><span class="font-bold">{{ $t('Filmed by') }} : </span>{{ selectedKnowledgebase.author }}</h6>
                </div>

                <div v-if="selectedKnowledgebase.type === 'Movie'">
                  {{ $t('Movie Link') }} :
                  <a :href="selectedKnowledgebase.link" target="_blank">{{ $t('IMDb Link')}}</a>
                  <h6><span class="font-bold">{{ $t('Filmed by') }} : </span> {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type == 'Link' || selectedKnowledgebase.type == 'Reference'">
                  <a :href="selectedKnowledgebase.type === 'Link' ? selectedKnowledgebase.link : selectedKnowledgebase.reference"
                    target="_blank">{{$t('goToLink')}} {{ selectedKnowledgebase.type }}</a>
                  <h6><span class="font-bold">{{ $t('Added by') }} : </span> {{ selectedKnowledgebase.author }}</h6>
                </div>


              </PrimeDialog>
            </div>
       



                </template>
              </PrimeCarousel>
            </div>






            <div class="card flex justify-content-center" v-if="visible">
              {{ selectedKnowledgebase }}
              <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal header="Header"
                :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <template #header>
                  <div class="inline-flex align-items-center justify-content-center gap-2">
                    <Avatar :image="'https://backend.ihayanow.com/' + selectedKnowledgebase.photo" shape="circle" />
                    <span class="font-bold white-space-nowrap"> {{ selectedKnowledgebase.title }}</span>
                  </div>
                </template>


                <div v-if="selectedKnowledgebase.type == 'Article' || selectedKnowledgebase.type == 'Tip'">
                  <p>{{ $t('Description') }} : {{ selectedKnowledgebase.body }}</p>
                  <h6>{{ $t('Written by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type === 'Youtube'">

                  <div class="youtube-player">
                    <iframe width="560" height="315" :src="embedURL" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <h6>{{ $t('Filmed by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>

                <div v-if="selectedKnowledgebase.type === 'Movie'">
                  {{ $t('Movie Link') }} :
                  <a :href="selectedKnowledgebase.link" target="_blank">{{ $t('IMDb Link')}}</a>
                  <h6>{{ $t('Filmed by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type == 'Link' || selectedKnowledgebase.type == 'Reference'">
                  <a :href="selectedKnowledgebase.type === 'Link' ? selectedKnowledgebase.link : selectedKnowledgebase.reference"
                    target="_blank">{{$t('goToLink')}} {{ selectedKnowledgebase.type }}</a>
                  <h6>{{ $t('Added by') }}: {{ selectedKnowledgebase.author }}</h6>
                </div>


              </PrimeDialog>
            </div>
          </div>
          <div v-else-if="searchResults.length > 0">
           
              <PrimeCarousel :value="searchResults" :numVisible="3" :numScroll="3"
              :responsiveOptions="carouselResponsiveOptions" 
                :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
             
                <template #item="knowledgebase">
                <div class="card col-md-4 mx-3" >
                   <div class="knowledgebase-image text-center">
                      <img class="col-12 " :src="'https://backend.ihayanow.com/' + knowledgebase.data.photo"
                        alt="Knowledge Image" />
                      </div>
                      <div class="col-12 text-center">
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Title') }} : {{ knowledgebase.data.title }}</h6>
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Type') }} : {{ $t(knowledgebase.data.type) }}</h6>
                        <h6 :style="{ flexDirection: selectedLanguage === 'ar' ? 'row-reverse' : 'row' }">{{ $t('Author') }} : {{ knowledgebase.data.author }}</h6>
                        <PrimeButton type="button" @click.prevent="KnowMore(knowledgebase.data, category)"
                          class="custom-button p-button p-button-rounded">{{ $t('Know More') }}
                        </PrimeButton>


                      </div>
                 
                  </div>

             <div class="card flex justify-content-center" v-if="visible">
             
              <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal header="Header"
                :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <template #header>
                  <div class="inline-flex align-items-center justify-content-center gap-2">
                    <Avatar :image="'https://backend.ihayanow.com/' + selectedKnowledgebase.photo" shape="circle" />
                    <span class="font-bold white-space-nowrap"> {{ $t('Title') }} : {{ selectedKnowledgebase.title }}</span>
                  </div>
                </template>


                <div v-if="selectedKnowledgebase.type == 'Article' || selectedKnowledgebase.type == 'Tip'">
                  <p><span class="font-bold">{{ $t('Description') }} : </span> {{ selectedKnowledgebase.body }}</p>
                  <h6><span class="font-bold">{{ $t('Written by') }} : </span> {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type === 'Youtube'">

                  <div class="youtube-player">
                    <iframe width="560" height="315" :src="embedURL" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <h6><span class="font-bold">{{ $t('Filmed by') }} : </span>{{ selectedKnowledgebase.author }}</h6>
                </div>

                <div v-if="selectedKnowledgebase.type === 'Movie'">
                  {{ $t('Movie Link') }} :
                  <a :href="selectedKnowledgebase.link" target="_blank">{{ $t('IMDb Link')}}</a>
                  <h6><span class="font-bold">{{ $t('Filmed by') }} : </span> {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type == 'Link' || selectedKnowledgebase.type == 'Reference'">
                  <a :href="selectedKnowledgebase.type === 'Link' ? selectedKnowledgebase.link : selectedKnowledgebase.reference"
                    target="_blank">{{$t('goToLink')}} {{ selectedKnowledgebase.type }}</a>
                  <h6><span class="font-bold">{{ $t('Added by') }} : </span> {{ selectedKnowledgebase.author }}</h6>
                </div>


              </PrimeDialog>
            </div>
       



                </template>
              </PrimeCarousel>
      






            <div class="card flex justify-content-center" v-if="visible">
              {{ selectedKnowledgebase }}
              <PrimeDialog :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" v-model:visible="visible" modal header="Header"
                :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <template #header>
                  <div class="inline-flex align-items-center justify-content-center gap-2">
                    <Avatar :image="'https://backend.ihayanow.com/' + selectedKnowledgebase.photo" shape="circle" />
                    <span class="font-bold white-space-nowrap"> {{ selectedKnowledgebase.title }}</span>
                  </div>
                </template>


                <div v-if="selectedKnowledgebase.type == 'Article' || selectedKnowledgebase.type == 'Tip'">
                  <p>{{ $t('Description') }} : {{ selectedKnowledgebase.body }}</p>
                  <h6>{{ $t('Written by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type === 'Youtube'">

                  <div class="youtube-player">
                    <iframe width="560" height="315" :src="embedURL" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <h6>{{ $t('Filmed by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>

                <div v-if="selectedKnowledgebase.type === 'Movie'">
                  {{ $t('Movie Link') }} :
                  <a :href="selectedKnowledgebase.link" target="_blank">{{ $t('IMDb Link')}}</a>
                  <h6>{{ $t('Filmed by') }} : {{ selectedKnowledgebase.author }}</h6>
                </div>
                <div v-if="selectedKnowledgebase.type == 'Link' || selectedKnowledgebase.type == 'Reference'">
                  <a :href="selectedKnowledgebase.type === 'Link' ? selectedKnowledgebase.link : selectedKnowledgebase.reference"
                    target="_blank">{{$t('goToLink')}} {{ selectedKnowledgebase.type }}</a>
                  <h6>{{ $t('Added by') }}: {{ selectedKnowledgebase.author }}</h6>
                </div>


              </PrimeDialog>
            </div>
          </div>
        </div>


      </div>





    </div>

    <Footer />
  </div>
</template>



<script>
import axios from 'axios';
import UserNavbar from './User/UserNavbar.vue';

import DoctorNavbar from './Doctor/DoctorNavbar.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';
import { ref } from "vue";


export default {
  name:'AppKnowledgebase',
  data() {
    return {
      visible: ref(false),
      searchResults: [], // New data property to hold search results
      keyword: '',
      knowledgebases: [],
      navbarComponent: null,
      categories_names: [], // Add categories_names in data()
      selectedSearchedKnowledgebase: null,
      selectedKnowledgebase: null,
      selectedCategory: [],
      youtubePlayer: null, // Variable to store the YouTube player instance
      youtubePlayerReady: false, // Flag to check if the YouTube player is ready
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      categories: [], // Initialize with empty array
      selectedCategoryIndex: -1
    };
  },

  methods: {

    extractVideoIdFromLink(youtubeLink) {
      // Check if the link contains a time parameter
      const timeParamIndex = youtubeLink.indexOf("&t=");
      if (timeParamIndex !== -1) {
        // Remove the time parameter from the link
        youtubeLink = youtubeLink.substring(0, timeParamIndex);
      }

      // Extract the video ID from the modified link
      const videoId = youtubeLink.split("v=")[1];
      return videoId;
    },
    async search() {

      await axios.post("https://backend.ihayanow.com/api/knowledgebase-search",
        { keyword: this.keyword },
        {
          headers: {
            'Content-Type': 'application/json',

            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          }
        }

      ).then(response => {
        this.searchResults = response.data;
        console.log(this.searchResults);

      })
        .catch(error => {
          console.log(error)
        })

    },


    getKnowledgeBase() {
      axios.get('https://backend.ihayanow.com/api/knowledgebase', {
        headers: {
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      })
        .then(response => {
          this.categories= response.data;
          console.log(this.categories);
        })
        .catch(error => {
          console.log(error);
        });
    },

   
    KnowMore(knowledgebase, category) {
      this.visible = true;
      this.selectedKnowledgebase = knowledgebase;
      this.selectedCategory = category;
      console.log(this.selectedKnowledgebase, this.selectedCategory);
    },
    Know(knowledgebase) {
      this.selectedSearchedKnowledgebase = knowledgebase;


    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },

    navigateToLink(link) {
      this.$router.push(link);
    },

  },

  components: {
    UserNavbar,
    DoctorNavbar,
    Footer,
    Navbar



  },

  mounted() {

    this.getKnowledgeBase();
    const userType = localStorage.getItem("type");
    if (userType === "User") {
      this.navbarComponent = UserNavbar;
    } else if (userType === "Doctor") {
      this.navbarComponent = DoctorNavbar;
    } else {

      this.navbarComponent = Navbar;
    }

  },
  computed: {

    searchButtonDirection() {
      return this.$i18n.locale === 'ar' ? 'ltr' : 'auto';
    },
    inputDirection() {
      return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
    embedURL() {
      if (
        this.selectedKnowledgebase &&
        this.selectedKnowledgebase.type === "Youtube"
      ) {
        const youtubeLink = this.selectedKnowledgebase.link;
        const videoId = this.extractVideoIdFromLink(youtubeLink);
        return `https://www.youtube.com/embed/${videoId}`;
      }
      return "";
    },

  },


}


</script>
<style scoped>

.knowledgebase-image {
 
  width: 200px;
    height: 200px;
  overflow: hidden;
  margin: 5px;
  display: flex;
    justify-content: center;
    align-items: center;
}

.knowledgebase-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.custom-button {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.custom-button .p-button {
  width: 25% !important;
}

.youtube-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
