<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <!-- <AdminNavbar /> -->
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div>
                                <h2>All Packages</h2>
                                <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
                                </PrimeMessage>
                                <PrimeMessage severity="error" v-if="this.errorMessage">{{ this.errorMessage }}
                                </PrimeMessage>

                                <DataTable :value="packages" :paginator="true" class="p-datatable-gridlines" :rows="10"
                                    dataKey="id" :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                                    <template #empty>
                                        <div v-if="packages.length == 0">No Packages</div>
                                    </template>
                                    <template #loading>
                                        Loading Packages data. Please wait.
                                    </template>

                                    <PrimeColumn field="name" header="Package Name" style="min-width: 12rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.name }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn field="level_name" header="Level Name" style="min-width: 12rem">
                                        {{ rowData.level_name }}
                                    </PrimeColumn>
                                    <PrimeColumn header="Package Price" field="price" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.price + ' EGP' }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn header="Test Discount" field="test_discount" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.test_discount + ' %' }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn header="Online Discount" field="online_discount" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.online_discount + ' %' }}
                                        </template>
                                    </PrimeColumn>

                                    <PrimeColumn header="Onsite Discount" field="onsite_discount" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.onsite_discount + ' %' }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn header="Package Duration" field="duration" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.duration }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn header="For All Doctors?" field="all_doctors" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.all_doctors == 1 ? "Yes" : "No" }}
                                        </template>
                                    </PrimeColumn>

                                    <PrimeColumn header="Package Description" field="description" style="min-width: 14rem">
                                        <template #body="rowData">
                                            {{ rowData.data.subscriptions.description }}
                                        </template>
                                    </PrimeColumn>

                                    <PrimeColumn header="Created At" field="created_at" style="min-width: 10rem">
                                        <template #body="rowData">

                                            {{ formatDate(rowData.data.subscriptions.created_at) }}
                                        </template>
                                    </PrimeColumn>
                                    <PrimeColumn header="Updated At" field="updated_at" style="min-width: 10rem">
                                        <template #body="rowData">

                                            {{ formatDate(rowData.data.subscriptions.updated_at) }}
                                        </template>
                                    </PrimeColumn>


                                    <PrimeColumn :exportable="false" style="min-width:8rem">
                                        <template #body="rowData">
                                            <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2"
                                                @click="showUpdateForm(rowData.data.subscriptions.id)" />
                                            <PrimeButton icon="pi pi-trash" outlined rounded severity="danger"
                                                @click="deletePackage(rowData.data.subscriptions.id)" />
                                        </template>
                                    </PrimeColumn>


                                </DataTable>
                                <div v-if="showUpdate">
                                    <h3>Update Levels</h3>
                                    <form @submit.prevent="submitUpdateForm">
                                        <div class="grid p-fluid mt-3">

                                            <div class="field col-12 md:col-6">
                                                <label for="name" style="font-weight: bold;">Package Name</label>
                                                <InputText id="name" type="text"
                                                    v-model="updatedPackages.subscriptions.name" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.name"
                                                    v-for="(error, index) in errors.name" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="level_name" style="font-weight: bold;">Level Name</label>
                                                <PrimeDropdown id="state" v-model="updatedPackages.subscriptions.level_id"
                                                    :options="level_ids" optionLabel="name" placeholder="Select One">
                                                </PrimeDropdown>
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.level_id"
                                                    v-for="(error, index) in errors.level_id" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="user_type" style="font-weight: bold;">For Which Type</label>
                                                <PrimeDropdown id="user_type" class="w-full mb-3"
                                                    v-model="updatedPackages.subscriptions.user_type" :options="types"
                                                    optionLabel="name" placeholder="Select One"
                                                    style="  border: 2px; border-radius: 4px;">
                                                </PrimeDropdown>
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.user_type"
                                                    v-for="(error, index) in errors.user_type" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="duration" style="font-weight: bold;">Package Duration</label>
                                                <PrimeDropdown id="duration" class="w-full mb-3"
                                                    v-model="updatedPackages.subscriptions.duration" :options="durations"
                                                    optionLabel="name" placeholder="Select One"
                                                    style="  border: 2px; border-radius: 4px;">
                                                </PrimeDropdown>
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.duration"
                                                    v-for="(error, index) in errors.duration" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="test_discount" style="font-weight: bold;">Package Test
                                                    Discount</label>
                                                <InputText id="test_discount" type="text"
                                                    v-model="updatedPackages.subscriptions.test_discount" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.test_discount"
                                                    v-for="(error, index) in errors.test_discount" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="onsite_discount" style="font-weight: bold;">Package Onsite
                                                    Discount</label>
                                                <InputText id="onsite_discount" type="text"
                                                    v-model="updatedPackages.subscriptions.onsite_discount" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.onsite_discount"
                                                    v-for="(error, index) in errors.onsite_discount" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="online_discount" style="font-weight: bold;">Package Online
                                                    Discount</label>
                                                <InputText id="online_discount" type="text"
                                                    v-model="updatedPackages.subscriptions.online_discount" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.online_discount"
                                                    v-for="(error, index) in errors.online_discount" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <label for="price" style="font-weight: bold;">Package Price</label>
                                                <InputText id="price" type="number"
                                                    v-model="updatedPackages.subscriptions.price" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.price"
                                                    v-for="(error, index) in errors.price" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 md:col-6"
                                                v-show="updatedPackages.subscriptions.user_type.name == 'Doctor'">
                                                <label for="all_doctors" style="font-weight: bold;">For All Doctors?</label>
                                                <PrimeDropdown id="all_doctors" class="w-full mb-3"
                                                    v-model="updatedPackages.subscriptions.all_doctors"
                                                    :options="all_doctorss" optionLabel="name" placeholder="Select One"
                                                    style="  border: 2px; border-radius: 4px;">
                                                </PrimeDropdown>
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.all_doctors"
                                                    v-for="(error, index) in errors.all_doctors" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                            <div class="field col-12 ">
                                                <label for="description" style="font-weight: bold;">Package
                                                    Description</label>
                                                <PrimeTextarea :placeholder="$t('Your Description')" :autoResize="true"
                                                    rows="9" cols="70"
                                                    v-model="updatedPackages.subscriptions.description" />
                                                <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                                                    v-for="(error, index) in errors.description" :key="index">
                                                    {{ error }}
                                                </InlineMessage>
                                            </div>
                                        </div>


                                        <div class="grid p-fluid mt-3">
                                            <div class="field col-12 md:col-6">
                                                <PrimeButton type="submit">Update</PrimeButton>
                                            </div>
                                            <div class="field col-12 md:col-6">
                                                <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
    name: 'AppSubscriptions',
    data() {
        return {
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            errors: {},
            level_ids: [],
            name: '',
            user_type: '',
            status: '',
            description: '',
            duration: '',
            type: '',
            test_discount: '',
            onsite_discount: '',
            online_discount: '',
            price: '',
            all_doctorss: [
                { name: 'Yes' },
                { name: 'No' },

            ],
            types: [
                { name: 'Doctor' },
                { name: 'User' },

            ],
            durations: [
                { name: 'Yearly' },
                { name: 'Monthly' },
                { name: 'Weekly' },
            ],
            packages: [],
            all_doctors: '',
            new_all_doctors: '',
            new_level_id: '',
            new_user_type: '',
            new_duration: '',
            errorMessage: '',
            successMessage: '',
            showUpdate: false,
            updateId: null,
            updatedPackages: null,

            layoutMode: 'static',

            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
                {
                    label: 'Home', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
                        // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

                    ]

                },
                {
                    label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
                    items: [
                        { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
                        { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

                    ]
                },
                {
                    label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
                    items: [

                        { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
                        { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
                        { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

                        { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
                    ]
                },
                {
                    label: 'Medication', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
                        { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
                    ]
                },
                {
                    label: 'Doctors', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
                        // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

                    ]
                },
                {
                    label: ' Patient Label', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Add Patient Label', icon: 'pi pi-fw pi-list', to: "/add-label-patient" },
                        { label: 'Show Patient Label', icon: 'pi pi-fw pi-list', to: "label-patient" }
                    ]
                },
                {
                    label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
                        { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
                        { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
                        { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
                    ]
                },
                {
                    label: 'Sessions', icon: 'pi pi-fw pi-clock',
                    items: [
                        { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


                    ]
                },
                {
                    label: 'Reports', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


                    ]
                },
                {
                    label: '', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
                    ]

                },
                {
                    label: '',
                    items: [
                        { label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
                    ]
                },
                {
                    label: '',
                    items: [
                        { label: 'Logout', icon: 'pi pi-power-off', command: this.logout }
                    ]

                },
            ]

        };
    },
    mounted() {
        this.fetchPackages();

        axios.get('https://backend.ihayanow.com/api/admin/doctors-levels', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret,
            },
        })
            .then(response => {
                console.log(response.data);
                this.level_ids = response.data.levels.map((level) => ({
                    name: level.category_name,
                    // Map the category_name to objects with 'name' property
                    id: level.id
                }));
                this.message = response.data.message;

                console.log(this.level_ids)
            })
            .catch(error => {
                console.log(error);
                console.log(error.response.data.message);
                this.errors = error.response.data.message;

            });

    },

    methods: {

        async logout() {
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret,
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear(); // Clear all items in local storage
                    this.$router.push('/login-page');
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async fetchPackages() {
            try {
                const response = await fetch('https://backend.ihayanow.com/api/admin/subscriptions', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret,
                    },
                });

                const data = await response.json();
                this.packages = data.packages;


                this.updatedPackages = this.packages;
                console.log(this.packages);

            } catch (error) {
                console.log('Error fetching Packages:', error);
            }
        },
        showUpdateForm(id) {
            console.log(id);
            this.showUpdate = true
            this.updateId = id
            this.updatedPackages =
                this.packages.find(package_sub => package_sub.subscriptions.id === id);

            console.log(this.updatedPackages);
        },
        hideUpdateForm() {
            this.showUpdate = false;
            this.updateId = null;
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        },

        async submitUpdateForm() {
            if (this.updatedPackages.subscriptions.level_id == this.updatedPackages.subscriptions.level_id.id
                || typeof this.updatedPackages.subscriptions.level_id == 'number') {

                this.new_level_id = this.updatedPackages.subscriptions.level_id;

            }
            // el case lw ekhtart
            else if (this.updatedPackages.subscriptions.level_id !== this.updatedPackages.subscriptions.level_id.id
                || typeof this.updatedPackages.subscriptions.level_id == 'object') {
                this.new_level_id = this.updatedPackages.subscriptions.level_id.id;
            } else {
                this.new_level_id = this.updatedPackages.subscriptions.level_id;
            }
            if (this.updatedPackages.subscriptions.duration.name == "Weekly" || this.updatedPackages.subscriptions.duration.name == "Monthly" ||
                this.updatedPackages.subscriptions.duration.name == "Yearly") {
                this.new_duration = this.updatedPackages.subscriptions.duration.name;
            } else {
                this.new_duration = this.updatedPackages.subscriptions.duration;
            }
            if (this.updatedPackages.subscriptions.user_type.name == "Doctor" ||
                this.updatedPackages.subscriptions.user_type.name == "User") {
                this.new_user_type = this.updatedPackages.subscriptions.user_type.name;
            } else {
                this.new_user_type = this.updatedPackages.subscriptions.user_type;
            }

            if (this.updatedPackages.subscriptions.all_doctors.name == "Yes" ||
                this.updatedPackages.subscriptions.all_doctors.name == "No") {
                this.new_all_doctors = this.updatedPackages.subscriptions.all_doctors.name;
                if (this.new_all_doctors == "Yes") {
                    this.new_all_doctors = 1;
                } else {
                    this.new_all_doctors = 0;
                }

            } else {
                this.new_all_doctors = this.updatedPackages.subscriptions.all_doctors;
            }

            console.log(this.updatedPackages.subscriptions.id,
                this.updatedPackages.subscriptions.new_level_id,
                this.updatedPackages.subscriptions.name,
                this.updatedPackages.subscriptions.description,
                this.new_all_doctors, this.updatedPackages.subscriptions.price,
                this.updatedPackages.subscriptions.onsite_discount,
                this.updatedPackages.subscriptions.online_discount,
                this.updatedPackages.subscriptions.test_discount,
                this.updatedPackages.subscriptions.new_duration,
                this.updatedPackages.subscriptions.new_user_type)

            await axios.post('https://backend.ihayanow.com/api/admin/subscriptions_update', {
                id: this.updatedPackages.subscriptions.id,
                level_id: this.new_level_id,
                name: this.updatedPackages.subscriptions.name,
                description: this.updatedPackages.subscriptions.description,
                all_doctors: this.new_all_doctors,
                price: this.updatedPackages.subscriptions.price,
                onsite_discount: this.updatedPackages.subscriptions.onsite_discount,
                online_discount: this.updatedPackages.subscriptions.online_discount,
                test_discount: this.updatedPackages.subscriptions.test_discount,
                duration: this.new_duration,
                user_type: this.new_user_type,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret,
                }
            })
                .then(response => {
                    this.successMessage = 'Level updated successfully.';
                    this.fetchPackages();
                    this.hideUpdateForm();
                    console.log(response);
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                    console.log(error);

                });
        },


        async deletePackage(packageId) {
            try {
                const response = await fetch(`https://backend.ihayanow.com/api/admin/subscriptions/${packageId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret,
                    },
                });
                if (response.ok) {
                    this.errorMessage = 'Package deleted successfully.';
                    this.showUpdateForm = false;
                    this.fetchPackages();
                } else {
                    console.log(response)
                    this.errorMessage = 'Failed to delete package.';
                }
            } catch (error) {
                console.error('Error deleting package:', error);
                this.errorMessage = 'Failed to delete package.';
            }
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
    },

    computed: {

        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
    }

}

</script>
  
<style scoped>
.avatar {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #eee;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-position: center;
}

.avatar-label {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    color: #555;
    cursor: pointer;
}

.avatar-upload {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.doctor-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.body-preview {
    max-height: 80px;
    /* Adjust the height as per your requirements */
    overflow: hidden;
}

.body-preview p {
    margin-bottom: 5px;
}

.body-preview button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
}


.layout-topbar {

    background-color: var(--surface-card);
}
</style>