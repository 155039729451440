<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <h5>All users and doctors in the system</h5>
              <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
              </PrimeMessage>

              <TabView>
                <TabPanel header="All Users">
                  <DataTable :value="users" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" responsiveLayout="scroll">

                    <template #empty>
                      <div v-if="users.length == 0">No Users</div>
                    </template>
                    <template #loading>
                      Loading Users data. Please wait.
                    </template>
                    <PrimeColumn field="photo" header="User Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        <div class="img-container">
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.totalUsers.photo"
                            alt="User Photo" />

                        </div>
                      </template>
                    </PrimeColumn>
                    {{ rowData }}
                    <PrimeColumn field="user_name" header="User Name" style="min-width: 12rem">
                      {{ rowData.data.user_name }}
                    </PrimeColumn>

                    <PrimeColumn field="birth_date" header="Birthdate" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.birth_date }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="age" header="Age" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.age + " Years" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="language" header="Language" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.language }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn field="weight" header="Weight" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.weight + " Kg" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="height" header="Height" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.height + " Cm" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="rating" header="Rate" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.rating }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="smoking" header="Smoker" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalUsers.smoking == 0 ? "No" : "Yes" }}
                      </template>
                    </PrimeColumn>

                  </DataTable>

                </TabPanel>
                <TabPanel header="All Doctors">
                  <DataTable :value="doctors" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" responsiveLayout="scroll">

                    <template #empty>
                      <div v-if="doctors.length == 0">No Doctors</div>
                    </template>
                    <template #loading>
                      Loading Doctors data. Please wait.
                    </template>
                    <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        <div class="img-container">
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.totalDoctors.photo"
                            alt="Doctor Photo" />


                        </div>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="doctor_name" header="Doctor Name" style="min-width: 12rem">
                      {{ rowData.doctor_name }}
                    </PrimeColumn>

                    <PrimeColumn field="speciality" header="Speciality" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.speciality }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Professional" field="professional" style="min-width: 10rem">
                      <template #body="rowData">

                      {{ rowData.data.totalDoctors.professional }}
                      </template>
                    </PrimeColumn>
                  

                    <PrimeColumn header="ID Copy" field="IDCopy" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.totalDoctors.IDCopy" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Liscence" field="liscence" style="min-width: 10rem">
                      <template #body="rowData">
                        <a :href="'https://backend.ihayanow.com' + rowData.data.totalDoctors.liscence" target="_blank">Preview
                          file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="birth_date" header="Birthdate" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalDoctors.birth_date }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Age" field="age" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.age + " Years" }}
</template>
                    </PrimeColumn>
                    <PrimeColumn field="language" header="Language" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalDoctors.language }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalDoctors.address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="gender" header="Gender" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.gender }}
                      </template>
                    </PrimeColumn>


                    <PrimeColumn header="Session Duration" field="session_duration" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn header="Session Type" field="session_type" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.session_type }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Onsite Price Per Hour" field="onsite_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.onsite_price + " EG " + " / " +  rowData.data.totalDoctors.session_duration  + " Minutes"}}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Online Price Per Hour" field="online_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.online_price  + " EG " + " / " +  rowData.data.totalDoctors.session_duration  + " Minutes"}}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="ID Number" field="IDNumber" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.totalDoctors.IDNumber }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn field="rating" header="Rate" style="min-width: 12rem">
                      <template #body="rowData">
                        {{ rowData.data.totalDoctors.rating }}
                      </template>
                    </PrimeColumn>


                  </DataTable>

                </TabPanel>


              </TabView>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
// import AdminNavbar from './AdminNavbar.vue';
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      
      success: false,
      doctor_name: '',
      doctors: [],
      users: [],
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
            { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
          items: [

            { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
            { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },
          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


          ]
        },
        {
          label: '', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
          ]
        },
        { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      ],
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    };
  },

  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },


    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }

  },


  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,

  },

  async created() {
    const response = await
      axios.get('https://backend.ihayanow.com/api/admin/all-users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      });
    // const data = await response.();
    console.log(response);
    this.doctors = response.data.Doctors;
    this.users = response.data.Users;
    console.log(this.doctors);
    console.log(this.users);

  },

  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }
}


</script>
    
<style scoped>


.layout-topbar {
   
   background-color: var(--surface-card);
   }

.doctor-photo {
  width: 150px;
  /* Adjust the width as per your requirements */
  height: 150px;
  /* Adjust the height as per your requirements */
  object-fit: cover;
  /* Ensure the image covers the entire space */
}</style>