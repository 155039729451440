
<template>
    <div :key="selectedLanguage" class="container">
        <component :is="navbarComponent" />



        <header class="header">
            <div class="overlay">
                <div class="row">
                    <div class=" md:col-12">
                        <img src="mental_logo.png" style="display:flex; margin:auto; width: 30%; height: 60%;"
                            alt="Mental Logo">
                        <p style="color: black; text-align: center;" class="text-xl">{{ $t('description') }}</p>
                        <div class="flex justify-content-center">
                    <div class="circle-button mr-4">
                                <PrimeButton @click.prevent="guestTherapist()" :label="$t('explore our therapists')"
                                    class="text-xl px-5" />
                            </div>
                            <div class="circle-button">
                                <PrimeButton @click.prevent="SignUpDoctor()" :label="$t('join us as therapist')"
                                    class="text-xl px-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section id="about">
            <div class="chat-container my-5 mx-5"></div>
            <div class="card pt-5">
                <div class="border-container">
                    <h2>{{ $t('profile.Our Therapists') }}</h2>


                </div>

                <PrimeCarousel v-if="doctors.length > 0" :value="doctors" :numVisible="3" :numScroll="3"
                    :responsiveOptions="carouselResponsiveOptions" :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
                    <template #item="doctor">

                        <div class="card m-3 col-md-4">

                            <div class="card-body">
                                <div class="profile-info">
                                    <div class="profile-image" :dir="this.$i18n.locale == 'ar' ? 'ltr' : 'rtl'">
                                        <img class="shadow-2"
                                            :src="'https://backend.ihayanow.com/' + doctor.data.profile.photo"
                                            alt="Profile Image" width="100" height="100">
                                    </div>
                                    <div class="profile-details">

                                        <h5 class="mb-1" v-if="this.$i18n.locale === 'ar'"
                                            :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'"><i
                                                class="pi pi-fw pi-user text-xl font-bold"></i>
                                            {{ $t('Dr.') }} {{ doctor.data.profile.arabic_name }}</h5>
                                        <h5 class="mb-1" v-else><i class="pi pi-fw pi-user text-xl font-bold"></i>
                                            {{ $t('Dr.') }} {{ doctor.data.name }}</h5>

                                        <p class="mb-0 font-weight-bold" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                            {{ doctor.data.profile.professional == "Psychologist" ?
                                                $t("profile.Psychologist") : $t("profile.Psychastric") }}
                                        </p>
                                        <p class="mb-0 font-weight-bold" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                            {{ $t("profile.Specialist in treating") }} :

                                            <span v-for="(spec, index) in doctor.data.speciality" :key="index">
                                                {{ this.$i18n.locale === 'ar' ? spec.name + ' ، ' : spec.english_name + ' ، ' }}
                                            </span>

                                        </p>

                                        <div class="pt-3 " v-if="this.$i18n.locale === 'ar'"
                                            :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                            <PrimeRating class="text-center" :modelValue="doctor.data.profile.rating"
                                                :readonly="true" :cancel="false">
                                            </PrimeRating>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="flex align-items-center justify-content-between pt-3"
                                    v-if="displayOnlinePrice(doctor.data.profile)"
                                    :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                    <span class="text-xl px-2 font-semibold" style="color: #6366F1;">

                                        <i class="pi pi-fw pi-money-bill px-1 text-xl"></i>
                                        {{ $t("Online Session Price") }} :
                                        {{ doctor.data.profile.online_price }} {{ $t('Payments.EGP') }} / {{
                                            doctor.data.profile.session_duration }}
                                        {{ $t('profile.minute') }}</span>


                                </div>
                                <div class="flex align-items-center justify-content-between pt-3"
                                    v-if="displayOnsitePrice(doctor.data.profile)"
                                    :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                    <span class="text-xl px-2 font-semibold" style="color: #6366F1;">
                                        <i class="pi pi-money-bill px-1 text-xl"></i>
                                        {{ $t("Onsite Session Price") }} :
                                        {{ doctor.data.profile.onsite_price }} {{ $t('Payments.EGP') }} / {{
                                            doctor.data.profile.session_duration }}
                                        {{ $t('profile.minute') }}</span>


                                </div>


                                <div class="flex align-items-center justify-content-between pt-3 mx-2"
                                    :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">

                                    <span class="text-xl font-semibold" style="color: #6366F1;"
                                        v-if="this.$i18n.locale === 'ar'">
                                        <i class="p-menuitem-icon pi pi-fw pi-globe"></i>
                                        {{ (doctor.data.profile.session_type == "كليهما" ||
                                            doctor.data.profile.session_type == "أونلاين و أوفلاين" ||
                                            doctor.data.profile.session_type ==
                                            "Online & Onsite")
                                            ? $t('profile.Online & Onsite Sessions') :
                                            $t('profile.Sessions') + ' ' + $t('profile.' + doctor.data.profile.session_type) }}
                                    </span>

                                    <span class="text-xl font-semibold" style="color: #6366F1;"
                                        v-else-if="this.$i18n.locale === 'en'">
                                        <i class="p-menuitem-icon pi pi-fw pi-globe"></i>
                                        {{ (doctor.data.profile.session_type == "Online & Onsite" ||
                                            doctor.data.profile.session_type
                                            == "كليهما"
                                            || doctor.data.profile.session_type == "أونلاين و أوفلاين")
                                            ? $t('profile.Online & Onsite Sessions') :
                                            $t('profile.' + doctor.data.profile.session_type) + ' ' + $t('profile.Sessions') }}

                                    </span>

                                </div>
                                <div class="flex align-items-center justify-content-between pt-3 mx-2"
                                    :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                    <p for="example-text-input" class="text-xl font-semibold" style="color: #6366F1;"
                                        v-if="this.$i18n.locale === 'ar'">
                                        <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>
                                        {{
                                            doctor.data.profile.address }} - {{ doctor.data.city_name.arabic_name }} - {{
        doctor.data.state_name.arabic_name }} -
                                        {{
                                            doctor.data.country_name.arabic_name }}
                                    </p>
                                    <p for="example-text-input" class="text-xl font-semibold" style="color: #6366F1;"
                                        v-else>{{
                                            doctor.data.profile.address }} -
                                        {{ doctor.data.city_name.english_name }} - {{ doctor.data.state_name.english_name }}
                                        - {{ doctor.data.country_name.english_name }}
                                    </p>
                                </div>

                                <div class="flex align-items-center justify-content-between pt-3 mx-2"
                                    v-if="this.$i18n.locale === 'ar'" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                    <p for="example-text-input" class="font-bold" style="color: green;">
                                        {{ $t("Number of reservations : ") }} {{ doctor.data.session_counter !== null ?
                                            doctor.data.session_counter : 0 }} {{ $t("sessions") }}
                                    </p>
                                </div>
                                <div class="flex align-items-center justify-content-between pt-3 mx-3 my-3"
                                    v-if="this.$i18n.locale === 'ar'" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                    <PrimeButton @click="goToProfile(doctor.data.profile.id)">{{ $t('profile.View Profile')
                                    }}
                                    </PrimeButton>

                                </div>

                            </div>

                        </div>

                    </template>
                </PrimeCarousel>

            </div>

        </section>

<!-- 
        <section id="about">
            <div class="chat-container my-5 mx-5"></div>
            <div class="card pt-5">
                <div class="border-container">
                    <h2>{{ $t('Reviews') }}</h2>


                </div>

                <div class="card">
                    <PrimeCarousel :value="doctorsRating" :numVisible="3" :numScroll="1"
                        :responsiveOptions="responsiveOptions" :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
                        <template #item="doctor">
                            <div class="profile-info d-flex justify-content-center">
                                <div class="profile-image" :dir="this.$i18n.locale == 'ar' ? 'ltr' : 'rtl'">
                                    <img class="shadow-2"
                                        :src="'https://backend.ihayanow.com/' + doctor.data.userProfile.photo"
                                        alt="Profile Image" width="100" height="100">
                                </div>

                                <div class="pt-3">
                                    <h5 class="mb-1" v-if="this.$i18n.locale === 'ar'"
                                        :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                        {{ doctor.data.userProfile.arabic_name }}</h5>
                                    <h5 class="mb-1" v-else>
                                        {{ doctor.data.userName.name }}</h5>

                                    <div :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                                        <PrimeRating class="text-center" :modelValue="doctor.data.doctorRating.rate"
                                            :readonly="true" :cancel="false">
                                        </PrimeRating>
                                    </div>
                                </div>


                            </div>
                            <p class="pt-3 text-center"> {{ doctor.data.doctorRating.review }}</p>
                        </template>

                    </PrimeCarousel>
                </div>




            </div>

        </section> -->
        <Footer />


    </div>
</template>
  
  
    
<script>

import Footer from './Footer.vue';
import UserNavbar from '../components/User/UserNavbar.vue'
import DoctorNavbar from '../components/Doctor/DoctorNavbar.vue'
import Navbar from './Navbar.vue'
import axios from 'axios';


export default {
    name: 'AppHome',
    data() {
        return {
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,
            doctors: [],
            doctorsRating: [],
            selectedLanguage: '',
            supportedLanguages: ['en', 'ar'],
            locale: this.$i18n.locale,
            specialities: [],
            isRtl: false,
            products: null,
            carouselResponsiveOptions: [
                {
                    breakpoint: "1024px",
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: "768px",
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: "560px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ],

        }
    },
    async mounted() {


        await this.getData();
        // const chatroom = new window.Chatroom({
        //     host: "https://35af-41-130-139-249.ngrok.io", // Replace with your chat service endpoint
        //     title: "Chat with Mike",
        //     container: document.querySelector(".chat-container"),
        //     welcomeMessage: "Hi, I am Mike. How may I help you?",
        //     speechRecognition: "en-US",
        //     voiceLang: "en-US"
        // });
        // chatroom.openChat();

        axios.get("https://backend.ihayanow.com/api/get-doctors-rating", {
            headers: {
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            },
        }).then(response => {
            this.doctorsRating = response.data; // Assign the array from the response data to calendars
            console.log(this.doctorsRating);
        })
            .catch(error => {
                console.error(error);
            });
    },


    methods: {
        guestTherapist() {
            this.$router.push({ name: 'guest-therapist-page' })

        },
       
        SignUpDoctor() {
            this.$router.push({ name: 'DoctorSignUp' })

        },
        goToProfile(profileId) {
            this.$router.push({ name: 'TherapistProfile', params: { id: profileId } })
        },
        async getData() {

            let result = await axios.get("https://backend.ihayanow.com/api/therapist_data",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Api-Key': this.apiKey,
                        'Api-Secret': this.apiSecret
                    }
                });
            this.doctors = result.data;
            // this.specialities=result.data.speciality;
            // console.log(this.doctors,this.specialities);
            //   this.$toast.open({
            //   message: 'Hello, World!',
            //    position: "top-right",
            //    type: "success",
            //             duration: 5000 * 10,
            //             dismissible: true

            // })
            //   console.log()
            //   VueToast.$toast.open('Hello');

        },

        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.locale = this.selectedLanguage;
            this.updatePageDirection();
        },
        updatePageDirection() {
            if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
                document.documentElement.dir = 'rtl';
            } else {
                document.documentElement.dir = 'ltr';
            }
        },

        async logout() {

            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret

                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear();

                    this.$router.push('/login-page');

                })
                .catch(error => {
                    console.log(error);
                });
        },
        displayOnlinePrice(doctor) {
            if (this.$i18n.locale === 'ar') {

                return doctor.session_type == 'Online' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اونلاين' || doctor.session_type == 'أونلاين' || doctor.session_type == 'أونلاين و أوفلاين';

            }
            else {
                return doctor.session_type == 'Online' || doctor.session_type === 'Online & Onsite' || doctor.session_type == 'اونلاين' || doctor.session_type == 'أونلاين' || doctor.session_type == 'أونلاين و أوفلاين';

            }
        },
        displayOnsitePrice(doctor) {
            if (this.$i18n.locale === 'ar') {
                return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';

            }
            else {
                return doctor.session_type == 'Onsite' || doctor.session_type == 'Online & Onsite' || doctor.session_type == 'اوفلاين' || doctor.session_type == 'أوفلاين' || doctor.session_type == 'أونلاين و أوفلاين';

            }
        },

    },
    created() {
        this.updatePageDirection();
    },
    computed: {
        menu() {
            return [
                {
                    label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', to: '/'

                },

                {
                    label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', to: "/knowledgebase-page"

                },
                {
                    label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', to: "/view-medications"

                },
                {
                    label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', to: "/guest-therapist-page"

                },
                {
                    label: this.$t('menu.sign_up'), icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: this.$t('menu.sign_up_as_user'), icon: 'pi pi-fw pi-user-plus', to: "/sign-up"
                        },
                        {
                            label: this.$t('menu.sign_up_as_doctor'), icon: 'pi pi-fw pi-user-plus', to: "/doctor-sign-up"
                        },
                    ]
                },
                {
                    label: this.$t('menu.sign_in'), icon: 'pi pi-fw pi-sign-in', to: "/login-page"

                },
                {
                    label: this.$t('logout'), icon: 'pi pi-power-off', command: this.logout,

                },

            ];
        },
        navbarComponent() {
            const userType = localStorage.getItem('type');
            if (userType === 'User') {
                return UserNavbar;
            } else if (userType === 'Doctor') {
                return DoctorNavbar;
            } else {
                return Navbar;
            }
        }

    },
    components: {
        Footer

    }


}
</script>
  
<style scoped>
.circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    padding: 15px;
    /* Adjust padding as needed */
    cursor: pointer;
}


.center-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.profile-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    /* margin-right: 10px; */

}

.p-menubar {
    background: transparent !important;
    border: 0px solid transparent !important;
}

.p-menubar ul {
    margin: 90px !important;
    padding: 50px !important;
}

.p-menuitem-content {
    background: transparent !important;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: black;
}

.header {
    height: 100%;
    min-height: 650px;
    background: url(../../public/background.jpg) no-repeat center center fixed;
    background-size: cover;
}

.header .overlay {
    position: absolute;
    left: 0;
    top: 5rem;
    width: 100%;
    height: 102%;
    background: rgba(250, 250, 250, 0.75);
    color: #fefefef5;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.header .overlay .subtitle {
    margin-top: -20px;
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
    font-weight: 300;
}


.text-center {
    text-align: center !important;
}


.p-carousel-container {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
}

.testimonial-card {
    background-color: rgba(204, 204, 204, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}



.comment {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.author {
    font-size: 16px;
    color: #666;
}

.p-rating {
    display: block !important;
}

.border-container {
    width: 30%;
    margin: 0 auto;
    text-align: center;
    /* If you want the text centered within the container */
    padding-bottom: 20px;

}

.border-container h2 {
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 20px;
}


@media (max-width: 768px) {
    .margin-left-230 {
        margin-left: 0;
    }

    .margin-right-230 {
        margin-right: 0;
    }

    .header .overlay {
        height: 102%;
    }
}</style>