<template>
    <div class="container">

    <component :is="navbarComponent" />

        <div class="layout-main">
            <div class="grid">
                <div class="card md:col-12 py-4">
                    <div class="md:col-2">
                        <h2 class="pb-4" style="border-bottom: 1px solid var(--primary-color);">
                            {{ $t('Packages.All Packages') }}</h2>
                    </div>
<div class="pricing-tiers-container" style="display: flex; flex-wrap: wrap;">

                    <div  class="md:col-4" v-for="(tier, index) in filteredPackages" :key="index">
                        <div class="pricing-tier" >
                            <h5 class="pb-4" style="border-bottom: 1px solid var(--primary-color);">{{
                                $t('Packages.level') + ' : ' + tier.level_name }}</h5>
                            <p class="name bold">{{ $t('Packages.name') + ' : ' + tier.subscriptions.name }}</p>
                            <p class="description">{{ $t('Packages.description') + ' : ' +
                                tier.subscriptions.description }}</p>

                            <p class="package_price">{{ tier.subscriptions.price + ' ' + $t('Payments.EGP') + ' / '
                                +
                                $t(tier.subscriptions.duration) }}</p>

                            <p class="price">{{ $t('Packages.test_discount') + ' : ' +
                                tier.subscriptions.test_discount + ' % ' }}</p>
                            <p class="price">{{ $t('Packages.onsite_discount') + ' : ' +
                                tier.subscriptions.onsite_discount + ' % ' }}</p>
                            <p class="price">{{ $t('Packages.online_discount') + ' : ' +
                                tier.subscriptions.online_discount + ' % ' }}</p>


                            <p class="name">{{ $t('Packages.all_doctors') + ' : ' }} {{
                                tier.subscriptions.all_doctors == '1' ? $t('Yes') : $t('No') }}</p>
                     
                            <PrimeButton :class="{ 'disabled': isSubscribed(tier.subscriptions.id) }"
                                :disabled="isSubscribed(tier.subscriptions.id)" @click="goToPayment(tier.subscriptions.id)">
                                {{ $t('Subscribe') }}</PrimeButton>

                            <div>
                                <span v-if="isSubscribed(tier.subscriptions.id)" class="message">{{
                                    this.$t('You already subscribed in this package') }}</span>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import DoctorNavbar from './Doctor/DoctorNavbar.vue';
import UserNavbar from './User/UserNavbar.vue';

import Footer from './Footer.vue';
import axios from 'axios';

export default {
    name:'AppPackages',
    data() {
        return {
            navbarComponent: null,
            userType: '',
            packages: [],
            subscripedPackages: [],
            level_names: [],
            apiKey: process.env.VUE_APP_API_KEY,
            apiSecret: process.env.VUE_APP_API_SECRET,

            subscribedPackageIds: [],
        };
    },
    mounted() {

        axios.get('https://backend.ihayanow.com/api/get_subscriptions', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Api-Key': this.apiKey,
                'Api-Secret': this.apiSecret
            },
        })
            .then(response => {
                this.packages = response.data.packages;
                
        this.userType = localStorage.getItem("type");
        if (this.userType === "User") {
            this.navbarComponent = UserNavbar;
        } else if (this.userType === "Doctor") {
            this.navbarComponent = DoctorNavbar;
        }
            })
            .catch(error => {
                console.log(error);
                console.log(error.response.data.message);
                this.errors = error.response.data.message;

            });
        this.fetchSubscribedPackages();


    },
    computed: {
  filteredPackages() {
    // Filter packages based on conditions
    return this.packages.filter(tier => {
      return (
        (this.userType == 'Doctor' && tier.subscriptions.user_type == 'Doctor') ||
        (this.userType == 'User' && tier.subscriptions.user_type == 'User')
      );
    });
  },
},
    methods: {
        fetchSubscribedPackages() {
            axios.get('https://backend.ihayanow.com/api/get_subscriped_packages', {

                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                },
            })
                .then(response => {
                    this.subscribedPackageIds = response.data.subscripedPackages.map(subscription => subscription.subscription_id);
                console.log(this.subscribedPackageIds)
                })
                .catch(error => {
                    console.error(error);
                });
        },
        isSubscribed(id) {
            // Check if the given package ID is in the subscribedPackageIds array
        
            console.log(this.subscribedPackageIds.includes(id))
            return this.subscribedPackageIds.includes(String(id));
        },

        goToPayment(packageId) {
            this.$router.push({
                name: 'package_payment', params: { id: packageId }

            })

        },

    },
    components: {
        DoctorNavbar,
        // UserNavbar,
        Footer

    }
};
</script>
  
<style scoped>
.pricing-tier {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.package_price {
    font-size: 2em;
    font-weight: bold;
}

.duration {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 20px;
}

.p {
    line-height: 0.4 !important;
}

button:hover {
    background-color: #263444;
}

.disabled {
    pointer-events: none;
    background-color: red;
    border: 0px solid !important;

}

.message {
    color: red;
    font-size: 12px;
}
</style>
  