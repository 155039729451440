<template>
  <div class="container">
    <UserNavbar />

    <!-- <div class="layout-main"> -->

    <div class="grid mt-5 card">

      <div class="card md:col-5 mx-4">
        <div class="card-body p-3">

          <div class="profile-info">
            <div class="profile-image">
              <img :src="'https://backend.ihayanow.com/' + profile.photo" alt="Profile Image">
            </div>
            <div class="profile-details mx-4">
              <h5 class="mb-1">{{ $t('profile.name') }} : {{ displayName() }}</h5>
              <p class="mb-0 font-weight-bold">
                {{ $t("profile.professional") }}: {{ profile.professional == "Psychologist" ?
                  $t("profile.Psychologist") : $t("profile.Psychastric") }} ,
                {{ $t("profile.Specialist in treating") }}
                <span v-for="(spec, index) in this.speciality" :key="index">

                  {{ this.$i18n.locale === 'ar' ? spec.name + ' ، ' : spec.english_name + ' ، ' }}
                </span>
              </p>
              <p class="mb-0 font-weight-bold">
                {{ $t("profile.language") }}: {{ $t(`${profile.language}`) }}
              </p>
              <PrimeRating v-model="profile.rating" class="pt-2" :cancel="false"></PrimeRating>


            </div>

          </div>

          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                  <i class="p-menuitem-icon pi pi-fw pi-cog"></i> {{ $t("profile.session_type") }}</span>
                <p for="example-text-input" class="form-control-label pt-2">
                  {{ profile.session_type == "Online & Onsite" ? $t("profile.Online & Onsite") :
                    $t(`${profile.session_type}`) }}
                </p>
              </span>

            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-clock"></i>{{ $t("profile.session_duration") }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.session_duration }} {{
                  $t('minutes') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6" v-if="displayOnsitePrice">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-money-bill"></i>
                  {{ $t("profile.onsite_session_price") + ' / '
                    + profile.session_duration + ' ' + $t('profile.minute') }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.onsite_price }} {{
                  $t('Payments.EGP') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6" v-if="displayOnlinePrice">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-credit-card"></i>{{
                      $t("profile.online_session_price") + ' / '
                      + profile.session_duration + ' ' + $t('profile.minute') }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.online_price }} {{
                  $t('Payments.EGP') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                  <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>{{ $t("profile.address") }}</span>
                <p for="example-text-input" class="form-control-label" v-if="this.$i18n.locale === 'ar'">{{
                  profile.address }} - {{ city_name.arabic_name }} - {{ state_name.arabic_name }} - {{
    country_name.arabic_name }}</p>
                <p for="example-text-input" class="form-control-label" v-else>{{ profile.address }} - {{
                  city_name.english_name }} - {{ state_name.english_name }} - {{ country_name.english_name }}
                </p>

              </span>
            </div>




          </div>
          <div class="grid p-fluid mt-3  text-center justify-content-center">

            <div class="md:col-4">
              <PrimeButton type="button" @click.prevent="showRequestForm()">
                {{ $t('Appointments.Request a Session') }}
              </PrimeButton>
            </div>
            <div class="md:col-4 ">
              <PrimeButton type="button" @click.prevent="showRating()">
                {{ $t('Write Your Rating') }}
              </PrimeButton>
            </div>
          </div>


        </div>
      </div>


      <div class="card md:col-6">
        <header :dir="selectedLanguage === 'ar' ? 'ltr' : 'rtl'">
          <p class="current-date"></p>
          <div class="icons">
            <span id="prev" class="material-symbols-rounded">
              <!-- chevron_left -->
            </span>
            <span id="next" class="material-symbols-rounded">
              <!-- chevron_right -->
            </span>
          </div>
        </header>
        <div class="calender md:col-12" :dir="selectedLanguage === 'ar' ? 'ltr' : 'rtl'">
          <ul class="weeks col-12">
            <li>{{ this.$t('Sun') }}</li>
            <li>{{ this.$t('Mon') }}</li>
            <li>{{ this.$t('Tue') }}</li>
            <li>{{ this.$t('Wed') }}</li>
            <li>{{ this.$t('Thu') }}</li>
            <li>{{ this.$t('Fri') }}</li>
            <li>{{ this.$t('Sat') }}</li>
          </ul>
          <ul class="days col-12">
            <dropdown class="appointments">

            </dropdown>
          </ul>
        </div>
      </div>
    </div>

    <div class="card md:col-12" v-if="selectedDay">
      <template v-if="this.calendars.length">
        <div v-for="(calendar, index) in this.calendars" :key="index">
          <template v-if="selectedDay.date.split('-')[2] == calendar.date.split('-')[2]">
            <template v-if="calendar.onsite == 1">
              <h5 class="mt-4"> {{ $t('Appointments.Onsite Appointments') }}</h5>
              <!-- <div v-for="time in getTimes(calendar.from, calendar.to)" :key="time"
        class="appointment-slot"> -->
              <PrimeButton id="myButton" v-for="time in getTimes(calendar.from, calendar.to, calendar.date)" :key="time"
                class="mt-3 mx-3" :class="{ 'disabled': isAppointmentDeleted(time, calendar.date) }"
                @click="goToPayment(calendar.id, time)">
                {{ time }}
                <span v-if="isAppointmentDeleted(time, calendar.date)" class="message">
                  {{ this.$t('Red appointment is selected') }}
                </span>
              </PrimeButton>
              <p v-if="getTimes(calendar.from, calendar.to, calendar.date) == 0" style="color:red;">
                {{ $t("Appointments.No time slots available") }}</p>


              <!-- </div> -->

            </template>
            <template v-else-if="calendar.onsite == 0">
              <h5 class="mt-4">{{ $t('Appointments.Online Appointments') }}</h5>
              <!--  @click="requestSession(selectedDay, time, $event.target)" -->


              <PrimeButton v-for="time in getTimes(calendar.from, calendar.to, calendar.date)" :key="time" id="myButton"
                class="mt-3 mx-3" @click="goToPayment(calendar.id, time)"
                :class="{ 'disabled': isAppointmentDeleted(time, calendar.date) }">
                {{ time }}
                <span v-if="isAppointmentDeleted(time, calendar.date)" class="message">
                  {{ this.$t('Red appointment is selected') }}</span>

              </PrimeButton>
              <p v-if="getTimes(calendar.from, calendar.to, calendar.date) == 0" style="color:red;">
                {{ $t("Appointments.No time slots available") }}</p>

            </template>
          </template>
        </div>
      </template>



      <template v-else>
        <p>{{ $t("Appointments.No time slots available") }}</p>
      </template>

    </div>

    
    <div class="col-12">
              <!-- <div class="profile-info">
             <p>If there is no appointment available you can request a session </p> 
                <PrimeButton type="button" @click.prevent="showRequestForm()" class="mt-4">
                  {{ $t('Appointments.Request a Session') }}
                </PrimeButton>
              </div> -->
<PrimeMessage class="w-full mt-3" severity="success" v-if="this.successData" :key="index">
                    {{ translateErrorMessage(this.successData) }}
                  </PrimeMessage>
<PrimeMessage class="w-full mt-3" severity="error" v-if="this.errorMessage" :key="index">
                    {{ translateErrorMessage(this.errorMessage) }}
                  </PrimeMessage>

              <div v-if="showUpdate" class="pt-3">
                <div class="card">
                  <h3>{{ $t('Appointments.Request a Session') }}</h3>
                  <PrimeMessage class="w-full mt-3" severity="success" v-if="this.successData" :key="index">
                    {{ translateErrorMessage(this.successData) }}
                  </PrimeMessage>
                  <!-- <h3> {{ $t('Appointments.Request a Session') }}</h3> -->
                  <form @submit.prevent="submitRequestForm()">
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <label style="font-weight: bold;">{{ $t('Appointments.Date') }} : </label>
                        <InputText type="date" v-model="date" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.date"
                          v-for="(error, index) in errors.date" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label style="font-weight: bold;">{{ $t('Sessions.From') }} :</label>
                        <InputText type="time" v-model="from" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.from"
                          v-for="(error, index) in errors.from" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label style="font-weight: bold;">{{ $t('Sessions.To') }} :</label>
                        <InputText type="time" v-model="to" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.to"
                          v-for="(error, index) in errors.to" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label for="onsite" style="font-weight: bold;">{{ $t('Appointments.Appointment Location') }}
                        </label>

                        <PrimeDropdown id="state" class="w-full mb-3" v-model="onsite" :options="onsites"
                          optionLabel="translationKey" :placeholder="$t('profile.Select_One')"
                          style="  border: 2px; border-radius: 4px;">
                        </PrimeDropdown>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.onsite"
                          v-for="(error, index) in errors.onsite" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>

                      <div class="field col-12 md:col-6">

                        <label for="type" style="font-weight: bold;">{{ $t('Appointments.Appointment Type') }}</label>
                        <PrimeDropdown id="state" class="w-full mb-3" v-model="type" :options="types"
                          optionLabel="translationKey" :placeholder="$t('profile.Select_One')"
                          style="  border: 2px; border-radius: 4px;">
                        </PrimeDropdown>
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                          v-for="(error, index) in errors.type" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12 md:col-6">
                        <label for="number_of_attends" style="font-weight: bold;">
                          {{ $t('Appointments.Number Of Attendance') }}</label>
                        <InputText type="number" v-model="number_of_attends" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.number_of_attendees"
                          v-for="(error, index) in errors.number_of_attendees" :key="index">
                          {{ translateErrorMessage(error) }}
                        </InlineMessage>
                      </div>
                    </div>
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <PrimeButton type="submit">{{ $t('Request') }}</PrimeButton>
                      </div>
                      <div class="field col-12 md:col-6">
                        <PrimeButton @click="hideRequestForm()">{{ $t('Cancel') }}</PrimeButton>
                      </div>
                    </div>
                  </form>
                </div>


              </div>
            </div>

            <div v-if="showRate" class="md:col-12 justify-content-center">
              <div class="card">
                <h1 class="text-center">{{ $t('rate.Rating') }}</h1>

                <div class="grid p-fluid mt-3">

                  <div class="field col-12 md:col-6">
                    <label style="font-weight: bold;">{{ $t('rate.Session Rate') }}</label>
                    <PrimeRating v-model="session_rating" :cancel="false"></PrimeRating>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.session_rating"
                      v-for="(error, index) in errors.to" :key="index">
                      {{ translateErrorMessage(error) }}
                    </InlineMessage>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="doctor_rating" style="font-weight: bold;">
                      {{ $t('rate.Doctor Rate') }}
                    </label>

                    <PrimeRating v-if="userType === 'User'" v-model="doctor_rating" :cancel="false">
                    </PrimeRating>
                  </div>

                  <div class="field col-12 ">
                    <label for="opinion" style="font-weight: bold;">
                      {{ $t('rate.Any Other Opinion') }}</label>
                    <PrimeTextarea :placeholder="$t('rate.Your Opinion')" :autoResize="true" rows="9" cols="70"
                      v-model="opinion" />
                  </div>

                </div>

                <div class="grid p-fluid mt-3">
                  <div class="field col-12 md:col-6">
                    <PrimeButton @click="submit_rate(profile.user_id)" type="submit">{{ $t('save') }}
                    </PrimeButton>
                  </div>
                  <div class="field col-12 md:col-6">
                    <PrimeButton @click="hideRating()">{{ $t('Cancel') }}</PrimeButton>
                  </div>

                </div>
              </div>
            </div>

    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import UserNavbar from './UserNavbar.vue';
import Footer from '../Footer.vue';
export default {
  name: 'UserCalendar',
  data() {
    return {
      errorMessage: '',

      selectedLanguage: '',
      calendars: [],
      Dates: [],
      sessionDuration: '', // set the default session duration to 60 minutes
      photo: '',
      name: '',
      rate: '',
      professional: '',
      selectedDate: null,
      view: 'month',
      id: null,
      selectedDay: null, // Add this line
      showUpdate: false,
      from: '',
      to: '',
      date: '',
      type: '',
      onsite: '',

      number_of_attends: '',
      endTime: '',
      time: '',
      doctor_id: '',
      new_type: '',
      new_onsite: '',
      errors: {},
      successData: '',
      arabic_name: '',
      deletedSelectedAppointment: [],
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,

      rating: 0,

      session_rating: 0,
      doctor_rating: 0,
      user_rating: 0,
      userType: '',
      opinion: '',

      profile: [],
      navbarComponent: null,
      country_name: '',
      state_name: '',
      city_name: '',
      speciality: [],
      speciality_arabic_name: [],
      speciality_english_name: [],
      speciality_arabic: [],
      speciality_english: [],

      showRate: false,
    };
  },

  created() {
    this.selectedLanguage = this.$i18n.locale;
    this.userType = localStorage.getItem("type");
    let result2 = axios.get("https://backend.ihayanow.com/api/get_profile_for_user", {
      params: {
        profile_id: this.$route.params.id
      }, headers: {
        'Content-Type': 'application/json',
        'Api-Key': this.apiKey,
        'Api-Secret': this.apiSecret
      },
    }).then(response => {
      result2 = response.data; // Assign the array from the response data to calendars
      console.log(result2);
      this.doctor_id = result2[0].profile.user_id;
      this.name = result2[0].name;
      this.email = result2[0].email;
      // console.log(this.name);
      this.profile = result2[0].profile;
      this.country_name = result2[0].country_name;
      this.state_name = result2[0].state_name;
      this.city_name = result2[0].city_name;
      this.speciality = result2[0].speciality;
      // console.log(this.profile.photo, this.speciality)
      // this.speciality_arabic_name, this.speciality_english_name);

    })
      .catch(error => {
        console.error(error);
      });

    let result = axios.get('https://backend.ihayanow.com/api/get-calendars', {
      params: {
        // profile_id
        user_id: this.$route.params.id
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Api-Key': this.apiKey,
        'Api-Secret': this.apiSecret
      }
    }).then(response => {
      result = response.data; // Assign the array from the response data to calendars
      console.log(result);
      const dynamicKey = this.doctor_id.toString();
      console.log(this.doctor_id, dynamicKey);
      // Check if the dynamicKey exists in the result object
      if (Object.prototype.hasOwnProperty.call(result, dynamicKey)) {

        console.log("result", result[dynamicKey]);
        this.calendars = result[dynamicKey].doctor_calendars;
        this.id = result[dynamicKey].doctor_id;
        this.Dates = result[dynamicKey].dates;
        this.sessionDuration = result[dynamicKey].session_duration;
        // .length !== 0
        if (result[dynamicKey].deleteSelcetdAppintment) {
          this.deletedSelectedAppointment = result[dynamicKey].deleteSelcetdAppintment;

        }

        // })
      }
      // } else {

      //   // this.photo = result[0].photo;
      //   // this.name = result[0].name;
      //   // this.arabic_name = result[0].arabic_name;
      //   // this.rate = result[0].rate;
      //   // this.professional = result[0].professional;
      //   this.id = result[0].doctor_id;
      //   this.calendars = result[0].doctor_calendars;
      //   this.Dates = result[0].dates;
      //   this.sessionDuration = result[0].session_duration;
      //   this.Dates = result[0].dates;
      //   this.deletedSelectedAppointment = result[0].deleteSelcetdAppintment;
      //   console.log("hi", result, this.calendars);
      //   console.log(this.deletedSelectedAppointment);
      // }


      const daysTag = document.querySelector(".days"),
        currentDate = document.querySelector(".current-date"),
        prevNextIcon = document.querySelectorAll(".icons span");
      // getting new date, current year and month +1 cuz it return may month
      let date = new Date(),
        currYear = date.getFullYear(),
        currMonth = date.getMonth();
      // storing full name of all months in array
      const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
      const renderCalendar = () => {
        let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(),
          lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(),

          lastDayofMonth = new Date(currYear, currMonth, lastDateofMonth).getDay(),
          lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate();
        let liTag = "";


        for (let i = firstDayofMonth; i > 0; i--) {
          // creating li of previous month last days
          liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
        }

        // creating li of all days of current month
        for (let i = 1; i <= lastDateofMonth; i++) {
          let dayString = i.toString().padStart(2, '0'); // Convert day to string
          let isActiveDate = ""; // Initialize isActiveDate variable

          if (currMonth === date.getMonth() && currYear === date.getFullYear()) {
            if (i === date.getDate()) {
              isActiveDate = (this.Dates.includes(dayString) ? "active" : "");

            } else {
              isActiveDate = (this.Dates.includes(dayString.toString()) ? "active" : "");
            }
          }

          if (isActiveDate == "active") {
            liTag += `<li class="${isActiveDate}">${i}</li>`;
          } else {
            liTag += `<li>${i}</li>`;

          }
        }

        for (let i = lastDayofMonth; i < 6; i++) { // creating li of next month first days
          liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`
        }
        currentDate.innerText = `${this.$t(months[currMonth])} ${currYear}`;
        // passing current mon and yr as currentDate text
        daysTag.innerHTML = liTag;
        const liElements = daysTag.querySelectorAll("li");

        liElements.forEach((li) => {
          li.addEventListener("click", () => {
            if (li.classList.contains("active")) {
              const selectedDayInfo = this.calendars.find(calendar => {
                const dayString = li.innerText.toString().padStart(2, '0');
                return this.Dates.includes(dayString) && moment(calendar.date).date() === parseInt(dayString);
              });
              this.selectedDay = selectedDayInfo;
            }
          });
        });
      }
      renderCalendar();

      prevNextIcon.forEach(icon => { // getting prev and next icons
        icon.addEventListener("click", () => { // adding click event on both icons
          // if clicked icon is previous icon then decrement current month by 1 else increment it by 1
          currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;
          if (currMonth < 0 || currMonth > 11) { // if current month is less than 0 or greater than 11
            // creating a new date of current year & month and pass it as date value
            date = new Date(currYear, currMonth, new Date().getDate());
            currYear = date.getFullYear(); // updating current year with new date year
            currMonth = date.getMonth(); // updating current month with new date month
          } else {
            date = new Date(); // pass the current date as date value
          }
          renderCalendar(); // calling renderCalendar function
        });
      });
    })
      .catch(error => {
        console.error(error);
      });






  },

  methods: {
    async submit_rate(doctorId) {
      await axios.post('https://backend.ihayanow.com/api/user/rate-doctor-from-profile', {
        rate: this.doctor_rating,
        session_rate: this.session_rating,
        review: this.opinion,
        doctor_id: doctorId

      }, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        }
      })
        .then(response => {

          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    isAppointmentDeleted(dateTime, Day) {
      const fromMeridiem = dateTime.includes('مساءا') ? 'مساءا' : 'صباحا';
      let formattedTime = moment(dateTime, 'hh:mm A').format('HH:mm:ss');
      if (fromMeridiem === 'مساءا') {
        if (moment(formattedTime, 'HH:mm:ss').format('HH') !== '12') {
          // from = moment(from, 'HH:mm:ss').add(12, 'hours').format('HH:mm:ss');
          const fromHour = parseInt(formattedTime.split(':')[0]);

          formattedTime = `${fromHour + 12}:${formattedTime.split(':')[1]}:00`;
        }

      } else {
        const fromHour = parseInt(formattedTime.split(':')[0]);
        formattedTime = `${fromHour === 12 ? '00' : fromHour}:${formattedTime.split(':')[1]}:00`;

        // formattedTime = `${fromHour}:${formattedTime.split(':')[1]}:00`;
      }
      if (Array.isArray(this.deletedSelectedAppointment) && this.deletedSelectedAppointment.length !== 0) {
        for (let i = 0; i < this.deletedSelectedAppointment.length; i++) {
          const appointment = this.deletedSelectedAppointment[i];
          if (appointment.date === Day) {
            const appointmentFrom = moment(appointment.date + ' ' + appointment.from, 'YYYY-MM-DD h:mm A');
            const appointmentTo = moment(appointment.date + ' ' + appointment.to, 'YYYY-MM-DD h:mm A');
            const dateTimeMoment = moment(appointment.date + ' ' + formattedTime, 'YYYY-MM-DD h:mm A');


            if (
              dateTimeMoment.isSameOrAfter(appointmentFrom) &&
              dateTimeMoment.isBefore(appointmentTo)
            ) {

              return dateTimeMoment.isSameOrAfter(appointmentFrom) &&
                dateTimeMoment.isBefore(appointmentTo);
            }
          }
        }
      }
      return false;
    },

    displayName() {
      if (this.$i18n.locale === 'ar') {
        return this.profile.professional == "Psychologist" ? this.$t("Psy.") + this.profile.arabic_name : this.$t("Dr.") + this.profile.arabic_name;
        // return this.profile.arabic_name;
      } else {
        return this.name;
      }
    },
    toggleDropdown(day) {
      day.showDropdown = !day.showDropdown;
    },


    getTimes(from, to, date) {
      // console.log(from, to)
      const times = [];
      const startTime = moment(from, 'HH:mm:ss');
      let endTime = moment(to, 'HH:mm:ss');
      console.log("startTime & endTime", startTime, endTime)

      // If endTime is before startTime, add a day to endTime
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      let currentTime = startTime;
      const theCurrentTime = new Date();
      console.log("currentTime which is from in database & theCurrentTime", currentTime, theCurrentTime)

      const timeNow = `${theCurrentTime.getHours()}:${theCurrentTime.getMinutes()}:${theCurrentTime.getSeconds()}`;
      const finalTimeNow = moment(timeNow, 'HH:mm:ss').format('HH:mm');

      const year = theCurrentTime.getFullYear();
      const month = theCurrentTime.getMonth() + 1; // Months are zero-based, so add 1
      const day = theCurrentTime.getDate();

      // Create a formatted date string
      const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
      console.log(date, formattedDate);
      // .format('h:mm A');
      console.log("timeNow & finalTimeNow", timeNow, finalTimeNow)

      while (currentTime.isBefore(endTime)) {
        // if (currentTime.isBefore(finalTimeNow)) {
        const formatTime = currentTime.format('HH:mm');
        console.log(currentTime, endTime, currentTime.isBefore(endTime), finalTimeNow, formatTime,
          formatTime > finalTimeNow)

        console.log("format time which is from ", formatTime);
        const formattedTime = currentTime.format('h:mm A');
        const formattedTimeTo = endTime.format('h:mm A');

        if (formattedTime.includes('AM') && formattedTimeTo.includes('AM')) {
          if (formatTime <= finalTimeNow && date !== formattedDate) {

            console.log("hi");
            const withoutAmPmIndicator = currentTime.format('h:mm');

            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
          // else 
          if (formatTime > finalTimeNow) {

            console.log("hi");
            const withoutAmPmIndicator = currentTime.format('h:mm');

            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
        }
        if (formattedTime.includes('AM') && formattedTimeTo.includes('PM')) {

          if (formatTime <= finalTimeNow && date !== formattedDate) {
            console.log("helllo", formatTime, finalTimeNow, formattedTime, formattedTimeTo);

            const withoutAmPmIndicator = currentTime.format('h:mm');
            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
          // else
          if (formatTime > finalTimeNow) {
            console.log("helllo", formatTime, finalTimeNow, formattedTime, formattedTimeTo);

            const withoutAmPmIndicator = currentTime.format('h:mm');
            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
        }
        if (formattedTime.includes('PM') && formattedTimeTo.includes('PM')) {
          if (formatTime <= finalTimeNow && date !== formattedDate) {
            console.log("kkkki");

            const withoutAmPmIndicator = currentTime.format('h:mm');

            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
          // else 
          if (formatTime > finalTimeNow) {
            console.log("kkkki");

            const withoutAmPmIndicator = currentTime.format('h:mm');

            if (formattedTime.includes('AM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
            } else if (formattedTime.includes('PM')) {
              times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
            }
          }
        }

        // if (formatTime > finalTimeNow) {
        //   console.log("hioooooooooo");
        //   console.log("formatTime & finalTimeNow",formatTime > finalTimeNow,formatTime, finalTimeNow)

        //   const withoutAmPmIndicator = currentTime.format('h:mm');

        //   if (formattedTime.includes('AM')) {
        //     times.push(withoutAmPmIndicator + ' ' + this.$t('am'));
        //   } else if (formattedTime.includes('PM')) {
        //     times.push(withoutAmPmIndicator + ' ' + this.$t('pm'));
        //   }
        // }
        currentTime.add(this.sessionDuration, 'minutes');

      }
      console.log(times);
      return times;
    },

    formatTime(time) {
      return moment(time, 'HH:mm:ss').format('h:mm A');
    },
    addMinutesToTime(time, minutes) {
      // Parse the input time using moment.js with the Arabic time format
      if (this.$i18n.locale === 'ar') {
        const parsedTime = moment(time, 'hh:mm A', 'ar');

        const newTime = parsedTime.add(minutes, 'minutes');
        newTime.locale('en');

        // Format the new time back to the Arabic time format
        const formattedTime = newTime.format('hh:mm A');
        const withoutAmPmIndicator = newTime.format('h:mm');
        let finalTime = '';
        if (formattedTime.includes('AM')) {
          finalTime = withoutAmPmIndicator + ' ' + this.$t('am');
        } else if (formattedTime.includes('PM')) {
          finalTime = withoutAmPmIndicator + ' ' + this.$t('pm');
        }
        // console.log(parsedTime, formattedTime, finalTime, withoutAmPmIndicator);
        return finalTime;
      } else {
        const parsedTime = moment(time, 'hh:mm A');

        const newTime = parsedTime.add(minutes, 'minutes');
        // newTime.locale('en');

        // Format the new time back to the Arabic time format
        const formattedTime = newTime.format('hh:mm A');
        const withoutAmPmIndicator = newTime.format('h:mm');
        let finalTime = '';
        if (formattedTime.includes('AM')) {
          finalTime = withoutAmPmIndicator + ' ' + this.$t('am');
        } else if (formattedTime.includes('PM')) {
          finalTime = withoutAmPmIndicator + ' ' + this.$t('pm');
        }
        console.log(parsedTime, formattedTime, finalTime, withoutAmPmIndicator);
        return finalTime;
      }

      // return moment(time, 'hh:mm:ss A').add(minutes, 'minutes').format('hh:mm:ss A');
    },

    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM Do YYYY');
    },
    goToPayment(sessionId, sessionTime) {
      this.$router.push({
        name: 'session_payment', params: { id: sessionId },
        query: {
          from: sessionTime,
          to: this.addMinutesToTime(sessionTime, this.sessionDuration),
        }
      })

    },
    translateErrorMessage(error) {
      return this.$i18n.t(error);
    },
    submitRequestForm() {
      if (this.onsite.translationKey == 'أونلاين' || this.onsite.translationKey == 'أوفلاين') {
        this.new_onsite = this.onsite.name;
      } else {
        this.new_onsite = this.onsite.name;
      }

if(this.new_onsite == 'أونلاين' || this.new_onsite == 'Online'){
  this.new_onsite =0;
}
else if(this.new_onsite == 'أوفلاين' || this.new_onsite == 'Onsite'){
  this.new_onsite =1;
}
      if (this.type.translationKey == 'فردي' || this.type.translationKey == 'جماعة') {
        this.new_type = this.type.name
      } else {
        this.new_type = this.type.name;
      }
      // this.new_onsite = this.new_onsite === 'Onsite' ? 1 : 0;

      console.log(this.profile.user_id, this.id, this.from, this.to, this.date, this.new_type, this.new_onsite, this.number_of_attends);
      console.log(typeof this.id, typeof this.from, typeof this.to, typeof this.date, typeof this.new_type, typeof this.new_onsite, typeof this.number_of_attends);

      // const url = `https://backend.ihayanow.com/api/user/request-session?doctor_id=
      // ${this.id}&from=${this.from}&to=${this.to}&date=${this.date}&type=${this.new_type}&onsite=${this.new_onsite}&number_of_attendees=${this.number_of_attends}`;
      // const token = localStorage.getItem('token');

      // axios.post(url, null, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     'Api-Key': this.apiKey,
      //     'Api-Secret': this.apiSecret
      //   },
      // }).then(response => {
      //   this.successData = response.data.message;
      //   // this.showUpdate = false;

      // })
      //   .catch(error => {
      //     console.log(error);
      //     this.errorMessage = error.response.data.errors;
      //     console.log(this.errors);
      //   });


  axios.post("https://backend.ihayanow.com/api/user/request-session",{
  doctor_id:this.id,
  from: this.from,
  to: this.to,
  date : this.date,
  type:this.new_type == undefined ? '' : this.new_type,
  onsite:this.new_onsite ,
  number_of_attendees:this.number_of_attends
},
{
  headers: {
    'Authorization':localStorage.getItem('token'),
    'Api-Key': this.apiKey,
    'Api-Secret': this.apiSecret
  }
}).then(response => {
  this.successData = response.data.message;

})
.catch(error => {
  console.log(error);
          this.errors = error.response.data.errors;
          console.log(this.errors);
});
    },
    showRequestForm() {
      this.showUpdate = true

    },
    hideRequestForm() {
      this.showUpdate = false;
    },
    showRating() {
      this.showRate = true

    },
    hideRating() {
      this.showRate = false;
    },

  },
  computed: {

    displayOnlinePrice() {
      return this.profile.session_type === "Online" || this.profile.session_type === "Online & Onsite";
    },

    displayOnsitePrice() {
      return this.profile.session_type === "Onsite" || this.profile.session_type === "Online & Onsite";
    },
    highlightedDates() {
      return this.calendars.map(calendar => moment(calendar.date).toDate());
    },

    onsites() {
      return [
        {
          name: 'Onsite', translationKey: this.$t('Onsite')
        },
        { name: 'Online', translationKey: this.$t('Online') }
      ]
    },

    types() {
      return [
        { name: 'Individual', translationKey: this.$t('Appointments.Individual') },
        { name: 'Group', translationKey: this.$t('Appointments.Group') }

      ]
    },







  },



  components: {
    UserNavbar, Footer
  },


};
</script>


<style scoped>
@import './../../../public/style.css';

.appointment-slot {
  display: flex;
  align-items: center;
}

.disabled {
  pointer-events: none;
  background-color: red;
  border: 0px solid !important;

}

.message {
  color: white;
  font-size: 12px;
}
</style>

