<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <div>
                <h2>Categories</h2>
                <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
                </PrimeMessage>
                <PrimeMessage severity="error" v-if="this.errorMessage">{{ this.errorMessage }}
                </PrimeMessage>

                <DataTable :value="categories" tableStyle="min-width: 50rem">
                  <template #empty>
                    <div v-if="categories.length == 0">No Knowledgebases Categories</div>
                  </template>
                  <PrimeColumn field="name" header="Name">
                    {{ rowData.name }}
                  </PrimeColumn>
                
                     <PrimeColumn field="english_name" header="English Name">
                    {{ rowData.english_name }}
                  </PrimeColumn>
                  <PrimeColumn field="description" header="Description">
                    {{ rowData.description }}
                  </PrimeColumn>
                  <PrimeColumn field="approved" header="Approved Category">
                    {{ rowData.approved === 1 ? 'Yes' : 'No This Added By User And Need Approval' }}
                  </PrimeColumn>
                  <PrimeColumn :exportable="false" style="min-width:8rem">
                    <template #body="rowData">
                        <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2" 
                        @click="showUpdateForm(rowData.data.id)" />
                        <PrimeButton icon="pi pi-trash" outlined rounded severity="danger" 
                        @click="deleteCategory(rowData.data.id)" />
                    </template>
                </PrimeColumn>
                </DataTable>



                <div v-if="showUpdate">
                  <h3 class="mt-5">Update Category</h3>
                  <form @submit.prevent="submitUpdateForm">

                    <div class="grid p-fluid mt-3">

                      <div class="field col-12">
                        <label>Name:</label>
                        <InputText type="text" v-model="updatedCategory.name" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.name"
                          v-for="(error, index) in errors.name" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                      <div class="field col-12">
                        <label>English Name:</label>
                        <InputText type="text" v-model="updatedCategory.english_name" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.english_name"
                          v-for="(error, index) in errors.name" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>

                    </div>
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 ">
                        <label>Description:</label>
                        <InputText type="text" v-model="updatedCategory.description" />
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                          v-for="(error, index) in errors.description" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>

                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <PrimeButton type="submit">Update Category</PrimeButton>
                      </div>
                      <div class="field col-12 md:col-6">
                        <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      previewphoto: "",
      errors: {},

      categories: [],
      category_names: [],
      category_namess: [],
      errorMessage: '',
      successMessage: '',
      showUpdate: false,
      updateId: null,
      updatedCategory: null,
      updatedCategoryNames: null,
      type: null,
      author: null,
      title: null,
      link: '',
      reference: '',
      knowfile: '',
      photo: '',
      updatedknowfile: '',
      updatedphoto: '',
      approved: '',
      english_name: '',
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      layoutMode: 'static',

      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
            { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // <!-- { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' }, -->

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase', icon: 'pi pi-fw pi-book',
          items: [

            { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
            { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },

        {
          label: 'Doctors', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
          label: ' Patient Label', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to: "/add-label-patient" },
            { label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to: "label-patient" }
          ]
        },
        {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


          ]
        },
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


          ]
        },
        {
          label: '', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
          ]

        },
        {
          label: '',
          items: [
            { label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
          ]
        },
        {
          label: '',
          items: [
            { label: 'Logout', icon: 'pi pi-power-off', command: this.logout }
          ]

        },
      ]

    };
  },
  mounted() {
    this.fetchCategories();
  },

  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage
          this.$router.push('/login-page');
        })
        .catch(error => {
          console.log(error);
        });
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    expandRow(rowData) {
      rowData.expanded = true;
    },
    collapseRow(rowData) {
      rowData.expanded = false;
    },
    async fetchCategories() {
      try {
        await axios.get('https://backend.ihayanow.com/api/admin/categories', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        }).then(response => {
          this.categories = response.data;
          this.categories = response.data.map(category => ({
            ...category,
            approved: category.approved === 1 ? 'Yes' : 'No This Added By User And Need Approval'
          }));
          console.log(this.categories)
          this.updatedCategory = this.categories;
        })

        // const data = await response.json();



      } catch (error) {
        console.log('Error fetching Knowledgebases:', error);
      }
    },
    showUpdateForm(id) {
      console.log(id);
      this.showUpdate = true
      this.updateId = id
      this.updatedCategory =
        this.categories.find(category => category.id === id);

      console.log(this.categories);
    },


    hideUpdateForm() {

      this.showUpdate = false;
      this.updateId = null;

    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    async submitUpdateForm() {

      await axios.post('https://backend.ihayanow.com/api/admin/categories_update', {
        id: this.updatedCategory.id,
        name: this.updatedCategory.name,
        english_name: this.updatedCategory.english_name,
        description: this.updatedCategory.description,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          this.successMessage = 'Category updated successfully.';
          this.fetchCategories();
          this.hideUpdateForm();
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.errors = error.response.data.message;

        });
    },


    async deleteCategory(knowledgebaseId) {
      try {
        await axios.get('https://backend.ihayanow.com/api/admin/delete-categories', {
          params: {
            id: knowledgebaseId
          },

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        }).then(response => {

          console.log(response)
          if (response.status == 200) {
            this.errorMessage = 'Category deleted successfully.';
            this.showUpdateForm = false;
            this.fetchCategories();
          } else {
            console.log(response)
            this.errorMessage = 'Failed to delete Category.';
          }
        }).catch(error => {
          console.log(error);
        })

      } catch (error) {
        console.error('Error deleting Category:', error);
        this.errorMessage = 'Failed to delete Category.';
      }
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },


  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
  },


  computed: {

    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }

}

</script>

<style scoped>
.doctor-photo {
  width: 150px;
  /* Adjust the width as per your requirements */
  height: 150px;
  /* Adjust the height as per your requirements */
  object-fit: cover;
  /* Ensure the image covers the entire space */
}

.body-preview {
  max-height: 80px;
  /* Adjust the height as per your requirements */
  overflow: hidden;
}

.body-preview p {
  margin-bottom: 5px;
}

.body-preview button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
}

.layout-topbar {

  background-color: var(--surface-card);
}
</style>