<template>
    <!-- <div class="container">
        <AdminNavbar /> -->
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <!-- <AdminNavbar /> -->
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <div class="grid">
                    <div class="field col-12">
                        <div class="card py-4">
                            <h5>Generate Credentials</h5>
                            <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
                            </PrimeMessage>
                            <PrimeMessage severity="error" v-show="this.error">{{ this.message }}
                            </PrimeMessage>
                            <form @submit.prevent="submitForm">

                                <div class="grid p-fluid mt-3">

                                    <div class="field col-12 md:col-6">
                                        <label for="platform_name" style="font-weight: bold;">Platform Name</label>
                                        <PrimeDropdown id="platform_name" class="w-full mb-3" v-model="platform_name"
                                            :options="platform_names" optionLabel="name" placeholder="Select One"
                                            style="  border: 2px; border-radius: 4px;">
                                        </PrimeDropdown>
                                        <InlineMessage class="w-full mt-3" v-show="errors && errors.platform_name"
                             v-for="(error, index) in errors.platform_name" :key="index">
                             {{ error }}
                          </InlineMessage>
                                       
                                    </div>



                                </div>

                                <hr class="horizontal dark" />
                                <div class="row edit-button text-center">
                                    <PrimeButton type="submit">Generate</PrimeButton>

                                </div>
                            </form>


                            <div class="mt-4">
                                <PrimeButton type="submit" @click="getData()">Show Credential</PrimeButton>

                            </div>
                            <div v-if="show">


                                <TabView>
                                    <TabPanel header="API Credentials" class="mt-4">
                                    <DataTable :value="apis" :paginator="true" class="p-datatable-gridlines" :rows="10"
                                        dataKey="id" :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                                        <template #empty>
                                            <div v-if="apis.length == 0">No Data</div>
                                        </template>
                                        <template #loading>
                                            Loading data. Please wait.
                                        </template>
                                        <PrimeColumn header="Platform Name" field="APP_Name" style="min-width: 10rem">
                                            <template #body="rowData">
                                                {{ rowData.data.APP_Name }}
                                            </template>
                                        </PrimeColumn>

                                        <PrimeColumn header="API Key " field="API_Key" style="min-width: 10rem">
                                            <template #body="rowData">
                                                {{ rowData.data.API_Key }}
                                            </template>
                                        </PrimeColumn>
                                        <PrimeColumn header="API Secret" field="API_Secret" style="min-width: 10rem">
                                            <template #body="rowData">
                                                {{ rowData.data.API_Secret }}
                                            </template>
                                        </PrimeColumn>

                                        <!-- <PrimeColumn header="Actions" style="min-width: 10rem">
                                            <template #body="rowData">
                                               
                                            </template>

                                        </PrimeColumn> -->
                                    </DataTable>

</TabPanel>



                                </TabView>


                                <PrimeButton class="mr-2 mb-2 text-center"
                                                    @click="Send(rowData)">
                                                    Send
                                                </PrimeButton>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
    data() {
        return {
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
            success: false,
            platform_name: '',
            platform_type: '',
            API_Secret: '',
            API_Key: '',
            value:'',
            apis: [],
            // IHAYANOW Web
            platform_names: [
                { name: 'IHAYANOW Web' },
                { name: 'IHAYANOW Mobile' },
            ],
            errors: {},
            show: false,
            error:false,

            showKnowFileInput: true,
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,

            menu: [
                {
                    label: 'Home', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },

                    ]

                },
                {
                    label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
                    items: [
                        { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
                        { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

                    ]
                },
                {
                    label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
                    items: [

                        { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
                        { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
           { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

                        { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
                    ]
                },
                {
                    label: 'Medication', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
                        { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
                    ]
                },
                {
                    label: 'Doctors', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
                        // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

                    ]
                },
                {
                    label: ' Patient Label', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to: "/add-label-patient" },
                        { label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to: "label-patient" }
                    ]
                },
                {
                    label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
                    items: [
                        { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
                        { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
                        { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
                        { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
                    ]
                },
                {
                    label: 'Sessions', icon: 'pi pi-fw pi-clock',
                    items: [
                        { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


                    ]
                },
                {
                    label: 'Reports', icon: 'pi pi-fw pi-file',
                    items: [
                        { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


                    ]
                },
                {
                    label: '', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
                    ]

                },
                { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
            ]
        };
    },

    methods: {

        async logout() {
            await axios.post('https://backend.ihayanow.com/api/logout', null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear();

                    this.$router.push('/login-page');

                })
                .catch(error => {
                    console.log(error);
                });
        },
      async  submitForm() {

            this.platform_type = navigator.platform;
          await  axios.post('https://backend.ihayanow.com/api/admin/validate-request', {
                platform_name: this.platform_name.name,
                platform_type: this.platform_type
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                },
            })
                .then(response => {

                    this.value=response.data.success

                    if (this.value == true) {
                        this.success = true;
                        this.message = response.data.message;
                        console.log("hi");
                    } else if (this.value == false) {
                        this.error=true;
                        this.success = false;
                        this.message = response.data.message;

                    }


                })
                .catch(error => {
                    this.errors = error.response.data.message;
                    console.log(error);
                });
        },

       async getData() {
            this.show = true;
         await axios.get('https://backend.ihayanow.com/api/admin/get-validate', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                    'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                },
            })
                .then(response => {
                    this.apis = response.data;
                    console.log(this.apis);
                })
                .catch(error => {
                    this.errors = error.response.data.message;
                    console.log(error);
                });

        },


       async Send(rowData){
        console.log(rowData);
           await axios.post('https://backend.ihayanow.com/api/admin/send-validate-request' ,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data','Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
                },
            })
                .then(response => {

                    this.value=response.data.success

                    if (this.value == true) {
                        this.success = true;
                        this.message = response.data.message;
                    } else if (this.value == false) {
                        this.error=true;
                        this.success = false;
                        this.message = response.data.message;

                    }


                })
                .catch(error => {
                    this.errors = error.response.data.message;
                    console.log(error);
                });
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },

    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        // 'Footer': Footer,
    },


    computed: {

        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
    }
};
</script>

<style scoped>

.layout-topbar {
   
   background-color: var(--surface-card);
   }
</style>