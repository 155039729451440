
<template>
  <div class="container">
    <component :is="navbarComponent" />


    <div class=" mt-5 card">
<div class="hello">

  <div class="card md:col-5 mx-4">
        <div class="card-body p-3">


          <div class="profile-info">
            <div class="profile-image">
              <img :src="'https://backend.ihayanow.com/' + profile.photo" alt="Profile Image">
            </div>
            <div class="profile-details mx-4">
              <h5 class="mb-1">{{ $t('profile.name') }} : {{ displayName }}</h5>
              <p class="mb-0 font-weight-bold">
                {{ $t("profile.professional") }}: {{ profile.professional == "Psychologist" ?
                  $t("profile.Psychologist") : $t("profile.Psychastric") }} ,
                {{ $t("profile.Specialist in treating") }}
                <span v-for="(spec, index) in this.speciality" :key="index">

                  {{ this.$i18n.locale === 'ar' ? spec.name + ' ، ' : spec.english_name + ' ، ' }}
                </span>
              </p>
              <p class="mb-0 font-weight-bold">
                {{ $t("profile.language") }}: {{ $t(`${profile.language}`) }}
              </p>
              <PrimeRating v-model="profile.rating" class="pt-2" :cancel="false"></PrimeRating>


            </div>

          </div>

          <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                  <i class="p-menuitem-icon pi pi-fw pi-cog"></i> {{ $t("profile.session_type") }}</span>
                <p for="example-text-input" class="form-control-label pt-2">
                  {{ profile.session_type == "Online & Onsite" ? $t("profile.Online & Onsite") :
                    $t(`${profile.session_type}`) }}
                </p>
              </span>

            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-clock"></i>{{ $t("profile.session_duration") }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.session_duration }} {{
                  $t('minutes') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6" v-if="displayOnsitePrice">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-money-bill"></i>{{
                      $t("profile.onsite_session_price") + ' / '
                      + profile.session_duration + ' ' + $t('profile.minute') }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.onsite_price }} {{
                  $t('Payments.EGP') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6" v-if="displayOnlinePrice">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label mr-2 " style="font-weight: bold;"> <i
                    class="p-menuitem-icon pi pi-fw pi-credit-card"></i>{{
                      $t("profile.online_session_price") + ' / '
                      + profile.session_duration + ' ' + $t('profile.minute') }}</span>
                <p for="example-text-input " class="form-control-label pt-2">{{ profile.online_price }} {{
                  $t('Payments.EGP') }}</p>
              </span>
            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label" style="border-bottom: 1px solid var(--primary-color);">
                <span for="example-text-input" class="form-control-label" style="font-weight: bold;">
                  <i class="p-menuitem-icon pi pi-fw pi-map-marker"></i>{{ $t("profile.address") }}</span>
                <p for="example-text-input" class="form-control-label" v-if="this.$i18n.locale === 'ar'">{{
                  profile.address }} - {{ city_name.arabic_name }} - {{ state_name.arabic_name }} - {{
    country_name.arabic_name }}</p>
                <p for="example-text-input" class="form-control-label" v-else>{{ profile.address }} - {{
                  city_name.english_name }} - {{ state_name.english_name }} - {{ country_name.english_name }}</p>

              </span>
            </div>




          </div>
          <div class="grid p-fluid mt-3  text-center justify-content-center">
            <div class="md:col-4 m-2">
              <PrimeButton type="button" @click.prevent="showRequestForm()">
                {{ $t('Appointments.Request a Session') }}
              </PrimeButton>
            </div>
            <div class="md:col-4 m-2">
              <PrimeButton type="button" @click.prevent="showRating()">
                {{ $t('Write Your Rating') }}
              </PrimeButton>
            </div>
          </div>

        </div>
      </div>


      <!-- <div class=" md:col-6">

        <div class="card" v-if="doctorRating.length > 0">

          <Carousel v-if="doctorRating.length > 0" :value="doctorRating" :numVisible="1" :numScroll="1"
            :responsiveOptions="carouselResponsiveOptions" :dir="selectedLanguage === 'ar' ? 'rtl' : 'ltr'">
            <template #item="doctor">
              <div class="profile-info d-flex justify-content-center">
                <div class="profile-image" :dir="this.$i18n.locale == 'ar' ? 'ltr' : 'rtl'">
                  <img class="shadow-2" :src="'https://backend.ihayanow.com/' + doctor.data.userProfile.photo"
                    alt="Profile Image" width="100" height="100">
                </div>

                <div class="pt-3">
                  <h5 class="mb-1" v-if="this.$i18n.locale === 'ar'" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                    {{ doctor.data.userProfile.arabic_name }}</h5>
                  <h5 class="mb-1" v-else>
                    {{ doctor.data.userName.name }}</h5>

                  <div  :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                    <PrimeRating class="text-center" :modelValue="doctor.data.doctorRating.rate" :readonly="true"
                      :cancel="false">
                    </PrimeRating>
                  </div>
                </div>


              </div>
              <p class="pt-3 text-center"> {{ doctor.data.doctorRating.review }}</p>
            </template>
          </Carousel>


        </div>
      </div> -->


</div>
     
    </div>



    <div v-if="showUpdate" class="md:col-12 justify-content-center ">
      <div class="pt-3">
        <div class="card">
          <h3>{{ $t('Appointments.Request a Session') }}</h3>
          <PrimeMessage class="w-full mt-3" severity="success" v-if="this.successData" :key="index">
            {{ translateErrorMessage(this.successData) }}
          </PrimeMessage>
          <!-- <h3> {{ $t('Appointments.Request a Session') }}</h3> -->
          <form @submit.prevent="submitRequestForm()">
            <div class="grid p-fluid mt-3">
              <div class="field col-12 md:col-6">
                <label style="font-weight: bold;">{{ $t('Appointments.Date') }} : </label>
                <InputText type="date" v-model="date" />
                <InlineMessage class="w-full mt-3" v-show="errors && errors.date" v-for="(error, index) in errors.date"
                  :key="index">
                  {{ translateErrorMessage(error) }}
                </InlineMessage>
              </div>
              <div class="field col-12 md:col-6">
                <label style="font-weight: bold;">{{ $t('Sessions.From') }} :</label>
                <InputText type="time" v-model="from" />
                <InlineMessage class="w-full mt-3" v-show="errors && errors.from" v-for="(error, index) in errors.from"
                  :key="index">
                  {{ translateErrorMessage(error) }}
                </InlineMessage>
              </div>
              <div class="field col-12 md:col-6">
                <label style="font-weight: bold;">{{ $t('Sessions.To') }} :</label>
                <InputText type="time" v-model="to" />
                <InlineMessage class="w-full mt-3" v-show="errors && errors.to" v-for="(error, index) in errors.to"
                  :key="index">
                  {{ translateErrorMessage(error) }}
                </InlineMessage>
              </div>
              <div class="field col-12 md:col-6">
                <label for="onsite" style="font-weight: bold;">{{ $t('Appointments.Appointment Location') }}
                </label>

                <PrimeDropdown id="state" class="w-full mb-3" v-model="onsite" :options="onsites"
                  optionLabel="translationKey" :placeholder="$t('profile.Select_One')"
                  style="  border: 2px; border-radius: 4px;">
                </PrimeDropdown>
              </div>

              <div class="field col-12 md:col-6">
                <label for="type" style="font-weight: bold;">{{ $t('Appointments.Appointment Type') }}</label>
                <PrimeDropdown id="state" class="w-full mb-3" v-model="type" :options="types" optionLabel="translationKey"
                  :placeholder="$t('profile.Select_One')" style="  border: 2px; border-radius: 4px;">
                </PrimeDropdown>
              </div>
              <div class="field col-12 md:col-6">
                <label for="number_of_attends" style="font-weight: bold;">
                  {{ $t('Appointments.Number Of Attendance') }}</label>
                <InputText type="number" v-model="number_of_attends" />

              </div>
            </div>
            <div class="grid p-fluid mt-3">
              <div class="field col-12 md:col-6">
                <PrimeButton type="submit">{{ $t('Request') }}</PrimeButton>
              </div>
              <div class="field col-12 md:col-6">
                <PrimeButton @click="hideRequestForm()">{{ $t('Cancel') }}</PrimeButton>
              </div>
            </div>
          </form>
        </div>


      </div>
    </div>


    <div v-if="showRate" class="md:col-12 justify-content-center">
      <div class="card">
        <h1 class="text-center">{{ $t('rate.Rating') }}</h1>

        <div class="grid p-fluid mt-3">

          <div class="field col-12 md:col-6">
            <label style="font-weight: bold;">{{ $t('rate.Session Rate') }}</label>
            <PrimeRating v-model="session_rating" :cancel="false"></PrimeRating>
            <InlineMessage class="w-full mt-3" v-show="errors && errors.session_rating"
              v-for="(error, index) in errors.to" :key="index">
              {{ translateErrorMessage(error) }}
            </InlineMessage>
          </div>
          <div class="field col-12 md:col-6">
            <label for="doctor_rating" style="font-weight: bold;">
              {{ $t('rate.Doctor Rate') }}
            </label>

            <PrimeRating v-if="userType === 'User'" v-model="doctor_rating" :cancel="false">
            </PrimeRating>
          </div>

          <div class="field col-12 ">
            <label for="opinion" style="font-weight: bold;">
              {{ $t('rate.Any Other Opinion') }}</label>
            <PrimeTextarea :placeholder="$t('rate.Your Opinion')" :autoResize="true" rows="9" cols="70"
              v-model="opinion" />
          </div>

        </div>

        <div class="grid p-fluid mt-3">
          <div class="field col-12 md:col-6">
            <PrimeButton @click="submit_rate()" type="submit">{{ $t('save') }}
            </PrimeButton>
          </div>
          <div class="field col-12 md:col-6">
            <PrimeButton @click="hideRating()">{{ $t('Cancel') }}</PrimeButton>
          </div>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>


  
<script>
import UserNavbar from './User/UserNavbar.vue';
import DoctorNavbar from './Doctor/DoctorNavbar.vue';
import Navbar from './Navbar.vue';
import axios from 'axios';
import Footer from './Footer.vue';
import Carousel from 'primevue/carousel';

export default {
  data() {
    return {
      name: "",
      email: "",
      from: '',
      to: '',
      date: '',
      type: 'individual',
      onsite: '',
      rating: 0,

      session_rating: 0,
      doctor_rating: 0,
      user_rating: 0,
      userType: '',
      opinion: '',
      new_type: '',
      new_onsite: '',
      errors: {},
      number_of_attends: 1,
      profile: [],
      navbarComponent: null,
      country_name: '',
      state_name: '',
      city_name: '',
      speciality: [],
      speciality_arabic_name: [],
      speciality_english_name: [],
      speciality_arabic: [],
      speciality_english: [],
      doctorRating: [],
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      showUpdate: false,
      showRate: false,
      carouselResponsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "768px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
          numScroll: 1,
        },
      ],

    };
  },

  async mounted() {
    this.userType = localStorage.getItem("type");
    // this.navbarComponent = userType === "User" ? UserNavbar : DoctorNavbar;
    if (this.userType === "User") {
      this.navbarComponent = UserNavbar
    } else if (this.userType === "Doctor") {
      this.navbarComponent = DoctorNavbar;
    } else {
      this.navbarComponent = Navbar;
    }
    await this.fetchProfile();
    // await this.getRating();





  },
  methods: {
    // async getRating(doctorId){
    // await  axios.get("https://backend.ihayanow.com/api/get-doctor-rating", {
    //     params: {
    //       doctor_id: doctorId
    //     }, headers: {
    //       'Content-Type': 'application/json',
    //       'Api-Key': this.apiKey,
    //       'Api-Secret': this.apiSecret
    //     },
    //   }).then(response => {
    //    const result = response.data; // Assign the array from the response data to calendars
    //  console.log(result);
    //   })
    //     .catch(error => {
    //       console.error(error);
    //     });

    // },
    async fetchProfile() {
      let result = axios.get("https://backend.ihayanow.com/api/get_profile_for_user", {
        params: {
          profile_id: this.$route.params.id
        }, headers: {
          'Content-Type': 'application/json',
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        },
      }).then(response => {
        result = response.data; // Assign the array from the response data to calendars
        console.log(result);
        this.name = result[0].name;
        this.email = result[0].email;
        console.log(this.name);
        this.profile = result[0].profile;
        this.country_name = result[0].country_name;
        this.state_name = result[0].state_name;
        this.city_name = result[0].city_name;
        this.speciality = result[0].speciality;
        console.log(this.profile, this.speciality)
        axios.get("https://backend.ihayanow.com/api/get-doctor-rating", {
          params: {
            doctor_id: this.profile.user_id
          }, headers: {
            // 'Authorization': `Bearer ${localStorage.getItem('token')}`,
            // 'Content-Type': 'application/json',
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret
          },
        }).then(response => {
          this.doctorRating = response.data; // Assign the array from the response data to calendars
          console.log(this.doctorRating);
        })
          .catch(error => {
            console.error(error);
          });

      })
        .catch(error => {
          console.error(error);
        });





    },

    submitRequestForm() {
      if (this.onsite.translationKey == 'أونلاين' || this.onsite.translationKey == 'أوفلاين') {
        this.new_onsite = this.onsite.name;
      } else {
        this.new_onsite = this.onsite.name;
      }
      if (this.type.translationKey == 'فردي' || this.type.translationKey == 'جماعة') {
        this.new_type = this.type.name
      } else {
        this.new_type = this.type.name;
      }
      this.new_onsite = this.new_onsite === 'Onsite' ? 1 : 0;

      console.log(this.id, this.from, this.to, this.date, this.new_type, this.new_onsite, this.number_of_attends);
      const url = `https://backend.ihayanow.com/api/user/request-session?doctor_id=
      ${this.id}&from=${this.from}&to=${this.to}&date=${this.date}&type=${this.new_type}
      &onsite=${this.new_onsite}&number_of_attendees=${this.number_of_attends}`;
      const token = localStorage.getItem('token');

      axios.post(url, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret
        },
      }).then(response => {
        this.successData = response.data.message;
        this.showUpdate = false;

      })
        .catch(error => {
          this.errors = error.response.data.message;
          console.log(this.errors);
        });
    },
    showRequestForm() {
      this.showUpdate = true

    },
    hideRequestForm() {
      this.showUpdate = false;
    },
    showRating() {
      this.showRate = true

    },
    hideRating() {
      this.showRate = false;
    },


  },
  components: { UserNavbar, Footer, Carousel },
  computed: {

    onsites() {
      return [
        {
          name: 'Onsite', translationKey: this.$t('Onsite')
        },
        { name: 'Online', translationKey: this.$t('Online') }
      ]
    },

    types() {
      return [
        { name: 'Individual', translationKey: this.$t('Appointments.Individual') },
        { name: 'Group', translationKey: this.$t('Appointments.Group') }

      ]
    },

    displayOnlinePrice() {
      return this.profile.session_type === "Online" || this.profile.session_type === "Online & Onsite";
    },

    displayOnsitePrice() {
      return this.profile.session_type === "Onsite" || this.profile.session_type === "Online & Onsite";
    },
    displayName() {
      if (this.$i18n.locale === 'ar') {
        return this.profile.arabic_name;
      } else {
        return this.name;
      }
    },
    //   displaySpeciality() {
    //     let specialty = '';

    // if (this.$i18n.locale === 'ar') {
    //   // Handle Arabic specialties
    //   console.log(typeof this.speciality_arabic_name,Array.isArray(this.speciality_arabic_name))
    //   specialty = Array.isArray(this.speciality_arabic_name)
    //     ? this.speciality_arabic_name.join(', ')
    //     : this.speciality_arabic_name;
    // } else {
    //   // Handle English specialties
    //   specialty = Array.isArray(this.speciality_english_name)
    //     ? this.speciality_english_name.join(', ')
    //     : this.speciality_english_name;
    // }

    // return specialty || ''; 

    //   },
  },
}

</script>

<style>
/* .p-carousel-container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
} */

.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.p-button {
  display: inline !important;
}

.profile-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details h5 {
  margin-bottom: 5px;
}
.hello{
  display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 750px) {
  .hello {
    display: block;
    flex-wrap: nowrap;
  }
}
</style>
