
<template>
  <div class="container">
    <guestUserNavbar />
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="card py-4">
            <h5> {{ $t('profile.Complete Profile') }}</h5>
            <form @submit.prevent="register">

              <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-6">
                  <label for="arabic_name" style="font-weight: bold;">{{ $t("profile.Arabic Name") }}</label>
                  <InputText id="arabic_name" type="text" v-model="arabic_name" />
            
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.arabic_name"
                           v-for="(error, index) in errors.arabic_name" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>

                </div>
                <div class="field col-12 md:col-6">
                  <label for="age" style="font-weight: bold;">{{ $t("profile.age") }}</label>
                  <InputText id="age" type="text" v-model="age" />
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.age"
                           v-for="(error, index) in errors.age" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>

                </div>
                <div class="field col-12 md:col-6">
                  <label for="birth_day" style="font-weight: bold;">{{ $t("profile.birthdate") }}</label>
                  <InputText id="birth_day" type="date" v-model="birth_date" />
                 
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.birth_date"
                           v-for="(error, index) in errors.birth_date" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                </div>
               

                <div class="field col-12 md:col-6">
                  <label for="language" style="font-weight: bold;">{{ $t('profile.language') }}</label>
                  <!-- <InputText id="language" type="text" v-model="language" /> -->
                  <PrimeDropdown id="state" v-model="language" :options="languages" optionKey="name"
                    optionLabel="translationKey" :placeholder="$t('profile.Select_One')">

                  </PrimeDropdown>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.language"
                           v-for="(error, index) in errors.language" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                  
                </div>
                <div class="field col-12 md:col-6">
                  <label for="gender" style="font-weight: bold;">{{ $t('profile.gender') }}</label>
                  <PrimeDropdown id="state" v-model="gender" :options="genders" optionKey="name"
                    optionLabel="translationKey" :placeholder="$t('profile.Select_One')">

                  </PrimeDropdown>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.gender"
                           v-for="(error, index) in errors.gender" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>


                </div>
                <div class="field col-12 md:col-6">
                  <label for="country" style="font-weight: bold;">{{ $t('profile.country') }}</label>
                  <!-- <InputText id="language" type="text" v-model="language" /> -->
                  <PrimeDropdown id="state" v-model="country" :options="countries" optionKey="name"
                    optionLabel="name" :placeholder="$t('profile.Select_One')">

                  </PrimeDropdown>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.country_id"
                           v-for="(error, index) in errors.country_id" :key="index">
                           {{ translateErrorMessage(error) }}
                  </InlineMessage>
                  
                </div>
                <div class="field col-12 md:col-6">
                  <label for="state" style="font-weight: bold;">{{ $t('profile.state') }}</label>
                  <!-- <InputText id="language" type="text" v-model="language" /> -->
                  <PrimeDropdown id="state" v-model="state" :options="states" optionKey="name"
                    optionLabel="name" :placeholder="$t('profile.Select_One')">

                  </PrimeDropdown>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.state_id"
                           v-for="(error, index) in errors.state_id" :key="index">
                           {{ translateErrorMessage(error) }}
                  </InlineMessage>
                  
                </div>
                <div class="field col-12 md:col-6">
                  <label for="city" style="font-weight: bold;">{{ $t('profile.city') }}</label>
                  <!-- <InputText id="language" type="text" v-model="language" /> -->
                  <PrimeDropdown id="city" v-model="city" :options="cities" optionKey="name"
                    optionLabel="name" :placeholder="$t('profile.Select_One')">

                  </PrimeDropdown>
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.city_id"
                           v-for="(error, index) in errors.city_id" :key="index">
                           {{ translateErrorMessage(error) }}
                  </InlineMessage>
                  
                </div>
                <div class="field col-12 md:col-6">
                  <label for="address" style="font-weight: bold;">{{ $t("profile.address") }}</label>
                  <InputText id="address" type="text" v-model="address" />
                
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.address"
                           v-for="(error, index) in errors.address" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                </div>
                <div class="field col-12 md:col-6">
                  <label for="weight" style="font-weight: bold;">{{ $t("profile.Weight") }}</label>
                  <InputText id="weight" type="text" v-model="weight" />
                
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.weight"
                           v-for="(error, index) in errors.weight" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                </div>
                <div class="field col-12 md:col-6">
                  <label for="height" style="font-weight: bold;">{{ $t("profile.Height") }}</label>
                  <InputText id="height" type="text" v-model="height" />
                 
                  <InlineMessage class="w-full mt-3" v-show="errors && errors.height"
                           v-for="(error, index) in errors.height" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage>
                </div>

                <div class="field col-12 md:col-6">
                  <label for="birth_day" style="font-weight: bold;">{{ $t('profile.Smoker') }}</label>
                  <PrimeDropdown id="state" v-model="smoker" :options="smoking" optionLabel="name"
                    :placeholder="$t('profile.Select_One')">
                  </PrimeDropdown>
                  <!-- <InlineMessage class="w-full mt-3" v-show="errors && errors.smoker"
                           v-for="(error, index) in errors.smoker" :key="index">
                           {{ translateErrorMessage(error) }}
                        </InlineMessage> -->


                </div>
              </div>
              <hr class="horizontal dark" />
              <div class="row edit-button text-center">
                <PrimeButton type="submit">{{ $t('save') }}</PrimeButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import guestUserNavbar from './guestUserNavbar.vue';
import Footer from '../Footer.vue';
export default {
  data() {
    return {
      profile_id: "",
      birth_date: "",
      age: "",
      language: "",
      gender: "",
      type: "",
      weight: "",
      height: "",
      // photo: "",
      event: "",
      smoker: '',
      arabic_name: '',
      new_smoker: '',
      new_language: '',
      address:'',
      country: {
      id: null,
      name: "",
      translationKey: "",
    },
    state: {
      id: null,
      name: "",
      translationKey: "",
    },
    city: {
      id: null,
      name: "",
      translationKey: "",
    },
      y: localStorage.getItem('token'),
      new_gender:'',
      errors: {},
      countries:[],
      cities:[],
      states:[],
      apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,
    }

  }, name: 'Complete-page',


  methods: {

 
    translateErrorMessage(error) {
         return this.$i18n.t(error);
      },
    async register() {
      if (this.smoker.name == 'نعم' || this.smoker.name == 'لا') {
        this.new_smoker = this.smoker.translationKey
      } else {
        this.new_smoker = this.smoker.translationKey;
      }
      if (this.language.translationKey == 'عربي' || this.language.translationKey == 'إنجليزي' || this.language.translationKey == 'عربي و إنجليزي') {
        this.new_language = this.language.name
      } else {
        this.new_language = this.language.name;
      }
      if (this.gender.translationKey == 'ذكر' || this.gender.translationKey == 'أنثي') {
        this.new_gender = this.gender.name
      } else {
        this.new_gender = this.gender.name;
      }



      try {
        const response = await axios('https://backend.ihayanow.com/api/user/profiles', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Api-Key':this.apiKey,
            'Api-Secret':this.apiSecret
          },
          data: {
            birth_date: this.birth_date,
            age: this.age,
            language: this.new_language,
            gender: this.new_gender,
            weight: this.weight,
            height: this.height,
            arabic_name: this.arabic_name,
            smoker: this.new_smoker == "Yes" ? '1' : '0',
            country_id: this.country.id, 
            state_id: this.state.id, 
            city_id: this.city.id, 
            address: this.address,
          },
        });


        const data = await response.data
        if (data.success) {
          this.profile_id = data.data.id; 
          localStorage.setItem('profile_id', data.data.id);
          // this.$router.push('/user-profile');
          this.$router.push('/therapists-page')
        
        }
        
      } catch (error) {
        this.errors = error.response.data.errors;
        console.error(this.errors)
      }
    },
    async fetchStates(countryId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}`,
      'Api-Key':this.apiKey ,
    'Api-Secret':this.apiSecret};
        const response = await axios.get(`https://backend.ihayanow.com/api/get-states/${countryId}`, { headers });
       
        this.states = response.data.map(state => ({
          id: state.id,
          name: this.$i18n.locale === 'ar' ? state.arabic_name : state.english_name,
          translationKey: state.english_name, // Assuming english_name is unique identifier
 
        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },
    async fetchCities(cityId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}`,'Api-Key':this.apiKey,
      'Api-Secret':this.apiSecret };
        const response = await axios.get(`https://backend.ihayanow.com/api/get-cities/${cityId}`, { headers });
       
        this.cities = response.data.map(city => ({
          id: city.id,
          name: this.$i18n.locale === 'ar' ? city.arabic_name : city.english_name,
          translationKey: city.english_name, // Assuming english_name is unique identifier
 
        }));
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    },
  },

  computed: {
    smoking() {
      return [
        { name: this.$t('Yes'), translationKey: 'Yes' },
        { name: this.$t('No'), translationKey: 'No' },

      ]

    },

    languages() {
      return [
        { translationKey: this.$t('profile.arabic'), name: "Arabic" },
        { translationKey: this.$t('profile.english'), name: "English" },
        { translationKey: this.$t('profile.both'), name: "Arabic & English"},
      ]
    },

    genders() {
      return [
        { translationKey: this.$t("profile.male"), name: 'Male' },
        { translationKey: this.$t("profile.female"), name: 'Female' },
      ]
    }
  },
  async mounted(){
    try {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}`,'Api-Key':this.apiKey ,
  'Api-Secret':this.apiSecret};
    const response = await axios.get("https://backend.ihayanow.com/api/get-countries", { headers });
    this.countries = response.data.map(country => ({
      id: country.id,
      name: this.$i18n.locale === 'ar' ? country.arabic_name : country.english_name,
      translationKey: country.english_name, // Assuming english_name is unique identifier
    }));
  } catch (error) {
    console.error("Error fetching countries:", error);
  }

  },
  watch: {
    // Watch for changes in the selected country
    country: {
    handler(newCountry) {
      if (newCountry.id) {
        this.fetchStates(newCountry.id); // Call API to fetch states
      }
    },
    deep: true, // Watch for changes in nested properties of country
  },
  state: {
    handler(newState) {
      if (newState.id) {
        this.fetchCities(newState.id); // Call API to fetch states
      }
    },
    deep: true, // Watch for changes in nested properties of country
  },
  },
  components: {
    guestUserNavbar,
    Footer,
  }

}

</script>


