<template>
<div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
    <!-- <AdminNavbar /> -->
    <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card">
              <h5>Doctors</h5>
              <PrimeMessage severity="success" v-show="this.success">{{ this.message }}
              </PrimeMessage>
              <TabView>
                <TabPanel header="Accepted Doctors">
                  <DataTable :value="doctors" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading1" responsiveLayout="scroll">
                    <template #empty>
                      <div v-if="doctors.length == 0">No Approved Doctors</div>
                    </template>
                    <template #loading>
                      Loading Doctors data. Please wait.
                    </template>
                    <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        <div class="img-container">
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.profile[0].photo" alt="Doctor Photo" />

                        </div>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="name" header="Doctor Name" style="min-width: 12rem">
                      {{ rowData.name }}
                    </PrimeColumn>

                    <PrimeColumn field="profile.arabic_name" header="Doctor Arabic Name" style="min-width: 12rem">
                      <template #body="rowData" >
                      {{ rowData.data.profile[0].arabic_name }}
                      </template>
                    </PrimeColumn>
                   
                  <PrimeColumn header="Speciality" field="speciality" style="min-width: 10rem">
                      <template #body="rowData" >
                        <div v-if="Array.isArray(rowData.data.speciality)">
      <p v-for="(speciality, index) in rowData.data.speciality" :key="index">{{ speciality.english_name + ' , ' }}</p>
    </div>
    <p v-else>{{ rowData.data.speciality }}</p>
                      </template>
                    </PrimeColumn>

                  <PrimeColumn header="Professional" field="profile.professional" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].professional }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="profile.address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                  </template>  
                  </PrimeColumn>
                    <PrimeColumn field="profile.gender" header="Gender" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].gender }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Birth Date" field="profile.birth_date" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].birth_date }}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="Age" field="profile.age" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].age + " Years"}}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="CV" field="profile.cv" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].cv" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Session Duration" field="profile.session_duration" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    
                    <PrimeColumn header="Session Type" field="profile.session_type" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_type  }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Onsite Price" field="profile.onsite_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].onsite_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Online Price" field="profile.online_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].online_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                    </template>
                    </PrimeColumn>
                    <PrimeColumn header="ID Number" field="profile.IDNumber" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].IDNumber }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn header="ID Copy" field="profile.IDCopy" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].IDCopy" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Liscence" field="profile.liscence" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].liscence" target="_blank">Preview
                          file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Rating" field="profile.rating" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].rating }}
                      </template>
                    </PrimeColumn>
                   
                   
                    <PrimeColumn header="Actions" style="min-width: 10rem">
                      <template #body="rowData">
                        <PrimeButton @click="returnToPending(rowData.data.profile[0].user_id)">
                          return to pending
                        </PrimeButton>
                      </template>
                    </PrimeColumn>
                  </DataTable>

                </TabPanel>


                <TabPanel header="Pending Doctors Changes">
                  <DataTable :value="changeDoctorData" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                    
                    <template #empty>
                      <div v-if="changeDoctorData.length == 0">No Doctors</div>
                    </template>
                    <template #loading>
                      Loading Doctors data. Please wait.
                    </template>

                    <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        
                        <div class="img-container">
                          
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.profile_changes.photo" alt="Doctor Photo" />
                          <span style="color:red;" class="pt-3" v-if="rowData.data.profile_changes.photo !== rowData.data.profile.photo">
                            Changed
                          </span>
                        </div>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="name" header="Doctor Name" style="min-width: 12rem">
                      {{ rowData.name }}
                    </PrimeColumn>

                    <PrimeColumn field="profile_changes.arabic_name" header="Doctor Arabic Name" style="min-width: 12rem">
                      <template #body="rowData" >
                      {{ rowData.data.profile_changes.arabic_name }}
                    
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.arabic_name !== rowData.data.profile.arabic_name">
                            Changed
                          </span>
                      </template>
                    </PrimeColumn>
                  <PrimeColumn header="Speciality" field="speciality" style="min-width: 10rem">
                      <template #body="rowData">
                        <div v-if="Array.isArray(rowData.data.speciality)">
      <p v-for="(speciality, index) in rowData.data.speciality" :key="index">{{ speciality.english_name + ' , ' }}</p>
    </div>
    <p v-else>{{ rowData.data.speciality }}</p>
                      </template>
                    </PrimeColumn>

                  <PrimeColumn header="Professional" field="profile_changes.professional" style="min-width: 10rem">
                      <template #body="rowData">
                        {{ rowData.data.profile_changes.professional }}
                
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.professional !== rowData.data.profile.professional">
                            Changed
                          </span>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="profile_changes.address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                          

                          <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.address !== rowData.data.profile.address || 
                          rowData.data.city_name.english_name !== rowData.data.city_profile_name.english_name || 
                          rowData.data.state_name.english_name !== rowData.data.state_profile_name.english_name || 
                          rowData.data.country_name.english_name !== rowData.data.country_profile_name.english_name ">
                            Changed
                          </span>
                  </template>  
                  </PrimeColumn>
                    <PrimeColumn header="Birth Date" field="profile_changes.birth_date" style="min-width: 14rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.birth_date }}
                  
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.birth_date !== rowData.data.profile.birth_date">
                            Changed
                          </span>
</template>
                    </PrimeColumn>
                    <PrimeColumn header="Age" field="profile_changes.age" style="min-width: 14rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.age + " Years"}}
                    
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.age !== rowData.data.profile.age">
                            Changed
                          </span>
</template>
                    </PrimeColumn>
                    <PrimeColumn header="CV" field="profile_changes.cv" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile_changes.cv" target="_blank">Preview file</a>

                        <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.cv !== rowData.data.profile.cv">
                            Changed
                          </span>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Session Duration" field="profile_changes.session_duration" style="min-width: 10rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.session_duration + " Minutes" }}
                 
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.session_duration !== rowData.data.profile.session_duration">
                            Changed
                          </span>
                      </template>
                    </PrimeColumn>
                    
                    <PrimeColumn header="Session Type" field="profile_changes.session_type" style="min-width: 10rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.session_type  }}
                 
                      <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.session_type !== rowData.data.profile.session_type">
                            Changed
                          </span>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Onsite Price " field="profile_changes.onsite_price" style="min-width: 10rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.onsite_price + " EG" + " / " +   rowData.data.profile_changes.session_duration + " Minutes" }}
                   
                    <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.onsite_price !== rowData.data.profile.onsite_price">
                            Changed
                          </span>
                    </template>
                    </PrimeColumn>
                    <PrimeColumn header="Online Price " field="profile_changes.online_price" style="min-width: 10rem">
                      <template #body="rowData">

                      {{ rowData.data.profile_changes.online_price + " EG" + " / " +   rowData.data.profile_changes.session_duration + " Minutes" }}
             
                    <span class="pt-3" style="color:red;" v-if="rowData.data.profile_changes.online_price !== rowData.data.profile.online_price">
                            Changed
                          </span>
                    </template>
                    </PrimeColumn>
                    <PrimeColumn header="Actions" style="min-width: 10rem">
                      <template #body="rowData">
                        <PrimeButton class="mr-2 mb-2 " icon="pi pi-check"  @click="approveChangeDoctor(rowData.data.profile_changes.profile_id)">
                          
                        </PrimeButton>
                        <PrimeButton class="mr-2 mb-2 "  style="background-color:red; border:none;" icon="pi pi-times"  @click="displayDeclineDiv(rowData.data.profile_changes.profile_id)">
                          
                        </PrimeButton>
                        
                      </template>

                    </PrimeColumn>
                  </DataTable>
                </TabPanel>

                <TabPanel header="Pending Doctors">
                  <DataTable :value="pendings" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                    <template #empty>
                      <div v-if="pendings.length == 0">No Doctors</div>
                    </template>
                    <template #loading>
                      Loading Doctors data. Please wait.
                    </template>

                    <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        <div class="img-container">
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.profile[0].photo" alt="Doctor Photo" />

                        </div>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="name" header="Doctor Name" style="min-width: 12rem">
                      {{ rowData.name }}
                    </PrimeColumn>
                    <PrimeColumn header="Doctor Arabic Name" field="profille.arabic_name" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].arabic_name }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Speciality" field="speciality" style="min-width: 10rem">
                      <template #body="rowData">



                        <div v-if="Array.isArray(rowData.data.speciality)">
      <p v-for="(speciality, index) in rowData.data.speciality" :key="index">
        <span v-if="speciality.approved == 0" style="color: red;">
          {{ speciality.english_name  }} 
          <span v-if="speciality.approved == 0" style="color: red;">
          There is a new category added check it first
        </span> 
         
        </span>
        <span v-if="speciality.approved == 1">
          {{ speciality.english_name + ' , ' }}  
        </span>
      
      </p>
    </div>
    <p v-else>{{ rowData.data.speciality }}</p>
                      </template>
                    </PrimeColumn>

                  <PrimeColumn header="Professional" field="profile.professional" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].professional }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="profile.address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                  </template>  
                  </PrimeColumn>
                    <PrimeColumn field="profile.gender" header="Gender" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].gender }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Birth Date" field="profile.birth_date" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].birth_date }}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="Age" field="profile.age" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].age + " Years"}}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="CV" field="profile.cv" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].cv" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Session Duration" field="profile.session_duration" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    
                    <PrimeColumn header="Session Type" field="profile.session_type" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_type  }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Onsite Price" field="profile.onsite_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].onsite_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Online Price" field="profile.online_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].online_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                    </template>
                    </PrimeColumn>
                    <PrimeColumn header="ID Number" field="profile.IDNumber" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].IDNumber }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn header="ID Copy" field="profile.IDCopy" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].IDCopy" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Liscence" field="profile.liscence" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].liscence" target="_blank">Preview
                          file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Rating" field="profile.rating" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].rating }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Actions" style="min-width: 10rem">
                      <template #body="rowData">
                       <PrimeButton class="mr-2 mb-2 " icon="pi pi-check"  @click="approveDoctor(rowData.data.profile[0].id)">
                        </PrimeButton>
                      </template>

                    </PrimeColumn>
                  </DataTable>
                </TabPanel>

<!--                 
                <TabPanel header="Requested Doctors Packages">
                  <DataTable :value="packages" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                    :rowHover="true" :loading="loading2" responsiveLayout="scroll">
                    <template #empty>
                      <div v-if="packages.length == 0">No Requests</div>
                    </template>
                    <template #loading>
                      Loading Doctors' Requests data. Please wait.
                    </template>

                    <PrimeColumn field="photo" header="Doctor Photo" style="min-width: 12rem">
                      <template #body="rowData">
                        <div class="img-container">
                          <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.profile[0].photo" alt="Doctor Photo" />
                        </div>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="name" header="Doctor Name" style="min-width: 12rem">
                      {{ rowData.name }}
                    </PrimeColumn>

                    <PrimeColumn header="Doctor Arabic Name" field="profille.arabic_name" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].arabic_name }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Speciality" field="speciality" style="min-width: 10rem">
                      <template #body="rowData">
                        <div v-if="Array.isArray(rowData.data.speciality)">
      <p v-for="(speciality, index) in rowData.data.speciality" :key="index">
       {{ speciality.english_name + ' , ' }}
      
      </p>
    </div>
    <p v-else>{{ rowData.data.speciality }}</p>
                      </template>
                    </PrimeColumn>

                  <PrimeColumn header="Professional" field="profile.professional" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].professional }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn field="profile.address" header="Address" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].address + " - " + rowData.data.city_name.english_name + " - " +
                          rowData.data.state_name.english_name + " - " + rowData.data.country_name.english_name }}
                  </template>  
                  </PrimeColumn>
                    <PrimeColumn field="profile.gender" header="Gender" style="min-width: 12rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].gender }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Birth Date" field="profile.birth_date" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].birth_date }}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="Age" field="profile.age" style="min-width: 14rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].age + " Years"}}
</template>
                    </PrimeColumn>
                    <PrimeColumn header="CV" field="profile.cv" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].cv" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Session Duration" field="profile.session_duration" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    
                    <PrimeColumn header="Session Type" field="profile.session_type" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].session_type  }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Onsite Price" field="profile.onsite_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].onsite_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Online Price" field="profile.online_price" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].online_price + " EG" + " / " +   rowData.data.profile[0].session_duration + " Minutes" }}
                    </template>
                    </PrimeColumn>
                    <PrimeColumn header="ID Number" field="profile.IDNumber" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].IDNumber }}
                      </template>
                    </PrimeColumn>

                    <PrimeColumn header="ID Copy" field="profile.IDCopy" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].IDCopy" target="_blank">Preview file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Liscence" field="profile.liscence" style="min-width: 10rem">
                      <template #body="rowData">

                        <a :href="'https://backend.ihayanow.com' + rowData.data.profile[0].liscence" target="_blank">Preview
                          file</a>
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Rating" field="profile.rating" style="min-width: 10rem">
                      <template #body="rowData">
                      {{ rowData.data.profile[0].rating }}
                      </template>
                    </PrimeColumn>
                    <PrimeColumn header="Actions" style="min-width: 10rem">
                      <template #body="rowData">
                       <PrimeButton class="mr-2 mb-2 " icon="pi pi-check"  @click="approveDoctor(rowData.data.profile[0].id)">
                        </PrimeButton>
                      </template>

                    </PrimeColumn>
                  </DataTable>
                </TabPanel> -->
              </TabView>



              <div v-if="showUpdate">
                  <h3>Decline Description</h3>
                  <form @submit.prevent="submitDeclineForm(this.updateId)">
                    
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 ">
                        <label>Description:</label>
                   
                        <PrimeTextarea  placeholder="Write your decline description"
                         :autoResize="true" rows="9" cols="70"
                                v-model="description" />
                       
                        <InlineMessage class="w-full mt-3" v-show="errors && errors.description"
                          v-for="(error, index) in errors.description" :key="index">
                          {{ error }}
                        </InlineMessage>
                      </div>
                    </div>
                  
                    <div class="grid p-fluid mt-3">
                      <div class="field col-12 md:col-6">
                        <PrimeButton type="submit" >Submit</PrimeButton>
                      </div>
                      <div class="field col-12 md:col-6">
                        <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AdminNavbar from './AdminNavbar.vue';
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      description:'',
      showUpdate: false,
      success:false,
      updateId:null,
      doctors: [],
      pendings: [],
      packages:[],
      changeDoctorData:[],
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      apiKey : process.env.VUE_APP_API_KEY,
      apiSecret : process.env.VUE_APP_API_SECRET,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
          { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon:'pi pi-fw pi-book',
          items: [
            
          { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
          { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' },
          ]
        },
        {
          label: 'Doctors',icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
            label: ' Patient Label', icon: 'pi pi-fw pi-file',
            items: [
            {label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to:"/add-label-patient"},
            {label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to:"label-patient"}
            ]
          },
          {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
             { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
       
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },
         

          ]
        },
        
        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },
         

          ]
        },
        {
              label: '', icon: 'pi pi-fw pi-users',
              items: [
              { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
              ]
    
            },
            { label: '',
          items: [
          {label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
        ]
      },
        {
          label: '',
          items: [
          {label: 'Logout', icon: 'pi pi-power-off', command: this.logout}
          ]

        },
      
      ]
    };
  },

  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage

          this.$router.push('/login-page');

        })
        .catch(error => {
          console.log(error);
        });
    },
    displayDeclineDiv(profileId){
    
      console.log(profileId);
      this.showUpdate = true
      this.updateId = profileId
      
    },
    hideUpdateForm() {

this.showUpdate = false;
// this.updateId = null;

},
    async approveDoctor(profileId) {
      // Retrieve the bearer token from local storage
      const token = localStorage.getItem('token');

      // Make a PUT request to approve the doctor
      fetch(`https://backend.ihayanow.com/api/admin/approve/${profileId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.success=true;
          this.message = data.message;
          console.log('Doctor approved:', data);
          window.location.reload();
          this.created();


        })
        .catch((error) => {
          console.error(error);
        });
    },
    async submitDeclineForm(profileId) {
      axios.post('https://backend.ihayanow.com/api/admin/decline-change', {
        profile_id:profileId,
        decline_description:this.description
      },
        
        {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      
      })
        .then(response => {
          console.log(response);
          this.success=true;
          this.message = response.data.message;
          this.hideUpdateForm();
      
          

        }).catch((error) => {
          console.error(error);
        });

   },
    async approveChangeDoctor(profileId) {

      console.log(profileId);
   
    axios.post('https://backend.ihayanow.com/api/admin/approve-change', {profile_id:profileId},
        
        {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      
      })
        .then(response => {
          this.success=true;
          this.message = response.data.message;
 
          if(response.data){
            window.location.reload();
          }
          console.log('Doctor Change approved:', response.data);

        }).catch((error) => {
          console.error(error);
        });

  
    },
    async returnToPending(userId) {
  
       await axios.post('https://backend.ihayanow.com/api/admin/return-doctor-to-pending',{
                user_id: userId
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Api-Key': this.apiKey,
                    'Api-Secret': this.apiSecret
                },
            })
                .then(response => {
                  this.message=response.data.message;
                    console.log(response);
             
                })
                .catch(error => {
                    console.log(error);
                });
    },
    calculateDuration(from, to) {
      const fromParts = from.split(':');
      const toParts = to.split(':');


      const fromSeconds = (+fromParts[0]) * 60 * 60 + (+fromParts[1]) * 60;
      const toSeconds = (+toParts[0]) * 60 * 60 + (+toParts[1]) * 60;
      let diff = toSeconds - fromSeconds;
      if (diff < 0) {
        diff += 24 * 60 * 60; // add 1 day if end time is earlier than start time
      }
      const hours = Math.floor(diff / (60 * 60));
      const minutes = Math.floor((diff / 60) % 60);
      const duration = `${hours} hours ${minutes} minutes`;

      return duration;
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }

  },

 
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'Footer': Footer,
  },

  async created() {
    await axios.get('https://backend.ihayanow.com/api/admin/approved-doctors', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      }).then(response=>{
        this.doctors = response.data;

    console.log(this.doctors);
      }).catch(error =>{
        console.log(error);
      });
 
    await axios.get('https://backend.ihayanow.com/api/admin/pending-doctors', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
      }).then(response=>{
        this.pendings = response.data;
    console.log(this.pendings);
      }).catch(error =>{
        console.log(error);
      });
 


 await axios.get('https://backend.ihayanow.com/api/admin/change-doctor-data',{
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key':this.apiKey,
        'Api-Secret':this.apiSecret,
        }
    }).then(response=>{
      this.changeDoctorData = response.data;
    console.log(this.changeDoctorData);
      }).catch(error =>{
        console.log(error);
      });
  
      
//     axios.get('https://backend.ihayanow.com/api/admin/subscription-doctor', {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
//                 'Api-Key':this.apiKey,
//         'Api-Secret':this.apiSecret,
//             },
//         })
//             .then(response => {
//                 // console.log(response.data);
//                 this.packages = response.data.message;
               
//  console.log(this.packages)
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.log(error.response.data.message);
//                 this.errors = error.response.data.message;

//             });
  },

  computed:{
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }
}


</script>

<style scoped>
.layout-topbar {
   
   background-color: var(--surface-card);}
.changed-cell {
  background-color: red;
}
.doctor-photo {
  width: 150px; /* Adjust the width as per your requirements */
  height: 150px; /* Adjust the height as per your requirements */
  object-fit: cover; /* Ensure the image covers the entire space */
}
</style>