<template>


        <div :key="selectedLanguage" >
    <PrimeMenubar  :model="menu" >
      <template #start>
        <div class="mx-2 layout-topbar">
          <img :src="require('/public/mental_logo.png')" alt="IHAYANOW logo" style="width:70px; height:70px;">
          <span class="text">IHAYANOW</span>
        </div>
      </template>

            <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
    </template>
            <template #end>
              
                <div class="toggle-with-notification">

                <div class="language-toggle " :class="{ 'margin-left-230': this.selectedLanguage == 'en', 'margin-right-230': this.selectedLanguage == 'ar' }">
                        <ToggleSwitchButton class="toggle" v-model="this.isActive" label="AR|EN" @click="toggleLanguage" />

                    </div>
                    </div>
            </template>

        </PrimeMenubar>
    </div>
</template>



<script>
import axios from 'axios';
import ToggleSwitchButton from "toggle-switch-button-vue";

export default {

    data() {
        return {
            isActive: 'AR',
            selectedLanguage: 'ar', // Store the selected language
            supportedLanguages: ['en', 'ar'],// Add the supported languages here
            locale: this.$i18n.locale, // Access the locale directly from $i18n
            isRtl: false, // Set initial value for page direction
            apiKey : process.env.VUE_APP_API_KEY,
         apiSecret : process.env.VUE_APP_API_SECRET,

        };
    },

    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            this.locale = this.selectedLanguage;
            this.updatePageDirection();
        },
        toggleLanguage() {
            this.selectedLanguage = this.selectedLanguage === 'ar' ? 'en' : 'ar';
            this.changeLanguage();
        },
        updatePageDirection() {
            if (this.$i18n.locale === 'ar' || this.selectedLanguage === 'ar') {
                document.documentElement.dir = 'rtl';

            } else {
                document.documentElement.dir = 'ltr';

            }

        },
        async logout(){
            await axios.post('https://backend.ihayanow.com/api/logout',null,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Api-Key':this.apiKey,
                    'Api-Secret':this.apiSecret
                }
            })
                .then(response => {
                    console.log(response);
                    localStorage.clear(); // Clear all items in local storage

                    this.$router.push('/login-page');

                })
                .catch(error => {
                    console.log(error);
                });
        },
    
    },
    created() {
        this.updatePageDirection(); 
    },
    computed: {
        menu() {
            return [

                {
                    label: this.$t('menu.home'), icon: 'pi pi-fw pi-home', route: '/'


                },
                {
                    label: this.$t('menu.Complete Your Data'), icon: 'pi pi-fw pi-pencil', route: "/Complete-registeration"

                },
                {
                    label: this.$t('menu.knowledge'), icon: 'pi pi-fw pi-globe', route: "/knowledgebase-page"

                },
                {
                    label: this.$t('menu.medications'), icon: 'pi pi-fw pi-database', route: "/view-medications"

                },
                {
                    label: this.$t('menu.show_doctors'), icon: 'pi pi-fw pi-users', route: "/guest-therapist-page"

                },
                {
                    label: this.$t('logout'), icon: 'pi pi-power-off', command: this.logout,

                },
            ];
        }

    },
    components: {
        ToggleSwitchButton
    }
}


</script>

<style scoped>

.toggle-switch-button {
    border: none !important;
}

.toggle-switch-button .switch-button-knob {
    border: solid 1px white !important;

}

.toggle-with-notification {
    display: flex;
    align-items: center;
}
.margin-left-230 {
    margin-left: 290px;
}

.margin-right-230 {
    margin-right: 440px;
}
</style>