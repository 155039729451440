import { createApp } from 'vue';
import App from './App.vue'
import PrimeVue from 'primevue/config';

// import axios from 'axios';
// const lang = localStorage.getItem('lang') || 'ar';

// axios.defaults.headers['Accepted-Language'] =lang;
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '../src/assets/styles/layout.scss'
// import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';
import '@fortawesome/vue-fontawesome'
// import '@/fortawesome/fontawesome-free/css/all.css'
// import '../node_modules/fort'
import router from './router';
import InputText from 'primevue/inputtext';
import PrimeCarousel from 'primevue/carousel';
// import PrimeMessage from 'primevue/message'

import PrimeDropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PrimeColumn from 'primevue/column';
import PrimeRating from 'primevue/rating';
import PrimeTextarea from 'primevue/textarea';


import DataView from 'primevue/dataview';
import { FilterService } from 'primevue/api';
import PrimeCheckbox from 'primevue/checkbox';
import PrimeSlider from 'primevue/slider';
import PrimeCalendar from 'primevue/calendar'
import InlineMessage from 'primevue/inlinemessage';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import PrimeButton from 'primevue/button';
import PrimeMessage from 'primevue/message';
import Menubar from 'primevue/menubar';
import PrimeDivider from 'primevue/divider';
import ImageUploader from 'vue-image-upload-resize'
import MegaMenu from 'primevue/megamenu';

import MultiSelect from 'primevue/multiselect';
import InputSwitch from 'primevue/inputswitch';


import Chart from 'primevue/chart';
import PanelMenu from 'primevue/panelmenu';
// import PrimeMultiSelect from 'primevue/multiselect';
// import Vue from 'vue';
// import VueI18n from 'vue-i18n';
import DataTable from 'primevue/datatable';
// import Carousel from 'vue-carousel';

import Dialog from 'primevue/dialog';



/* import the fontawesome core */

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEdit, faEye, faUserFriends, faMapMarker, faHome, faGlobe, faFile, faHourglassStart, faSignOutAlt, faBirthdayCake, faStar, faClock, faCalendarAlt, faMale, faFemale, faWeight, faArrowsAltV, faSmoking } from '@fortawesome/free-solid-svg-icons'

/* import specific icons */
// import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faVideo, faVideoSlash, faMicrophoneLines, faMicrophoneLinesSlash, faChalkboardUser, faUserGear } from '@fortawesome/free-solid-svg-icons'

// import i18n from './i18n'

import i18n from './i18n'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

library.add(faUserSecret,faVideo, faVideoSlash, faMicrophoneLines, faMicrophoneLinesSlash, faChalkboardUser, faUserGear)


library.add(faEdit, faUserFriends, faClock, faHome, faGlobe, faFile, faMapMarker, faStar, faHourglassStart, faEye, faSignOutAlt, faBirthdayCake, faCalendarAlt, faMale, faFemale, faWeight, faArrowsAltV, faSmoking)


const app = createApp(App);
app.use(i18n);
app.use(ImageUploader);
app.use(router);

app.use(VueToast);
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('InputText', InputText);

app.component('PrimeDropdown', PrimeDropdown);
app.component('FileUpload', FileUpload);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('PrimeColumn', PrimeColumn);
app.component('PrimeRating', PrimeRating);
app.component('DataView', DataView);
app.component('PrimeButton', PrimeButton);
app.component('PanelMenu', PanelMenu);
app.component('PrimeMenubar', Menubar);
app.component('PrimeTextarea', PrimeTextarea);


app.component('InlineMessage', InlineMessage);
app.component('PrimeMessage', PrimeMessage);
app.component('PrimeDivider', PrimeDivider);
app.component('PrimeCarousel', PrimeCarousel);
app.component('MegaMenu', MegaMenu);
app.component('InputSwitch', InputSwitch);




app.component('FilterService', FilterService);
app.component('PrimeCheckbox', PrimeCheckbox);
app.component('PrimeSlider', PrimeSlider);
app.component('PrimeCalendar', PrimeCalendar);
app.component('PrimeChart', Chart);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('MultiSelect', MultiSelect);
app.component('PrimeDialog', Dialog);



app.config.productionTip = false
app.mount('#app');

