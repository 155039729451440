<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <!-- <AdminNavbar /> -->
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>
    <div class="layout-main-container">
      <!-- <div class="layout-main"> -->
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <div>
              <h2>Knowledgebase</h2>
              <PrimeMessage severity="success" v-if="this.successMessage">{{ this.successMessage }}
              </PrimeMessage>
              <PrimeMessage severity="error" v-if="this.errorMessage">{{ this.errorMessage }}
              </PrimeMessage>


              <DataTable :value="knowledgebases" class="p-datatable-gridlines" dataKey="id" :rows="10" :paginator="true">

                <PrimeColumn header="Knowlege Image" field="photo" style="min-width: 12rem">
                  <template #body="rowData">

                    <div class="img-container">
                      <img class="doctor-photo" :src="'https://backend.ihayanow.com/' + rowData.data.photo"
                        alt="Knowlege Image" />
                    </div>
                  </template>
                </PrimeColumn>
                <PrimeColumn field="category_id" header="Category" style="min-width: 12rem">
                  <template #body="rowData">
                    {{ getCategoryName(rowData.data.category_id) }}
                  </template>
                </PrimeColumn>
                <PrimeColumn field="author" header="Author" style="min-width: 12rem">
                  {{ rowData.author }}
                </PrimeColumn>
                <PrimeColumn field="title" header="Title" style="min-width: 12rem">
                  {{ rowData.title }}
                </PrimeColumn>
                <PrimeColumn header="Type" field="type" style="min-width: 14rem">

                  {{ rowData.type }}

                </PrimeColumn>

                <PrimeColumn header="Link" field="link" style="min-width: 10rem">
                  <template #body="rowData">
                    <div v-if="rowData.data.link">
                      <a :href="rowData.data.link" v-if="rowData.data.link != null" target="_blank">Knowledgebase
                        Link</a>
                    </div>
                    <div v-else>
                      <p>No Link content available</p>
                    </div>
                  </template>
                </PrimeColumn>
                <PrimeColumn header="Reference" field="reference" style="min-width: 10rem">
                  <template #body="rowData">
                    <div v-if="rowData.data.reference">
                      <a :href="rowData.data.reference" v-if="rowData.data.reference != null"
                        target="_blank">Knowledgebase Reference</a>
                    </div>
                    <div v-else>
                      <p>No reference content available</p>
                    </div>
                  </template>
                </PrimeColumn>

                <PrimeColumn header="Body" field="body" style="min-width: 10rem">
                  <template #body="rowData">
                    <div class="body-preview" v-if="rowData.data.body">
                      <div v-if="!rowData.expanded">
                        <p>{{ truncateText(rowData.data.body, 100) }}</p>
                        <button @click="expandRow(rowData)">Read More</button>
                      </div>
                      <div v-else>
                        <p>{{ rowData.data.body }}</p>
                        <button @click="collapseRow(rowData)">Show Less</button>
                      </div>
                    </div>
                    <div v-else>
                      <p>No body content available</p>
                    </div>
                  </template>
                </PrimeColumn>


                <PrimeColumn header="Created At" field="created_at" style="min-width: 10rem">
                  <template #body="rowData">

                    {{ formatDate(rowData.data.created_at) }}
                  </template>
                </PrimeColumn>
                <PrimeColumn header="Updated At" field="updated_at" style="min-width: 10rem">
                  <template #body="rowData">

                    {{ formatDate(rowData.data.updated_at) }}
                  </template>
                </PrimeColumn>
                <PrimeColumn :exportable="false" style="min-width:8rem">
                  <template #body="rowData">
                    <PrimeButton icon="pi pi-pencil" outlined rounded class="mr-2 mb-2"
                      @click="showUpdateForm(rowData.data.id)" />
                    <PrimeButton icon="pi pi-trash" outlined rounded severity="danger"
                      @click="deleteKnowledgebase(rowData.data.id)" />
                  </template>
                </PrimeColumn>


              </DataTable>


              <!-- <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
 -->

              <div v-if="showUpdate">
                <h3 class="mt-5">Update Knowledgebase</h3>
                <form @submit.prevent="submitUpdateForm">
                  <div class="field col-12 ">
                    <div class="avatar" :style="{ 'background-image': 'url(' + previewphoto + ')' }">
                      <InputText type="file" accept="image/*" hidden @change="uploadProfilePicture" id="avatar" />
                      <label for="avatar" class="avatar-label">Choose image</label>
                    </div>
                    <InlineMessage class="w-full mt-3" v-show="errors && errors.photo"
                      v-for="(error, index) in errors.photo" :key="index">
                      {{ error }}
                    </InlineMessage>
                  </div>
                  <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6">
                      <label>Category:</label>
                      <PrimeDropdown v-model="updatedKnowledgebase.category_id" :options="category_namess"
                        placeholder="Select One">
                        <template #option="{ option }">
                          {{ getCategoryName(option.id) }}
                        </template>
                      </PrimeDropdown>
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.category_id"
                        v-for="(error, index) in errors.category_id" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label>Author:</label>
                      <InputText type="text" v-model="updatedKnowledgebase.author" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.author"
                        v-for="(error, index) in errors.author" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>

                  </div>
                  <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6">
                      <label>Title:</label>
                      <InputText type="text" v-model="updatedKnowledgebase.title" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.title"
                        v-for="(error, index) in errors.title" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6">
                      <label>Type:</label>
                      <PrimeDropdown id="state" v-model="updatedKnowledgebase.type" :options="types" optionLabel="name"
                        placeholder="Select One">
                      </PrimeDropdown>
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.type"
                        v-for="(error, index) in errors.type" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                  </div>
                  <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6"
                      v-if="updatedKnowledgebase.type && (updatedKnowledgebase.type.name === 'Link' || updatedKnowledgebase.type.name === 'Movie' || updatedKnowledgebase.type.name === 'Youtube')">
                      <label for="link" style="font-weight: bold;">Link</label>
                      <InputText id="link" type="text" v-model="updatedKnowledgebase.link" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.link"
                        v-for="(error, index) in errors.link" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                    <div class="field col-12 md:col-6"
                      v-if="updatedKnowledgebase.type && (updatedKnowledgebase.type.name === 'Link')">
                      <label for="reference" style="font-weight: bold;">Reference</label>
                      <InputText id="reference" type="text" v-model="updatedKnowledgebase.reference" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.reference"
                        v-for="(error, index) in errors.reference" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                  </div>
                  <div class="grid p-fluid mt-3">
                    <div class="field col-12"
                      v-if="updatedKnowledgebase.type && (updatedKnowledgebase.type.name === 'Article' || updatedKnowledgebase.type.name === 'Tip')">
                      <label for="body" style="font-weight: bold;">Body</label>
                      <PrimeTextarea placeholder="Your Message" :autoResize="true" rows="9" cols="70"
                        v-model="updatedKnowledgebase.body" />
                      <InlineMessage class="w-full mt-3" v-show="errors && errors.body"
                        v-for="(error, index) in errors.body" :key="index">
                        {{ error }}
                      </InlineMessage>
                    </div>
                  </div>
                  <div class="grid p-fluid mt-3">
                    <div class="field col-12 md:col-6">
                      <PrimeButton type="submit">Update Knowledgebase</PrimeButton>
                    </div>
                    <div class="field col-12 md:col-6">
                      <PrimeButton @click="hideUpdateForm">Cancel</PrimeButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
export default {
  data() {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      apiSecret: process.env.VUE_APP_API_SECRET,
      previewphoto: "",
      errors: {},
      data: [],
      knowledgebases: [],
      category_names: [],
      category_namess: [],
      errorMessage: '',
      successMessage: '',
      showUpdate: false,
      updateId: null,
      updatedKnowledgebase: null,
      updatedCategoryNames: null,
      type: null,
      author: null,
      title: null,
      link: '',
      reference: '',
      knowfile: '',
      photo: '',
      updatedknowfile: '',
      updatedphoto: '',
      types: [
        { name: 'Article' },
        { name: 'Link' },
        { name: 'Tip' },
        { name: 'Movie' },
        { name: 'Youtube' },
      ],
      layoutMode: 'static',

      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: 'Home', icon: 'pi pi-fw pi-home',
          items: [
            { label: 'Admin Home', icon: 'pi pi-fw pi-users', to: '/admin' },
            // { label: 'Home Page', icon: 'pi pi-fw pi-home', to: '/' },

          ]

        },
        {
          label: 'Promocodes', icon: 'pi pi-fw pi-ticket',
          items: [
            { label: 'Create Promocode', icon: 'pi pi-fw pi-plus', to: '/create-promocode' },
            { label: 'Promocodes', icon: 'pi pi-fw pi-tags', to: '/promocode-page' },

          ]
        },
        {
          label: 'Knowledgebase ', icon: 'pi pi-fw pi-book',
          items: [

            { label: 'Add Category', icon: 'pi pi-fw pi-plus', to: "/add-knowledgebase-category" },
            { label: 'Show Category', icon: 'pi pi-fw pi-eye', to: "/show-knowledgebase-category" },
            { label: 'Create Knowledgebase', icon: 'pi pi-fw pi-pencil', to: "/create-knowledgebase-page" },

            { label: 'Show  Knowledgebase', icon: 'pi pi-fw pi-list', to: "/admin-knowledgebase-page" }
          ]
        },
        {
          label: 'Medication', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Medications', icon: 'pi pi-fw pi-plus', to: '/add-medications' },
            { label: 'Show Medications', icon: 'pi pi-fw pi-eye', to: '/medications-page' }
          ]
        },
        {
          label: 'Doctors', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'Show Doctors', icon: 'pi pi-fw pi-users', to: "/accepted-doctors" },
            // { label: 'Show Most Reserved Doctors', icon: 'pi pi-fw pi-users', to: "/most-reserved-doctors" },

          ]
        },
        {
          label: ' Patient Label', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Add Patient Label', icon: 'pi pi-fw pi-plus', to: "/add-label-patient" },
            { label: 'Show Patient Label', icon: 'pi pi-fw pi-eye', to: "label-patient" }
          ]
        },
        {
          label: 'Levels & Packages', icon: 'pi pi-fw pi-globe',
          items: [
            { label: 'Add Level', icon: 'pi pi-fw pi-plus', to: '/add-levels' },
            { label: 'Show Levels', icon: 'pi pi-fw pi-eye', to: '/show-levels' },
            { label: 'Add Packages', icon: 'pi pi-fw pi-plus', to: '/add-subscriptions' },
            { label: 'Show Packages', icon: 'pi pi-fw pi-eye', to: '/show-subscriptions' },
          ]
        },
        {
          label: 'Sessions', icon: 'pi pi-fw pi-clock',
          items: [
            { label: 'Show  Sessions', icon: 'pi pi-fw pi-list', to: "/sessions" },


          ]
        },

        {
          label: 'Reports', icon: 'pi pi-fw pi-file',
          items: [
            { label: 'Show  Reports', icon: 'pi pi-fw pi-list', to: "/reports" },


          ]
        },
        {
          label: '', icon: 'pi pi-fw pi-users',
          items: [
            { label: 'All Users', icon: 'pi pi-fw pi-users', to: '/all-users' },
          ]

        },
        {
          label: '',
          items: [
            { label: 'Generate Credentials', icon: 'pi pi-fw pi-plus', to: '/generate-credentials' }
          ]
        },
        {
          label: '',
          items: [
            { label: 'Logout', icon: 'pi pi-power-off', command: this.logout }
          ]

        },
      ]

    };
  },
  mounted() {
    this.fetchKnowledgebases();
  },

  methods: {
    async logout() {
      await axios.post('https://backend.ihayanow.com/api/logout', null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          localStorage.clear(); // Clear all items in local storage
          this.$router.push('/login-page');
        })
        .catch(error => {
          console.log(error);
        });
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    expandRow(rowData) {
      rowData.expanded = true;
    },
    collapseRow(rowData) {
      rowData.expanded = false;
    },
    getCategoryName(categoryId) {

      const category = this.category_namess.find(knowledgebase => knowledgebase.id === categoryId);
      return category ? category.name.name : '';
    },

    uploadProfilePicture(event) {
      this.photo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.photo);
      reader.onload = () => {
        this.previewphoto = reader.result;
      }
    },

    async fetchKnowledgebases() {
      try {
        await axios.get('https://backend.ihayanow.com/api/knowledgebases', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        }).then(response => {
          this.knowledgebases = this.organizeByCategoryId(response.data);
          this.updatedKnowledgebase = this.knowledgebases;

          this.category_namess = response.data.map(item => ({
            id: item.category_name.id,
            name: item.category_name
          }));
        })
          .catch(error => {
            this.errors = error.response.data.error;
     
          });
      } catch (error) {
        console.log('Error fetching Knowledgebases:', error);
      }
    },
    organizeByCategoryId(knowledgebases) {
      const organizedData = [];
      knowledgebases.forEach(item => {
        organizedData.push(...item.knowledgebases);
      });
      return organizedData;
    },

    showUpdateForm(id) {
      this.showUpdate = true
      this.updateId = id
      this.updatedKnowledgebase =
        this.knowledgebases.find(knowledgebase => knowledgebase.id === id);

    },


    hideUpdateForm() {

      this.showUpdate = false;
      this.updateId = null;

    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    async submitUpdateForm() {
      if (this.updatedKnowledgebase.link == '') {
        this.updatedKnowledgebase.link = null;
      }
      if (this.updatedKnowledgebase.reference == '') {
        this.updatedKnowledgebase.reference = null;
      }

      await axios.post('https://backend.ihayanow.com/api/admin/knowledgebases_update', {
        id: this.updatedKnowledgebase.id,
        type: this.updatedKnowledgebase.type,
        title: this.updatedKnowledgebase.title,
        link: this.updatedKnowledgebase.link,
        reference: this.updatedKnowledgebase.reference,
        author: this.updatedKnowledgebase.author,
        category_id: this.updatedKnowledgebase.category_id,
        photo: this.photo,
        body:this.updatedKnowledgebase.body

      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Api-Key': this.apiKey,
          'Api-Secret': this.apiSecret,
        }
      })
        .then(response => {
          console.log(response);
          this.successMessage = 'Knowledgebase updated successfully.';
          this.fetchKnowledgebases();
          this.hideUpdateForm();
    
        })
        .catch(error => {
       console.log(error);
          this.errors = error.response.data.errors;
          console.log(error.response.data.errors);
              
            

        });
    },


    async deleteKnowledgebase(knowledgebaseId) {
      try {
        const response = await fetch(`https://backend.ihayanow.com/api/admin/knowledgebases/${knowledgebaseId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Api-Key': this.apiKey,
            'Api-Secret': this.apiSecret,
          },
        });

        if (response.ok) {
          this.errorMessage = 'Knowledgebase deleted successfully.';
          this.showUpdateForm = false;
          this.fetchKnowledgebases();
        } else {
      
          this.errorMessage = 'Failed to delete knowledgebase.';
        }
      } catch (error) {
        console.error('Error deleting knowledgebase:', error);
        this.errorMessage = 'Failed to delete knowledgebase.';
      }
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },


  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
  },


  computed: {

    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
  }

}

</script>

<style scoped>
.layout-topbar {

  background-color: var(--surface-card);
}

.doctor-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.body-preview {
  max-height: 80px;
  /* Adjust the height as per your requirements */
  overflow: hidden;
}

.body-preview p {
  margin-bottom: 5px;
}

.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
}

.avatar-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.avatar-upload {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.body-preview button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
}
</style>